import Section1 from "./section1";
import Section1v1 from "./section1v1";
import Section2 from "./section2";
import Section2v1 from "./section2v1";
import Section3v1 from "./section3v1";

export default function About() {
    return (
        <>
            <Section1v1 />
            <Section2v1 />
            <Section3v1 />
        </>
    );
}
