import React, { useEffect, useMemo, useState } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import SelectInput from "../../../Components/Extra/Input/SelectInput";
import { ModalContext } from "../../../Components/Extra/Modal/ModalContext";
import {
    CustomOption,
    SingleOption,
} from "../../../Components/Extra/Select/Select";
import translator from "../../../Components/Extra/Translation/Translate";
import { IColdWalletOrderPayload } from "../../../Models/ColdWalletOrder";
import Option from "../../../Models/Select";
import User from "../../../Models/User";
import alert_message from "../../../Services/Alert/AlertMessage";
import ColdWalletOrderService from "../../../Services/ColdWalletOrder/ColdWalletOrderService";
import {
    KpSelectOption,
    createSelectOption,
} from "../../Components/Inputs/KpSelect";
import { useAssets } from "../../Provider/AssetsProvider";

const AddressInfo = ({ quantity, user }: { quantity: number; user: User }) => {
    const { translate } = translator();
    const { handleModal } = React.useContext(ModalContext);
    const [processing, setProcessing] = useState<boolean>(false);
    const [country, setCountry] = useState<string>(user.country || "");
    const [city, setCity] = useState<string>(user.city || "");
    const [postalCode, setPostalCode] = useState<string>(user.post_code || "");
    const [address, setAddress] = useState<string>(user.address || "");
    const [phone, setPhone] = useState<string>(user.phone || "");
    const [countryListOptions, setCountryListOptions] = useState<Array<Option>>(
        [],
    );

    const [countryOption, setCountryOption] = React.useState<Option | null>(
        null,
    );
    const { assets } = useAssets();
    const fromOptions = useMemo<KpSelectOption[]>(() => {
        const res = assets.map((asset) => createSelectOption(asset, "crypto"));

        return res;
    }, [assets]);

    const [wallet, setWallet] = useState<KpSelectOption | null>(null);

    const createOrder = (cold_wallet_order: IColdWalletOrderPayload) => {
        setProcessing(true);
        const create = ColdWalletOrderService.create(cold_wallet_order);
        alert_message
            .promise(
                translate(
                    "ColdWallet",
                    "Cold_Wallet_Order_Creation_In_Progress",
                ),
                create,
            )
            .then(() => {
                handleModal();
            })
            .finally(() => {
                setProcessing(false);
            });
    };

    const handleSubmit = (e: any) => {
        e.preventDefault();

        if (wallet) {
            const order: IColdWalletOrderPayload = {
                quantity: quantity,
                country: country,
                city: city,
                postal_code: postalCode,
                address: address,
                phone_number: phone,
                wallet: wallet.value,
            };

            createOrder(order);
        }
    };

    const handleChangeCountry = (selected: Option | null) => {
        setCountryOption(selected);
        if (selected) setCountry(selected.value);
    };

    const getCountryList = async () => {
        const country_list =
            await ColdWalletOrderService.getCountryListOption();
        setCountryListOptions(country_list);
    };

    useEffect(() => {
        getCountryList();
        setCountryOption(countryListOptions[0]);
    }, []);

    return (
        <form onSubmit={(e) => handleSubmit(e)}>
            <div className="flex justify-center items text-center  w-full">
                <div className="">Information</div>
            </div>
            <div className="flex justify-center items  w-full">
                <div className="w-full mt-8 mx-4">
                    <div className="w-full">
                        <div className="intro-y w-full mt-1">
                            <SelectInput
                                id="wallet"
                                options={fromOptions}
                                components={{
                                    Option: CustomOption,
                                    SingleValue: SingleOption,
                                }}
                                value={wallet}
                                onChange={(val) => {
                                    setWallet(val);
                                }}
                            />
                            <label
                                htmlFor="wallet"
                                className="!peer-focus:text-[#053B3B] absolute top-2 left-1 origin-[0] -translate-y-4 scale-75 bg-white focus:bg-transparent px-2 text-sm text-gray-500 duration-300 peer-placeholder-shown:top-1/2 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:scale-100 peer-focus:top-2 peer-focus:-translate-y-4 peer-focus:scale-75 peer-focus:px-2"
                            >
                                {translate("ColdWallet", "Wallet")}
                            </label>
                        </div>
                        <div className="relative z-10 w-full group my-2">
                            {countryListOptions.length > 0 && (
                                <SelectInput
                                    id="country"
                                    options={countryListOptions}
                                    components={{
                                        Option: CustomOption,
                                        SingleValue: SingleOption,
                                    }}
                                    value={countryOption}
                                    onChange={handleChangeCountry}
                                />
                            )}
                            <label
                                htmlFor="country"
                                className="!peer-focus:text-[#053B3B] absolute top-2 left-1 origin-[0] -translate-y-4 scale-75 bg-white focus:bg-transparent px-2 text-sm text-gray-500 duration-300 peer-placeholder-shown:top-1/2 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:scale-100 peer-focus:top-2 peer-focus:-translate-y-4 peer-focus:scale-75 peer-focus:px-2"
                            >
                                {translate("ColdWallet", "Country")}
                            </label>
                        </div>
                        <div className="relative z-0 w-full group my-2">
                            <input
                                type="text"
                                id="city"
                                className="border-1 peer block w-full appearance-none rounded-lg bg-transparent px-2.5 pb-2.5 pt-4 text-sm text-gray-900 focus:outline-none focus:ring-0"
                                placeholder=" "
                                value={city}
                                onChange={(e) => {
                                    setCity(e.target.value);
                                }}
                                required
                            />
                            <label
                                htmlFor="city"
                                className="!peer-focus:text-[#053B3B] absolute top-2 left-1 origin-[0] -translate-y-4 scale-75 bg-white focus:bg-transparent px-2 text-sm text-gray-500 duration-300 peer-placeholder-shown:top-1/2 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:scale-100 peer-focus:top-2 peer-focus:-translate-y-4 peer-focus:scale-75 peer-focus:px-2"
                            >
                                {translate("ColdWallet", "City")}
                            </label>
                        </div>
                        <div className="relative z-0 w-full group my-2">
                            <input
                                type="text"
                                id="postal-code"
                                className="border-1 peer block w-full appearance-none rounded-lg bg-transparent px-2.5 pb-2.5 pt-4 text-sm text-gray-900 focus:outline-none focus:ring-0"
                                placeholder=" "
                                value={postalCode}
                                onChange={(e) => {
                                    setPostalCode(e.target.value);
                                }}
                                required
                            />
                            <label
                                htmlFor="postal-code"
                                className="!peer-focus:text-[#053B3B] absolute top-2 left-1 origin-[0] -translate-y-4 scale-75 bg-white focus:bg-transparent px-2 text-sm text-gray-500 duration-300 peer-placeholder-shown:top-1/2 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:scale-100 peer-focus:top-2 peer-focus:-translate-y-4 peer-focus:scale-75 peer-focus:px-2"
                            >
                                {translate("ColdWallet", "Postal_code")}
                            </label>
                        </div>
                        <div className="relative z-0 w-full group my-2">
                            <input
                                type="text"
                                id="address"
                                className="border-1 peer block w-full appearance-none rounded-lg bg-transparent px-2.5 pb-2.5 pt-4 text-sm text-gray-900 focus:outline-none focus:ring-0"
                                placeholder=" "
                                value={address}
                                onChange={(e) => {
                                    setAddress(e.target.value);
                                }}
                                required
                            />
                            <label
                                htmlFor="address"
                                className="!peer-focus:text-[#053B3B] absolute top-2 left-1 origin-[0] -translate-y-4 scale-75 bg-white focus:bg-transparent px-2 text-sm text-gray-500 duration-300 peer-placeholder-shown:top-1/2 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:scale-100 peer-focus:top-2 peer-focus:-translate-y-4 peer-focus:scale-75 peer-focus:px-2"
                            >
                                {translate("ColdWallet", "Address")}
                            </label>
                        </div>
                        <div className="relative  w-full">
                            <PhoneInput
                                value={
                                    (user.phone_country_code || "") +
                                    (user.phone || "")
                                }
                                excludeCountries={[
                                    "af",
                                    "ir",
                                    "iq",
                                    "kp",
                                    "ly",
                                    "ss",
                                    "sd",
                                    "sy",
                                    "ye",
                                ]}
                                countryCodeEditable={false}
                                enableLongNumbers={15}
                                enableSearch={true}
                                onChange={(phone, data) => {
                                    // setTel(phone);
                                    if (data) {
                                        const dialCode = (data as any).dialCode;
                                        if (dialCode) {
                                            // setPhoneCountryCode(dialCode);
                                            const number = phone
                                                .replace("+", "")
                                                .replace(dialCode, "");
                                            setPhone(number);
                                        }
                                    }
                                }}
                                specialLabel={translate(
                                    "Profil",
                                    "Phone_number",
                                )}
                            />
                            <label
                                htmlFor="select"
                                className="!peer-focus:text-[#053B3B] absolute top-2 left-1  origin-[0] -translate-y-4 scale-75 bg-white focus:bg-transparent px-2 text-sm text-gray-500 duration-300 peer-placeholder-shown:top-1/2 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:scale-100 peer-focus:top-2 peer-focus:-translate-y-4 peer-focus:scale-75 peer-focus:px-2"
                            >
                                {translate("Profil", "Phone_number")}
                            </label>
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex justify-end items md:right w-full">
                <button
                    type="submit"
                    className="btn btn-dark mt-2 mr-2"
                    disabled={processing}
                >
                    {translate("ColdWallet", "Validate")}
                </button>
                <button
                    type="button"
                    className="btn  mt-2"
                    onClick={() => handleModal()}
                    disabled={processing}
                >
                    {translate("Button", "Cancel")}
                </button>
            </div>
        </form>
    );
};

const ColdWalletOrderInfo = ({
    quantity,
    user,
}: {
    quantity: number;
    user: User;
}) => {
    const { translate } = translator();
    const { handleModal } = React.useContext(ModalContext);

    return (
        <div className="mt-2 text-center">
            <button
                type="button"
                className="btn btn-dark mb-2 w-32"
                onClick={() => {
                    handleModal(
                        <AddressInfo quantity={quantity} user={user} />,
                    );
                }}
            >
                {translate("ColdWallet", "Buy")}
            </button>
        </div>
    );
};

export default ColdWalletOrderInfo;
