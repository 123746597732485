import { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import Pricing04 from "../../../images/carte-kalypay/land-fidelity-black.png";
import Pricing02 from "../../../images/carte-kalypay/land-fidelity-gold.png";
import Pricing01 from "../../../images/carte-kalypay/land-fidelity-red.png";
import FidelityModel from "../../../../Models/Fidelity";
import {
    NewConfig,
    NewSubConfig,
} from "../../../../Admin/Components/Fidelity/FidelityForm";
import translator from "../../../../Components/Extra/Translation/Translate";
import FidelityService from "../../../../Services/FidelityService/FidelityService";
import RoutesNames from "../../../../Services/RoutesNames";
import UtilsService from "../../../../Services/UtilsService";

const CardFeatures = ({ card }: { card: FidelityModel | undefined }) => {
    const { translate } = translator();
    let withdraw_atm = 2500;
    let payment = 5500;
    if (card) {
        if (card.name.toUpperCase() === "GOLD") {
            withdraw_atm = 5000;
            payment = 10000;
        }
        if (card.name.toUpperCase() === "BLACk") {
            withdraw_atm = 10000;
            payment = 25000;
        }
    }

    if (!card)
        return (
            <ul className="text-slate-500 text-left space-y-2 blur">
                <li className="flex items-start">
                    <svg
                        className="w-3 h-3 fill-current text-kalypay-500 mr-3 mt-1.5 shrink-0"
                        viewBox="0 0 12 12"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                    </svg>
                    <span>
                        {translate("CARD", "WITHDRAW_LIMIT")}:{" "}
                        {UtilsService.formatAmount(withdraw_atm, 0)}$
                    </span>
                </li>
                <li className="flex items-start">
                    <svg
                        className="w-3 h-3 fill-current text-kalypay-500 mr-3 mt-1.5 shrink-0"
                        viewBox="0 0 12 12"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                    </svg>
                    <span>
                        {translate("CARD", "PAYMENT_LIMIT")}:{" "}
                        {UtilsService.formatAmount(payment, 0)}$
                    </span>
                </li>

                <li className="flex items-start">
                    <svg
                        className="w-3 h-3 fill-current text-kalypay-500 mr-3 mt-1.5 shrink-0"
                        viewBox="0 0 12 12"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                    </svg>
                    <span>Contactless payments</span>
                </li>
                <li className="flex items-start">
                    <svg
                        className="w-3 h-3 fill-current text-kalypay-500 mr-3 mt-1.5 shrink-0"
                        viewBox="0 0 12 12"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                    </svg>
                    <span>Mobile payments</span>
                </li>
                <li className="flex items-start">
                    <svg
                        className="w-3 h-3 fill-current text-kalypay-500 mr-3 mt-1.5 shrink-0"
                        viewBox="0 0 12 12"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                    </svg>
                    <span>Extra card (optional)</span>
                </li>
                <li className="flex items-start">
                    <svg
                        className="w-3 h-3 fill-current text-kalypay-500 mr-3 mt-1.5 shrink-0"
                        viewBox="0 0 12 12"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                    </svg>
                    <span>Free payments worldwide</span>
                </li>
                <li className="flex items-start">
                    <svg
                        className="w-3 h-3 fill-current text-kalypay-500 mr-3 mt-1.5 shrink-0"
                        viewBox="0 0 12 12"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                    </svg>
                    <span>Free domestic ATM withdrawals</span>
                </li>
            </ul>
        );

    const config: NewConfig = JSON.parse(card.configuration);
    return (
        <>
            {card && (
                <ul className="text-slate-500 text-left space-y-2 ">
                    <li className="flex items-start">
                        <svg
                            className="w-3 h-3 fill-current text-kalypay-500 mr-3 mt-1.5 shrink-0"
                            viewBox="0 0 12 12"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                        </svg>
                        <span>
                            {translate("CARD", "WITHDRAW_LIMIT")}:{" "}
                            {UtilsService.formatAmount(withdraw_atm, 0)}$
                        </span>
                    </li>
                    <li className="flex items-start">
                        <svg
                            className="w-3 h-3 fill-current text-kalypay-500 mr-3 mt-1.5 shrink-0"
                            viewBox="0 0 12 12"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                        </svg>
                        <span>
                            {translate("CARD", "PAYMENT_LIMIT")}:{" "}
                            {UtilsService.formatAmount(payment, 0)}$
                        </span>
                    </li>
                    {config.config_list.map(
                        (feature: NewSubConfig, key: number) =>
                            feature.status && (
                                <li className="flex items-start">
                                    <svg
                                        className="w-3 h-3 fill-current text-kalypay-500 mr-3 mt-1.5 shrink-0"
                                        viewBox="0 0 12 12"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                                    </svg>
                                    <span>
                                        {translate(
                                            "Fidelity",
                                            `${feature.type}`,
                                        )}{" "}
                                    </span>
                                </li>
                            ),
                    )}
                </ul>
            )}
        </>
    );
};

export default function Section4() {
    const { translate } = translator();
    const [cards, setCards] = useState<FidelityModel[]>([]);

    const getFidelity = async () => {
        try {
            const data = await FidelityService.getAll();
            setCards(data);
        } catch (err: any) {}
    };

    const red = cards.find((card) => card.name.toLowerCase() === "red");
    const black = cards.find((card) => card.name.toLowerCase() === "black");
    const gold = cards.find((card) => card.name.toLowerCase() === "gold");

    useEffect(() => {
        getFidelity();
    }, []);

    return (
        <section id="cards" className="lg:!mx-20 mx-4">
            <div className="relative w-full mx-auto">
                {/* Bg */}
                <div
                    className="absolute inset-0  mb-24 md:mb-0  pointer-events-none -z-10"
                    aria-hidden="true"
                />
                <div
                    className="absolute inset-0  mb-24 md:mb-0  pointer-events-none -z-10"
                    aria-hidden="true"
                />

                <div className="max-w-7xl mx-auto">
                    <div className="py-12 md:py-20">
                        {/* Section content */}
                        <div className="relative max-w-xl mx-auto md:max-w-none text-center md:text-left">
                            {/* Section header */}
                            <div className="mb-12 md:mb-20" data-aos="fade-up">
                                <h2 className="text-white bg-clip-text mb-3 font-bold md:!text-5xl text-3xl leading-tight tracking-tighter">
                                    {translate(
                                        "LANDING_IBAN",
                                        "section4_titre",
                                    )}
                                </h2>
                                <p className="text-lg text-slate-300 mb-8">
                                    {translate(
                                        "LANDING_IBAN",
                                        "section4_titre_p",
                                    )}
                                </p>
                            </div>

                            {/* Pricing tables */}
                            <div
                                className="max-w-sm md:max-w-2xl xl:max-w-none mx-auto grid gap-8 md:grid-cols-2 xl:grid-cols-3 xl:gap-6 items-start"
                                data-aos="fade-up"
                                data-aos-delay="100"
                            >
                                {/* Pricing table 1 */}
                                <div className="relative flex flex-col h-full bg-slate-800 rounded-2xl py-8 px-6">
                                    <div className="mb-4">
                                        <div className="text-center text-slate-300 mb-3">
                                            <h4 className="font-bold text-lg">
                                                {" "}
                                                Red
                                            </h4>
                                            <p>
                                                {translate(
                                                    "LANDING_IBAN",
                                                    "section4_red_p",
                                                )}
                                            </p>
                                        </div>
                                        <img
                                            className="w-52 mx-auto rounded-full "
                                            src={Pricing01}
                                            alt="Red"
                                        />
                                    </div>
                                    <div className="mb-5">
                                        <div className="text-2xl text-slate-300 font-bold text-center mb-4">
                                            ${red?.price || 0}/m
                                        </div>
                                        <NavLink
                                            to={RoutesNames.Loaylty}
                                            className="btn-sm w-full inline-flex items-center text-black bg-kalypay-500 hover:bg-[#ffd67c] shadow-sm"
                                        >
                                            {translate("LANDING_IBAN", "get")}{" "}
                                            Red
                                        </NavLink>
                                    </div>
                                    <div className="text-slate-100 font-medium mb-4">
                                        {translate("LANDING_IBAN", "features")}
                                    </div>
                                    <CardFeatures card={red} />
                                </div>

                                {/* Pricing table 2 */}
                                <div className="relative flex flex-col h-full bg-gradient-to-b from-yellow-200 to-blue-50 rounded-2xl py-8 px-6">
                                    <div className="absolute top-0 right-0 -translate-y-1/2 mr-6 inline-flex text-sm text-black bg-kalypay-500 font-[550] rounded-full px-3 py-px">
                                        {translate("LANDING_IBAN", "popular")}
                                    </div>
                                    <div className="mb-4">
                                        <div className="text-center mb-3">
                                            <h4 className="font-bold text-lg">
                                                {" "}
                                                Gold
                                            </h4>
                                            <p>
                                                {translate(
                                                    "LANDING_IBAN",
                                                    "section4_gold_p",
                                                )}
                                            </p>
                                        </div>
                                        <img
                                            className="w-52 mx-auto rounded-full "
                                            src={Pricing02}
                                            alt="GoldGold"
                                        />
                                    </div>
                                    <div className="mb-5">
                                        <div className="text-2xl text-slate-800 font-bold text-center mb-4">
                                            ${gold?.price || 0}/m
                                        </div>
                                        <NavLink
                                            to={RoutesNames.Loaylty}
                                            className="btn-sm w-full inline-flex items-center text-slate-100 bg-slate-800 hover:bg-slate-900 shadow-sm"
                                        >
                                            {translate("LANDING_IBAN", "get")}{" "}
                                            Gold
                                        </NavLink>
                                    </div>
                                    <div className="text-slate-800 font-medium mb-4">
                                        {translate("LANDING_IBAN", "features")}
                                    </div>
                                    <CardFeatures card={gold} />
                                </div>

                                {/* Pricing table 4 */}
                                <div className="relative flex flex-col h-full bg-slate-800 rounded-2xl py-8 px-6">
                                    <div className="mb-4">
                                        <div className="text-center text-slate-300 mb-3">
                                            <h4 className="font-bold text-lg">
                                                {" "}
                                                Black
                                            </h4>
                                            <p>
                                                {translate(
                                                    "LANDING_IBAN",
                                                    "section4_black_p",
                                                )}
                                            </p>
                                        </div>
                                        <img
                                            className="w-52 mx-auto rounded-full "
                                            src={Pricing04}
                                            alt="Black"
                                        />
                                    </div>
                                    <div className="mb-5">
                                        <div className="text-2xl text-slate-300 font-bold text-center mb-4">
                                            ${black?.price || 0}/m
                                        </div>
                                        <NavLink
                                            to={RoutesNames.Loaylty}
                                            className="btn-sm w-full inline-flex items-center text-black bg-kalypay-500 hover:bg-[#ffd67c] shadow-sm"
                                        >
                                            {translate("LANDING_IBAN", "get")}{" "}
                                            Black
                                        </NavLink>
                                    </div>
                                    <div className="text-slate-100 font-medium mb-4">
                                        {translate("LANDING_IBAN", "features")}
                                    </div>
                                    <CardFeatures card={black} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
