import Section1 from "./section1";
import Section2 from "./section2";
import Section3 from "./section3";
import Section4 from "./section4";
import Section5 from "./section5";

export default function Ambassador() {
    return (
        <div className="p-4 text-white" id="ambassador">
            <div className="max-w-7xl mx-auto px-4 py-4">
                <Section1 />
                <Section2 />
                <Section3 />
                <Section4 />
                <Section5 />
            </div>
        </div>
    );
}
