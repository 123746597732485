import translator from "../../../Components/Extra/Translation/Translate";
import useAuthentication from "../../../Services/Authentication/useAuthentication";
import Cta from "../fintech/cta";
import Illustration from "../../images/hero-illustration-kp.png";

const CGV = () => {
    const { translate } = translator();
    const { isAuthenticated } = useAuthentication();
    return (
        <>
            {/* Page Title */}
            <section className="relative z-10 h-[50vh] overflow-hidden">
                {/* Bg */}
                <div
                    className="absolute inset-0 rounded-bl-[100px] mb-28 md:mb-0 pointer-events-none -z-10"
                    aria-hidden="true"
                />

                <div className="max-w-[70%] mx-auto px-4 sm:px-6">
                    <div className="pt-36 pb-14">
                        {/* Page Title content */}
                        <div className="relative max-w-xl mx-auto md:max-w-none text-center md:text-left">
                            {/* Content */}
                            <div className="">
                                {/* Copy */}
                                <h1
                                    className="h1 text-white md:mt-10 mt-6 text-center"
                                    data-aos="fade-up"
                                    data-aos-delay="100"
                                    dangerouslySetInnerHTML={{
                                        __html: translate(
                                            "INFORMATIONS",
                                            "CGV_LEGALE_TITLE",
                                        ),
                                    }}
                                />
                            </div>

                            {/* Image */}
                            <div className="max-w-sm mx-auto md:max-w-none md:absolute md:left-[90%] md:top-0 -mb-12 md:-mt-12 md:mb-0">
                                <div className="relative md:-ml-3 md:-mr-24 md:mx-0">
                                    <img
                                        className="absolute opacity-50 top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 mt-16 md:mt-0 pointer-events-none -z-10 max-w-none mix-blend-lighten"
                                        src={Illustration}
                                        alt="Hero illustration"
                                        aria-hidden="true"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* Contenu Page */}
            <section className="-mt-36 text-white" id="transfert" data-aos-id-3>
                <div className="relative max-w-8xl mx-auto">
                    {/* Bg */}
                    <div
                        className="absolute inset-0 md:rounded-tl-[100px] mb-24 md:mb-0  pointer-events-none -z-10"
                        aria-hidden="true"
                    />

                    <div className="max-w-6xl mx-auto px-4 sm:px-6">
                        <div className="pb-6 pt-12 md:pt-20">
                            <div className="mb-12 md:mb-20" data-aos="fade-up">
                                <div className="text-base">
                                    <p
                                        style={{ textAlign: "left" }}
                                        className="mb-4"
                                    >
                                        {translate(
                                            "CGV",
                                            "This_site_is_operated_by",
                                        )}{" "}
                                        <strong>KALYSSI Europe</strong>.{" "}
                                        {translate(
                                            "CGV",
                                            "Throughout_the_site_the_terms_",
                                        )}{" "}
                                        <strong>KALYSSI Europe</strong>.
                                    </p>
                                    <p
                                        style={{ textAlign: "left" }}
                                        className="mb-4"
                                    >
                                        {translate(
                                            "CGV",
                                            "This_website_including_all_information_",
                                        )}
                                    </p>
                                    <p
                                        style={{ textAlign: "left" }}
                                        className="mb-4"
                                    >
                                        {translate(
                                            "CGV",
                                            "Please_read_these_Terms_of_Use_carefully_",
                                        )}
                                    </p>
                                    <p
                                        style={{ textAlign: "left" }}
                                        className="mb-4"
                                    >
                                        {translate(
                                            "CGV",
                                            "By_visiting_our_site_and_",
                                        )}
                                    </p>
                                    <p
                                        style={{ textAlign: "left" }}
                                        className="mb-4"
                                    >
                                        {translate(
                                            "CGV",
                                            "Each_new_tool_or_feature_that_",
                                        )}
                                    </p>
                                    <p
                                        style={{ textAlign: "left" }}
                                        className="mb-4 mt-10"
                                    >
                                        <strong>
                                            {translate("CGV", "ARTICLE_1")}
                                        </strong>{" "}
                                        –{" "}
                                        {translate(
                                            "CGV",
                                            "TERMS_OF_USE_OF_OUR_SITE",
                                        )}
                                    </p>
                                    <p
                                        style={{ textAlign: "left" }}
                                        className="mb-4"
                                    >
                                        {translate(
                                            "CGV",
                                            "By_agreeing_to_these_Terms_of_Use_",
                                        )}
                                    </p>
                                    <p
                                        style={{ textAlign: "left" }}
                                        className="mb-4"
                                    >
                                        {translate(
                                            "CGV",
                                            "You_must_not_transmit_any_computer_",
                                        )}
                                    </p>
                                    <p
                                        style={{ textAlign: "left" }}
                                        className="mb-4"
                                    >
                                        {translate(
                                            "CGV",
                                            "A_breach_or_violation_of_any_of_the_",
                                        )}
                                    </p>
                                    <p
                                        style={{ textAlign: "left" }}
                                        className="mb-4 mt-10"
                                    >
                                        <strong>
                                            {translate("CGV", "ARTICLE_2")}
                                        </strong>{" "}
                                        –
                                        {translate(
                                            "CGV",
                                            "TERMS_AND_CONDITIONS",
                                        )}
                                    </p>
                                    <p
                                        style={{ textAlign: "left" }}
                                        className="mb-4"
                                    >
                                        {translate(
                                            "CGV",
                                            "You_understand_that_your_content_",
                                        )}{" "}
                                        :
                                    </p>
                                    <p
                                        style={{ textAlign: "left" }}
                                        className="mb-4"
                                    >
                                        {translate(
                                            "CGV",
                                            "Transmissions_over_multiple_networks_",
                                        )}
                                    </p>
                                    <p
                                        style={{ textAlign: "left" }}
                                        className="mb-4"
                                    >
                                        {translate(
                                            "CGV",
                                            "You_agree_not_to_reproduce_",
                                        )}
                                    </p>
                                    <p
                                        style={{ textAlign: "left" }}
                                        className="mb-4"
                                    >
                                        {translate(
                                            "CGV",
                                            "The_headings_used_in_this_agreement_",
                                        )}
                                    </p>
                                    <p
                                        style={{ textAlign: "left" }}
                                        className="mb-4 mt-10"
                                    >
                                        <strong>
                                            {translate("CGV", "ARTICLE_3")}
                                        </strong>{" "}
                                        –{" "}
                                        {translate(
                                            "CGV",
                                            "INTELLECTUAL_PROPERTY",
                                        )}
                                    </p>
                                    <p
                                        style={{ textAlign: "left" }}
                                        className="mb-4"
                                    >
                                        {translate(
                                            "CGV",
                                            "All_images_drawings_texts_",
                                        )}
                                    </p>
                                    <p
                                        style={{ textAlign: "left" }}
                                        className="mb-4"
                                    >
                                        {translate(
                                            "CGV",
                                            "You_may_not_use_our_products_for_any_",
                                        )}
                                    </p>
                                    <p>
                                        <strong></strong>
                                    </p>
                                    <p> </p>
                                    <p
                                        style={{ textAlign: "left" }}
                                        className="mb-4"
                                    >
                                        <strong>
                                            {translate("CGV", "ARTICLE_4")}
                                        </strong>{" "}
                                        –
                                        {translate(
                                            "CGV",
                                            "ACCURACY_COMPLETENESS_AND_CURRENT_INFORMATION",
                                        )}
                                    </p>
                                    <p
                                        style={{ textAlign: "left" }}
                                        className="mb-4"
                                    >
                                        {translate(
                                            "CGV",
                                            "We_cannot_be_held_responsible_if_",
                                        )}
                                    </p>
                                    <p
                                        style={{ textAlign: "left" }}
                                        className="mb-4"
                                    >
                                        {translate(
                                            "CGV",
                                            "This_site_may_contain_certain_historical_",
                                        )}
                                    </p>
                                    <p
                                        style={{ textAlign: "left" }}
                                        className="mb-4 mt-10"
                                    >
                                        <strong>
                                            {translate("CGV", "ARTICLE_5")}
                                        </strong>{" "}
                                        –
                                        {translate(
                                            "CGV",
                                            "MODIFICATION_OF_THE_SERVICE_AND_PRICES",
                                        )}
                                    </p>
                                    <p
                                        style={{ textAlign: "left" }}
                                        className="mb-4"
                                    >
                                        {translate(
                                            "CGV",
                                            "The_prices_of_our_products_and_",
                                        )}
                                        <br />
                                        {translate(
                                            "CGV",
                                            "We_reserve_the_right_to_modify_",
                                        )}
                                        <br />
                                        {translate(
                                            "CGV",
                                            "We_cannot_be_held_responsible_",
                                        )}
                                    </p>
                                    <p
                                        style={{ textAlign: "left" }}
                                        className="mb-4 mt-10"
                                    >
                                        <strong>
                                            {translate("CGV", "ARTICLE_6")}
                                        </strong>{" "}
                                        –
                                        {translate(
                                            "CGV",
                                            "PRODUCTS_OR_SERVICES",
                                        )}
                                    </p>
                                    <p
                                        style={{ textAlign: "left" }}
                                        className="mb-4"
                                    >
                                        {translate(
                                            "CGV",
                                            "As_part_of_the_use_of_the_products_",
                                        )}
                                    </p>
                                    <p
                                        style={{ textAlign: "left" }}
                                        className="mb-4"
                                    ></p>
                                    <p
                                        style={{ textAlign: "left" }}
                                        className="mb-4"
                                    >
                                        <strong></strong>
                                    </p>
                                    <p
                                        style={{ textAlign: "left" }}
                                        className="mb-4"
                                    >
                                        <strong></strong>
                                    </p>
                                    <p
                                        style={{ textAlign: "left" }}
                                        className="mb-4"
                                    >
                                        <strong>
                                            {translate("CGV", "ARTICLE_7")}
                                        </strong>{" "}
                                        –
                                        {translate(
                                            "CGV",
                                            "BILLING_ACCURACY_AND_ACCOUNT_INFORMATION",
                                        )}
                                    </p>
                                    <p
                                        style={{ textAlign: "left" }}
                                        className="mb-4"
                                    >
                                        {translate(
                                            "CGV",
                                            "We_reserve_the_right_to_refuse_any_",
                                        )}
                                    </p>
                                    <p
                                        style={{ textAlign: "left" }}
                                        className="mb-4"
                                    >
                                        {translate(
                                            "CGV",
                                            "In_the_event_that_we_make_",
                                        )}
                                    </p>
                                    <p
                                        style={{ textAlign: "left" }}
                                        className="mb-4"
                                    >
                                        {translate(
                                            "CGV",
                                            "You_agree_to_provide_current_",
                                        )}
                                    </p>
                                    <p
                                        style={{ textAlign: "left" }}
                                        className="mb-4 mt-10"
                                    >
                                        <strong>
                                            {translate("CGV", "ARTICLE_8")}
                                        </strong>{" "}
                                        – {translate("CGV", "OPTIONAL_TOOLS")}
                                    </p>
                                    <p
                                        style={{ textAlign: "left" }}
                                        className="mb-4"
                                    >
                                        {translate(
                                            "CGV",
                                            "We_may_provide_you_with_",
                                        )}
                                    </p>
                                    <p
                                        style={{ textAlign: "left" }}
                                        className="mb-4"
                                    >
                                        {translate(
                                            "CGV",
                                            "You_acknowledge_and_agree_",
                                        )}
                                    </p>
                                    <p
                                        style={{ textAlign: "left" }}
                                        className="mb-4"
                                    >
                                        {translate(
                                            "CGV",
                                            "Any_use_by_you_of_optional_",
                                        )}
                                    </p>
                                    <p
                                        style={{ textAlign: "left" }}
                                        className="mb-4"
                                    >
                                        {translate(
                                            "CGV",
                                            "We_may_also_in_the_future_",
                                        )}
                                    </p>
                                    <p
                                        style={{ textAlign: "left" }}
                                        className="mb-4 mt-10"
                                    >
                                        <strong>
                                            {translate("CGV", "ARTICLE_9")}
                                        </strong>{" "}
                                        –{" "}
                                        {translate("CGV", "THIRD_PARTY_LINKS")}
                                    </p>
                                    <p
                                        style={{ textAlign: "left" }}
                                        className="mb-4"
                                    >
                                        {translate(
                                            "CGV",
                                            "Certain_content_products_and_",
                                        )}
                                    </p>
                                    <p
                                        style={{ textAlign: "left" }}
                                        className="mb-4"
                                    >
                                        {translate(
                                            "CGV",
                                            "Third_party_links_on_this_site_may_",
                                        )}
                                    </p>
                                    <p
                                        style={{ textAlign: "left" }}
                                        className="mb-4"
                                    >
                                        {translate(
                                            "CGV",
                                            "We_are_not_liable_for_any_harm_",
                                        )}
                                    </p>
                                    <p
                                        style={{ textAlign: "left" }}
                                        className="mb-4 mt-10"
                                    >
                                        <strong>
                                            {translate("CGV", "ARTICLE_10")}
                                        </strong>
                                        –
                                        {translate(
                                            "CGV",
                                            "WAIVER_OF_THE_RIGHT_OF_WITHDRAWAL",
                                        )}
                                    </p>
                                    <p
                                        style={{ textAlign: "left" }}
                                        className="mb-4"
                                    >
                                        {translate(
                                            "CGV",
                                            "In_accordance_with_article_L_",
                                        )}
                                    </p>
                                    <p
                                        style={{ textAlign: "left" }}
                                        className="mb-4"
                                    >
                                        {translate(
                                            "CGV",
                                            "The_customer_is_informed_that_",
                                        )}{" "}
                                        :
                                    </p>
                                    <p
                                        style={{ textAlign: "left" }}
                                        className="mb-4"
                                    >
                                        {translate(
                                            "CGV",
                                            "The_supply_of_goods_or_services_",
                                        )}
                                    </p>
                                    <p
                                        style={{ textAlign: "left" }}
                                        className="mb-4"
                                    >
                                        {translate(
                                            "CGV",
                                            "Supply_of_digital_content_not_",
                                        )}
                                    </p>
                                    <p
                                        style={{ textAlign: "left" }}
                                        className="mb-4"
                                    >
                                        {translate(
                                            "CGV",
                                            "By_accepting_these_general_conditions_",
                                        )}
                                    </p>
                                    <p
                                        style={{ textAlign: "left" }}
                                        className="mb-4"
                                    ></p>
                                    <p
                                        style={{ textAlign: "left" }}
                                        className="mb-4"
                                    ></p>
                                    <p
                                        style={{ textAlign: "left" }}
                                        className="mb-4"
                                    ></p>
                                    <p
                                        style={{ textAlign: "left" }}
                                        className="mb-4"
                                    >
                                        <strong>
                                            {translate("CGV", "ARTICLE_11")}
                                        </strong>
                                        –{translate("CGV", "HAZARD_WARNING")}
                                    </p>
                                    <p
                                        style={{ textAlign: "left" }}
                                        className="mb-4"
                                    >
                                        {translate(
                                            "CGV",
                                            "The_products_and_services_offered_",
                                        )}
                                    </p>
                                    <p
                                        style={{ textAlign: "left" }}
                                        className="mb-4"
                                    >
                                        {translate(
                                            "CGV",
                                            "Digital_asset_markets_are_",
                                        )}
                                    </p>
                                    <p
                                        style={{ textAlign: "left" }}
                                        className="mb-4"
                                    >
                                        {translate(
                                            "CGV",
                                            "The_products_and_services_offered_by_",
                                        )}
                                    </p>
                                    <p
                                        style={{ textAlign: "left" }}
                                        className="mb-4"
                                    >
                                        {translate(
                                            "CGV",
                                            "Past_performance_of_digital_assets_",
                                        )}
                                    </p>
                                    <p
                                        style={{ textAlign: "left" }}
                                        className="mb-4 mt-10"
                                    >
                                        <strong>
                                            {translate("CGV", "ARTICLE_12")}
                                        </strong>
                                        –
                                        {translate(
                                            "CGV",
                                            "ACCEPTANCE_OF_RISKS",
                                        )}
                                    </p>
                                    <p
                                        style={{ textAlign: "left" }}
                                        className="mb-4"
                                    >
                                        {translate(
                                            "CGV",
                                            "The_customer_acknowledges_that_",
                                        )}
                                    </p>
                                    <p
                                        style={{ textAlign: "left" }}
                                        className="mb-4"
                                    >
                                        {translate(
                                            "CGV",
                                            "The_client_acknowledges_and_",
                                        )}
                                    </p>
                                    <p
                                        style={{ textAlign: "left" }}
                                        className="mb-4"
                                    >
                                        {translate(
                                            "CGV",
                                            "By_accepting_these_general_conditions_the_",
                                        )}
                                    </p>
                                    <p
                                        style={{ textAlign: "left" }}
                                        className="mb-4 mt-10"
                                    >
                                        <strong>
                                            {translate("CGV", "ARTICLE_13")}
                                        </strong>{" "}
                                        –
                                        {translate(
                                            "CGV",
                                            "PERSONAL_INFORMATION",
                                        )}
                                    </p>
                                    <p
                                        style={{ textAlign: "left" }}
                                        className="mb-4"
                                    >
                                        {translate(
                                            "CGV",
                                            "Your_submission_of_personal_information_",
                                        )}{" "}
                                        <a href="https://perfectnodes.com/privacy-policy/">
                                            {translate("CGV", "Privacy_Policy")}
                                        </a>
                                        .
                                    </p>
                                    <p
                                        style={{ textAlign: "left" }}
                                        className="mb-4 mt-10"
                                    >
                                        <strong>
                                            {translate("CGV", "ARTICLE_14")}
                                        </strong>{" "}
                                        –{" "}
                                        {translate(
                                            "CGV",
                                            "ERRORS_INACCURACIES_AND_OMISSIONS",
                                        )}
                                    </p>
                                    <p
                                        style={{ textAlign: "left" }}
                                        className="mb-4"
                                    >
                                        {translate(
                                            "CGV",
                                            "Occasionally_there_may_be_information_",
                                        )}
                                    </p>
                                    <p
                                        style={{ textAlign: "left" }}
                                        className="mb-4"
                                    >
                                        {translate(
                                            "CGV",
                                            "We_undertake_no_obligation_to_update_",
                                        )}
                                    </p>
                                    <p>
                                        <strong></strong>
                                    </p>
                                    <p>
                                        <strong></strong>
                                    </p>
                                    <p>
                                        <strong></strong>
                                    </p>
                                    <p
                                        style={{ textAlign: "left" }}
                                        className="mb-4"
                                    >
                                        <strong>
                                            {translate("CGV", "ARTICLE_15")}
                                        </strong>{" "}
                                        –{translate("CGV", "PROHIBITED_USES")}
                                    </p>
                                    <p
                                        style={{ textAlign: "left" }}
                                        className="mb-4"
                                    >
                                        {translate(
                                            "CGV",
                                            "In_addition_to_other_prohibitions_as_set_",
                                        )}{" "}
                                        :
                                        <br />
                                        {translate(
                                            "CGV",
                                            "For_illegal_purposes_",
                                        )}
                                    </p>
                                    <p
                                        style={{ textAlign: "left" }}
                                        className="mb-4"
                                    >
                                        {translate(
                                            "CGV",
                                            "You_must_not_use_our_products_and_",
                                        )}
                                    </p>
                                    <p
                                        style={{ textAlign: "left" }}
                                        className="mb-4"
                                    >
                                        {translate(
                                            "CGV",
                                            "A_breach_or_violation_of_any_rules_or_",
                                        )}
                                    </p>
                                    <p
                                        style={{ textAlign: "left" }}
                                        className="mb-4 mt-10"
                                    >
                                        <strong>
                                            {translate("CGV", "ARTICLE_16")}
                                        </strong>{" "}
                                        –{" "}
                                        {translate(
                                            "CGV",
                                            "LIMITATION_AND_EXCLUSIONS_OF_RESPONSIBILITIES",
                                        )}
                                    </p>
                                    <p
                                        style={{ textAlign: "left" }}
                                        className="mb-4"
                                    >
                                        {translate(
                                            "CGV",
                                            "The_responsibility_of_the_company_",
                                        )}
                                        <br />
                                        {translate("CGV", "In_any_event_the_")}
                                    </p>
                                    <p
                                        style={{ textAlign: "left" }}
                                        className="mb-4"
                                    >
                                        {translate(
                                            "CGV",
                                            "We_do_not_guarantee_",
                                        )}
                                    </p>
                                    <p
                                        style={{ textAlign: "left" }}
                                        className="mb-4"
                                    >
                                        {translate(
                                            "CGV",
                                            "You_accept_that_from_",
                                        )}
                                    </p>
                                    <p
                                        style={{ textAlign: "left" }}
                                        className="mb-4"
                                    >
                                        {translate(
                                            "CGV",
                                            "You_expressly_agree_that_your_",
                                        )}
                                    </p>
                                    <p
                                        style={{ textAlign: "left" }}
                                        className="mb-4"
                                    >
                                        {translate(
                                            "CGV",
                                            "The_service_as_well_as_all_",
                                        )}
                                    </p>
                                    <p
                                        style={{ textAlign: "left" }}
                                        className="mb-4"
                                    ></p>
                                    <p
                                        style={{ textAlign: "left" }}
                                        className="mb-4"
                                    >
                                        <strong>
                                            {translate(
                                                "CGV",
                                                "Kalyssi_cannot_be_held_",
                                            )}{" "}
                                            :
                                        </strong>
                                    </p>
                                    <p
                                        style={{ textAlign: "left" }}
                                        className="mb-4"
                                    >
                                        {translate(
                                            "CGV",
                                            "Any_loss_or_damage_that_",
                                        )}
                                        , <em>bug</em>,{" "}
                                        {translate(
                                            "CGV",
                                            "steals_malfunction_accidental_or_",
                                        )}
                                    </p>
                                    <p
                                        style={{ textAlign: "left" }}
                                        className="mb-4"
                                    >
                                        <span>
                                            {translate(
                                                "CGV",
                                                "Kalyssi_SAS_our_directors_",
                                            )}
                                        </span>
                                    </p>
                                    <p
                                        style={{ textAlign: "left" }}
                                        className="mb-4"
                                    >
                                        <span>
                                            {translate(
                                                "CGV",
                                                "Because_some_states_or_",
                                            )}
                                        </span>
                                    </p>
                                    <p
                                        style={{ textAlign: "left" }}
                                        className="mb-4"
                                    >
                                        <span>
                                            {translate(
                                                "CGV",
                                                "By_accepting_these_General_",
                                            )}
                                        </span>
                                    </p>
                                    <p> </p>
                                    <p
                                        style={{ textAlign: "left" }}
                                        className="mb-4"
                                    >
                                        <strong>
                                            {translate("CGV", "Major_force")}
                                        </strong>
                                    </p>
                                    <p
                                        style={{ textAlign: "left" }}
                                        className="mb-4"
                                    >
                                        {translate(
                                            "CGV",
                                            "Kalyssi_cannot_be_held_responsible_",
                                        )}{" "}
                                        :
                                    </p>
                                    <p
                                        style={{ textAlign: "left" }}
                                        className="mb-4"
                                    >
                                        {translate(
                                            "CGV",
                                            "The_suspension_or_absence_of_",
                                        )}
                                    </p>
                                    <p
                                        style={{ textAlign: "left" }}
                                        className="mb-4"
                                    >
                                        {translate(
                                            "CGV",
                                            "By_accepting_these_conditions_",
                                        )}
                                    </p>
                                    <p> </p>
                                    <p
                                        style={{ textAlign: "left" }}
                                        className="mb-4"
                                    >
                                        <strong></strong>
                                    </p>
                                    <p
                                        style={{ textAlign: "left" }}
                                        className="mb-4"
                                    >
                                        <strong>
                                            {translate("CGV", "ARTICLE_17")}
                                        </strong>{" "}
                                        – {translate("CGV", "COMPENSATION")}
                                    </p>
                                    <p
                                        style={{ textAlign: "left" }}
                                        className="mb-4"
                                    >
                                        {translate(
                                            "CGV",
                                            "You_agree_to_compensate_defend_",
                                        )}
                                    </p>
                                    <p
                                        style={{ textAlign: "left" }}
                                        className="mb-4 mt-10"
                                    >
                                        <strong>
                                            {translate("CGV", "ARTICLE_18")}
                                        </strong>
                                        –{translate("CGV", "DISSOCIABILITY")}
                                    </p>
                                    <p
                                        style={{ textAlign: "left" }}
                                        className="mb-4"
                                    >
                                        {translate(
                                            "CGV",
                                            "In_the_event_that_any_provision_",
                                        )}
                                    </p>
                                    <p
                                        style={{ textAlign: "left" }}
                                        className="mb-4 mt-10"
                                    >
                                        <strong>
                                            {translate("CGV", "ARTICLE_19")}
                                        </strong>{" "}
                                        –{translate("CGV", "TERMINATION")}
                                    </p>
                                    <p
                                        style={{ textAlign: "left" }}
                                        className="mb-4"
                                    >
                                        {translate(
                                            "CGV",
                                            "Obligations_and_responsibilities_",
                                        )}
                                    </p>
                                    <p
                                        style={{ textAlign: "left" }}
                                        className="mb-4"
                                    >
                                        {translate(
                                            "CGV",
                                            "These_General_Conditions_will_",
                                        )}
                                    </p>
                                    <p
                                        style={{ textAlign: "left" }}
                                        className="mb-4"
                                    >
                                        {translate(
                                            "CGV",
                                            "If_we_judge_or_suspect_to_our_",
                                        )}
                                    </p>
                                    <p
                                        style={{ textAlign: "left" }}
                                        className="mb-4 mt-10"
                                    >
                                        <strong>
                                            {translate("CGV", "ARTICLE_20")}
                                        </strong>{" "}
                                        –
                                        {translate(
                                            "CGV",
                                            "INTEGRALITY_OF_THE_AGREEMENT",
                                        )}
                                    </p>
                                    <p
                                        style={{ textAlign: "left" }}
                                        className="mb-4"
                                    >
                                        {translate(
                                            "CGV",
                                            "Any_breach_of_us_exercise_or_",
                                        )}
                                    </p>
                                    <p
                                        style={{ textAlign: "left" }}
                                        className="mb-4"
                                    >
                                        {translate(
                                            "CGV",
                                            "These_General_Conditions_or_any_other_",
                                        )}
                                    </p>
                                    <p
                                        style={{ textAlign: "left" }}
                                        className="mb-4"
                                    ></p>
                                    <p
                                        style={{ textAlign: "left" }}
                                        className="mb-4"
                                    ></p>
                                    <p
                                        style={{ textAlign: "left" }}
                                        className="mb-4"
                                    >
                                        <strong></strong>
                                    </p>
                                    <p
                                        style={{ textAlign: "left" }}
                                        className="mb-4"
                                    >
                                        <strong>
                                            {translate("CGV", "ARTICLE_21")}
                                        </strong>{" "}
                                        –
                                        {translate(
                                            "CGV",
                                            "MODIFICATION_OF_THE_GENERAL_CONDITIONS_OF_SALE_AND_USE",
                                        )}
                                    </p>
                                    <p
                                        style={{ textAlign: "left" }}
                                        className="mb-4"
                                    >
                                        {translate(
                                            "CGV",
                                            "You_can_consult_the_most_",
                                        )}
                                    </p>
                                    <p
                                        style={{ textAlign: "left" }}
                                        className="mb-4"
                                    >
                                        {translate(
                                            "CGV",
                                            "We_reserve_the_right_to_our_",
                                        )}
                                    </p>
                                    <p
                                        style={{ textAlign: "left" }}
                                        className="mb-4 mt-10"
                                    >
                                        <strong>
                                            {translate("CGV", "ARTICLE_22")}
                                        </strong>{" "}
                                        – {translate("CGV", "APPLICABLE_LAW")}
                                    </p>
                                    <p
                                        style={{ textAlign: "left" }}
                                        className="mb-4"
                                    >
                                        <span>
                                            {translate(
                                                "CGV",
                                                "These_general_conditions_as_",
                                            )}{" "}
                                            <strong>
                                                KALYSSI Europe s.r.o
                                            </strong>
                                        </span>
                                        <strong>.</strong>
                                    </p>
                                    <p
                                        style={{ textAlign: "left" }}
                                        className="mb-4 mt-10"
                                    >
                                        <strong>
                                            {translate("CGV", "ARTICLE_23")}
                                        </strong>{" "}
                                        –{translate("CGV", "CONTACT_DETAILS")}
                                    </p>
                                    <p
                                        style={{ textAlign: "left" }}
                                        className="mb-4"
                                    >
                                        {translate(
                                            "CGV",
                                            "Questions_concerning_the_conditions_",
                                        )}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <Section03 />
            <Section04 />
            <Section05 />
            <Section06 />
            <Section07 />
            <Section08 />
            <Faqs /> */}
            <Cta />
        </>
    );
};

export default CGV;
