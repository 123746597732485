export interface IAssetBorrowingConfig {
    crypto: number;
    status: boolean;
}

export interface IAssetLtv {
    crypto: number;
    iltv: number;
    mcltv: number;
    llltv: number;
    interest_basic: number;
    interest_red: number;
    interest_gold: number;
    interest_black: number;
    name?: string;
}

export interface IAssetBorrowing {
    crypto: number;
    status: boolean;
}
export default class AssetLtv {
    id?: number;
    iltv: number;
    mcltv: number;
    llltv: number;
    interest_basic: number;
    interest_red: number;
    interest_gold: number;
    interest_black: number;
    name!: string;
    crypto!: any;

    constructor(
        iltv: number,
        mcltv: number,
        llltv: number,
        interest_basic: number,
        interest_red: number,
        interest_gold: number,
        interest_black: number,
    ) {
        this.iltv = iltv;
        this.mcltv = mcltv;
        this.llltv = llltv;
        this.interest_basic = interest_basic;
        this.interest_red = interest_red;
        this.interest_gold = interest_gold;
        this.interest_black = interest_black;
    }
}

export interface GlobalBorrowingConfiguration {
    days: number;
}
