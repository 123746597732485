import { Outlet } from "react-router-dom";
import ExportPdfProvider from "../../App/ExportPdfProvider";
import KpLoadingBar from "../Components/KpLoadingBar";
import AppProvider from "../Provider/AppProvider";
import AssetsProvider from "../Provider/AssetsProvider";
import CardProvider from "../Provider/CardProvider";
import FidelityProvider from "../Provider/FidelityProvider";
import Header from "./Header";
import MobileBottomMenu from "./MobileBottomMenu";
import Sidebar from "./Sidebar";
import { TransactionDataProvider } from "../Provider/TransactionDataProvider";

// import "../css/style.css";

const MosaicBase = () => {
    return (
        <AppProvider>
            <CardProvider>
                <FidelityProvider>
                    <AssetsProvider>
                        <TransactionDataProvider>
                            <ExportPdfProvider>
                                <KpLoadingBar />
                                <div className="flex h-[100dvh] overflow-hidden bg-slate-100 dark:bg-slate-900">
                                    {/* Sidebar */}
                                    <Sidebar />

                                    {/* Content area */}
                                    <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
                                        {/*  Site header */}
                                        <Header />

                                        <Outlet />
                                    </div>
                                    <MobileBottomMenu />
                                </div>
                            </ExportPdfProvider>
                        </TransactionDataProvider>
                    </AssetsProvider>
                </FidelityProvider>
            </CardProvider>
        </AppProvider>
    );
};

export default MosaicBase;
