import { UserCryptoWallet } from "./UserWallet";

export default class Transaction {
    id: number;
    description: string;
    amount: number;
    status: string;
    date: string;
    type!: WalletHistoryType;
    user_wallet!: UserCryptoWallet;
    user?: any;
    fee?: number;
    target?: UserCryptoWallet;
    date_fr?: string;
    usd_price?: number;
    isClishaNode: boolean;
    clishaAbbr?: string;
    isStopedClisha: boolean;
    constructor(
        id: number,
        description: string,
        amount: number,
        status: string,
        date: string,
        user: any,
    ) {
        this.isClishaNode = description.includes("Achat noeud");
        this.isStopedClisha =
            description.includes("Arrêt de earning ") &&
            description.includes("CLISHA");

        this.description = this.isClishaNode
            ? "Achat de Noeud CLISHA"
            : description;

        if (this.isStopedClisha) {
            this.description = "Arrêt Noeud  CLISHA";
        }

        if (this.isClishaNode) {
            this.clishaAbbr = description.includes("USDCC") ? "USDCC" : "USDT";
        }

        this.amount = amount;
        this.status = status;
        this.date = date;
        this.user = user;
        this.id = id;
    }
}

export interface OTransactionCount {
    deposit: number;
    all: number;
    withdraw: number;
    all_transactions: number;
}

export enum WalletHistoryType {
    DEPOSIT = "deposit",

    STAKING = "staking",
    MIGRATION_CEX = "MIGRATION_CEX",
    MIGRATION_DEFI = "MIGRATION_DEFI",
    MIGRATION_USDT = "MIGRATION_USDT",
    MIGRATION_BASIC = "MIGRATION_BASIC",
    STOP_STAKING = "stop_staking",
    UNSTAKING = "unstaking",
    TRANSFER_IN = "transferin",
    TRANSFERT_OUT = "transferout",
    COMMISSION = "commission",
    SWAPIN = "swapin",
    SWAPOUT = "swapout",
    DAILY_ROI = "daily_roi",
    DAILY_KNT = "daily_knt",
    ORDER_PACKBOOSTER = "order_packbooster",
    DEPOSIT_VISA = "deposit_visa",
    DEPOSIT_VIREMENT = "deposit_virement",
    DEPOSIT_KALYPAY = "deposit_kalypay",
    DEPOSIT_CRYPTO = "deposit_crypto",

    WITHDRAW_KALYPAY = "withdraw_kalypay",
    WITHDRAW = "withdraw",
    WITHDRAW_MANUAL = "withdraw_manual",
    WITHDRAW_AGENT = "withdraw_agent",
    WITHDRAW_AGENT_RECEPTION = "withdraw_agent_reception",
    WITHDRAW_CRYPTO = "withdraw_crypto",
    WITHDRAW_VIREMENT = "withdraw_virement",

    WITHDRAW_PM = "withdraw_pm",
    WITHDRAW_PAYEER = "withdraw_payeer",
    WITHDRAW_INTECH = "withdraw_intech",
    WITHDRAW_INTOUCH = "withdraw_intouch",
    WITHDRAW_ORANGE = "withdraw_orange",

    DEPOSIT_PM = "deposit_pm",
    DEPOSIT_INSTANT = "deposit_instant",
    DEPOSIT_PAYEER = "deposit_payeer",
    DEPOSIT_INTECH = "deposit_intech",
    DEPOSIT_INTOUCH = "deposit_intouch",
    DEPOSIT_ORANGE = "deposit_orange",

    AIRTIME_INTECH = "airtime_intech",
    AIRTIME_INTOUCH = "airtime_intouch",
    AIRTIME_ORANGE = "airtime_orange",

    RANK_BONUS = "rank_bonus",
    POOL_BONUS = "pool_bonus",
    COMMISSION_PURCHASE = "commission_purchase",
    COMMISSION_PURCHASE_BUSINESS = "commission_purchase_business",
    COMMISSION_PURCHASE_FIDELITY_BUSINESS = "commission_purchase_fidelity_business",
    COMMISSION_LOYALTY = "commission_loyalty",
    COMMISSION_SWAP = "commission_swap",
    COMMISSION_SWAP_BUSINESS = "commission_swap_business",
    COMMISSION_WITHDRAW = "commission_withdraw",
    COMMISSION_WITHDRAW_BUSINESS = "commission_withdraw_business",
    COMMISSION_SERVICE_CHARGE = "commission_service_charge",
    PURCHASE_FIDELITY = "purchase_fidelity",
    PURCHASE_FIDELITY_RECURRENCE = "purchase_fidelity_recurrence",
    CASHBACK_KLC = "cashback_klc",
    SOLDE_MIGRATION = "solde_migration",
    GAIN_A_TERM = "gain_a_term",

    DEPOSIT_AGENT = "deposit_agent",
    DEPOSIT_AGENT_RECEPTION = "deposit_agent_reception",
    SELL_OUT = "sell_out",
    CB_SELL_OUT = "cb_sell_out",
    BUY = "buy",
    MARCHAND_PAYMENT = "marchand_payment",
    MARCHAND_PAYMENT_RECEPTION = "marchand_payment_reception",
    WITHDRAW_CARD = "withdraw_card",
    CREDIT_CARD = "credit_card",
    BUY_CARD = "buy_card",
    DEBIT_CARD = "debit_card",
    COLD_WALLET_ORDER = "cold_wallet_order",

    BUY_CRYPTO = "buy_crypto",
    SELL_CRYPTO = "sell_crypto",

    COMMISSION_POS = "commission_pos",
    COMMISSION_COM = "commission_com",
    COMMISSION_RES_COM = "commission_res_com",

    COMMISSION_CLIENT = "commission_client",
    EARNING_COM = "earning_com",
    EARNING_COM_BUSINESS = "earning_com_business",
    CASHBACK_RED = "cashback_red",
    CASHBACK_GOLD = "cashback_gold",
    CASHBACK_BLACK = "cashback_black",

    TRANSFERT_CARD_FOUNDS = "TRANSFERT_CARD_FOUNDS",
    CANCEL_BUY_CARD = "CANCEL_BUY_CARD",
    AFFILIATE_COM_KLC_FILLEUL = "AFFILIATE_COM_KLC_FILLEUL",
    AFFILIATE_COM_KLC_PARRAIN = "AFFILIATE_COM_KLC_PARRAIN",
    UN_POOL_CEX = "UN_POOL_CEX",
    POOL_CEX = "pool_cex",
    POOL_DEX = "POOL_DEX",
    UN_POOL_DEX = "UN_POOL_DEX",
    POOL_DAO = "POOL_DAO",
    UN_POOL_DAO = "UN_POOL_DAO",
    AFFILIATE_COM_USDT_FILLEUL = "AFFILIATE_COM_USDT_FILLEUL",
    AFFILIATE_COM_USDT_PARRAIN = "AFFILIATE_COM_USDT_PARRAIN",
    MIGRATE_UN_POOL = "MIGRATE_UN_POOL",
    MIGRATE_TO_POOL = "MIGRATE_TO_POOL",
    REDRESSEMENT_SOLDE = "REDRESSEMENT_SOLDE",
    HISTORY_MIGRATION = "HISTORY_MIGRATION",
    LOAN_CREDIT = "LOAN_CREDIT",
    BORROW_GUARANTEE_DEBIT = "BORROW_GUARANTEE_DEBIT",
    CARD_WITHDRAW_ATM = "WITHDRAW_ATM",
    CARD_PAYMENT_POS = "CARD_PAYMENT_POS",
    REFUND_GUARANTEE = "REFUND_GUARANTEE",
    BORROW_REFUND_AUTO = "BORROW_REFUND_AUTO",
    BORROW_REFUND = "BORROW_REFUND",
    WARRANTY_LIQUIDATION = "WARRANTY_LIQUIDATION",

    WITHDRAW_BANK_TRANSFERT = "withdraw_bank_transfert",
}

export interface ICSVTransaction {
    type: string;
    date: string;
    amount: number;
    crypto_in: string;
    crypto_out: string;
    fee: number;
    crypto_fee: string;
    swapping_plateform: string;
    description: string;
    label: string;
}

export enum WalletHistoryStatus {
    INITIALIZED = "initialized",
    IN_PROGRESS = "in_progress",
    IN_PROCESS = "in_process",
    PENDING = "pending",
    COMPLETED = "completed",
    CANCELLED = "cancelled",
}

export const OUT_TRANSACTIONS = [
    WalletHistoryType.WITHDRAW,
    WalletHistoryType.WITHDRAW_CRYPTO,
    WalletHistoryType.WITHDRAW_KALYPAY,
    WalletHistoryType.TRANSFERT_OUT,
    WalletHistoryType.SWAPOUT,
    WalletHistoryType.ORDER_PACKBOOSTER,
    WalletHistoryType.CREDIT_CARD,
    WalletHistoryType.ORDER_PACKBOOSTER,
    WalletHistoryType.WITHDRAW,
    WalletHistoryType.WITHDRAW_CRYPTO,
    WalletHistoryType.WITHDRAW_KALYPAY,
    WalletHistoryType.WITHDRAW_KALYPAY,
    WalletHistoryType.WITHDRAW_MANUAL,
    WalletHistoryType.WITHDRAW_AGENT,
    WalletHistoryType.WITHDRAW_CRYPTO,
    WalletHistoryType.WITHDRAW_VIREMENT,
    WalletHistoryType.WITHDRAW_PM,
    WalletHistoryType.WITHDRAW_PAYEER,
    WalletHistoryType.WITHDRAW_INTECH,
    WalletHistoryType.WITHDRAW_INTOUCH,
    WalletHistoryType.WITHDRAW_ORANGE,
    WalletHistoryType.AIRTIME_INTECH,
    WalletHistoryType.AIRTIME_ORANGE,
    WalletHistoryType.AIRTIME_INTOUCH,
    WalletHistoryType.MARCHAND_PAYMENT,
    WalletHistoryType.BUY,
    WalletHistoryType.BORROW_GUARANTEE_DEBIT,
    WalletHistoryType.BORROW_GUARANTEE_DEBIT,
    WalletHistoryType.BORROW_GUARANTEE_DEBIT,
    WalletHistoryType.CARD_WITHDRAW_ATM,
    WalletHistoryType.CARD_PAYMENT_POS,
    WalletHistoryType.BORROW_REFUND,
    WalletHistoryType.BORROW_REFUND_AUTO,
    WalletHistoryType.WARRANTY_LIQUIDATION,
    WalletHistoryType.WITHDRAW_BANK_TRANSFERT,
];

export const DEPOSIT_TYPE = [
    WalletHistoryType.DEPOSIT,
    WalletHistoryType.DEPOSIT_CRYPTO,
    WalletHistoryType.DEPOSIT_KALYPAY,
    WalletHistoryType.DEPOSIT_VISA,
    WalletHistoryType.DEPOSIT_VIREMENT,
    WalletHistoryType.DEPOSIT_PM,
    WalletHistoryType.DEPOSIT_PAYEER,
    WalletHistoryType.DEPOSIT_INTECH,
    WalletHistoryType.DEPOSIT_INTOUCH,
    WalletHistoryType.DEPOSIT_ORANGE,
    // WalletHistoryType.UNSTAKING,
];

export const WITHDRAW_TYPE = [
    WalletHistoryType.WITHDRAW,
    WalletHistoryType.WITHDRAW_CRYPTO,
    WalletHistoryType.WITHDRAW_KALYPAY,
    WalletHistoryType.WITHDRAW_KALYPAY,
    WalletHistoryType.WITHDRAW_MANUAL,
    WalletHistoryType.WITHDRAW_AGENT,
    WalletHistoryType.WITHDRAW_CRYPTO,
    WalletHistoryType.WITHDRAW_VIREMENT,
    WalletHistoryType.WITHDRAW_PM,
    WalletHistoryType.WITHDRAW_PAYEER,
    WalletHistoryType.WITHDRAW_INTECH,
    WalletHistoryType.WITHDRAW_INTOUCH,
    WalletHistoryType.WITHDRAW_ORANGE,

    WalletHistoryType.AIRTIME_INTECH,
    WalletHistoryType.AIRTIME_ORANGE,
    WalletHistoryType.AIRTIME_INTOUCH,
];

export const MOBILE_TYPE = [
    WalletHistoryType.DEPOSIT_INTECH,
    WalletHistoryType.DEPOSIT_INTOUCH,
    WalletHistoryType.DEPOSIT_ORANGE,
    WalletHistoryType.WITHDRAW_INTECH,
    WalletHistoryType.WITHDRAW_INTOUCH,
    WalletHistoryType.WITHDRAW_ORANGE,
    WalletHistoryType.AIRTIME_INTECH,
    WalletHistoryType.AIRTIME_ORANGE,
    WalletHistoryType.AIRTIME_INTOUCH,
];

export const RELANCE_TYPE = [
    WalletHistoryType.DEPOSIT_INTECH,
    WalletHistoryType.DEPOSIT_INTOUCH,
    WalletHistoryType.DEPOSIT_ORANGE,
    WalletHistoryType.DEPOSIT_VISA,
];

export const PM_TYPE = [
    WalletHistoryType.DEPOSIT_PM,
    WalletHistoryType.WITHDRAW_PM,
];

export const PAYEER_TYPE = [
    WalletHistoryType.DEPOSIT_PAYEER,
    WalletHistoryType.WITHDRAW_PAYEER,
];

export const CRYPTO_TYPE = [
    WalletHistoryType.DEPOSIT_CRYPTO,
    WalletHistoryType.WITHDRAW_CRYPTO,
];

export interface ValidTransactionData {
    kyc: boolean;
    user_transaction: number;
    max_transaction_without_kyc: number;
}

export enum VirementType {
    INCOMING = "incoming",
    OUTGOING = "outgoing",
}

export enum VirementRequestStatus {
    INITIALIZED = "initialized",
    PENDING = "pending",
    COMPLETED_V1 = "completed_v1",
    COMPLETED = "completed",
    CANCELLED = "cancelled",
}
