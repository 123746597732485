import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import translator from "../../../../Components/Extra/Translation/Translate";
import TransactionService from "../../../../Services/Wallets/TransactionService";

const DepositWithdrawCard = () => {
    const location = useLocation();
    const { translate } = translator();
    const { user, role } = location.state || {};
    const [asset, setAsset] = useState<any>(null);
    const getTotalAgentDepoistWithdraw = async () => {
        try {
            const res = await TransactionService.getTotalAgentDepoistWithdraw(
                user.uid,
            );
            setAsset(res);
        } catch (err: any) {}
    };
    useEffect(() => {
        getTotalAgentDepoistWithdraw();
    }, []);
    return (
        asset && (
            <div className="grid grid-cols-12 gap-6 mt-1">
                <div className="flex flex-col col-span-full sm:col-span-6 xl:col-span-3 bg-white dark:bg-slate-800 shadow-lg rounded-sm border border-slate-200 dark:border-slate-700">
                    <div className="px-5 py-6">
                        <header>
                            <h3 className="text-sm font-semibold text-slate-500 uppercase mb-1 ">
                                <span className="text-slate-800 dark:text-slate-100 block">
                                    {translate("My_Transactions", "Deposit")}
                                </span>{" "}
                                <span className="block">USD</span>
                            </h3>
                            <div className="text-2xl font-bold text-slate-800 dark:text-slate-100  text-center">
                                {`${asset?.deposit}$` || "0$"}
                            </div>
                        </header>
                    </div>
                    {/* Chart built with Chart.js 3 */}
                    <div className="grow"></div>
                </div>
                <div className="flex flex-col col-span-full sm:col-span-6 xl:col-span-3 bg-white dark:bg-slate-800 shadow-lg rounded-sm border border-slate-200 dark:border-slate-700">
                    <div className="px-5 py-6">
                        <header>
                            <h3 className="text-sm font-semibold text-slate-500 uppercase mb-1 ">
                                <span className="text-slate-800 dark:text-slate-100 block">
                                    {translate("My_Transactions", "Withdrawal")}
                                </span>{" "}
                                <span className="block">USD</span>
                            </h3>
                            <div className="text-2xl font-bold text-slate-800 dark:text-slate-100  text-center">
                                {`${asset?.withdraw}$` || "0$"}
                            </div>
                        </header>
                    </div>
                    {/* Chart built with Chart.js 3 */}
                    <div className="grow"></div>
                </div>
            </div>
        )
    );
};
export default DepositWithdrawCard;
