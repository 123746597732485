import Select from "react-tailwindcss-select";
import { Option as DOption } from "react-tailwindcss-select/dist/components/type";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import NumberInput from "../../../Components/Extra/Input/InputNumber";
import SaveBouton from "../../../Components/Extra/Bouton/SaveBouton";
import AssetLtv, { IAssetLtv } from "../../../Models/AssetBorrowingConfig";
import Crypto from "../../../Models/Crypto";
import AssetBorrowingConfigService from "../../../Services/assetBorrowingConfig/AssetBorrowingConfigService";
import alert_message from "../../../Services/Alert/AlertMessage";
import translator from "../../../Components/Extra/Translation/Translate";
import Swal from "sweetalert2";
import CryptoService from "../../../Services/Wallets/CryptoService";
import DeleteSvg from "../../../Components/Extra/Svg/DeleteSvg";
import CurrencyService from "../../../Services/Currency/CurrencyService";
interface formProps {
    crypto: Crypto;
    assetltv?: AssetLtv;
    add?: boolean;
    label: string;
    refresh: () => void;
}

const AssetBorrowingConfigForm = ({
    crypto,
    assetltv,
    add,
    label = "Ajouter",
    refresh,
}: formProps) => {
    const { translate } = translator();
    const location = useLocation();
    const navigate = useNavigate();
    // if (label == "Ajouter") assetltv = null;
    const [name, setName] = useState<string>(assetltv ? assetltv.name : "");
    const [iltv, setIltv] = useState<number>(
        assetltv ? Number(assetltv.iltv) : 0,
    );
    const [mcltv, setMcltv] = useState<number>(
        assetltv ? Number(assetltv.mcltv) : 0,
    );
    const [llltv, setLlltv] = useState<number>(
        assetltv ? Number(assetltv.llltv) : 0,
    );

    const [interestBasic, setInterestBasic] = useState<number>(
        assetltv ? Number(assetltv.interest_basic) : 0,
    );
    const [interestRed, setInterestRed] = useState<number>(
        assetltv ? Number(assetltv.interest_red) : 0,
    );
    const [interestGold, setInterestGold] = useState<number>(
        assetltv ? Number(assetltv.interest_gold) : 0,
    );
    const [interestBlack, setInterestBlack] = useState<number>(
        assetltv ? Number(assetltv.interest_black) : 0,
    );
    const [processing, setProcessing] = useState<boolean>(false);
    const handleSubmit = (e: any) => {
        e.preventDefault();

        const data: IAssetLtv = {
            crypto: crypto?.id || 0,
            name: name,
            iltv: iltv,
            mcltv: mcltv,
            llltv: llltv,
            interest_basic: interestBasic,
            interest_red: interestRed,
            interest_gold: interestGold,
            interest_black: interestBlack,
        };
        if (assetltv && assetltv.id && label === "Modifier") {
            updateAssetLtv(assetltv.id, data);
        } else {
            addAssetLtv(data);
            setName("");
            setIltv(0);
            setMcltv(0);
            setLlltv(0);
            setInterestBasic(0);
            setInterestRed(0);
            setInterestGold(0);
            setInterestBlack(0);
        }
    };

    const deleteassetLtv = async (
        event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
        elt: AssetLtv,
    ) => {
        event.preventDefault();
        setProcessing(true);

        Swal.fire({
            title: translate("Admin_Assets", "Supression"),
            text: `${translate("Admin_Assets", "Voulez vous supprimer ")} ${
                elt.name
            } ?`,
            icon: "question",
            showCloseButton: true,
            showCancelButton: true,
            confirmButtonColor: "#daa520",
            cancelButtonText: translate("Admin_Assets", "Cancel"),
        }).then((res) => {
            if (res.isConfirmed) {
                try {
                    const promise =
                        AssetBorrowingConfigService.deleteAssetLtvById(
                            elt.id || 0,
                        );

                    alert_message
                        .promise(
                            translate("Admin_Assets", "Deletion_In_Progress"),
                            promise,
                        )
                        .finally(() => {
                            setProcessing(false);
                            refresh();
                        });
                } catch (error) {}
            }
        });
    };
    const addAssetLtv = (payload: IAssetLtv) => {
        const update = AssetBorrowingConfigService.createAssetLtv(payload);
        alert_message
            .promise(translate("Admin_Notice", "Update_In_Progress"), update)
            .then(() => {
                refresh();
            });
    };
    const updateAssetLtv = (id: number, payload: IAssetLtv) => {
        const update = AssetBorrowingConfigService.updateAssetLtv(id, payload);
        alert_message
            .promise(translate("Admin_Notice", "Update_In_Progress"), update)
            .then(() => {
                refresh();
            });
    };
    useEffect(() => {}, []);

    return (
        <div className="my-2">
            <form onSubmit={(e) => handleSubmit(e)}>
                <div className="grid grid-cols-12 gap-6 box">
                    <div className="col-span-12 lg:col-span-12 2xl:col-span-9">
                        <div className="intro-y">
                            <div className="p-5">
                                <div className="grid md:grid-cols-2 md:gap-6">
                                    <div className="relative z-0 w-full group">
                                        <input
                                            type="text"
                                            id="fiat-name"
                                            className="border-1 peer block w-full appearance-none rounded-lg bg-transparent px-2.5 pb-2.5 pt-4 text-sm text-gray-900 focus:outline-none focus:ring-0"
                                            placeholder=" "
                                            value={name}
                                            onChange={(e) =>
                                                setName(e.target.value)
                                            }
                                            required
                                        />
                                        <label
                                            htmlFor="fiat-name"
                                            className="!peer-focus:text-[#053B3B] absolute top-2 left-1 z-10 origin-[0] -translate-y-4 scale-75 bg-white focus:bg-transparent px-2 text-sm text-gray-500 duration-300 peer-placeholder-shown:top-1/2 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:scale-100 peer-focus:top-2 peer-focus:-translate-y-4 peer-focus:scale-75 peer-focus:px-2"
                                        >
                                            Name
                                        </label>
                                    </div>
                                    <div className="relative z-0 w-full group">
                                        <NumberInput
                                            val={iltv}
                                            onChange={setIltv}
                                            decimal={2}
                                            min={0}
                                            className="border-1 peer block w-full appearance-none rounded-lg bg-transparent px-2.5 pb-2.5 pt-4 text-sm text-gray-900 focus:outline-none focus:ring-0"
                                        />

                                        <label
                                            htmlFor="fiat-withdrawal-fee"
                                            className="!peer-focus:text-[#053B3B] absolute top-2 left-1 z-10 origin-[0] -translate-y-4 scale-75 bg-white focus:bg-transparent px-2 text-sm text-gray-500 duration-300 peer-placeholder-shown:top-1/2 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:scale-100 peer-focus:top-2 peer-focus:-translate-y-4 peer-focus:scale-75 peer-focus:px-2"
                                        >
                                            LTV initiale en %
                                        </label>
                                    </div>
                                    <div className="relative z-0 w-full group">
                                        <NumberInput
                                            val={mcltv}
                                            onChange={setMcltv}
                                            decimal={2}
                                            min={0}
                                            className="border-1 peer block w-full appearance-none rounded-lg bg-transparent px-2.5 pb-2.5 pt-4 text-sm text-gray-900 focus:outline-none focus:ring-0"
                                        />

                                        <label
                                            htmlFor="fiat-withdrawal-fee"
                                            className="!peer-focus:text-[#053B3B] absolute top-2 left-1 z-10 origin-[0] -translate-y-4 scale-75 bg-white focus:bg-transparent px-2 text-sm text-gray-500 duration-300 peer-placeholder-shown:top-1/2 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:scale-100 peer-focus:top-2 peer-focus:-translate-y-4 peer-focus:scale-75 peer-focus:px-2"
                                        >
                                            LTV d'appel de marge en %
                                        </label>
                                    </div>
                                    <div className="relative z-0 w-full group">
                                        <NumberInput
                                            val={llltv}
                                            onChange={setLlltv}
                                            decimal={2}
                                            min={0}
                                            className="border-1 peer block w-full appearance-none rounded-lg bg-transparent px-2.5 pb-2.5 pt-4 text-sm text-gray-900 focus:outline-none focus:ring-0"
                                        />

                                        <label
                                            htmlFor="fiat-withdrawal-fee"
                                            className="!peer-focus:text-[#053B3B] absolute top-2 left-1 z-10 origin-[0] -translate-y-4 scale-75 bg-white focus:bg-transparent px-2 text-sm text-gray-500 duration-300 peer-placeholder-shown:top-1/2 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:scale-100 peer-focus:top-2 peer-focus:-translate-y-4 peer-focus:scale-75 peer-focus:px-2"
                                        >
                                            LTV de liquidation en %
                                        </label>
                                    </div>

                                    <div className="relative z-0 w-full group">
                                        <NumberInput
                                            val={interestBasic}
                                            onChange={setInterestBasic}
                                            decimal={2}
                                            min={0}
                                            className="border-1 peer block w-full appearance-none rounded-lg bg-transparent px-2.5 pb-2.5 pt-4 text-sm text-gray-900 focus:outline-none focus:ring-0"
                                        />

                                        <label
                                            htmlFor="fiat-withdrawal-fee"
                                            className="!peer-focus:text-[#053B3B] absolute top-2 left-1 z-10 origin-[0] -translate-y-4 scale-75 bg-white focus:bg-transparent px-2 text-sm text-gray-500 duration-300 peer-placeholder-shown:top-1/2 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:scale-100 peer-focus:top-2 peer-focus:-translate-y-4 peer-focus:scale-75 peer-focus:px-2"
                                        >
                                            Interest Basic en %
                                        </label>
                                    </div>

                                    <div className="relative z-0 w-full group">
                                        <NumberInput
                                            val={interestRed}
                                            onChange={setInterestRed}
                                            decimal={2}
                                            min={0}
                                            className="border-1 peer block w-full appearance-none rounded-lg bg-transparent px-2.5 pb-2.5 pt-4 text-sm text-gray-900 focus:outline-none focus:ring-0"
                                        />

                                        <label
                                            htmlFor="fiat-withdrawal-fee"
                                            className="!peer-focus:text-[#053B3B] absolute top-2 left-1 z-10 origin-[0] -translate-y-4 scale-75 bg-white focus:bg-transparent px-2 text-sm text-gray-500 duration-300 peer-placeholder-shown:top-1/2 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:scale-100 peer-focus:top-2 peer-focus:-translate-y-4 peer-focus:scale-75 peer-focus:px-2"
                                        >
                                            Interest Red en %
                                        </label>
                                    </div>

                                    <div className="relative z-0 w-full group">
                                        <NumberInput
                                            val={interestGold}
                                            onChange={setInterestGold}
                                            decimal={2}
                                            min={0}
                                            className="border-1 peer block w-full appearance-none rounded-lg bg-transparent px-2.5 pb-2.5 pt-4 text-sm text-gray-900 focus:outline-none focus:ring-0"
                                        />

                                        <label
                                            htmlFor="fiat-withdrawal-fee"
                                            className="!peer-focus:text-[#053B3B] absolute top-2 left-1 z-10 origin-[0] -translate-y-4 scale-75 bg-white focus:bg-transparent px-2 text-sm text-gray-500 duration-300 peer-placeholder-shown:top-1/2 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:scale-100 peer-focus:top-2 peer-focus:-translate-y-4 peer-focus:scale-75 peer-focus:px-2"
                                        >
                                            Interest Gold en %
                                        </label>
                                    </div>

                                    <div className="relative z-0 w-full group">
                                        <NumberInput
                                            val={interestBlack}
                                            onChange={setInterestBlack}
                                            decimal={2}
                                            min={0}
                                            className="border-1 peer block w-full appearance-none rounded-lg bg-transparent px-2.5 pb-2.5 pt-4 text-sm text-gray-900 focus:outline-none focus:ring-0"
                                        />

                                        <label
                                            htmlFor="fiat-withdrawal-fee"
                                            className="!peer-focus:text-[#053B3B] absolute top-2 left-1 z-10 origin-[0] -translate-y-4 scale-75 bg-white focus:bg-transparent px-2 text-sm text-gray-500 duration-300 peer-placeholder-shown:top-1/2 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:scale-100 peer-focus:top-2 peer-focus:-translate-y-4 peer-focus:scale-75 peer-focus:px-2"
                                        >
                                            Interest Black en %
                                        </label>
                                    </div>
                                </div>
                                <div className="mt-5 mt-5 flex justify-end">
                                    <SaveBouton
                                        htmlType="submit"
                                        className={"mx-2"}
                                    >
                                        {label}
                                    </SaveBouton>
                                    {label === "Modifier" && assetltv && (
                                        <button
                                            className="btn btn-danger mb-2 w-32"
                                            type="button"
                                            onClick={(event) =>
                                                deleteassetLtv(event, assetltv)
                                            }
                                        >
                                            <DeleteSvg />
                                            {translate(
                                                "Admin_Assets",
                                                "Delete",
                                            )}
                                        </button>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    );
};
export default AssetBorrowingConfigForm;
