import React from "react";
import FormNewPassword from "./FormNewPassword";
import translator from "../../../../Components/Extra/Translation/Translate";

const NewPassword: React.FC = () => {
    const { translate } = translator();
    return (
        <div className="login h-screen !p-0">
            <div className="container">
                <div className="block grid-cols-2 gap-4 xl:grid">
                    <div className="hidden min-h-screen flex-col xl:flex">
                        <div className="my-auto mr-[100px]">
                            <div className="-intro-x lg:text-[40px] xl:text-[40px] 2xl:text-[42px] font-medium leading-tight text-white">
                                KALYPAY <br />
                                {translate("SignIn", "New_Text")}
                                <br />
                                {translate("SignIn", "New_Text_2")}
                            </div>
                        </div>
                    </div>
                    <div className="my-10 sm:w-full flex !sm:h-auto sm:pt-[50px] justify-center !h-screen xl:my-0 xl:h-auto overflow-hegith py-[70px] sm:pb-[70px] md:py-[70px] lg:py-[70px] xl:py-[70px] 2xl:py-8 mdg-register-ctn ">
                        <FormNewPassword />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default NewPassword;
