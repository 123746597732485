import React from "react";
import bgabout from "../../../Landing/images/aboutus.jpg";
import translator from "../../../Components/Extra/Translation/Translate";

export default function section1v1() {
    const { translate } = translator();
    return (
        <section
            className="flex justify-center items-center lg:mx-20 my-20"
            id="iban"
        >
            <div className="max-w-screen-xl mx-auto w-full ">
                <div
                    data-aos="zoom-in-up"
                    className="relative h-[75vh] md:!rounded-[85px] overflow-hidden md:!my-10"
                >
                    <div
                        className="absolute w-full inset-0 brightness-75 backdrop-blur-[4px] bg-cover bg-center "
                        style={{ backgroundImage: `url(${bgabout})` }}
                    ></div>

                    <div className="relative z-10 flex items-end justify-center h-full text-center">
                        <div className="sm:p-12 p-4">
                            <button
                                type="button"
                                className="bg-transparent border-2 dark:border-white border-white my-5 transition-all text-white font-bold text-sm rounded-md px-6 py-2.5"
                            >
                                A propos
                            </button>
                            <h2 className="text-white lg:!text-4xl text-3xl font-semibold lg:!leading-[56px]">
                                {translate("LANDING_ABOUT", "section1_title1")}
                            </h2>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        // <div>
        //     <section className="mx-0 lg:mx-20 md:!my-32 mt-16">
        //         <div className="relative flex justify-center items-center">
        //             <div className="relative max-w-screen-xl mx-auto">
        //                 <img
        //                     src={bgabout}
        //                     alt=""
        //                     className="w-full md:!rounded-[85px] rounded-none"
        //                 />
        //                 {/* Superposition sombre plus légère */}
        //                 <div className="absolute inset-0 bg-black opacity-40 md:!rounded-[85px] rounded-none"></div>
        //             </div>

        //             <h1 className="absolute md:!text-5xl text-2xl text-nowrap text-white text-center font-bold top-[55%] left-1/2 transform -translate-x-1/2 -translate-y-1/2 drop-shadow-md">
        //                 {translate("LANDING_ABOUT", "section1_title1")}
        //             </h1>
        //         </div>
        //     </section>
        // </div>
    );
}
