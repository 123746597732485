import {
    checkAml,
    getCoins,
    getDetailsById,
    getIncomingAnalysisByObjectId,
    getList,
    getOutgoinAnalysisByObjectId,
    linkToAddress,
} from "../Services/AML/aml-service";
import { AnalysisResult } from "../Services/AML/type";

export type AMLAddress = {
    hash: string;
    blockchain: string;
};

export type AMLCheckResult = {
    objectId: string;
    addresses: AMLAddress[];
    aml_id: number;
};
export const useAml = () => {
    const check = (seach: string): Promise<AMLCheckResult> => {
        return checkAml(seach).then(({ objectId, check_id, score }) => {
            let addresses: AMLAddress[] = [];
            if (score.addresses && score.addresses.items) {
                addresses = score.addresses.items.map((item: any) => ({
                    hash: item.hash,
                    blockchain: item.blockchain,
                }));
            }

            return {
                objectId: objectId,
                addresses: addresses,
                aml_id: check_id,
            };
        });
    };

    const incoming = (id_aml: number): Promise<AnalysisResult | null> => {
        return getIncomingAnalysisByObjectId(id_aml).then((res) => {
            return res.analysis.incoming && res.analysis.incoming.hasResult
                ? res.analysis.incoming.result
                : null;
        });
    };

    const outgoing = (id_aml: number): Promise<AnalysisResult | null> => {
        return getOutgoinAnalysisByObjectId(id_aml).then((res) => {
            return res.analysis.outgoing && res.analysis.outgoing.hasResult
                ? res.analysis.outgoing.result
                : null;
        });
    };

    const linkSearchToAddress = (
        id_aml: number,
        blockchain: string,
        address: string,
    ): Promise<boolean> => {
        return linkToAddress(id_aml, blockchain, address)
            .then(() => true)
            .catch((err) => {
                return Promise.reject(err);
            });
    };

    const findList = async () => {
        return getList();
    };

    const getDetails = async (id_aml: number) => {
        return getDetailsById(id_aml);
    };

    const getTransactionData = async (id_aml: number) => {
        return getCoins(id_aml);
    };

    return {
        check,
        incoming,
        outgoing,
        linkSearchToAddress,
        findList,
        getDetails,
        getTransactionData,
    };
};
