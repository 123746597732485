import translator from "../../../Components/Extra/Translation/Translate";
import team from "../../images/discover/head_discover.jpg";

export default function Head() {
    const { translate } = translator();
    return (
        <section
            className="flex justify-center items-center lg:mx-20 my-20"
            data-aos="zoom-in-up"
        >
            <div className="max-w-screen-xl mx-auto w-full ">
                <div className="relative h-[75vh]  md:!rounded-[85px] overflow-hidden md:!my-10">
                    <div
                        className="absolute inset-0 brightness-50 backdrop-blur-[4px] bg-cover bg-center "
                        style={{ backgroundImage: `url(${team})` }}
                    ></div>

                    <div className="relative z-10 flex items-end justify-center h-full text-center">
                        <div className="sm:p-12 p-4">
                            <button
                                type="button"
                                className="bg-transparent border-2 border-white text-white mt-12 transition-all font-bold text-sm rounded-md px-6 py-2.5"
                            >
                                {translate(
                                    "LANDING_IBAN",
                                    "section1_titre2_button",
                                )}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
