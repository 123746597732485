import { NavLink } from "react-router-dom";
import translator from "../../../Components/Extra/Translation/Translate";
import useAuthentication from "../../../Services/Authentication/useAuthentication";
import RoutesNames from "../../../Services/RoutesNames";
import Features from "../../images/features-03.png";
import Section01 from "../fintech/section-01";
import Section02 from "../fintech/section-02";
import Section03 from "../fintech/section-03";
import Section04 from "../fintech/section-04";
import Section05 from "../fintech/section-05";
import { Section06 } from "../fintech/section-06";
import Section07 from "../fintech/section-07";
import Section08 from "../fintech/section-08";
import Faqs from "../fintech/faqs";
import Cta from "../fintech/cta";
import Caroussel from "../../../Components/Dashboard/Caroussel";
import Illustration from "../../images/hero-illustration-kp.png";

const Confidentialite = () => {
    const { translate } = translator();
    const { isAuthenticated } = useAuthentication();
    return (
        <>
            {/* Page Title */}
            <section className="relative z-10 h-[50vh] overflow-hidden">
                {/* Bg */}
                <div
                    className="absolute inset-0 rounded-bl-[100px] mb-28 md:mb-0 pointer-events-none -z-10"
                    aria-hidden="true"
                />

                <div className="max-w-[70%] mx-auto px-4 sm:px-6">
                    <div className="pt-36 pb-14">
                        {/* Page Title content */}
                        <div className="relative max-w-xl mx-auto md:max-w-none text-center md:text-left">
                            {/* Content */}
                            <div className="">
                                {/* Copy */}
                                <h1
                                    className="h1 text-white mb-6 text-center"
                                    data-aos="fade-up"
                                    data-aos-delay="100"
                                    dangerouslySetInnerHTML={{
                                        __html: translate(
                                            "INFORMATIONS",
                                            "PRIVACY_TITLE",
                                        ),
                                    }}
                                />
                            </div>

                            {/* Image */}
                            <div className="max-w-sm mx-auto md:max-w-none md:absolute md:left-[90%] md:top-0 -mb-12 md:-mt-12 md:mb-0">
                                <div className="relative md:-ml-3 md:-mr-24 md:mx-0">
                                    <img
                                        className="absolute opacity-50 top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 mt-16 md:mt-0 pointer-events-none -z-10 max-w-none mix-blend-lighten"
                                        src={Illustration}
                                        alt="Hero illustration"
                                        aria-hidden="true"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* Contenu Page */}
            <section className="-mt-36 text-white" id="transfert" data-aos-id-3>
                <div className="relative max-w-8xl mx-auto">
                    {/* Bg */}
                    <div
                        className="absolute inset-0 md:rounded-tl-[100px] mb-24 md:mb-0  pointer-events-none -z-10"
                        aria-hidden="true"
                    />

                    <div className="max-w-6xl mx-auto px-4 sm:px-6">
                        <div className="pb-6 pt-12 md:pt-20">
                            <div className="mb-12 md:mb-20" data-aos="fade-up">
                                <div className="text-base">
                                    <p
                                        style={{ textAlign: "left" }}
                                        className="mb-4"
                                    >
                                        <strong>
                                            {translate(
                                                "Confidentiality",
                                                "ARTICLE_1",
                                            )}
                                        </strong>
                                        –
                                        {translate(
                                            "Confidentiality",
                                            "PERSONAL_INFORMATION_COLLECTED",
                                        )}
                                    </p>
                                    <p
                                        style={{ textAlign: "left" }}
                                        className="mb-4"
                                    >
                                        {translate(
                                            "Confidentiality",
                                            "When_you_make_a_purchase_on_our_",
                                        )}
                                    </p>
                                    <p
                                        style={{ textAlign: "left" }}
                                        className="mb-4"
                                    >
                                        {translate(
                                            "Confidentiality",
                                            "When_you_browse_our_site_",
                                        )}
                                    </p>
                                    <p
                                        style={{ textAlign: "left" }}
                                        className="mb-4"
                                    >
                                        {translate(
                                            "Confidentiality",
                                            "Marketing_email_the_current_",
                                        )}
                                    </p>
                                    <p
                                        style={{ textAlign: "left" }}
                                        className="mb-4 mt-10"
                                    >
                                        <strong>
                                            {translate(
                                                "Confidentiality",
                                                "ARTICLE_2",
                                            )}
                                        </strong>
                                        –
                                        {translate(
                                            "Confidentiality",
                                            "CONSENT",
                                        )}
                                    </p>
                                    <p
                                        style={{ textAlign: "left" }}
                                        className="mb-4"
                                    >
                                        {translate(
                                            "Confidentiality",
                                            "When_you_provide_your_information_",
                                        )}
                                    </p>
                                    <p
                                        style={{ textAlign: "left" }}
                                        className="mb-4"
                                    >
                                        {translate(
                                            "Confidentiality",
                                            "If_we_ask_you_to_provide_us_",
                                        )}
                                    </p>
                                    <p
                                        style={{ textAlign: "left" }}
                                        className="mb-4"
                                    >
                                        {translate(
                                            "Confidentiality",
                                            "How_can_I_remove_my_consent",
                                        )}
                                    </p>
                                    <p
                                        style={{ textAlign: "left" }}
                                        className="mb-4"
                                    >
                                        {translate(
                                            "Confidentiality",
                                            "If_after_giving_us_your_agreement_",
                                        )}
                                        :{" "}
                                        <a className="wt-cli-manage-consent-link">
                                            {translate(
                                                "Confidentiality",
                                                "Cookie_settings",
                                            )}
                                        </a>
                                    </p>
                                    <p
                                        style={{ textAlign: "left" }}
                                        className="mb-4 mt-10"
                                    >
                                        <strong>
                                            {translate(
                                                "Confidentiality",
                                                "ARTICLE_3",
                                            )}
                                        </strong>
                                        –
                                        {translate(
                                            "Confidentiality",
                                            "SERVICES_PROVIDED_BY_THIRD_PARTIES",
                                        )}
                                    </p>
                                    <p
                                        style={{ textAlign: "left" }}
                                        className="mb-4"
                                    >
                                        {translate(
                                            "Confidentiality",
                                            "In_general_suppliers_allow_",
                                        )}
                                    </p>
                                    <p
                                        style={{ textAlign: "left" }}
                                        className="mb-4"
                                    >
                                        {translate(
                                            "Confidentiality",
                                            "Some_third_party_service_providers_",
                                        )}
                                    </p>
                                    <p
                                        style={{ textAlign: "left" }}
                                        className="mb-4"
                                    >
                                        {translate(
                                            "Confidentiality",
                                            "Regarding_these_suppliers_We_",
                                        )}
                                    </p>
                                    <p
                                        style={{ textAlign: "left" }}
                                        className="mb-4"
                                    >
                                        {translate(
                                            "Confidentiality",
                                            "We_must_not_forget_that_some_",
                                        )}
                                    </p>
                                    <p
                                        style={{ textAlign: "left" }}
                                        className="mb-4"
                                    >
                                        {translate(
                                            "Confidentiality",
                                            "For_example_if_you_are_at_Canada_",
                                        )}
                                    </p>
                                    <p
                                        style={{ textAlign: "left" }}
                                        className="mb-4"
                                    >
                                        {translate(
                                            "Confidentiality",
                                            "Once_you_leave_the_site_of_our_shop_",
                                        )}
                                    </p>
                                    <p
                                        style={{ textAlign: "left" }}
                                        className="mb-4"
                                    ></p>
                                    <p
                                        style={{ textAlign: "left" }}
                                        className="mb-4"
                                    ></p>
                                    <p
                                        style={{ textAlign: "left" }}
                                        className="mb-4"
                                    ></p>
                                    <p
                                        style={{ textAlign: "left" }}
                                        className="mb-4"
                                    >
                                        <strong>
                                            {translate(
                                                "Confidentiality",
                                                "ARTICLE_4",
                                            )}
                                        </strong>
                                        –
                                        {translate(
                                            "Confidentiality",
                                            "ABOUT_COOKIE_POLICIES",
                                        )}
                                    </p>
                                    <p
                                        style={{ textAlign: "left" }}
                                        className="mb-4"
                                    >
                                        {translate(
                                            "Confidentiality",
                                            "Cookie_policy_explains_what_cookies_",
                                        )}
                                    </p>
                                    <p
                                        style={{ textAlign: "left" }}
                                        className="mb-4"
                                    >
                                        {translate(
                                            "Confidentiality",
                                            "You_can_modify_or_Remove_your_",
                                        )}
                                    </p>
                                    <p
                                        style={{ textAlign: "left" }}
                                        className="mb-4"
                                    >
                                        {translate(
                                            "Confidentiality",
                                            "Learn_more_about_who_we_are_",
                                        )}
                                    </p>
                                    <p
                                        style={{ textAlign: "left" }}
                                        className="mb-4"
                                    >
                                        {translate(
                                            "Confidentiality",
                                            "Your_consent_applies_to_following_areas",
                                        )}{" "}
                                        :{" "}
                                        <a href="https://kalypay.com">
                                            https://kalypay.com
                                        </a>
                                    </p>
                                    <p
                                        style={{ textAlign: "left" }}
                                        className="mb-4"
                                    ></p>
                                    <p
                                        style={{ textAlign: "left" }}
                                        className="mb-4"
                                    ></p>
                                    <p
                                        style={{ textAlign: "left" }}
                                        className="mb-4"
                                    ></p>
                                    <p
                                        style={{ textAlign: "left" }}
                                        className="mb-4"
                                    >
                                        <strong>
                                            {translate(
                                                "Confidentiality",
                                                "ARTICLE_5",
                                            )}
                                        </strong>
                                        –
                                        {translate(
                                            "Confidentiality",
                                            "WHAT_ARE_COOKIES",
                                        )}
                                    </p>
                                    <p
                                        style={{ textAlign: "left" }}
                                        className="mb-4"
                                    >
                                        {translate(
                                            "Confidentiality",
                                            "Cookies_are_small_files_text_that_",
                                        )}
                                    </p>
                                    <p
                                        style={{ textAlign: "left" }}
                                        className="mb-4"
                                    ></p>
                                    <p
                                        style={{ textAlign: "left" }}
                                        className="mb-4"
                                    ></p>
                                    <p
                                        style={{ textAlign: "left" }}
                                        className="mb-4"
                                    ></p>
                                    <p
                                        style={{ textAlign: "left" }}
                                        className="mb-4"
                                    >
                                        <strong>
                                            {translate(
                                                "Confidentiality",
                                                "ARTICLE_6",
                                            )}
                                        </strong>{" "}
                                        –
                                        {translate(
                                            "Confidentiality",
                                            "EXFHOW_DO_WE_USE_COOKIES",
                                        )}
                                    </p>
                                    <p
                                        style={{ textAlign: "left" }}
                                        className="mb-4"
                                    >
                                        {translate(
                                            "Confidentiality",
                                            "Like_most_online_services_our_",
                                        )}
                                    </p>
                                    <p
                                        style={{ textAlign: "left" }}
                                        className="mb-4"
                                    >
                                        {translate(
                                            "Confidentiality",
                                            "Third_party_cookies_used_on_our_website_",
                                        )}
                                    </p>
                                    <p
                                        style={{ textAlign: "left" }}
                                        className="mb-4"
                                    ></p>
                                    <p
                                        style={{ textAlign: "left" }}
                                        className="mb-4"
                                    ></p>
                                    <p
                                        style={{ textAlign: "left" }}
                                        className="mb-4"
                                    ></p>
                                    <p
                                        style={{ textAlign: "left" }}
                                        className="mb-4"
                                    >
                                        <strong>
                                            {translate(
                                                "Confidentiality",
                                                "ARTICLE_7",
                                            )}
                                        </strong>{" "}
                                        –
                                        {translate(
                                            "Confidentiality",
                                            "WHAT_TYPES_OF_COOKIES_DO_WE_USE",
                                        )}
                                    </p>
                                    <p
                                        style={{ textAlign: "left" }}
                                        className="mb-4"
                                    >
                                        <strong>
                                            {translate(
                                                "Confidentiality",
                                                "Essential",
                                            )}{" "}
                                            :
                                        </strong>{" "}
                                        {translate(
                                            "Confidentiality",
                                            "Some_cookies_are_essential_for_you_can_",
                                        )}
                                    </p>
                                    <p
                                        style={{ textAlign: "left" }}
                                        className="mb-4"
                                    >
                                        <strong>
                                            {translate(
                                                "Confidentiality",
                                                "Statistics",
                                            )}{" "}
                                            :
                                        </strong>{" "}
                                        {translate(
                                            "Confidentiality",
                                            "these_cookies_store_information_such_",
                                        )}
                                    </p>
                                    <p
                                        style={{ textAlign: "left" }}
                                        className="mb-4"
                                    >
                                        <strong>
                                            {translate(
                                                "Confidentiality",
                                                "Marketing",
                                            )}{" "}
                                            :
                                        </strong>{" "}
                                        {translate(
                                            "Confidentiality",
                                            "Our_website_Web_displays_ads_These_",
                                        )}
                                    </p>
                                    <p
                                        style={{ textAlign: "left" }}
                                        className="mb-4"
                                    >
                                        {translate(
                                            "Confidentiality",
                                            "The_information_stored_in_these_",
                                        )}
                                    </p>
                                    <p
                                        style={{ textAlign: "left" }}
                                        className="mb-4"
                                    >
                                        <strong>
                                            {translate(
                                                "Confidentiality",
                                                "Functional",
                                            )}{" "}
                                            :
                                        </strong>{" "}
                                        {translate(
                                            "Confidentiality",
                                            "It_s_about_Cookies_that_help_some_non_essential_",
                                        )}
                                    </p>
                                    <p
                                        style={{ textAlign: "left" }}
                                        className="mb-4"
                                    >
                                        <strong>
                                            {translate(
                                                "Confidentiality",
                                                "Preferences",
                                            )}{" "}
                                            :
                                        </strong>{" "}
                                        {translate(
                                            "Confidentiality",
                                            "these_cookies_help_us_store_your_Navigation_",
                                        )}
                                    </p>
                                    <p
                                        style={{ textAlign: "left" }}
                                        className="mb-4"
                                    ></p>
                                    <p
                                        style={{ textAlign: "left" }}
                                        className="mb-4"
                                    ></p>
                                    <p
                                        style={{ textAlign: "left" }}
                                        className="mb-4"
                                    ></p>
                                    <p
                                        style={{ textAlign: "left" }}
                                        className="mb-4"
                                    >
                                        <strong>
                                            {translate(
                                                "Confidentiality",
                                                "ARTICLE_8",
                                            )}
                                        </strong>{" "}
                                        –
                                        {translate(
                                            "Confidentiality",
                                            "HOW_CAN_I_CONTROL_MY_COOKIE_MATERIAL_PREFERENCE",
                                        )}
                                    </p>
                                    <p
                                        style={{ textAlign: "left" }}
                                        className="mb-4"
                                    >
                                        {translate(
                                            "Confidentiality",
                                            "If_you_decide_to_change_your_preferences_",
                                        )}
                                    </p>
                                    <p
                                        style={{ textAlign: "left" }}
                                        className="mb-4"
                                    >
                                        {translate(
                                            "Confidentiality",
                                            "In_addition_to_that_different_browsers_",
                                        )}
                                        :{" "}
                                        <a className="wt-cli-manage-consent-link">
                                            {translate(
                                                "Confidentiality",
                                                "Cookie_settings_",
                                            )}
                                        </a>
                                    </p>
                                    <p
                                        style={{ textAlign: "left" }}
                                        className="mb-4"
                                    ></p>
                                    <p
                                        style={{ textAlign: "left" }}
                                        className="mb-4"
                                    ></p>
                                    <p
                                        style={{ textAlign: "left" }}
                                        className="mb-4"
                                    ></p>
                                    <p
                                        style={{ textAlign: "left" }}
                                        className="mb-4"
                                    >
                                        <strong>
                                            {translate(
                                                "Confidentiality",
                                                "CONNECTIONS",
                                            )}
                                        </strong>
                                    </p>
                                    <p
                                        style={{ textAlign: "left" }}
                                        className="mb-4"
                                    >
                                        {translate(
                                            "Confidentiality",
                                            "You_are_looking_for_websites_on_our_",
                                        )}
                                    </p>
                                    <p> </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <Section03 />
            <Section04 />
            <Section05 />
            <Section06 />
            <Section07 />
            <Section08 />
            <Faqs /> */}
            <Cta />
        </>
    );
};

export default Confidentialite;
