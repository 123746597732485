import { createContext, useContext, useEffect, useState } from "react";
import { UserCryptoWallet } from "../../Models/UserWallet";
import useAuthentication from "../../Services/Authentication/useAuthentication";
import UtilsService from "../../Services/UtilsService";
import WalletService from "../../Services/Wallets/WalletService";
import { useCardContext } from "./CardProvider";
import UserCard, { USER_CARD_STATUS } from "../../Models/UserCard";

interface ContextProps {
    assets: UserCryptoWallet[];
    currentAsset: UserCryptoWallet | undefined;
    refreshAssets: () => any;
    setCurrentAsset: (asset: UserCryptoWallet) => any;
    walletSynchronisation: boolean;
    refreshed: number;
    getGlobalBalance: () => number;
    getFiatBalance: () => number;
    convertAmount: (amount: number) => number;
    convertAmountWithCurrency: (amount: number, limit?: number) => string;
    getCardBalance: () => number;
    getCryptoBalance: () => number;
}

const AssetsContext = createContext<ContextProps>({
    assets: [],
    currentAsset: undefined,
    refreshAssets: () => true,
    setCurrentAsset: (val: UserCryptoWallet) => {
        return;
    },
    walletSynchronisation: false,
    refreshed: 0,
    getGlobalBalance: () => 0,
    convertAmount: (amount: number) => 0,
    convertAmountWithCurrency: (amount: number) => "",
    getFiatBalance: () => 0,
    getCardBalance: () => 0,
    getCryptoBalance: () => 0,
});

export default function AssetsProvider({
    children,
}: {
    children: React.ReactNode;
}) {
    const { currency_data } = useAuthentication();
    const [walletSynchronisation, setWalletSynchronisation] =
        useState<boolean>(false);
    const [nbCall, setNbCall] = useState<number>(0);
    const [assets, setAssets] = useState<UserCryptoWallet[]>([]);
    const [asset, setAsset] = useState<UserCryptoWallet | undefined>(undefined);

    const { userCards } = useCardContext();

    const convertAmount = (amount: number) => {
        const converted = UtilsService.convertDevise(
            1,
            currency_data.usd_unit_price,
            amount,
            currency_data.usd_unit_price > 0.5 ? 8 : 2,
        );

        return converted;
    };

    const convertAmountWithCurrency = (amount: number, limit?: number) => {
        const converted = convertAmount(amount);

        return `${currency_data.symbol}${UtilsService.formatAmount(
            converted,
            limit,
        )}`;
    };

    const refreshAssets = async () => {
        setWalletSynchronisation(true);

        const data = await WalletService.getUserWallet();
        setWalletSynchronisation(false);
        setAssets(data);
        setNbCall(nbCall + 1);
    };

    useEffect(() => {
        if (nbCall === 0) {
            refreshAssets();
        }
    }, [nbCall]);

    const getGlobalBalance = (): number => {
        const total = assets.reduce(
            (prevTotal: number, asset: UserCryptoWallet) => {
                const current = asset.wallet.solde * asset.current_price;

                return prevTotal + current;
            },
            0,
        );

        return total;
    };
    const getFiatBalance = (): number => {
        const total = assets.reduce(
            (prevTotal: number, asset: UserCryptoWallet) => {
                const current =
                    asset.type === "fiat"
                        ? asset.wallet.solde * asset.current_price
                        : 0;

                return prevTotal + current;
            },
            0,
        );

        return total;
    };
    const getCardBalance = (): number => {
        const total = userCards.reduce((prevTotal: number, card: UserCard) => {
            const current = [
                USER_CARD_STATUS.ACTIVE,
                USER_CARD_STATUS.PREACTIVE,
            ].includes(card.status)
                ? card.last_balance
                : 0;

            return prevTotal + current;
        }, 0);

        return total;
    };
    const getCryptoBalance = (): number => {
        const total = assets.reduce(
            (prevTotal: number, asset: UserCryptoWallet) => {
                const current =
                    asset.type === "crypto"
                        ? asset.wallet.solde * asset.current_price
                        : 0;

                return prevTotal + current;
            },
            0,
        );

        return total;
    };

    return (
        <AssetsContext.Provider
            value={{
                walletSynchronisation,
                assets,
                refreshAssets,
                getGlobalBalance,
                getFiatBalance,
                getCardBalance,
                getCryptoBalance,
                refreshed: nbCall,
                currentAsset: asset,
                setCurrentAsset: setAsset,
                convertAmount: convertAmount,
                convertAmountWithCurrency: convertAmountWithCurrency,
            }}
        >
            {children}
        </AssetsContext.Provider>
    );
}

export const useAssets = () => useContext(AssetsContext);
