import { ICurrency } from "./Currency";

interface Parrain {
    uid: string;
    first_name: string;
    last_name: string;
}

export enum UserType {
    PARTICULIER = "particulier",
    ENTERPRISE = "enterprise",
}
export default class User {
    id?: number;
    uid: string;
    reinvest_auto: boolean;
    hide_zero_amount: boolean;
    hide_not_active_earning?: boolean;
    user_name: string;
    last_name: string;
    first_name: string;
    email: string;
    scopes?: string[];
    photo?: string;
    parrain?: Parrain;
    haveTransactionPassword?: boolean;
    active_2fa?: boolean;
    created_at?: Date;
    cap?: number;
    cag?: number;
    is_active?: boolean;
    is_fiat?: boolean;
    kyc?: string;
    type?: UserType;
    entreprise?: string;
    siret?: string;
    fidelity?: any;
    date_of_birth?: string;
    city?: string;
    longitude?: string;
    latitude?: string;
    post_code?: string;
    address?: string;
    phone_country_code?: string;
    phone?: string;
    currency?: ICurrency;
    country!: string;
    withdrawal_security_type?: WithdrawSecurityType;
    is_blocked?: boolean;
    kyc_data?: any;
    grant_kyc?: boolean;
    is_bloqued?: boolean;
    constructor(
        uid: string,
        user_name: string,
        last_name: string,
        first_name: string,
        email: string,
        reinvest_auto: boolean,
        hide_zero_amount: boolean,
        country: string,
    ) {
        this.uid = uid;
        this.user_name = user_name;
        this.last_name = last_name;
        this.first_name = first_name;
        this.email = email;
        this.reinvest_auto = reinvest_auto;
        this.hide_zero_amount = hide_zero_amount;
        this.country = country;
    }
}

export interface IUserSignUp {
    email: string;
    first_name: string;
    last_name: string;
    username?: string;
    password: string;
    uid_parrain?: string;
    token?: string | null;

    type?: string;
    entreprise?: string;
    siret?: string;
    country?: string;
}

export interface IUserPasswordUpdate {
    oldPassword: string;
    newPassword: string;
}
export interface IUserPasswordReset {
    new_password: string;
    confirm_password: string;
    token: string;
}

export interface IUserTransactionPasswordCreate {
    transactionPassword: string;
}

export interface User2Fa {
    key?: string;
    application?: string;
    username?: string;
    status: boolean;
}
export interface Token2Fa {
    token: string;
}
export interface IUserRequestResetPassword {
    email: string;
    url: string;
}
export interface DirectActifCount {
    children_with_deleted: number;
    children: number;
    child_grandchild: number;
    nb_direct_actif: number;
    wallet_amount: number;
}

export interface PublicUserDta {
    email: string;
    first_name: string;
    last_name: string;
    username: string | null | undefined;
    photo: string | null | undefined;
    uid: string;
    cap?: number;
    cag?: number;
    is_active?: boolean;
    children: PublicUserDta[];
    date_of_birth?: Date;
    phone_country_code?: string;
    address?: string;
    phone?: string;
    position?: {
        lat: string;
        lng: string;
    };
    country?: string;
}

export interface ICommissionType {
    type: boolean;
}

export interface IEditProfil {
    uid: string;
    email: string;
}

export interface IResetTransactionPassword {
    code: string;
    new_password: string;
}

export interface IAddAffiliation {
    uid_to_add: string;
    uid_parrain: string;
}

export interface HeaderFilter {
    [key: string]: {
        filter: boolean;
        is_asc: boolean;
    };
}

export interface Uid {
    uid: string;
}

export enum RoleName {
    ADMIN = "admin",
    USER = "user",
    MERCHANT = "merchant",
    POINT_OF_SALE = "point_of_sale",
    COMMERCIAL = "commercial",
    SALES_MANAGER = "sales_manager",
    ADVISOR = "advisor",
    FINANCIAL_ADMIN = "financial_admin",

    BUSINESSS_DEVELOPER = "business_developer",
}

export enum WithdrawSecurityType {
    TYPE_EMAIL = "email",
    TYPE_2FA = "2fa",
}

export interface WithdrawSecurityTypePayload {
    type: WithdrawSecurityType;
    otp?: string;
}

export interface IUnlockAccount {
    // new_password: string;
    // confirm_password: string;
    code: string;
    uid: string;
}
export interface MailPayload {
    mail: string;
    code?: string;
}
