import { NavLink } from "react-router-dom";
import translator from "../../../Components/Extra/Translation/Translate";
import React, { useState } from "react";

const AccordionItem = ({
    question,
    answer,
}: {
    question: string;
    answer: React.ReactNode;
}) => {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <div className="bg-slate-700 rounded-lg">
            <button
                type="button"
                className="w-full text-base font-semibold text-left p-6 text-slate-300 flex items-center"
                onClick={() => setIsOpen(!isOpen)}
            >
                <span className="mr-4">{question}</span>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className={`w-7 fill-current ml-auto shrink-0 transition-transform duration-200 ${
                        isOpen ? "rotate-180" : ""
                    }`}
                    viewBox="0 0 24 24"
                >
                    {isOpen ? (
                        <path d="M19 13H5v-2h14v2z" />
                    ) : (
                        <path d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z" />
                    )}
                </svg>
            </button>
            <div
                className={`overflow-hidden transition-all duration-300 ease-in-out ${
                    isOpen ? "max-h-[1000px]" : "max-h-0"
                }`}
            >
                <div className="p-6">
                    <p className="text-sm text-gray-600">{answer}</p>
                </div>
            </div>
        </div>
    );
};

export default function FAQSection() {
    const { translate } = translator();

    return (
        <section className="flex justify-center items-center mx-4 mb-20 lg:mx-20">
            <div className="max-w-screen-xl mx-auto w-full">
                <div className="font-[sans-serif]">
                    <div className="mb-12">
                        <h2 className="text-white text-center bg-clip-text font-bold md:!text-5xl text-3xl leading-tight tracking-tighter">
                            Frequently asked questions
                        </h2>
                    </div>
                    <div className="grid lg:grid-cols-2 items-baseline gap-6">
                        <AccordionItem
                            question={translate("Landing_Support", "To_start")}
                            answer={
                                <div className="text-slate-500 ">
                                    <ul className="text-slate-500 text-left space-y-2 ml-3">
                                        <li className="flex items-start">
                                            <svg
                                                className="w-3 h-3 fill-current text-kalypay-500 mr-3 mt-1.5 shrink-0"
                                                viewBox="0 0 12 12"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <circle
                                                    cx="6"
                                                    cy="6"
                                                    r="3"
                                                    fill="yellow"
                                                />
                                            </svg>
                                            <NavLink
                                                to={`/support/to-start/getting-started-with-kalypay`}
                                            >
                                                {translate(
                                                    "Landing_Support",
                                                    "Getting_started_with_KalyPay",
                                                )}
                                            </NavLink>
                                        </li>
                                        <li className="flex items-start">
                                            <svg
                                                className="w-3 h-3 fill-current text-kalypay-500 mr-3 mt-1.5 shrink-0"
                                                viewBox="0 0 12 12"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <circle
                                                    cx="6"
                                                    cy="6"
                                                    r="3"
                                                    fill="yellow"
                                                />
                                            </svg>
                                            <NavLink
                                                to={`/support/to-start/verify-your-account`}
                                            >
                                                {translate(
                                                    "Landing_Support",
                                                    "Verify_your_account",
                                                )}
                                            </NavLink>
                                        </li>
                                        <li className="flex items-start">
                                            <svg
                                                className="w-3 h-3 fill-current text-kalypay-500 mr-3 mt-1.5 shrink-0"
                                                viewBox="0 0 12 12"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <circle
                                                    cx="6"
                                                    cy="6"
                                                    r="3"
                                                    fill="yellow"
                                                />
                                            </svg>
                                            <NavLink
                                                to={`/support/to-start/crypto-education`}
                                            >
                                                {translate(
                                                    "Landing_Support",
                                                    "Crypto_Education",
                                                )}
                                            </NavLink>
                                        </li>
                                    </ul>
                                </div>
                            }
                        />

                        <AccordionItem
                            question={translate(
                                "Landing_Support",
                                "KalyPay_cards",
                            )}
                            answer={
                                <div className="text-slate-500">
                                    <ul className="text-slate-500 text-left space-y-2 ml-3">
                                        <li className="flex items-start">
                                            <svg
                                                className="w-3 h-3 fill-current text-kalypay-500 mr-3 mt-1.5 shrink-0"
                                                viewBox="0 0 12 12"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <circle
                                                    cx="6"
                                                    cy="6"
                                                    r="3"
                                                    fill="yellow"
                                                />
                                            </svg>
                                            <NavLink
                                                to={`/support/kalypay-cards/manage-your-subscription`}
                                            >
                                                {translate(
                                                    "Landing_Support",
                                                    "Manage_your_subscription",
                                                )}
                                            </NavLink>
                                        </li>
                                        <li className="flex items-start">
                                            <svg
                                                className="w-3 h-3 fill-current text-kalypay-500 mr-3 mt-1.5 shrink-0"
                                                viewBox="0 0 12 12"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <circle
                                                    cx="6"
                                                    cy="6"
                                                    r="3"
                                                    fill="yellow"
                                                />
                                            </svg>
                                            <NavLink
                                                to={`/support/kalypay-cards/get-your-cards`}
                                            >
                                                {translate(
                                                    "Landing_Support",
                                                    "Get_your_cards",
                                                )}
                                            </NavLink>
                                        </li>
                                        <li className="flex items-start">
                                            <svg
                                                className="w-3 h-3 fill-current text-kalypay-500 mr-3 mt-1.5 shrink-0"
                                                viewBox="0 0 12 12"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <circle
                                                    cx="6"
                                                    cy="6"
                                                    r="3"
                                                    fill="yellow"
                                                />
                                            </svg>
                                            <NavLink
                                                to={`/support/kalypay-cards/transactions-on-your-cards`}
                                            >
                                                {translate(
                                                    "Landing_Support",
                                                    "Transactions_on_your_cards",
                                                )}
                                            </NavLink>
                                        </li>
                                    </ul>
                                </div>
                            }
                        />

                        <AccordionItem
                            question={translate(
                                "Landing_Support",
                                "KalyPay_account_management",
                            )}
                            answer={
                                <div className="text-slate-500">
                                    <ul className="text-slate-500 text-left space-y-2 ml-3">
                                        <li className="flex items-start">
                                            <svg
                                                className="w-3 h-3 fill-current text-kalypay-500 mr-3 mt-1.5 shrink-0"
                                                viewBox="0 0 12 12"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <circle
                                                    cx="6"
                                                    cy="6"
                                                    r="3"
                                                    fill="yellow"
                                                />
                                            </svg>
                                            <NavLink
                                                to={`/support/kalypay-account-management/access-the-kalypay-account`}
                                            >
                                                {translate(
                                                    "Landing_Support",
                                                    "Access_the_KalyPay_account",
                                                )}
                                            </NavLink>
                                        </li>
                                        <li className="flex items-start">
                                            <svg
                                                className="w-3 h-3 fill-current text-kalypay-500 mr-3 mt-1.5 shrink-0"
                                                viewBox="0 0 12 12"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <circle
                                                    cx="6"
                                                    cy="6"
                                                    r="3"
                                                    fill="yellow"
                                                />
                                            </svg>
                                            <NavLink
                                                to={`/support/kalypay-account-management/manage-your-account`}
                                            >
                                                {translate(
                                                    "Landing_Support",
                                                    "Manage_your_account",
                                                )}
                                            </NavLink>
                                        </li>
                                        <li className="flex items-start">
                                            <svg
                                                className="w-3 h-3 fill-current text-kalypay-500 mr-3 mt-1.5 shrink-0"
                                                viewBox="0 0 12 12"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <circle
                                                    cx="6"
                                                    cy="6"
                                                    r="3"
                                                    fill="yellow"
                                                />
                                            </svg>
                                            <NavLink
                                                to={`/support/kalypay-account-management/withdraw-funds-from-a-closed-account`}
                                            >
                                                {translate(
                                                    "Landing_Support",
                                                    "Withdraw_funds_from_a_closed_account",
                                                )}
                                            </NavLink>
                                        </li>
                                        <li className="flex items-start">
                                            <svg
                                                className="w-3 h-3 fill-current text-kalypay-500 mr-3 mt-1.5 shrink-0"
                                                viewBox="0 0 12 12"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <circle
                                                    cx="6"
                                                    cy="6"
                                                    r="3"
                                                    fill="yellow"
                                                />
                                            </svg>
                                            <NavLink
                                                to={`/support/kalypay-account-management/prohibited-region`}
                                            >
                                                {translate(
                                                    "Landing_Support",
                                                    "Prohibited_region",
                                                )}
                                            </NavLink>
                                        </li>
                                    </ul>
                                </div>
                            }
                        />
                        <AccordionItem
                            question={translate("Landing_Support", "Security")}
                            answer={
                                <div className="text-slate-500">
                                    <ul className="text-slate-500 text-left space-y-2 ml-3">
                                        <li className="flex items-start">
                                            <svg
                                                className="w-3 h-3 fill-current text-kalypay-500 mr-3 mt-1.5 shrink-0"
                                                viewBox="0 0 12 12"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <circle
                                                    cx="6"
                                                    cy="6"
                                                    r="3"
                                                    fill="yellow"
                                                />
                                            </svg>
                                            <NavLink
                                                to={`/support/security/avoid-phishing`}
                                            >
                                                {translate(
                                                    "Landing_Support",
                                                    "Avoid_phishing",
                                                )}
                                            </NavLink>
                                        </li>
                                        <li className="flex items-start">
                                            <svg
                                                className="w-3 h-3 fill-current text-kalypay-500 mr-3 mt-1.5 shrink-0"
                                                viewBox="0 0 12 12"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <circle
                                                    cx="6"
                                                    cy="6"
                                                    r="3"
                                                    fill="yellow"
                                                />
                                            </svg>
                                            <NavLink
                                                to={`/support/security/avoid-scams`}
                                            >
                                                {translate(
                                                    "Landing_Support",
                                                    "Avoid_scams",
                                                )}
                                            </NavLink>
                                        </li>
                                    </ul>
                                </div>
                            }
                        />
                        <AccordionItem
                            question={translate(
                                "Landing_Support",
                                "Fees_and_transactions_on_KalyPay",
                            )}
                            answer={
                                <div className="text-slate-500">
                                    <ul className="text-slate-500 text-left space-y-2 ml-3">
                                        <li className="flex items-start">
                                            <svg
                                                className="w-3 h-3 fill-current text-kalypay-500 mr-3 mt-1.5 shrink-0"
                                                viewBox="0 0 12 12"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <circle
                                                    cx="6"
                                                    cy="6"
                                                    r="3"
                                                    fill="yellow"
                                                />
                                            </svg>
                                            <NavLink
                                                to={`/support/fees-and-transaction-on-kalypay/transaction`}
                                            >
                                                {translate(
                                                    "Landing_Support",
                                                    "Transaction",
                                                )}
                                            </NavLink>
                                        </li>
                                        <li className="flex items-start">
                                            <svg
                                                className="w-3 h-3 fill-current text-kalypay-500 mr-3 mt-1.5 shrink-0"
                                                viewBox="0 0 12 12"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <circle
                                                    cx="6"
                                                    cy="6"
                                                    r="3"
                                                    fill="yellow"
                                                />
                                            </svg>
                                            <NavLink
                                                to={`/support/fees-and-transaction-on-kalypay/operation-with-fiat-currencies`}
                                            >
                                                {translate(
                                                    "Landing_Support",
                                                    "Operation_with_Fiat_currencies",
                                                )}
                                            </NavLink>
                                        </li>
                                        <li className="flex items-start">
                                            <svg
                                                className="w-3 h-3 fill-current text-kalypay-500 mr-3 mt-1.5 shrink-0"
                                                viewBox="0 0 12 12"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <circle
                                                    cx="6"
                                                    cy="6"
                                                    r="3"
                                                    fill="yellow"
                                                />
                                            </svg>
                                            <NavLink
                                                to={`/support/fees-and-transaction-on-kalypay/send-or-receive-crypto`}
                                            >
                                                {translate(
                                                    "Landing_Support",
                                                    "Send_or_receive_crypto",
                                                )}
                                            </NavLink>
                                        </li>
                                        <li className="flex items-start">
                                            <svg
                                                className="w-3 h-3 fill-current text-kalypay-500 mr-3 mt-1.5 shrink-0"
                                                viewBox="0 0 12 12"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <circle
                                                    cx="6"
                                                    cy="6"
                                                    r="3"
                                                    fill="yellow"
                                                />
                                            </svg>
                                            <NavLink
                                                to={`/support/fees-and-transaction-on-kalypay/buy-sell-or-convert-crypto`}
                                            >
                                                {translate(
                                                    "Landing_Support",
                                                    "Buy_sell_or_convert_crypto",
                                                )}
                                            </NavLink>
                                        </li>
                                    </ul>
                                </div>
                            }
                        />
                        <AccordionItem
                            question={translate(
                                "Landing_Support",
                                "KalyPay_Agents",
                            )}
                            answer={
                                <div className="text-slate-500">
                                    <ul className="text-slate-500 text-left space-y-2 ml-3">
                                        <li className="flex items-start">
                                            <svg
                                                className="w-3 h-3 fill-current text-kalypay-500 mr-3 mt-1.5 shrink-0"
                                                viewBox="0 0 12 12"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <circle
                                                    cx="6"
                                                    cy="6"
                                                    r="3"
                                                    fill="yellow"
                                                />
                                            </svg>
                                            <NavLink
                                                to={`/support/kalypay-agents/agent`}
                                            >
                                                {translate(
                                                    "Landing_Support",
                                                    "Agent",
                                                )}
                                            </NavLink>
                                        </li>
                                    </ul>
                                </div>
                            }
                        />
                        <AccordionItem
                            question={translate(
                                "Landing_Support",
                                "Earn_rewards",
                            )}
                            answer={
                                <div className="text-slate-500">
                                    <ul className="text-slate-500 text-left space-y-2 ml-3">
                                        <li className="flex items-start">
                                            <svg
                                                className="w-3 h-3 fill-current text-kalypay-500 mr-3 mt-1.5 shrink-0"
                                                viewBox="0 0 12 12"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <circle
                                                    cx="6"
                                                    cy="6"
                                                    r="3"
                                                    fill="yellow"
                                                />
                                            </svg>
                                            <NavLink
                                                to={`/support/earn-rewards/loyalty`}
                                            >
                                                {translate(
                                                    "Landing_Support",
                                                    "Loyalty",
                                                )}
                                            </NavLink>
                                        </li>
                                        <li className="flex items-start">
                                            <svg
                                                className="w-3 h-3 fill-current text-kalypay-500 mr-3 mt-1.5 shrink-0"
                                                viewBox="0 0 12 12"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <circle
                                                    cx="6"
                                                    cy="6"
                                                    r="3"
                                                    fill="yellow"
                                                />
                                            </svg>
                                            <NavLink
                                                to={`/support/earn-rewards/benefits`}
                                            >
                                                {translate(
                                                    "Landing_Support",
                                                    "Benefits",
                                                )}
                                            </NavLink>
                                        </li>
                                    </ul>
                                </div>
                            }
                        />
                        <AccordionItem
                            question={translate("Landing_Support", "Borrowing")}
                            answer={
                                <div className="text-slate-500">
                                    <ul className="text-slate-500 text-left space-y-2 ml-3">
                                        <li className="flex items-start">
                                            <svg
                                                className="w-3 h-3 fill-current text-kalypay-500 mr-3 mt-1.5 shrink-0"
                                                viewBox="0 0 12 12"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <circle
                                                    cx="6"
                                                    cy="6"
                                                    r="3"
                                                    fill="yellow"
                                                />
                                            </svg>
                                            <NavLink
                                                to={`/support/borrowing/borrowing`}
                                            >
                                                {translate(
                                                    "Landing_Support",
                                                    "Borrowing_SUB",
                                                )}
                                            </NavLink>
                                        </li>
                                    </ul>
                                </div>
                            }
                        />
                    </div>
                </div>
            </div>
        </section>
    );
}

// export default function Faqs() {
//     const [isOpen, setIsOpen] = useState(false);
//     const { translate } = translator();

//     return (
//         <section>
//             <div className="max-w-6xl mx-auto px-4 sm:px-6">
//                 <div className="md:pb-20" data-aos="fade-up">
//                     {/* Section header */}
//                     <div className="pb-12">
//                         <h2 className="h2">FAQs</h2>
//                     </div>

//                     {/* Columns */}
//                     <div className="md:flex md:space-x-12 space-y-8 md:space-y-0">
//                         {/* Column */}
//                         <div className="w-full md:w-1/2 space-y-8">
//                             {/* Item */}
//                             <div className="space-y-2">
//                                 <div
//                                     aria-current="page"
//                                     className="text-xl font-bold"
//                                 >
//                                     {translate("Landing_Support", "To_start")}
//                                 </div>
//                                 <div className="text-slate-500">
//                                     <ul className="text-slate-500 text-left space-y-2 ml-3">
//                                         <li className="flex items-start">
//                                             <svg
//                                                 className="w-3 h-3 fill-current text-kalypay-500 mr-3 mt-1.5 shrink-0"
//                                                 viewBox="0 0 12 12"
//                                                 xmlns="http://www.w3.org/2000/svg"
//                                             >
//                                                 <circle
//                                                     cx="6"
//                                                     cy="6"
//                                                     r="3"
//                                                     fill="yellow"
//                                                 />
//                                             </svg>
//                                             <NavLink
//                                                 to={`/support/to-start/getting-started-with-kalypay`} // /support/to-start/getting-started-with-kalypay
//                                             >
//                                                 {translate(
//                                                     "Landing_Support",
//                                                     "Getting_started_with_KalyPay",
//                                                 )}
//                                             </NavLink>
//                                         </li>
//                                         <li className="flex items-start">
//                                             <svg
//                                                 className="w-3 h-3 fill-current text-kalypay-500 mr-3 mt-1.5 shrink-0"
//                                                 viewBox="0 0 12 12"
//                                                 xmlns="http://www.w3.org/2000/svg"
//                                             >
//                                                 <circle
//                                                     cx="6"
//                                                     cy="6"
//                                                     r="3"
//                                                     fill="yellow"
//                                                 />
//                                             </svg>
//                                             <NavLink
//                                                 to={`/support/to-start/verify-your-account`} // /support/to-start/verify-your-account
//                                             >
//                                                 {translate(
//                                                     "Landing_Support",
//                                                     "Verify_your_account",
//                                                 )}
//                                             </NavLink>
//                                         </li>
//                                         <li className="flex items-start">
//                                             <svg
//                                                 className="w-3 h-3 fill-current text-kalypay-500 mr-3 mt-1.5 shrink-0"
//                                                 viewBox="0 0 12 12"
//                                                 xmlns="http://www.w3.org/2000/svg"
//                                             >
//                                                 <circle
//                                                     cx="6"
//                                                     cy="6"
//                                                     r="3"
//                                                     fill="yellow"
//                                                 />
//                                             </svg>
//                                             <NavLink
//                                                 to={`/support/to-start/crypto-education`} // /support/to-start/crypto-education
//                                             >
//                                                 {translate(
//                                                     "Landing_Support",
//                                                     "Crypto_Education",
//                                                 )}
//                                             </NavLink>
//                                         </li>
//                                     </ul>
//                                 </div>
//                             </div>
//                         </div>
//                         <div className="w-full md:w-1/2 space-y-8">
//                             {/* Item */}
//                             <div className="space-y-2">
//                                 <div
//                                     aria-current="page"
//                                     className="text-xl font-bold"
//                                 >
//                                     {translate(
//                                         "Landing_Support",
//                                         "KalyPay_cards",
//                                     )}
//                                 </div>
//                                 <div className="text-slate-500">
//                                     <ul className="text-slate-500 text-left space-y-2 ml-3">
//                                         <li className="flex items-start">
//                                             <svg
//                                                 className="w-3 h-3 fill-current text-kalypay-500 mr-3 mt-1.5 shrink-0"
//                                                 viewBox="0 0 12 12"
//                                                 xmlns="http://www.w3.org/2000/svg"
//                                             >
//                                                 <circle
//                                                     cx="6"
//                                                     cy="6"
//                                                     r="3"
//                                                     fill="yellow"
//                                                 />
//                                             </svg>
//                                             <NavLink
//                                                 to={`/support/kalypay-cards/manage-your-subscription`} // /support/kalypay-cards/manage-your-subscription
//                                             >
//                                                 {translate(
//                                                     "Landing_Support",
//                                                     "Manage_your_subscription",
//                                                 )}
//                                             </NavLink>
//                                         </li>
//                                         <li className="flex items-start">
//                                             <svg
//                                                 className="w-3 h-3 fill-current text-kalypay-500 mr-3 mt-1.5 shrink-0"
//                                                 viewBox="0 0 12 12"
//                                                 xmlns="http://www.w3.org/2000/svg"
//                                             >
//                                                 <circle
//                                                     cx="6"
//                                                     cy="6"
//                                                     r="3"
//                                                     fill="yellow"
//                                                 />
//                                             </svg>
//                                             <NavLink
//                                                 to={`/support/kalypay-cards/get-your-cards`} // /support/kalypay-cards/get-your-cards
//                                             >
//                                                 {translate(
//                                                     "Landing_Support",
//                                                     "Get_your_cards",
//                                                 )}
//                                             </NavLink>
//                                         </li>
//                                         <li className="flex items-start">
//                                             <svg
//                                                 className="w-3 h-3 fill-current text-kalypay-500 mr-3 mt-1.5 shrink-0"
//                                                 viewBox="0 0 12 12"
//                                                 xmlns="http://www.w3.org/2000/svg"
//                                             >
//                                                 <circle
//                                                     cx="6"
//                                                     cy="6"
//                                                     r="3"
//                                                     fill="yellow"
//                                                 />
//                                             </svg>
//                                             <NavLink
//                                                 to={`/support/kalypay-cards/transactions-on-your-cards`} // /support/kalypay-cards/transactions-on-your-cards
//                                             >
//                                                 {translate(
//                                                     "Landing_Support",
//                                                     "Transactions_on_your_cards",
//                                                 )}
//                                             </NavLink>
//                                         </li>
//                                     </ul>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                     <div className="md:flex md:space-x-12 space-y-8 md:space-y-0 mt-4">
//                         <div className="w-full md:w-1/2 space-y-8">
//                             {/* Item */}
//                             <div className="space-y-2">
//                                 <div
//                                     aria-current="page"
//                                     className="text-xl font-bold"
//                                 >
//                                     {translate(
//                                         "Landing_Support",
//                                         "KalyPay_account_management",
//                                     )}
//                                 </div>
//                                 <div className="text-slate-500">
//                                     <ul className="text-slate-500 text-left space-y-2 ml-3">
//                                         <li className="flex items-start">
//                                             <svg
//                                                 className="w-3 h-3 fill-current text-kalypay-500 mr-3 mt-1.5 shrink-0"
//                                                 viewBox="0 0 12 12"
//                                                 xmlns="http://www.w3.org/2000/svg"
//                                             >
//                                                 <circle
//                                                     cx="6"
//                                                     cy="6"
//                                                     r="3"
//                                                     fill="yellow"
//                                                 />
//                                             </svg>
//                                             <NavLink
//                                                 to={`/support/kalypay-account-management/access-the-kalypay-account`} // /support/kalypay-account-management/access-the-kalypay-account
//                                             >
//                                                 {translate(
//                                                     "Landing_Support",
//                                                     "Access_the_KalyPay_account",
//                                                 )}
//                                             </NavLink>
//                                         </li>
//                                         <li className="flex items-start">
//                                             <svg
//                                                 className="w-3 h-3 fill-current text-kalypay-500 mr-3 mt-1.5 shrink-0"
//                                                 viewBox="0 0 12 12"
//                                                 xmlns="http://www.w3.org/2000/svg"
//                                             >
//                                                 <circle
//                                                     cx="6"
//                                                     cy="6"
//                                                     r="3"
//                                                     fill="yellow"
//                                                 />
//                                             </svg>
//                                             <NavLink
//                                                 to={`/support/kalypay-account-management/manage-your-account`} // /support/kalypay-account-management/manage-your-account
//                                             >
//                                                 {translate(
//                                                     "Landing_Support",
//                                                     "Manage_your_account",
//                                                 )}
//                                             </NavLink>
//                                         </li>
//                                         <li className="flex items-start">
//                                             <svg
//                                                 className="w-3 h-3 fill-current text-kalypay-500 mr-3 mt-1.5 shrink-0"
//                                                 viewBox="0 0 12 12"
//                                                 xmlns="http://www.w3.org/2000/svg"
//                                             >
//                                                 <circle
//                                                     cx="6"
//                                                     cy="6"
//                                                     r="3"
//                                                     fill="yellow"
//                                                 />
//                                             </svg>
//                                             <NavLink
//                                                 to={`/support/kalypay-account-management/withdraw-funds-from-a-closed-account`} // /support/kalypay-account-management/withdraw-funds-from-a-closed-account
//                                             >
//                                                 {translate(
//                                                     "Landing_Support",
//                                                     "Withdraw_funds_from_a_closed_account",
//                                                 )}
//                                             </NavLink>
//                                         </li>
//                                         <li className="flex items-start">
//                                             <svg
//                                                 className="w-3 h-3 fill-current text-kalypay-500 mr-3 mt-1.5 shrink-0"
//                                                 viewBox="0 0 12 12"
//                                                 xmlns="http://www.w3.org/2000/svg"
//                                             >
//                                                 <circle
//                                                     cx="6"
//                                                     cy="6"
//                                                     r="3"
//                                                     fill="yellow"
//                                                 />
//                                             </svg>
//                                             <NavLink
//                                                 to={`/support/kalypay-account-management/prohibited-region`} // /support/kalypay-account-management/prohibited-region
//                                             >
//                                                 {translate(
//                                                     "Landing_Support",
//                                                     "Prohibited_region",
//                                                 )}
//                                             </NavLink>
//                                         </li>
//                                     </ul>
//                                 </div>
//                             </div>
//                         </div>
//                         <div className="w-full md:w-1/2 space-y-8">
//                             {/* Item */}
//                             <div className="space-y-2">
//                                 <div
//                                     aria-current="page"
//                                     className="text-xl font-bold"
//                                 >
//                                     {translate("Landing_Support", "Security")}
//                                 </div>
//                                 <div className="text-slate-500">
//                                     <ul className="text-slate-500 text-left space-y-2 ml-3">
//                                         <li className="flex items-start">
//                                             <svg
//                                                 className="w-3 h-3 fill-current text-kalypay-500 mr-3 mt-1.5 shrink-0"
//                                                 viewBox="0 0 12 12"
//                                                 xmlns="http://www.w3.org/2000/svg"
//                                             >
//                                                 <circle
//                                                     cx="6"
//                                                     cy="6"
//                                                     r="3"
//                                                     fill="yellow"
//                                                 />
//                                             </svg>
//                                             <NavLink
//                                                 to={`/support/security/avoid-phishing`} // /support/security/avoid-phishing
//                                             >
//                                                 {translate(
//                                                     "Landing_Support",
//                                                     "Avoid_phishing",
//                                                 )}
//                                             </NavLink>
//                                         </li>
//                                         <li className="flex items-start">
//                                             <svg
//                                                 className="w-3 h-3 fill-current text-kalypay-500 mr-3 mt-1.5 shrink-0"
//                                                 viewBox="0 0 12 12"
//                                                 xmlns="http://www.w3.org/2000/svg"
//                                             >
//                                                 <circle
//                                                     cx="6"
//                                                     cy="6"
//                                                     r="3"
//                                                     fill="yellow"
//                                                 />
//                                             </svg>
//                                             <NavLink
//                                                 to={`/support/security/avoid-scams`} // /support/security/avoid-scams
//                                             >
//                                                 {translate(
//                                                     "Landing_Support",
//                                                     "Avoid_scams",
//                                                 )}
//                                             </NavLink>
//                                         </li>
//                                     </ul>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                     <div className="md:flex md:space-x-12 space-y-8 md:space-y-0 mt-4">
//                         <div className="w-full md:w-1/2 space-y-8">
//                             {/* Item */}
//                             <div className="space-y-2">
//                                 <div
//                                     aria-current="page"
//                                     className="text-xl font-bold"
//                                 >
//                                     {translate(
//                                         "Landing_Support",
//                                         "Fees_and_transactions_on_KalyPay",
//                                     )}
//                                 </div>
//                                 <div className="text-slate-500">
//                                     <ul className="text-slate-500 text-left space-y-2 ml-3">
//                                         <li className="flex items-start">
//                                             <svg
//                                                 className="w-3 h-3 fill-current text-kalypay-500 mr-3 mt-1.5 shrink-0"
//                                                 viewBox="0 0 12 12"
//                                                 xmlns="http://www.w3.org/2000/svg"
//                                             >
//                                                 <circle
//                                                     cx="6"
//                                                     cy="6"
//                                                     r="3"
//                                                     fill="yellow"
//                                                 />
//                                             </svg>
//                                             <NavLink
//                                                 to={`#`} // /support/fees-and-transaction-on-kalypay/transaction
//                                             >
//                                                 {translate(
//                                                     "Landing_Support",
//                                                     "Transaction",
//                                                 )}
//                                             </NavLink>
//                                         </li>
//                                         <li className="flex items-start">
//                                             <svg
//                                                 className="w-3 h-3 fill-current text-kalypay-500 mr-3 mt-1.5 shrink-0"
//                                                 viewBox="0 0 12 12"
//                                                 xmlns="http://www.w3.org/2000/svg"
//                                             >
//                                                 <circle
//                                                     cx="6"
//                                                     cy="6"
//                                                     r="3"
//                                                     fill="yellow"
//                                                 />
//                                             </svg>
//                                             <NavLink
//                                                 to={`/support/fees-and-transaction-on-kalypay/operation-with-fiat-currencies`} // /support/fees-and-transaction-on-kalypay/operation-with-fiat-currencies
//                                             >
//                                                 {translate(
//                                                     "Landing_Support",
//                                                     "Operation_with_Fiat_currencies",
//                                                 )}
//                                             </NavLink>
//                                         </li>
//                                         <li className="flex items-start">
//                                             <svg
//                                                 className="w-3 h-3 fill-current text-kalypay-500 mr-3 mt-1.5 shrink-0"
//                                                 viewBox="0 0 12 12"
//                                                 xmlns="http://www.w3.org/2000/svg"
//                                             >
//                                                 <circle
//                                                     cx="6"
//                                                     cy="6"
//                                                     r="3"
//                                                     fill="yellow"
//                                                 />
//                                             </svg>
//                                             <NavLink
//                                                 to={`/support/fees-and-transaction-on-kalypay/send-or-receive-crypto`} // /support/fees-and-transaction-on-kalypay/send-or-receive-crypto
//                                             >
//                                                 {translate(
//                                                     "Landing_Support",
//                                                     "Send_or_receive_crypto",
//                                                 )}
//                                             </NavLink>
//                                         </li>
//                                         <li className="flex items-start">
//                                             <svg
//                                                 className="w-3 h-3 fill-current text-kalypay-500 mr-3 mt-1.5 shrink-0"
//                                                 viewBox="0 0 12 12"
//                                                 xmlns="http://www.w3.org/2000/svg"
//                                             >
//                                                 <circle
//                                                     cx="6"
//                                                     cy="6"
//                                                     r="3"
//                                                     fill="yellow"
//                                                 />
//                                             </svg>
//                                             <NavLink
//                                                 to={`/support/fees-and-transaction-on-kalypay/buy-sell-or-convert-crypto`} // /support/fees-and-transaction-on-kalypay/buy-sell-or-convert-crypto
//                                             >
//                                                 {translate(
//                                                     "Landing_Support",
//                                                     "Buy_sell_or_convert_crypto",
//                                                 )}
//                                             </NavLink>
//                                         </li>
//                                     </ul>
//                                 </div>
//                             </div>
//                         </div>
//                         <div className="w-full md:w-1/2 space-y-8">
//                             {/* Item */}
//                             <div className="space-y-2">
//                                 <div
//                                     aria-current="page"
//                                     className="text-xl font-bold"
//                                 >
//                                     {translate(
//                                         "Landing_Support",
//                                         "KalyPay_Agents",
//                                     )}
//                                 </div>
//                                 <div className="text-slate-500">
//                                     <ul className="text-slate-500 text-left space-y-2 ml-3">
//                                         <li className="flex items-start">
//                                             <svg
//                                                 className="w-3 h-3 fill-current text-kalypay-500 mr-3 mt-1.5 shrink-0"
//                                                 viewBox="0 0 12 12"
//                                                 xmlns="http://www.w3.org/2000/svg"
//                                             >
//                                                 <circle
//                                                     cx="6"
//                                                     cy="6"
//                                                     r="3"
//                                                     fill="yellow"
//                                                 />
//                                             </svg>
//                                             <NavLink
//                                                 to={`/support/kalypay-agents/agent`} // /support/kalypay-agents/country-franchise
//                                             >
//                                                 {translate(
//                                                     "Landing_Support",
//                                                     "Agent",
//                                                 )}
//                                             </NavLink>
//                                         </li>
//                                         {/* <li className="flex items-start">
//                                             <svg
//                                                 className="w-3 h-3 fill-current text-kalypay-500 mr-3 mt-1.5 shrink-0"
//                                                 viewBox="0 0 12 12"
//                                                 xmlns="http://www.w3.org/2000/svg"
//                                             >
//                                                 <circle
//                                                     cx="6"
//                                                     cy="6"
//                                                     r="3"
//                                                     fill="yellow"
//                                                 />
//                                             </svg>
//                                             <NavLink
//                                                 to={`#`} // /support/kalypay-agents/under-franchise
//                                             >
//                                                 {translate(
//                                                     "Landing_Support",
//                                                     "Under_franchise",
//                                                 )}
//                                             </NavLink>
//                                         </li>
//                                         <li className="flex items-start">
//                                             <svg
//                                                 className="w-3 h-3 fill-current text-kalypay-500 mr-3 mt-1.5 shrink-0"
//                                                 viewBox="0 0 12 12"
//                                                 xmlns="http://www.w3.org/2000/svg"
//                                             >
//                                                 <circle
//                                                     cx="6"
//                                                     cy="6"
//                                                     r="3"
//                                                     fill="yellow"
//                                                 />
//                                             </svg>
//                                             <NavLink
//                                                 to={`#`} // /support/kalypay-agents/point-of-sale
//                                             >
//                                                 {translate(
//                                                     "Landing_Support",
//                                                     "Point_of_sale",
//                                                 )}
//                                             </NavLink>
//                                         </li>
//                                         <li className="flex items-start">
//                                             <svg
//                                                 className="w-3 h-3 fill-current text-kalypay-500 mr-3 mt-1.5 shrink-0"
//                                                 viewBox="0 0 12 12"
//                                                 xmlns="http://www.w3.org/2000/svg"
//                                             >
//                                                 <circle
//                                                     cx="6"
//                                                     cy="6"
//                                                     r="3"
//                                                     fill="yellow"
//                                                 />
//                                             </svg>
//                                             <NavLink
//                                                 to={`#`} // /support/kalypay-agents/merchant
//                                             >
//                                                 {translate(
//                                                     "Landing_Support",
//                                                     "Agent",
//                                                 )}
//                                             </NavLink>
//                                         </li> */}
//                                         {/* <li className="flex items-start">
//                                             <svg
//                                                 className="w-3 h-3 fill-current text-kalypay-500 mr-3 mt-1.5 shrink-0"
//                                                 viewBox="0 0 12 12"
//                                                 xmlns="http://www.w3.org/2000/svg"
//                                             >
//                                                 <circle
//                                                     cx="6"
//                                                     cy="6"
//                                                     r="3"
//                                                     fill="yellow"
//                                                 />
//                                             </svg>
//                                             <NavLink
//                                                 to={`/support/kalypay-agents/agent`} // /support/kalypay-agents/agent
//                                             >
//                                                 {translate(
//                                                     "Landing_Support",
//                                                     "Agent",
//                                                 )}
//                                             </NavLink>
//                                         </li> */}
//                                     </ul>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                     <div className="md:flex md:space-x-12 space-y-8 md:space-y-0 mt-4">
//                         <div className="w-full md:w-1/2 space-y-8">
//                             {/* Item */}
//                             <div className="space-y-2">
//                                 <div
//                                     aria-current="page"
//                                     className="text-xl font-bold"
//                                 >
//                                     {translate(
//                                         "Landing_Support",
//                                         "Earn_rewards",
//                                     )}
//                                 </div>
//                                 <div className="text-slate-500">
//                                     <ul className="text-slate-500 text-left space-y-2 ml-3">
//                                         <li className="flex items-start">
//                                             <svg
//                                                 className="w-3 h-3 fill-current text-kalypay-500 mr-3 mt-1.5 shrink-0"
//                                                 viewBox="0 0 12 12"
//                                                 xmlns="http://www.w3.org/2000/svg"
//                                             >
//                                                 <circle
//                                                     cx="6"
//                                                     cy="6"
//                                                     r="3"
//                                                     fill="yellow"
//                                                 />
//                                             </svg>
//                                             <NavLink
//                                                 to={`/support/earn-rewards/loyalty`} // /support/earn-rewards/loyalty
//                                             >
//                                                 {translate(
//                                                     "Landing_Support",
//                                                     "Loyalty",
//                                                 )}
//                                             </NavLink>
//                                         </li>
//                                         <li className="flex items-start">
//                                             <svg
//                                                 className="w-3 h-3 fill-current text-kalypay-500 mr-3 mt-1.5 shrink-0"
//                                                 viewBox="0 0 12 12"
//                                                 xmlns="http://www.w3.org/2000/svg"
//                                             >
//                                                 <circle
//                                                     cx="6"
//                                                     cy="6"
//                                                     r="3"
//                                                     fill="yellow"
//                                                 />
//                                             </svg>
//                                             <NavLink
//                                                 to={`/support/earn-rewards/benefits`} // /support/earn-rewards/benefits
//                                             >
//                                                 {translate(
//                                                     "Landing_Support",
//                                                     "Benefits",
//                                                 )}
//                                             </NavLink>
//                                         </li>
//                                         {/* <li className="flex items-start">
//                                             <svg
//                                                 className="w-3 h-3 fill-current text-kalypay-500 mr-3 mt-1.5 shrink-0"
//                                                 viewBox="0 0 12 12"
//                                                 xmlns="http://www.w3.org/2000/svg"
//                                             >
//                                                 <circle
//                                                     cx="6"
//                                                     cy="6"
//                                                     r="3"
//                                                     fill="yellow"
//                                                 />
//                                             </svg>
//                                             <NavLink
//                                                 to={`#`} // /support/earn-rewards/manage-your-subscription
//                                             >
//                                                 {translate(
//                                                     "Landing_Support",
//                                                     "Manage_your_subscription",
//                                                 )}
//                                             </NavLink>
//                                         </li> */}
//                                     </ul>
//                                 </div>
//                             </div>
//                         </div>
//                         <div className="w-full md:w-1/2 space-y-8">
//                             {/* Item */}
//                             <div className="space-y-2">
//                                 <div
//                                     aria-current="page"
//                                     className="text-xl font-bold"
//                                 >
//                                     {translate("Landing_Support", "Borrowing")}
//                                 </div>
//                                 <div className="text-slate-500">
//                                     <ul className="text-slate-500 text-left space-y-2 ml-3">
//                                         <li className="flex items-start">
//                                             <svg
//                                                 className="w-3 h-3 fill-current text-kalypay-500 mr-3 mt-1.5 shrink-0"
//                                                 viewBox="0 0 12 12"
//                                                 xmlns="http://www.w3.org/2000/svg"
//                                             >
//                                                 <circle
//                                                     cx="6"
//                                                     cy="6"
//                                                     r="3"
//                                                     fill="yellow"
//                                                 />
//                                             </svg>
//                                             <NavLink
//                                                 to={`/support/borrowing/borrowing`} // /support/kalypay-agents/country-franchise
//                                             >
//                                                 {translate(
//                                                     "Landing_Support",
//                                                     "Borrowing_SUB",
//                                                 )}
//                                             </NavLink>
//                                         </li>
//                                     </ul>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </section>
//     );
// }
