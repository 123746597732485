import { Briefcase, Shield, Heart } from "lucide-react";
import translator from "../../../../Components/Extra/Translation/Translate";

export default function Section3() {
    const { translate } = translator();

    return (
        <div className="max-w-8xl mx-auto my-8 px-3" data-aos="zoom-out">
            <h2 className="text-kalypay-100 text-2xl font-bold mb-8 text-center">
                {translate("LANDING_DISCOVER", "offerSection_title")}
            </h2>
            <div className="grid md:grid-cols-3 gap-6">
                <div className="p-6 border rounded-lg shadow-lg text-center">
                    <Briefcase className="h-8 w-8 mx-auto text-kalypay-100 mb-4" />
                    <h3 className="text-[16px] font-bold mb-2">
                        {translate("LANDING_DISCOVER", "offerSection_title1")}
                    </h3>
                    <p className="text-slate-400 max-w-60 mx-auto">
                        {translate(
                            "LANDING_DISCOVER",
                            "offerSection_description1",
                        )}
                    </p>
                </div>

                <div className="p-6 border rounded-lg shadow-lg text-center">
                    <Shield className="h-8 w-8 mx-auto text-kalypay-100 mb-4" />
                    <h3 className="text-[16px] font-bold mb-2">
                        {translate("LANDING_DISCOVER", "offerSection_title2")}
                    </h3>
                    <p className="text-slate-400  max-w-60 mx-auto">
                        {translate(
                            "LANDING_DISCOVER",
                            "offerSection_description2",
                        )}
                    </p>
                </div>

                <div className="p-6 border rounded-lg shadow-lg text-center">
                    <Heart className="h-8 w-8 mx-auto text-kalypay-100 mb-4" />
                    <h3 className="text-[16px] font-bold mb-2">
                        {translate("LANDING_DISCOVER", "offerSection_title3")}
                    </h3>
                    <p className="text-slate-400  max-w-60 mx-auto">
                        {translate(
                            "LANDING_DISCOVER",
                            "offerSection_description3",
                        )}
                    </p>
                </div>
            </div>
        </div>
    );
}
