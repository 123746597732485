import { useState } from "react";
import SaveBouton from "../../../Components/Extra/Bouton/SaveBouton";
import translator from "../../../Components/Extra/Translation/Translate";
import Moderation from "../../../Models/Moderation";
import MailModeration from "../../../Models/MailModeration";
import MailModerationService from "../../../Services/MailModeration/MailModerationService";

const FormAddQuestion = ({
    moderation,
    quest,
    refresh,
}: {
    moderation: MailModeration;
    quest?: any;
    refresh: () => void;
}) => {
    const { translate } = translator();
    const [question, setQuestion] = useState(quest?.question);
    const addQuestion = (sanitizedPayload: any) => {
        const update = MailModerationService.addQuestion(sanitizedPayload).then(
            () => {
                refresh();
                setQuestion("");
            },
        );
    };

    const handleSubmit = (e: any) => {
        e.preventDefault();
        const sanitizedPayload = {
            question: question,
            id: moderation.id,
        };

        addQuestion(sanitizedPayload);
    };
    const deleteQuestion = (quest: any) => {
        if (quest?.id) {
            const update = MailModerationService.deleteQuestion(quest.id).then(
                () => {
                    refresh();
                    setQuestion("");
                },
            );
        }
    };
    return (
        <form onSubmit={(e) => handleSubmit(e)}>
            <div className="mt-2 flex-1 border-t border-slate-200/60 px-5 pt-2 lg:border-0 flex flex-col lg:flex-row">
                <div className="lg:w-5/6 mt-1 flex items-center">
                    <div className="relative w-full">
                        <input
                            type="text"
                            id="email-input"
                            className="border-1 peer block w-full appearance-none rounded-lg bg-transparent px-2.5 pb-2.5 pt-4 text-sm text-gray-900 focus:outline-none focus:ring-0"
                            placeholder=" "
                            value={question}
                            onChange={(e) => setQuestion(e.target.value)}
                            required
                        />
                        <label
                            htmlFor="email-input"
                            className="absolute top-2 left-1 z-10 origin-[0] -translate-y-4 scale-75 bg-white px-2 text-sm text-gray-500 duration-300 peer-placeholder-shown:top-1/2 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:scale-100 peer-focus:top-2 peer-focus:-translate-y-4 peer-focus:scale-75 peer-focus:px-2"
                        >
                            {translate("Profil", "Question")}
                        </label>
                    </div>
                </div>
                <div className="lg:w-1/6 mt-1 flex items-center justify-end">
                    {quest ? (
                        <button
                            type="button"
                            onClick={() => deleteQuestion(quest)}
                            className={`bg-[#A91C2A] hover:bg-[#9B1B27] text-white font-bold py-2 px-4 rounded-full`}
                        >
                            {" "}
                            {translate("Profil", "Delete")}
                        </button>
                    ) : (
                        <button
                            type="submit"
                            onClick={() => deleteQuestion(quest)}
                            className={`bg-indigo-500 hover:bg-indigo-700 text-white font-bold py-2 px-4 rounded-full`}
                        >
                            {" "}
                            {translate("Profil", "Add")}
                        </button>
                    )}
                </div>
            </div>
        </form>
    );
};

export default FormAddQuestion;
