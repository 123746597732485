import React, { useState } from "react";
import { useNavigate } from "react-router";
import { useAppSelector } from "../../../../globalState/hook";
import { IUserRequestResetPassword } from "../../../../Models/User";
import alert_message from "../../../../Services/Alert/AlertMessage";
import UserService from "../../../../Services/User/UserService";
import translator from "../../../../Components/Extra/Translation/Translate";
import LockSvg from "../../../../Components/Extra/Svg/LockSvg";

const FormForgot: React.FC = () => {
    const navigate = useNavigate();
    const { translate } = translator();
    const { authLoading } = useAppSelector((store) => store.auth);

    const [processing, setProcessing] = useState<boolean>(false);

    const [email, setEmail] = useState<string>("");

    const handleRequestResetPassword = async (
        e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    ) => {
        e.preventDefault();
        setProcessing(true);
        const payload: IUserRequestResetPassword = {
            email: email,
            url: `${location.protocol}//${location.host}`,
        };
        const res = UserService.forgotPassword(payload);
        alert_message.promise(" ...", res);
        setProcessing(false);
    };
    return (
        <div className="m-auto vl-mob w-full rounded-md sm:block md:flex lg:flex xl:flex 2xl:flex justify-center bg-white-log px-5 py-8 shadow-md sm:w-3/4 sm:px-8 lg:w-2/4 xl:bg-transparent xl:p-0 xl:shadow-none">
            <form>
                <div className="relative">
                    <div className="intro-x flex justify-center">
                        {translate("SignIn", "Please_make_official_site")}
                    </div>
                    <div className="intro-x w-full mb-4 text-sm flex justify-center">
                        <span className="flex items-center justify-center to-bg-slate-200 rounded sm:w-[100%] lg:w-[55%] xl:w-[55%] 2xl:w-[55%] font-medium">
                            <LockSvg />
                            <span className="mt-1">
                                <span className="text-[#00AA4D]">https://</span>
                                kalypay.com
                            </span>
                        </span>
                    </div>
                    <h2 className="intro-x text-center text-2xl font-bold xl:text-left xl:text-3xl mt-8">
                        {translate("Forgot", "Forgot_Password")}
                    </h2>
                    <span className="intro-x text-center mt-8">
                        {translate("Forgot", "Info")}
                    </span>
                    <div className="intro-x mt-8">
                        <div className="relative mt-4">
                            <input
                                type="email"
                                id="email"
                                className="border-1 peer block w-full appearance-none rounded-lg bg-transparent px-2.5 pb-2.5 pt-4 text-sm text-gray-900 focus:outline-none focus:ring-0"
                                placeholder=""
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                            <label
                                htmlFor="email"
                                className="!peer-focus:text-[#053B3B] absolute top-2 left-1 z-10 origin-[0] -translate-y-4 scale-75 bg-white px-2 text-sm text-gray-500 duration-300 peer-placeholder-shown:top-1/2 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:scale-100 peer-focus:top-2 peer-focus:-translate-y-4 peer-focus:scale-75 peer-focus:px-2"
                            >
                                {translate("Forgot", "Email")}
                            </label>
                        </div>
                    </div>
                    <div className="intro-x mt-5 text-center xl:mt-8 xl:text-left flex justify-center">
                        <button
                            className="btn btn-perfect xl:mr-3 w-1/2"
                            type="submit"
                            disabled={processing}
                            onClick={(e) => {
                                handleRequestResetPassword(e);
                            }}
                        >
                            {translate("Send", "Send")}
                        </button>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default FormForgot;
