import { useEffect, useState, useRef } from "react";

import { NavLink } from "react-router-dom";

import { useAppSelector } from "../../../globalState/hook";
import { CustomMessage } from "../../../Models/Constants";
import alert_message from "../../../Services/Alert/AlertMessage";
import AppAuthentication from "../../../Services/Authentication/AppAuthentication";

import translator from "../../../Components/Extra/Translation/Translate";

import HostInfo from "../../components/HostInfo";
import RoutesNames from "../../../Services/RoutesNames";
import { KpSimpleInput } from "../../../Front/Components/Inputs/KpInput";

import ReCAPTCHA from "react-google-recaptcha";

const AuthComponent = () => {
    const key =
        process.env.REACT_APP_RECAPTCHA_SITE_KEY_V2 ??
        "6LcE7cIUAAAAAB10AYmbca9JLhs_woOHhugekSS5";

    const { authLoading } = useAppSelector((store) => store.auth);
    const { siginAsync } = AppAuthentication();
    const [userName, setUserName] = useState<string>("");
    const [password, setPassword] = useState<string>("");

    const [message, setMessage] = useState<CustomMessage | null>();
    const [token, setToken] = useState<string | null>(null);
    const { translate } = translator();

    const recaptchaRef = useRef<ReCAPTCHA | null>(null);

    const [rememberMe, setRememberMe] = useState(false);

    const handleConnexion = async (e: any) => {
        e.preventDefault();
        if (token) {
            const promise = siginAsync(
                userName,
                password,
                token || "",
                translate,
                rememberMe,
            );
            alert_message
                .promise(`${translate("Authentication", "Sign_in")}`, promise)
                .then(() => {
                    recaptchaRef.current?.reset();
                })
                .catch(() => {
                    recaptchaRef.current?.reset();
                });
        } else {
            const recaptcha_msg: CustomMessage = {
                type: "error",
                message: "Please verify that you are not a robot.",
            };

            setMessage(recaptcha_msg);
        }
        setToken(null);
    };

    useEffect(() => {
        if (token) setMessage(null);
    }, [token]);

    return (
        <div className="m-auto vl-mob w-full rounded-md sm:block md:flex lg:flex xl:flex 2xl:flex justify-center bg-white-log px-5 py-8 shadow-md sm:w-3/4 sm:px-8 lg:w-2/4 xl:bg-transparent xl:p-0 xl:shadow-none mdg-signup-box">
            <form onSubmit={(e) => handleConnexion(e)}>
                <div className="relative">
                    <HostInfo />
                    <h2 className="intro-x text-center text-2xl font-bold xl:text-left xl:text-3xl mt-8">
                        {translate("SignIn", "Sign_in")}
                    </h2>
                    <div className="intro-x mt-8">
                        <div className="relative">
                            <KpSimpleInput
                                onChange={(e) => setUserName(e.target.value)}
                                val={userName}
                                name="username"
                                type={"text"}
                                label={translate("SignIn", "ID_OR_EMAIL")}
                                placeholder={translate("SignIn", "ID_OR_EMAIL")}
                            />
                        </div>
                        <div className="relative mt-4">
                            <KpSimpleInput
                                onChange={(e) => setPassword(e.target.value)}
                                val={password}
                                name="password"
                                type={"password"}
                                label={translate("SignIn", "Password")}
                                placeholder={translate("SignIn", "Password")}
                            />
                            {/* <input
                                type="password"
                                id="password-input"
                                className="border-1 peer block w-full appearance-none rounded-lg bg-transparent px-2.5 pb-2.5 pt-4 text-sm text-gray-900 focus:outline-none focus:ring-0"
                                placeholder=""
                                required
                                value={password}
                            />
                            <label
                                htmlFor="password-input"
                                className="!peer-focus:text-[#0f0f0f] absolute top-2 left-1 z-10 origin-[0] -translate-y-4 scale-75 bg-white px-2 text-sm text-gray-500 duration-300 peer-placeholder-shown:top-1/2 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:scale-100 peer-focus:top-2 peer-focus:-translate-y-4 peer-focus:scale-75 peer-focus:px-2"
                            >
                                {translate("SignIn", "Password")}
                            </label> */}
                        </div>
                    </div>
                    <div className="intro-x mt-4 flex text-xs sm:text-sm">
                        <div className="mr-auto flex items-center">
                            <input
                                id="remember-me"
                                type="checkbox"
                                className="form-check-input mr-2 border"
                                checked={rememberMe}
                                onChange={(e) =>
                                    setRememberMe(e.target.checked)
                                }
                            />
                            <label
                                htmlFor="remember-me"
                                className="cursor-pointer select-none"
                            >
                                {translate("SignIn", "Remember_me")}
                            </label>
                        </div>
                        <NavLink to="/forgot-password" className="md-ml-135">
                            {translate("SignIn", "Forgot_Password")}
                        </NavLink>
                    </div>

                    <div className="intro-x  flex justify-center text-center xl:text-left">
                        <div className="intro-x mt-5 flex justify-center text-center xl:mt-8 xl:text-left">
                            <ReCAPTCHA
                                ref={recaptchaRef}
                                sitekey={key}
                                onChange={(token) => setToken(token)}
                            />
                        </div>
                    </div>
                    <div className="intro-x flex justify-center text-center xl:text-left">
                        {message && !token && (
                            <div
                                className={
                                    message.type === "success"
                                        ? "text-green-600"
                                        : "text-red-500"
                                }
                            >
                                {message.message}
                            </div>
                        )}
                    </div>
                    <div className="intro-x mt-2 xl:flex xl:justify-center lg:flex lg:justify-center text-center xl:text-left block">
                        <button
                            className="btn btn-perfect w-full align-top xl:mr-3 xl:w-32 mb-2"
                            type="submit"
                            disabled={authLoading}
                        >
                            {translate("SignIn", "Sign_in")}
                        </button>
                        <NavLink
                            className="btn btn-outline-secondary w-full align-top xl:mr-3 xl:w-32 mb-2"
                            to={RoutesNames.Register}
                        >
                            {authLoading
                                ? translate("SignIn", "Connecting")
                                : translate("SignIn", "Register")}
                        </NavLink>
                    </div>
                </div>
            </form>
        </div>
    );
};
export default AuthComponent;
