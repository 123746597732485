import translator from "../../../../Components/Extra/Translation/Translate";

export default function Section2() {
    const { translate } = translator();
    return (
        <div
            className="max-w-8xl mx-auto my-4 px-3 py-10"
            data-aos="fade-right"
        >
            <h2 className="text-kalypay-100 text-2xl font-bold mb-4">
                {translate("LANDING_DISCOVER", "whySection_title")}
            </h2>
            <div className="mt-7">
                <div className="grid md:grid-cols-2 items-center md:gap-14 gap-10">
                    <div className="rounded-md border-l-2 pl-3 border-kalypay-100">
                        <h3 className="text-[16px] font-bold mb-4">
                            {translate("LANDING_DISCOVER", "whySection_title1")}
                        </h3>
                        <p>
                            {translate(
                                "LANDING_DISCOVER",
                                "whySection_description1",
                            )}
                        </p>
                    </div>

                    <div className="rounded-md border-l-2 pl-3 border-kalypay-100">
                        <h3 className="text-[16px] font-bold mb-4">
                            {translate("LANDING_DISCOVER", "whySection_title2")}
                        </h3>
                        <p>
                            {translate(
                                "LANDING_DISCOVER",
                                "whySection_description2",
                            )}
                        </p>
                    </div>

                    <div className="rounded-md border-l-2 pl-3 border-kalypay-100">
                        <h3 className="text-[16px] font-bold mb-4">
                            {translate("LANDING_DISCOVER", "whySection_title3")}
                        </h3>
                        <p>
                            {translate(
                                "LANDING_DISCOVER",
                                "whySection_description3",
                            )}
                        </p>
                    </div>

                    <div className="rounded-md border-l-2 pl-3 border-kalypay-100">
                        <h3 className="text-[16px] font-bold mb-4">
                            {translate("LANDING_DISCOVER", "whySection_title4")}
                        </h3>
                        <p>
                            {translate(
                                "LANDING_DISCOVER",
                                "whySection_description4",
                            )}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}
