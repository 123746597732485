import translator from "../../../Components/Extra/Translation/Translate";
import about from "../../images/about1.jpg";

export default function Section3v1() {
    const { translate } = translator();
    return (
        <div>
            {/* <!-- ======== 1.2. hero section ======== --> */}
            <section className="flex justify-center items-center mx-4 lg:!mx-20 md:my-40 my-20">
                <div className="max-w-screen-xl mx-auto w-full ">
                    <div className="flex flex-col-reverse  md:flex-row justify-center items-center gap-5 sm:gap-4 md:!gap-12 text-left">
                        <div
                            data-aos="fade-down"
                            className="relative flex justify-center items-center w-full md:w-1/2 md:bg-left"
                        >
                            <img
                                src={about}
                                alt="hero_img2"
                                className="md:!rounded-[50px] rounded-2xl object-cover h-auto md:!min-h-[400px] w-full"
                            />
                        </div>
                        <div
                            data-aos="fade-up"
                            className="flex flex-col justify-center gap-4 items-start md:items-start w-full md:w-1/2"
                        >
                            <h4 className="text-white bg-clip-text mb-3 font-bold md:!text-4xl text-3xl leading-tight tracking-tighter">
                                {translate("LANDING_ABOUT", "section2_titre1")}
                            </h4>
                            <div className="text-slate-300 text-base font-light space-y-8 leading-normal ">
                                <p>
                                    {translate(
                                        "LANDING_ABOUT",
                                        "section2_titre1_p1",
                                    )}
                                </p>
                                <p>
                                    {translate(
                                        "LANDING_ABOUT",
                                        "section2_titre1_p2",
                                    )}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}
