const CARD_CONFIG = {
    RED: {
        max_withdraw: 1250,
        max_payment: 5000,
        max_payment_pos: 5000,
    },
    GOLD: {
        max_withdraw: 2500,
        max_payment: 25000,
        max_payment_pos: 20000,
    },
    BLACK: {
        max_withdraw: 5000,
        max_payment: 50000,
        max_payment_pos: 40000,
    },
};

export default CARD_CONFIG;
