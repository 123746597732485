export enum KycStatus {
    INITIATED = "initiated",
    VERIFIED = "verified",
    HALF_VERIFIED = "half_verified",
    PENDING = "pending",
}

export enum UserType {
    USER = "user",
    ENTERPRISE = "enterprise",
}
export default class Kyc {
    id?: number;
    session_id!: string;
    steps?: string;
    info?: string;
    type!: UserType;
    status!: KycStatus;

    constructor(session_id: string, type: UserType, status: KycStatus) {
        this.session_id = session_id;
        this.type = type;
        this.status = status;
    }
}
