import { ICurrency } from "../../Models/Currency";
import { RoleName } from "../../Models/User";
import { useAppSelector } from "../../globalState/hook";
export default function useAuthentication() {
    const { user, isAuthenticated } = useAppSelector((state) => state.auth);
    const isAdmin = user && user.scopes && user.scopes.includes(RoleName.ADMIN);
    const isMerchant =
        user && user.scopes && user.scopes.includes(RoleName.MERCHANT);
    const isAdvisor =
        user && user.scopes && user.scopes.includes(RoleName.ADVISOR);
    const isPos =
        user && user.scopes && user.scopes.includes(RoleName.POINT_OF_SALE);
    const isFinancialAdmin =
        user && user.scopes && user.scopes.includes(RoleName.FINANCIAL_ADMIN);
    const isSaleManager =
        user && user.scopes && user.scopes.includes(RoleName.SALES_MANAGER);

    const isCommercial =
        user && user.scopes && user.scopes.includes(RoleName.COMMERCIAL);
    const isBusnessDeveloper =
        user &&
        user.scopes &&
        user.scopes.includes(RoleName.BUSINESSS_DEVELOPER);

    const isAgent = isCommercial || isPos || isSaleManager;

    const isSU = user && user.uid === process.env.REACT_APP_SU_UID;

    const default_currency_data: ICurrency = {
        name: "US Dollar",
        symbol: "$",
        abbrev: "USD",
        usd_unit_price: 1,
    };

    const currency_data: ICurrency = user?.currency || default_currency_data;
    const currency = currency_data ? currency_data.abbrev : "USD";

    return {
        isAdmin,
        user,
        isAuthenticated,
        isAdvisor,
        isCommercial,
        isFinancialAdmin,
        isPos,
        isSaleManager,
        isMerchant,
        isAgent,
        isSU,
        currency,
        currency_data,
        isBusnessDeveloper,
    };
}
