import HighNotice, { IHighNotice } from "../../Models/HighNotice";
import axios from "../AxiosInstance";
import UtilsService from "../UtilsService";

const map = (item: any): HighNotice => {
    const notice = new HighNotice(item.link, item.status);

    notice.link = item.link;
    notice.status = item.status;
    notice.created_at = item.created_at;
    notice.updated_at = item.updated_at;
    notice.photo = UtilsService.getPhotoUrl(item.photo);
    notice.id = item.id;
    notice.rank = item.rank;
    return notice;
};

const createFormData = (payload: IHighNotice): FormData => {
    const formData = new FormData();
    formData.append("link", payload.link);
    if (payload.status) {
        formData.append("status", "1");
    }
    if (payload.photo) {
        formData.append("photo", payload.photo);
    }

    return formData;
};

const getAll = async (): Promise<Array<HighNotice>> => {
    return axios
        .get(`/high-notice`)
        .then(({ data }) => {
            const assets: HighNotice[] = data.map((item: any) => map(item));

            return assets;
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const getAllActive = async (): Promise<Array<HighNotice>> => {
    return axios
        .get(`/high-notice/active`)
        .then(({ data }) => {
            const assets: HighNotice[] = data.map((item: any) => map(item));

            return assets;
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const getById = async (id: number): Promise<HighNotice> => {
    return axios
        .get(`/high-notice/${id}`)
        .then(({ data }) => {
            const asset: HighNotice = map(data);

            return asset;
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const deleteById = async (id?: number) => {
    return axios
        .delete(`/high-notice/${id}`)
        .then((res) => Promise.resolve(res.data.message))
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const create = async (payload: IHighNotice): Promise<HighNotice | string> => {
    const formData = createFormData(payload);

    return axios
        .post("/high-notice", formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        })
        .then(() => {
            return Promise.resolve("Information créé avec succès");
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const update = async (
    notice_id: number,
    payload: IHighNotice,
): Promise<string> => {
    const formData = createFormData(payload);

    return axios
        .put(`/high-notice/${notice_id}`, formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        })
        .then(() => {
            return Promise.resolve("Mis à jour avec succès");
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const orderList = async (payload: string) => {
    try {
        const result = await axios.put(`/high-notice/order-list`, {
            payload,
        });

        return Promise.resolve(result.data.message);
    } catch (err: any) {
        const error =
            err.response && err.response.data && err.response.data.message
                ? err.response.data.message
                : err.message;

        return Promise.reject(error);
    }
};

const HighNoticeService = {
    getAll,
    getAllActive,
    deleteById,
    getById,
    create,
    update,
    orderList,
};

export default HighNoticeService;
