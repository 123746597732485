import { DatatableFilter } from "../../../Models/DatatableFilter";
import VisaTransaction from "../../../Models/VisaTransaction";
import axios from "../../AxiosInstance";
import UtilsService from "../../UtilsService";

const map = (item: any): VisaTransaction => {
    const transaction = new VisaTransaction(
        item.id,
        item.amount,
        item.reference,
        item.fee,
        item.created_at,
    );

    transaction.walletHistory = item.walletHistory;
    transaction.status = item.status;
    transaction.created_at = UtilsService.getBeautifulFormatedDateWithTime(
        item.created_at,
    );
    transaction.currency = item.currency;
    transaction.fee_usd = item.fee_usd;
    transaction.amount_usd = item.amount_usd;
    transaction.total_amount = item.total_amount;
    return transaction;
};
const getAllVisaPaginate = async (filter: DatatableFilter): Promise<any> => {
    return axios
        .get(`/visa/all-transaction`, {
            params: { ...filter },
        })
        .then(({ data }) => {
            const assets: VisaTransaction[] = data.data.map((item: any) =>
                map(item),
            );

            return {
                data: assets,
                count: data.count,
                currentPage: data.currentPage,
                nextPage: data.nextPage,
                prevPage: data.prevPage,
                lastPage: data.lastPage,
            };
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const check = async (ref: string): Promise<string> => {
    return axios
        .get(`/transaction/visa-transaction/${ref}`)
        .then(({ data }) => {
            return data.message;
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const complete = async (ref: string): Promise<string> => {
    return axios
        .put(`/transaction/complete-visa-transaction/${ref}`)
        .then(() => {
            return "Completed";
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const checkRef = async (ref: string): Promise<string> => {
    return axios
        .get(`/transaction/${ref}`)
        .then(({ data }) => {
            return data.message;
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const checkRefInstant = async (ref: string): Promise<string> => {
    return axios
        .get(`/transaction/instant-transaction/${ref}`)
        .then(({ data }) => {
            return data.message;
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const VisaTransactionService = {
    getAllVisaPaginate,
    check,
    complete,
    checkRef,
    checkRefInstant,
};

export default VisaTransactionService;
