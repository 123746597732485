import { json } from "stream/consumers";
import { DatatableFilter } from "../../Models/DatatableFilter";
import MailModeration, {
    StatusMailModeration,
} from "../../Models/MailModeration";
import { IMapPosition } from "../../Models/MapPosition";
import User, {
    DirectActifCount,
    IAddAffiliation,
    IEditProfil,
    IUserPasswordReset,
    IUserRequestResetPassword,
    PublicUserDta,
} from "../../Models/User";
import PartenarUser from "../../Models/UserPartenar";
import axios from "../AxiosInstance";
import UtilsService from "../UtilsService";
const map = (item: any): MailModeration => {
    const mailModeration = new MailModeration(
        item.id,
        item.new_email,
        item.old_email,
    );

    mailModeration.user = item.user;
    mailModeration.created_at = item.created_at;
    mailModeration.status = item.status;

    return mailModeration;
};

const updateEmail = async (payload: any): Promise<any> => {
    return axios
        .put(`/mail-moderation/`, {
            new_email: payload.new_email,
        })
        .then(({ data }) => {
            return data.message;
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const addQuestion = async (payload: any): Promise<any> => {
    return axios
        .put(`/mail-moderation/add-questions/${payload.id}`, {
            question: payload.question,
        })
        .then(({ data }) => {
            return data.message;
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const deleteQuestion = async (id: number): Promise<any> => {
    return axios
        .put(`/mail-moderation/delete-questions/${id}`)
        .then(({ data }) => {
            return data.message;
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const findByQuestionMailModeration = async (id: number): Promise<any> => {
    return axios
        .get(`/mail-moderation/find-questions/${id}`)
        .then(({ data }) => {
            return data;
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};
const changeStatus = async (data: any): Promise<any> => {
    const status = data.status as StatusMailModeration;
    let url = "";
    if (status === StatusMailModeration.ACCEPTED) {
        url = `/mail-moderation/accepted/${data.id}`;
    }
    if (status === StatusMailModeration.ACCEPTED_V1) {
        url = `/mail-moderation/accepted-v1/${data.id}`;
    }
    if (status === StatusMailModeration.REJECTED) {
        url = `/mail-moderation/rejeted/${data.id}`;
    }
    return axios
        .put(url)
        .then(({ data }) => {
            return data;
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const getAllFilter = async (filter: DatatableFilter): Promise<any> => {
    return axios
        .get(`/mail-moderation/get-all`, { params: filter })
        .then(({ data }) => {
            const assets: User[] = data.data.map((item: any) => map(item));

            return {
                data: assets,
                count: data.count,
                currentPage: data.currentPage,
                nextPage: data.nextPage,
                prevPage: data.prevPage,
                lastPage: data.lastPage,
            };
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const getUserQuestions = async (): Promise<any> => {
    return axios
        .get(`/mail-moderation/user-pending-mail-moderation/`)
        .then(({ data }) => {
            return data;
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};
const answerUserQuestion = async (payload: any): Promise<any> => {
    return axios
        .put(`mail-moderation/responses-questions`, { responses: payload })
        .then((data) => {
            return data.data;
            // return Promise.resolve(data.data);
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const MailModerationService = {
    getAllFilter,
    updateEmail,
    changeStatus,
    addQuestion,
    findByQuestionMailModeration,
    deleteQuestion,
    getUserQuestions,
    answerUserQuestion,
};

export default MailModerationService;
