import React, { useEffect, useState } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useNavigate } from "react-router-dom";
import HighNotice from "../../Models/HighNotice";
import HighNoticeService from "../../Services/HighNoticeService/HighNoticeService";
import UtilsService from "../../Services/UtilsService";

const Caroussel = () => {
    const [listHighNotice, setListHighNotice] = useState<HighNotice[]>([]);
    const navigate = useNavigate();

    const getMyHighNotice = async () => {
        const res = await HighNoticeService.getAllActive();
        setListHighNotice(res);
    };

    useEffect(() => {
        getMyHighNotice();
    }, []);

    return (
        <Carousel
            additionalTransfrom={0}
            arrows={false}
            autoPlay={true}
            autoPlaySpeed={10000}
            customTransition="all 1s"
            transitionDuration={500}
            centerMode={false}
            className=""
            containerClass="container-with !mb-7"
            // dotListClass="!mx-2 box p-1"
            draggable
            focusOnSelect={false}
            infinite={true}
            itemClass=""
            keyBoardControl
            minimumTouchDrag={80}
            pauseOnHover
            renderArrowsWhenDisabled={false}
            renderButtonGroupOutside={false}
            renderDotsOutside
            responsive={{
                desktop: {
                    breakpoint: {
                        max: 3000,
                        min: 1024,
                    },
                    items: 5,
                    partialVisibilityGutter: 40,
                },
                mobile: {
                    breakpoint: {
                        max: 767,
                        min: 0,
                    },
                    items: 1,
                    partialVisibilityGutter: 30,
                },
                tablet: {
                    breakpoint: {
                        max: 1024,
                        min: 768,
                    },
                    items: 2,
                    partialVisibilityGutter: 30,
                },
            }}
            rewind={false}
            rewindWithAnimation={false}
            rtl={false}
            shouldResetAutoplay
            showDots={false}
            sliderClass=""
            slidesToSlide={1}
            swipeable
        >
            {listHighNotice.map((high: HighNotice, index: number) => (
                <div
                    className="m-2"
                    key={index}
                    role="button"
                    onClick={() => window.open(high.link)}
                >
                    <img
                        src={
                            high.photo
                                ? high?.photo
                                : UtilsService.getPulicImage(
                                      "/dist/image/profile-2.jpg",
                                  )
                        }
                        alt=""
                        className="w-full h-res rounded-lg object-fill user-drag-none"
                    />
                </div>
            ))}
        </Carousel>
    );
};

export default Caroussel;
