import { useEffect, useState } from "react";
import { useAml } from "../../../Hooks/useAml";
import { PieChart, Pie, Cell, Tooltip, ResponsiveContainer } from "recharts";
import { useAppProvider } from "../../Provider/AppProvider";
import {
    AmlCheckList,
    AnalysisDetail,
    AnalysisResult,
    TransactionData,
} from "../../../Services/AML/type";
import translator from "./../../../Components/Extra/Translation/Translate";
import DataTable from "../../../Components/Extra/DataTable/DataTable";
import { useAssets } from "../../Provider/AssetsProvider";
import UtilsService from "../../../Services/UtilsService";

export const Analysis = ({ id }: { id: number }) => {
    const { incoming, outgoing, getDetails, getTransactionData } = useAml();
    const { processing, finalizeProcessing, setProcessing } = useAppProvider();
    const [incomingData, setIncomingData] = useState<AnalysisResult | null>(
        null,
    );
    const [outgoingData, setOutgoingData] = useState<AnalysisResult | null>(
        null,
    );

    const [detail, setDetail] = useState<AmlCheckList>();
    const [coins, setCoins] = useState<TransactionData>();
    const { assets } = useAssets();

    useEffect(() => {
        setProcessing(true);
        Promise.allSettled([
            incoming(id),
            outgoing(id),
            getDetails(id),
            getTransactionData(id),
        ]).then((res) => {
            if (res[0].status === "fulfilled") setIncomingData(res[0].value);
            if (res[1].status === "fulfilled") setOutgoingData(res[1].value);
            if (res[2].status === "fulfilled") setDetail(res[2].value);
            if (res[3].status === "fulfilled") setCoins(res[3].value);
            finalizeProcessing();
        });
    }, [id]);

    const asset = assets.find(
        (asset) => asset.name.toUpperCase() === detail?.blockchain,
    );

    return (
        <div className="w-full mx-auto  ">
            <div className="mb-6"></div>
            <div className="rounded-lg border bg-card text-card-foreground shadow-sm mb-6">
                <div className="flex flex-col space-y-1.5 p-6">
                    <h1 className="text-2xl font-semibold text-gray-800">
                        Risk Assessment
                    </h1>
                </div>
                <div className="p-6">
                    {detail && (
                        <div className="flex justify-between  group  p-2 rounded-md">
                            <div className=" block md:flex items-center  gap-2">
                                <div className="text-sm items-center  text-slate-600 flex gap-1 font-bold">
                                    {asset && (
                                        <img
                                            src={UtilsService.getPhotoUrl(
                                                asset.logo,
                                            )}
                                            alt={asset.name}
                                            className="w-6 h-6"
                                        />
                                    )}
                                    {detail.blockchain}
                                </div>
                                <div className="text-sm font-medium text-slate-600 group-hover:text-slate-700 ">
                                    {detail.address}
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <div className="grid grid-cols-1 md:!grid-cols-2 gap-6">
                <RiskScoreCard
                    title="Incoming risk score"
                    loading={processing}
                    analys={incomingData}
                />
                <RiskScoreCard
                    title="Outgoing risk score"
                    loading={processing}
                    analys={outgoingData}
                />
            </div>
            <div className="grid grid-cols-1">
                <Assets />
            </div>
        </div>
    );
};

const Assets = () => {
    return (
        <div className="rounded-lg border bg-card text-card-foreground shadow-sm">
            <div className="flex flex-col space-y-1.5 p-6 border-b  border-gray-200">
                {/* <DataTable></DataTable> */}
            </div>
        </div>
    );
};

const RiskScoreCardSkeleton = () => {
    const score = Math.floor(Math.random() * 100);

    return (
        <div className="p-6">
            <div className="flex items-center justify-between mb-4">
                <div>
                    <h3 className="text-2xl font-bold text-orange-500">
                        Niveau de risque
                    </h3>
                </div>
                <div className="relative w-24 h-24">
                    <div className="absolute inset-0 flex items-center justify-center">
                        <span className="text-3xl font-bold">{score}</span>
                    </div>
                    <div className={"blur-sm"}>
                        <PieChart width={96} height={96}>
                            <Pie
                                data={[
                                    { value: score },
                                    { value: 100 - score },
                                ]}
                                dataKey="value"
                                cx="50%"
                                cy="50%"
                                innerRadius={36}
                                outerRadius={48}
                                startAngle={90}
                                endAngle={-270}
                                className=""
                            >
                                <Cell fill="#f59e0b" />
                                <Cell fill="#e5e7eb" />
                            </Pie>
                        </PieChart>
                    </div>
                </div>
            </div>
            <div className="mb-4 blur-md">
                <PieChart width={200} height={200}>
                    <Pie
                        data={[
                            { name: "Green", value: 40 },
                            { name: "Yellow", value: 30 },
                            { name: "Red", value: 30 },
                        ]}
                        cx="50%"
                        cy="50%"
                        outerRadius={80}
                        fill="#8884d8"
                        dataKey="value"
                    >
                        {[
                            { name: "Green", value: 40 },
                            { name: "Yellow", value: 30 },
                            { name: "Red", value: 30 },
                        ].map((entry, index) => (
                            <Cell
                                key={`cell-${index}`}
                                fill={
                                    ["#4caf50", "#ffeb3b", "#f44336"][index % 3]
                                }
                            />
                        ))}
                    </Pie>
                </PieChart>
            </div>
            <div className="flex items-center justify-between mb-4 ">
                <div className="flex items-center space-x-2">
                    <input
                        type="radio"
                        id="direct"
                        name="viewType"
                        className="form-radio"
                    />
                    <label htmlFor="direct" className="text-sm">
                        Direct only
                    </label>
                </div>
                <div className="flex items-center space-x-2">
                    <input
                        type="radio"
                        id="indirect"
                        name="viewType"
                        className="form-radio"
                        checked
                    />
                    <label htmlFor="indirect" className="text-sm">
                        Indirect
                    </label>
                </div>
            </div>
            <div className="mb-4 hid">
                <label className="block text-sm font-medium text-gray-700 mb-1">
                    Depth
                </label>
                {/* <Slider defaultValue={[6]} max={6} step={1} /> */}
            </div>
            <div className="relative w-full overflow-auto">
                <table className="w-full caption-bottom text-sm">
                    <thead className="[&_tr]:border-b">
                        <tr className="border-b transition-colors hover:bg-muted/50 data-[state=selected]:bg-muted">
                            <th className="h-12 px-4 text-left align-middle font-medium text-muted-foreground [&:has([role=checkbox])]:pr-0">
                                Percentage
                            </th>
                            <th className="h-12 px-4 text-left align-middle font-medium text-muted-foreground [&:has([role=checkbox])]:pr-0">
                                Risk Scoring
                            </th>
                            <th className="h-12 px-4 text-left align-middle font-medium text-muted-foreground [&:has([role=checkbox])]:pr-0">
                                Type
                            </th>
                            <th className="h-12 px-4 text-left align-middle font-medium text-muted-foreground [&:has([role=checkbox])]:pr-0">
                                Name
                            </th>
                            <th className="h-12 px-4 text-left align-middle font-medium text-muted-foreground [&:has([role=checkbox])]:pr-0">
                                Countervalue
                            </th>
                        </tr>
                    </thead>
                    <tbody>{/* Add table rows here */}</tbody>
                </table>
            </div>

            {/* </CardContent> */}
        </div>
    );
};

const COLORS = {
    NO_RISK: "#36AA00",
    LOW_RISK: "#36AA00",
    MEDIUM_RISK: "#FBC400",
    HIGH_RISK: "#FBC400",
    CRITICAL_RISK: "#ffeb3b",
};

function RiskScoreCard({
    title,
    analys,
    loading,
}: {
    title: string;

    analys: AnalysisResult | null;
    loading: boolean;
}) {
    const score = analys ? analys.score : 0;
    const { translate } = translator();

    const CustomTooltip = ({ active, payload }: any) => {
        console.log({ active, payload });
        if (active && payload && payload.length) {
            return (
                <div className="bg-white p-2 border border-gray-200 rounded shadow">
                    <p className="font-semibold">{payload[0].payload.name}</p>
                    <p>{`Percentage: ${payload[0].value.toFixed(2)}%`}</p>
                    <p>{`Severity: ${payload[0].payload.severity}`}</p>
                </div>
            );
        }
        return null;
    };

    return (
        <div className="rounded-lg border bg-card text-card-foreground shadow-sm">
            <div className="flex flex-col space-y-1.5 p-6 border-b  border-gray-200">
                <h3 className="whitespace-nowrap text-2xl font-semibold leading-none tracking-tight">
                    {title}
                </h3>
            </div>

            {loading && <RiskScoreCardSkeleton />}
            {!loading && !analys && (
                <div className="p-6 m-auto min-h-[400px] flex justify-center items-center">
                    Aucune données trouvée
                </div>
            )}
            {!loading && analys && (
                <div className="p-6">
                    <div className="flex items-center justify-between mb-4">
                        <div>
                            <h3 className="text-2xl font-bold text-orange-500">
                                {translate("AML_RISK", analys?.severity ?? "")}
                            </h3>
                        </div>
                        <div className="relative w-24 h-24">
                            <div className="absolute inset-0 flex items-center justify-center">
                                <span className="text-3xl font-bold">
                                    {score}
                                </span>
                            </div>
                            <div className={`${loading ? "blur-sm" : ""}`}>
                                <PieChart width={96} height={96}>
                                    <Pie
                                        data={[
                                            { value: score },
                                            { value: 100 - score },
                                        ]}
                                        dataKey="value"
                                        cx="50%"
                                        cy="50%"
                                        innerRadius={36}
                                        outerRadius={48}
                                        startAngle={90}
                                        endAngle={-270}
                                        className=""
                                    >
                                        <Cell fill={COLORS[analys.severity]} />
                                        <Cell fill="#e5e7eb" />
                                    </Pie>
                                </PieChart>
                            </div>
                        </div>
                    </div>
                    <div className="mb-4 mx-auto">
                        <ResponsiveContainer width="100%" height={300}>
                            <PieChart>
                                <Pie
                                    data={analys.details.map((item) => ({
                                        name: item.name,
                                        value: item.percentage,
                                    }))}
                                    cx="50%"
                                    cy="50%"
                                    outerRadius={100}
                                    fill="#8884d8"
                                    dataKey="value"
                                    labelLine={true}
                                    strokeWidth={1}
                                >
                                    {analys.details.map((item, index) => {
                                        return (
                                            <Cell
                                                key={`cell-${index}`}
                                                fill={COLORS[item.severity]}
                                            />
                                        );
                                    })}
                                    <Tooltip />
                                </Pie>
                            </PieChart>
                        </ResponsiveContainer>

                        <PieChart
                            id={"pie"}
                            data={analys.details.map((item) => ({
                                name: item.name,
                                value: item.percentage,
                            }))}
                            title={"Area of Countries"}
                        >
                            <Tooltip></Tooltip>
                        </PieChart>
                    </div>
                    <div className="hidden items-center justify-between mb-4">
                        <div className="flex items-center space-x-2">
                            <input
                                type="radio"
                                id="direct"
                                name="viewType"
                                className="form-radio"
                            />
                            <label htmlFor="direct" className="text-sm">
                                Direct only
                            </label>
                        </div>
                        <div className="flex items-center space-x-2">
                            <input
                                type="radio"
                                id="indirect"
                                name="viewType"
                                className="form-radio"
                                checked
                            />
                            <label htmlFor="indirect" className="text-sm">
                                Indirect
                            </label>
                        </div>
                    </div>
                    <div className="mb-4 hidden">
                        <label className="block text-sm font-medium text-gray-700 mb-1">
                            Depth
                        </label>
                        {/* <Slider defaultValue={[6]} max={6} step={1} /> */}
                    </div>
                    <div className="relative w-full overflow-auto">
                        <DataTable
                            pageLengthSelection={false}
                            pageLength={5}
                            className="table-report -mt-2 table"
                            columns={[
                                {
                                    index: "0",
                                    title: "Percentage",
                                    content: (data: AnalysisDetail) => (
                                        <>{data.percentage}%</>
                                    ),
                                },
                                {
                                    index: "1",
                                    title: "Risk Scoring",
                                    content: (data: AnalysisDetail) => (
                                        <>{data.score}</>
                                    ),
                                },
                                {
                                    index: "2",
                                    title: "Type",
                                    content: (data: AnalysisDetail) => (
                                        <>{data.type}</>
                                    ),
                                },
                                {
                                    index: "3",
                                    title: "Name",
                                    content: (data: AnalysisDetail) => (
                                        <>{data.name}</>
                                    ),
                                },
                                {
                                    index: "4",
                                    title: "Countervalue",
                                    content: (data: AnalysisDetail) => (
                                        <>{data.amountUsd}</>
                                    ),
                                },
                            ]}
                            data={analys.details}
                        ></DataTable>
                    </div>

                    {/* </CardContent> */}
                </div>
            )}
        </div>
    );
}
