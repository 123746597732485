import { Transition } from "@headlessui/react";

import { useEffect, useRef } from "react";
import { useAppProvider } from "../../../Front/Provider/AppProvider";
import SidebarLink from "./sidebar-link";
import SidebarLinkGroup from "./sidebar-link-group";
import translator from "../../../Components/Extra/Translation/Translate";

export default function SupportSidebar() {
    const sidebar = useRef<HTMLDivElement>(null);
    const { sidebarOpen, setSidebarOpen } = useAppProvider();
    const segments = location.href;
    const { translate } = translator();

    // close on click outside
    useEffect(() => {
        const clickHandler = ({
            target,
        }: {
            target: EventTarget | null;
        }): void => {
            if (!sidebar.current) return;
            if (!sidebarOpen || sidebar.current.contains(target as Node))
                return;
            setSidebarOpen(false);
        };
        document.addEventListener("click", clickHandler);
        return () => document.removeEventListener("click", clickHandler);
    });

    // close if the esc key is pressed
    useEffect(() => {
        const keyHandler = ({ keyCode }: { keyCode: number }): void => {
            if (!sidebarOpen || keyCode !== 27) return;
            setSidebarOpen(false);
        };
        document.addEventListener("keydown", keyHandler);
        return () => document.removeEventListener("keydown", keyHandler);
    });

    return (
        <>
            {/* Backdrop */}
            <Transition
                className="md:hidden inset-0 z-10 bg-white bg-opacity-75 transition-opacity"
                show={sidebarOpen}
                enter="transition ease-out duration-200"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="transition ease-out duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
                aria-hidden="true"
            />

            {/* Sidebar */}
            <div ref={sidebar}>
                <Transition
                    show={sidebarOpen}
                    unmount={false}
                    as="aside"
                    id="sidebar"
                    className="left-0 top-0 bottom-0 w-64 h-screen bg-white border-r border-slate-200 px-4 sm:px-6 md:ml-0 md:pr-8 md:left-auto overflow-y-auto no-scrollbar md:shrink-0 z-10 md:!opacity-100 md:!block"
                    enter="transition ease-out duration-200 transform"
                    enterFrom="opacity-0 -translate-x-full"
                    enterTo="opacity-100 translate-x-0"
                    leave="transition ease-out duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="pt-28 pb-12 md:pt-36 md:pb-16">
                        {/* <h3 className="text-lg font-bold text-slate-800 mb-4">
                            Popular topics
                        </h3> */}

                        {/* Support nav */}
                        <nav className="md:block">
                            <ul className="space-y-3">
                                <SidebarLinkGroup
                                    title={translate(
                                        "Landing_Support",
                                        "To_start",
                                    )}
                                    open={segments.includes("to-start")}
                                >
                                    <li>
                                        <SidebarLink href="/support/to-start/getting-started-with-kalypay">
                                            {translate(
                                                "Landing_Support",
                                                "Getting_started_with_KalyPay",
                                            )}
                                        </SidebarLink>
                                    </li>
                                    <li>
                                        <SidebarLink href="/support/to-start/verify-your-account">
                                            {translate(
                                                "Landing_Support",
                                                "Verify_your_account",
                                            )}
                                        </SidebarLink>
                                    </li>
                                    <li>
                                        <SidebarLink href="/support/to-start/crypto-education">
                                            {translate(
                                                "Landing_Support",
                                                "Crypto_Education",
                                            )}
                                        </SidebarLink>
                                    </li>
                                </SidebarLinkGroup>
                                <SidebarLinkGroup
                                    title={translate(
                                        "Landing_Support",
                                        "KalyPay_account_management",
                                    )}
                                    open={segments.includes(
                                        "kalypay-account-management",
                                    )}
                                >
                                    <li>
                                        <SidebarLink href="/support/kalypay-account-management/access-the-kalypay-account">
                                            {translate(
                                                "Landing_Support",
                                                "Access_the_KalyPay_account",
                                            )}
                                        </SidebarLink>
                                    </li>
                                    <li>
                                        <SidebarLink href="/support/kalypay-account-management/manage-your-account">
                                            {translate(
                                                "Landing_Support",
                                                "Manage_your_account",
                                            )}
                                        </SidebarLink>
                                    </li>
                                    <li>
                                        <SidebarLink href="/support/kalypay-account-management/withdraw-funds-from-a-closed-account">
                                            {translate(
                                                "Landing_Support",
                                                "Withdraw_funds_from_a_closed_account",
                                            )}
                                        </SidebarLink>
                                    </li>
                                    <li>
                                        <SidebarLink href="/support/kalypay-account-management/prohibited-region">
                                            {translate(
                                                "Landing_Support",
                                                "Prohibited_region",
                                            )}
                                        </SidebarLink>
                                    </li>
                                </SidebarLinkGroup>
                                <SidebarLinkGroup
                                    title={translate(
                                        "Landing_Support",
                                        "Fees_and_transactions_on_KalyPay",
                                    )}
                                    open={segments.includes(
                                        "fees-and-transaction-on-kalypay",
                                    )}
                                >
                                    {/* <li>
                                        <SidebarLink href="/support/fees-and-transaction-on-kalypay/transaction">
                                            {translate(
                                                "Landing_Support",
                                                "Transaction",
                                            )}
                                        </SidebarLink>
                                    </li> */}
                                    <li>
                                        <SidebarLink href="/support/fees-and-transaction-on-kalypay/operation-with-fiat-currencies">
                                            {translate(
                                                "Landing_Support",
                                                "Operation_with_Fiat_currencies",
                                            )}
                                        </SidebarLink>
                                    </li>
                                    <li>
                                        <SidebarLink href="/support/fees-and-transaction-on-kalypay/send-or-receive-crypto">
                                            {translate(
                                                "Landing_Support",
                                                "Send_or_receive_crypto",
                                            )}
                                        </SidebarLink>
                                    </li>
                                    <li>
                                        <SidebarLink href="/support/fees-and-transaction-on-kalypay/buy-sell-or-convert-crypto">
                                            {translate(
                                                "Landing_Support",
                                                "Buy_sell_or_convert_crypto",
                                            )}
                                        </SidebarLink>
                                    </li>
                                </SidebarLinkGroup>
                                <SidebarLinkGroup
                                    title={translate(
                                        "Landing_Support",
                                        "Earn_rewards",
                                    )}
                                    open={segments.includes("earn-rewards")}
                                >
                                    <li>
                                        <SidebarLink href="/support/earn-rewards/loyalty">
                                            {translate(
                                                "Landing_Support",
                                                "Loyalty",
                                            )}
                                        </SidebarLink>
                                    </li>
                                    <li>
                                        <SidebarLink href="/support/earn-rewards/benefits">
                                            {translate(
                                                "Landing_Support",
                                                "Benefits",
                                            )}
                                        </SidebarLink>
                                    </li>
                                </SidebarLinkGroup>
                                <SidebarLinkGroup
                                    title={translate(
                                        "Landing_Support",
                                        "KalyPay_cards",
                                    )}
                                    open={segments.includes("kalypay-cards")}
                                >
                                    <li>
                                        <SidebarLink href="/support/kalypay-cards/manage-your-subscription">
                                            {translate(
                                                "Landing_Support",
                                                "Manage_your_subscription",
                                            )}
                                        </SidebarLink>
                                    </li>
                                    <li>
                                        <SidebarLink href="/support/kalypay-cards/get-your-cards">
                                            {translate(
                                                "Landing_Support",
                                                "Get_your_cards",
                                            )}
                                        </SidebarLink>
                                    </li>
                                    <li>
                                        <SidebarLink href="/support/kalypay-cards/transactions-on-your-cards">
                                            {translate(
                                                "Landing_Support",
                                                "Transactions_on_your_cards",
                                            )}
                                        </SidebarLink>
                                    </li>
                                </SidebarLinkGroup>
                                {/* <SidebarLinkGroup
                                    title={translate(
                                        "Landing_Support",
                                        "Refer_a_friend_and_affiliate",
                                    )}
                                    open={segments.includes(
                                        "refer-a-friend-and-affiliate",
                                    )}
                                >
                                    <li>
                                        <SidebarLink href="/support/refer-a-friend-and-affiliate/sponsorship-basics">
                                            {translate(
                                                "Landing_Support",
                                                "Sponsorship_basics",
                                            )}
                                        </SidebarLink>
                                    </li>
                                    <li>
                                        <SidebarLink href="/support/refer-a-friend-and-affiliate/the-basics-of-affiliation">
                                            {translate(
                                                "Landing_Support",
                                                "The_basics_of_affiliation",
                                            )}
                                        </SidebarLink>
                                    </li>
                                </SidebarLinkGroup> */}
                                <SidebarLinkGroup
                                    title={translate(
                                        "Landing_Support",
                                        "Security",
                                    )}
                                    open={segments.includes("security")}
                                >
                                    <li>
                                        <SidebarLink href="/support/security/avoid-phishing">
                                            {translate(
                                                "Landing_Support",
                                                "Avoid_phishing",
                                            )}
                                        </SidebarLink>
                                    </li>
                                    <li>
                                        <SidebarLink href="/support/security/avoid-scams">
                                            {translate(
                                                "Landing_Support",
                                                "Avoid_scams",
                                            )}
                                        </SidebarLink>
                                    </li>
                                </SidebarLinkGroup>
                                <SidebarLinkGroup
                                    title={translate(
                                        "Landing_Support",
                                        "KalyPay_Agents",
                                    )}
                                    open={segments.includes("kalypay-agents")}
                                >
                                    <li>
                                        <SidebarLink href="/support/kalypay-agents/agent">
                                            {translate(
                                                "Landing_Support",
                                                "Agent",
                                            )}
                                        </SidebarLink>
                                    </li>
                                    {/* <li>
                                        <SidebarLink href="/support/kalypay-agents/under-franchise">
                                            {translate(
                                                "Landing_Support",
                                                "Under_franchise",
                                            )}
                                        </SidebarLink>
                                    </li>
                                    <li>
                                        <SidebarLink href="/support/kalypay-agents/point-of-sale">
                                            {translate(
                                                "Landing_Support",
                                                "Point_of_sale",
                                            )}
                                        </SidebarLink>
                                    </li>
                                    <li>
                                        <SidebarLink href="/support/kalypay-agents/merchant">
                                            {translate(
                                                "Landing_Support",
                                                "Agent",
                                            )}
                                        </SidebarLink>
                                    </li>
                                    <li>
                                        <SidebarLink href="/support/kalypay-agents/agent">
                                            {translate(
                                                "Landing_Support",
                                                "Agent",
                                            )}
                                        </SidebarLink>
                                    </li> */}
                                </SidebarLinkGroup>
                                <SidebarLinkGroup
                                    title={translate(
                                        "Landing_Support",
                                        "Borrowing",
                                    )}
                                    open={segments.includes("borrowing")}
                                >
                                    <li>
                                        <SidebarLink href="/support/borrowing/borrowing">
                                            {translate(
                                                "Landing_Support",
                                                "Borrowing_SUB",
                                            )}
                                        </SidebarLink>
                                    </li>
                                </SidebarLinkGroup>
                            </ul>
                        </nav>
                    </div>
                </Transition>
            </div>
        </>
    );
}
