import { createContext, useContext, useEffect, useState } from "react";
import User from "../../Models/User";
import { UserCryptoWallet } from "../../Models/UserWallet";
import UserService from "../../Services/User/UserService";
import UtilsService from "../../Services/UtilsService";
import WalletService from "../../Services/Wallets/WalletService";

interface ContextProps {
    assets: UserCryptoWallet[];
    currentAsset: UserCryptoWallet | undefined;
    refreshAssets: () => any;
    setCurrentAsset: (asset: UserCryptoWallet) => any;
    walletSynchronisation: boolean;
    refreshed: number;
    getGlobalBalance: () => number;
    getFiatBalance: () => number;
    getCardBalance: () => number;
    getCryptoBalance: () => number;
    uid: string | undefined;
    setUID: (uid: string) => any;
    role: string | undefined;
    setRole: (role: string) => any;
    gettedUser: User | undefined;
}

const ProfilContext = createContext<ContextProps>({
    assets: [],
    currentAsset: undefined,
    refreshAssets: () => true,
    setCurrentAsset: (val: UserCryptoWallet) => {
        return;
    },
    walletSynchronisation: false,
    refreshed: 0,
    getGlobalBalance: () => 0,
    getFiatBalance: () => 0,
    getCardBalance: () => 0,
    getCryptoBalance: () => 0,
    uid: undefined,
    setUID: (val: string) => {
        return;
    },
    role: undefined,
    setRole: (val: string) => {
        return;
    },
    gettedUser: undefined,
});

export default function ProfilProvider({
    children,
}: {
    children: React.ReactNode;
}) {
    const [walletSynchronisation, setWalletSynchronisation] =
        useState<boolean>(false);
    const [nbCall, setNbCall] = useState<number>(0);
    const [assets, setAssets] = useState<UserCryptoWallet[]>([]);
    const [asset, setAsset] = useState<UserCryptoWallet | undefined>(undefined);
    const [uid, setUID] = useState<string>("");
    const [role, setRole] = useState<string>("");
    const [user, setUser] = useState<User>();

    const refreshAssets = async () => {
        if (!user) return;
        setNbCall(nbCall + 1);
        setWalletSynchronisation(true);

        const data = await WalletService.getUserWalletByUID(user.uid);
        setWalletSynchronisation(false);
        setAssets(data);
    };

    const getUser = async () => {
        if (uid && role) {
            let user;
            switch (role) {
                case "sales_manager":
                    user = await UserService.getSalesManagerByUid(uid);
                    break;

                case "commercial":
                    user = await UserService.getCommercialByUid(uid);
                    break;

                case "point_of_sale":
                    user = await UserService.getPointOfSaleByUid(uid);
                    break;

                case "customer":
                    user = await UserService.getCustomerByUid(uid);
                    break;

                case "business_developer":
                    user = await UserService.getCustomerByUid(uid);
                    break;

                default:
                    break;
            }

            setUser(user);
        }
    };

    useEffect(() => {
        if (nbCall === 0) {
            refreshAssets();
        }
    }, [nbCall, user]);

    useEffect(() => {
        getUser();
    }, [uid, role]);

    const getGlobalBalance = (): number => {
        const total = assets.reduce(
            (prevTotal: number, asset: UserCryptoWallet) => {
                const current = asset.wallet.solde;

                return prevTotal + current;
            },
            0,
        );

        return UtilsService.formatDecimal(total, 2);
    };
    const getFiatBalance = (): number => {
        const total = assets.reduce(
            (prevTotal: number, asset: UserCryptoWallet) => {
                const current = asset.type === "fiat" ? asset.wallet.solde : 0;

                return prevTotal + current;
            },
            0,
        );

        return UtilsService.formatDecimal(total, 2);
    };
    const getCardBalance = (): number => {
        const total = assets.reduce(
            (prevTotal: number, asset: UserCryptoWallet) => {
                const current = 0;

                return prevTotal + current;
            },
            0,
        );

        return UtilsService.formatDecimal(total, 2);
    };
    const getCryptoBalance = (): number => {
        const total = assets.reduce(
            (prevTotal: number, asset: UserCryptoWallet) => {
                const current =
                    asset.type === "crypto" ? asset.wallet.solde : 0;

                return prevTotal + current;
            },
            0,
        );

        return UtilsService.formatDecimal(total, 2);
    };

    return (
        <ProfilContext.Provider
            value={{
                walletSynchronisation,
                assets,
                refreshAssets,
                getGlobalBalance,
                getFiatBalance,
                getCardBalance,
                getCryptoBalance,
                refreshed: nbCall,
                currentAsset: asset,
                setCurrentAsset: setAsset,
                uid: uid,
                setUID: setUID,
                role: role,
                setRole: setRole,
                gettedUser: user,
            }}
        >
            {children}
        </ProfilContext.Provider>
    );
}

export const useProfil = () => useContext(ProfilContext);
