import { NavLink } from "react-router-dom";
import translator from "../../Components/Extra/Translation/Translate";
import RoutesNames from "../../Services/RoutesNames";
import UtilsService from "../../Services/UtilsService";
import { useAppProvider } from "../../Front/Provider/AppProvider";
import Dashboard from "../../Components/Dashboard/Dashboard";
import { KpSettingIcon } from "../../Front/Icons/MosaicIcons";
import { Settings2, Users2 } from "lucide-react";

const MobileBottomMenu = () => {
    const { sidebarOpen, setSidebarOpen } = useAppProvider();
    const { translate } = translator();

    return (
        <div className=" !bg-black z-[90] md:hidden !fixed !bottom-[0px] !top-auto !w-full">
            <div className="bg-black w-full py-0 px-3 text-white flex items-center space-x-2">
                <div className="w-1/5">
                    <NavLink
                        to={RoutesNames.Admin.Dashboard}
                        className="block text-[10px] text-center leading-3 cursor-default"
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            icon-name="monitor"
                            data-lucide="monitor"
                            className="lucide lucide-monitor m-auto mb-2"
                        >
                            <rect
                                x="2"
                                y="3"
                                width="20"
                                height="14"
                                rx="2"
                                ry="2"
                            ></rect>
                            <line x1="8" y1="21" x2="16" y2="21"></line>
                            <line x1="12" y1="17" x2="12" y2="21"></line>
                        </svg>
                        {translate("ACCOUNT_MENU", "DASHBOARD")}
                    </NavLink>
                </div>
                <div className="w-1/5">
                    <NavLink
                        to={RoutesNames.Admin.User}
                        className="block text-[10px] text-center leading-3 cursor-default"
                    >
                        <Users2 className="m-auto mb-2" />
                        {translate("ACCOUNT_MENU", "User")}
                    </NavLink>
                </div>
                <div className="w-1/5">
                    <button className=" mobile-menu-toggler block text-[10px] text-center cursor-default !right-auto !relative !top-auto mobile-menu-toggler bottom-[10px] bg-black rounded-[50%] p-[10px]">
                        <img
                            src={UtilsService.getPulicImage(
                                "/dist/image/Logo_Fav_KP.png",
                            )}
                            className="w-[50px] m-auto"
                            alt="Logo Kalypay"
                        />
                    </button>
                </div>
                <div className="w-1/5">
                    <NavLink
                        to={RoutesNames.Admin.Transactions}
                        className="block text-[10px] text-center leading-3 cursor-default"
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            fill="currentColor"
                            className="bi bi-card-list m-auto mb-2"
                            viewBox="0 0 16 16"
                        >
                            {" "}
                            <path d="M14.5 3a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h13zm-13-1A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-13z" />{" "}
                            <path d="M5 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 5 8zm0-2.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm0 5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm-1-5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0zM4 8a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0zm0 2.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0z" />{" "}
                        </svg>
                        {translate("ACCOUNT_MENU", "TRANSACTIONS")}
                    </NavLink>
                </div>
                <div className="w-1/5">
                    <NavLink
                        to={RoutesNames.Admin.SettingPayment}
                        className="block text-[10px] text-center leading-3 cursor-default"
                    >
                        <Settings2 className="m-auto mb-2" />

                        {/* <KpCreditCardIcon /> */}
                        {translate("ACCOUNT_MENU", "Setting")}
                    </NavLink>
                </div>
            </div>
        </div>
    );
};

export default MobileBottomMenu;
