import axios from "../AxiosInstance";
import { KycConfiguration, SwapConfiguration } from "../../Models/Swap";
import UtilsService from "../UtilsService";
import { TransferConfiguration } from "../../Models/Transfer";
import {
    AgentAdvantage,
    EAdvantage,
    SettingKey,
} from "../../Models/AgentAdvantage";
import { IColdWallet } from "../../Models/ColdWallet";
import {
    AirtimeConfig,
    DepositConfig,
    ExternalTransferConfig,
    GeneralConfiguration,
    InternalTransferConfig,
    WithdrawConfig,
} from "../../Models/GeneralSetting";
import { GlobalBorrowingConfiguration } from "../../Models/AssetBorrowingConfig";

const getSwapSetting = async (): Promise<SwapConfiguration> => {
    return axios
        .get(`/setting/swap`)
        .then(({ data }) => {
            return data;
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const swapSetting = async (
    payload: SwapConfiguration,
): Promise<SwapConfiguration | string> => {
    return axios
        .post("/setting/swap", payload)
        .then(() => {
            return Promise.resolve("Configuration de swap avec succès");
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const getKycSetting = async (): Promise<KycConfiguration> => {
    return axios
        .get(`/setting/kyc`)
        .then(({ data }) => {
            return data;
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const kycSetting = async (
    payload: KycConfiguration,
): Promise<KycConfiguration | string> => {
    return axios
        .post("/setting/kyc", payload)
        .then(() => {
            return Promise.resolve("Configuration de kyc avec succès");
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const findAgentAdvantageSetting = async (
    key: SettingKey,
): Promise<AgentAdvantage> => {
    return axios
        .get(`/setting/agent-advantage/${key}`)
        .then(({ data }) => {
            return data;
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const agentAdvantageSetting = async (
    payload: AgentAdvantage,
    key: SettingKey,
): Promise<AgentAdvantage | string> => {
    return axios
        .post("/setting/agent-advantage", { ...payload, key })
        .then(() => {
            return Promise.resolve("Configuration de kyc avec succès");
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const transferSetting = async (
    payload: TransferConfiguration,
): Promise<TransferConfiguration | string> => {
    return axios
        .post("/setting/transfer", payload)
        .then(() => {
            return Promise.resolve("Configuration de transfert avec succès");
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const getTransferSetting = async (): Promise<TransferConfiguration> => {
    return axios
        .get(`/setting/transfer`)
        .then(({ data }) => {
            return data;
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};
const coldWalletSetting = async (
    payload: IColdWallet,
): Promise<IColdWallet | string> => {
    return axios
        .post("/setting/cold-wallet", payload)
        .then(() => {
            return Promise.resolve("Configuration de Cold Wallet avec succès");
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};
const getColdWalletSetting = async (): Promise<IColdWallet> => {
    return axios
        .get(`/setting/cold-wallet`)
        .then(({ data }) => {
            return data;
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const generalSetting = async (
    payload: GeneralConfiguration,
): Promise<string> => {
    return axios
        .post("/setting/general", payload)
        .then(() => {
            return Promise.resolve("Configuration générale avec succès");
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const getGeneralSetting = async (): Promise<GeneralConfiguration> => {
    return axios
        .get(`/setting/general`)
        .then(({ data }) => {
            return data;
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const updateWithdrawConfiguration = async (
    payload: WithdrawConfig,
): Promise<string> => {
    return axios
        .post("/setting/withdraw-config", payload)
        .then(() => {
            return Promise.resolve("Configuration du retrait avec succès");
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const getWithdrawConfiguration = async (): Promise<WithdrawConfig> => {
    return axios
        .get(`/setting/withdraw-config`)
        .then(({ data }) => {
            return data as WithdrawConfig;
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const updateDepositConfiguration = async (
    payload: DepositConfig,
): Promise<string> => {
    return axios
        .post("/setting/deposit-config", payload)
        .then(() => {
            return Promise.resolve("Configuration du retrait avec succès");
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const getDepositConfiguration = async (): Promise<DepositConfig> => {
    return axios
        .get(`/setting/deposit-config`)
        .then(({ data }) => {
            return data as DepositConfig;
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const updateInternalTransferConfiguration = async (
    payload: InternalTransferConfig,
): Promise<string> => {
    return axios
        .post("/setting/internal-transfer-config", payload)
        .then(() => {
            return Promise.resolve("Configuration du retrait avec succès");
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const getInternalTransferConfiguration =
    async (): Promise<InternalTransferConfig> => {
        return axios
            .get(`/setting/internal-transfer-config`)
            .then(({ data }) => {
                return data as InternalTransferConfig;
            })
            .catch((err: any) => {
                return Promise.reject(UtilsService.getAxiosErrorMessage(err));
            });
    };

const updateAirtimeConfiguration = async (
    payload: AirtimeConfig,
): Promise<string> => {
    return axios
        .post("/setting/airtime-config", payload)
        .then(() => {
            return Promise.resolve("Configuration du retrait avec succès");
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const getAirtimeConfiguration = async (): Promise<AirtimeConfig> => {
    return axios
        .get(`/setting/airtime-config`)
        .then(({ data }) => {
            return data as AirtimeConfig;
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const updateExternalTransferConfiguration = async (
    payload: ExternalTransferConfig,
): Promise<string> => {
    return axios
        .post("/setting/external-transfer-config", payload)
        .then(() => {
            return Promise.resolve("Configuration du retrait avec succès");
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const getExternalTransferConfiguration =
    async (): Promise<ExternalTransferConfig> => {
        return axios
            .get(`/setting/external-transfer-config`)
            .then(({ data }) => {
                return data as ExternalTransferConfig;
            })
            .catch((err: any) => {
                return Promise.reject(UtilsService.getAxiosErrorMessage(err));
            });
    };

const getAdvantage = (
    advantage_data: AgentAdvantage | undefined,
    type: EAdvantage,
): number => {
    if (advantage_data === undefined) return 0;
    if (typeof advantage_data[type] === "number") {
        return Number(advantage_data[type]);
    }

    return 0;
};

const updateGlobalBorrowingSetting = async (
    payload: GlobalBorrowingConfiguration,
): Promise<string> => {
    return axios
        .post("/setting/global-borrowing", payload)
        .then(() => {
            return Promise.resolve("Configuration avec succès");
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const getGlobalBorrowingSetting =
    async (): Promise<GlobalBorrowingConfiguration> => {
        return axios
            .get(`/setting/global-borrowing`)
            .then(({ data }) => {
                return data;
            })
            .catch((err: any) => {
                return Promise.reject(UtilsService.getAxiosErrorMessage(err));
            });
    };

const SettingService = {
    getSwapSetting,
    swapSetting,
    getKycSetting,
    kycSetting,
    transferSetting,
    getTransferSetting,
    agentAdvantageSetting,
    findAgentAdvantageSetting,
    coldWalletSetting,
    getColdWalletSetting,
    generalSetting,
    getGeneralSetting,
    updateWithdrawConfiguration,
    getWithdrawConfiguration,
    updateDepositConfiguration,
    getDepositConfiguration,
    updateInternalTransferConfiguration,
    getInternalTransferConfiguration,
    updateAirtimeConfiguration,
    getAirtimeConfiguration,
    updateExternalTransferConfiguration,
    getExternalTransferConfiguration,
    getAdvantage,
    updateGlobalBorrowingSetting,
    getGlobalBorrowingSetting,
};

export default SettingService;
