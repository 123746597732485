import { useEffect } from "react";
import { useAppProvider } from "../../Provider/AppProvider";
import translator from "../../../Components/Extra/Translation/Translate";
import KpBreadCrumb from "../../Components/KpBreadCrumb";
import { CheckForm } from "./check-form";
import { useParams } from "react-router-dom";
import { Analysis } from "./analysis";
import { AmlList } from "./list";

const AMLCheck = () => {
    const { translate } = translator();
    const { setProcessing, finalizeProcessing } = useAppProvider();

    const { id } = useParams();

    useEffect(() => {
        finalizeProcessing();
    }, []);
    return (
        <main className="grow  bg-white">
            <div className="lg:relative lg:flex bg-white dark:bg-slate-900 min-h-screen">
                {/* Content */}
                <div className="px-6 sm:px-6 xl:!px-[80px] w-full  mx-auto mb-5">
                    <div className="sm:flex sm:justify-between sm:items-center mb-5">
                        <KpBreadCrumb
                            title={translate("ACCOUNT_MENU", "AML check")}
                        ></KpBreadCrumb>
                    </div>
                    {!id && (
                        <div>
                            <CheckForm></CheckForm>
                            <AmlList />
                        </div>
                    )}

                    {id && <Analysis id={Number(id)}></Analysis>}
                </div>
            </div>
        </main>
    );
};

export default AMLCheck;
