import { Outlet } from "react-router";

import HeadAdmin from "./HeadAdmin";
import SideAdmin from "./SideAdmin";
import SideMobileBo from "./SideMobileBo";
import MobileBottomMenu from "./MobileBottomMenu";
import AppProvider from "../../Front/Provider/AppProvider";

const BaseAdmin = () => {
    return (
        <AppProvider>
            <div>
                <SideMobileBo />
                <HeadAdmin />
                <div className="mdg-section flex md:mt-0">
                    <SideAdmin />
                    <div className="content mdg-content h-screen overflow-y-scroll w-content !z-0 !rounded-none mb-10">
                        <Outlet />
                    </div>
                    <MobileBottomMenu />
                </div>
            </div>
        </AppProvider>
    );
};

export default BaseAdmin;
