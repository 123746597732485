import { useEffect, useState } from "react";
import { AmlCheckList } from "../../../Services/AML/type";
import { useAppProvider } from "../../Provider/AppProvider";
import { useAml } from "../../../Hooks/useAml";
import DataTable from "../../../Components/Extra/DataTable/DataTable";
import UtilsService from "../../../Services/UtilsService";
import { useAssets } from "../../Provider/AssetsProvider";
import KpButton from "../../Components/Inputs/KpButton";
import { EyeIcon } from "lucide-react";
import { useNavigate } from "react-router-dom";

export const AmlList = () => {
    const [list, setList] = useState<AmlCheckList[]>([]);
    const { setProcessing, processing, finalizeProcessing } = useAppProvider();
    const { findList } = useAml();
    const { assets } = useAssets();
    const navigate = useNavigate();

    useEffect(() => {
        setProcessing(true);
        findList()
            .then((res) => {
                setList(res);
                finalizeProcessing();
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);

    return (
        <div>
            <DataTable
                pageLengthSelection={false}
                pageLength={5}
                className="table-report -mt-2 table"
                columns={[
                    {
                        index: "0",
                        title: "Date",
                        content: (data: AmlCheckList) => (
                            <>
                                {UtilsService.getBeautifulFormatedDateFr(
                                    data.createdAt,
                                )}
                            </>
                        ),
                    },
                    {
                        index: "1",
                        title: "Addresse",
                        content: (data: AmlCheckList) => {
                            const asset = assets.find(
                                (asset) =>
                                    asset.name.toUpperCase() ===
                                    data.blockchain,
                            );

                            return (
                                <div className="flex justify-between cursor-pointer group  p-2 rounded-md">
                                    <div className=" block md:flex items-center gap-2">
                                        <div className="text-sm items-center  text-slate-600 flex gap-1 font-bold">
                                            {asset && (
                                                <img
                                                    src={UtilsService.getPhotoUrl(
                                                        asset.logo,
                                                    )}
                                                    alt={asset.name}
                                                    className="w-6 h-6"
                                                />
                                            )}
                                            {data.blockchain}
                                        </div>
                                        <div className="text-sm font-medium text-slate-600 group-hover:text-slate-700 group-hover:underline max-w-[200px] md:max-w-[300px] truncate">
                                            {data.address}
                                        </div>
                                    </div>
                                </div>
                            );
                        },
                    },
                    {
                        index: "2",
                        title: "Action",
                        content: (data: AmlCheckList) => (
                            <>
                                <KpButton
                                    onClick={() =>
                                        navigate(`/aml-check/${data.id}`)
                                    }
                                >
                                    <EyeIcon />
                                </KpButton>
                            </>
                        ),
                    },
                ]}
                data={list}
            />
        </div>
    );
};
