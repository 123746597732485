import { useEffect } from "react";
import { useLocation } from "react-router-dom";

// Hook personnalisé pour le scroll vers un élément basé sur le hash dans l'URL
const useScrollToHash = () => {
    const location = useLocation();

    useEffect(() => {
        if (location.hash) {
            const element = document.getElementById(location.hash.substring(1));
            if (element) {
                element.scrollIntoView({ behavior: "smooth" });
            }
        }
    }, [location]);
};

export default useScrollToHash;
