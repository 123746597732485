import React, { useMemo, useState } from "react";
import { ModalContext } from "../../../../../Components/Extra/Modal/ModalContext";
import translator from "../../../../../Components/Extra/Translation/Translate";
import Borrowing, { IAddGuarantee } from "../../../../../Models/Borrowing";
import NumberInput from "../../../../../Components/Extra/Input/InputNumber";
import BorrowingService from "../../../../../Services/Borrowing/BorrowingService";
import alert_message from "../../../../../Services/Alert/AlertMessage";
import { useAssets } from "../../../../Provider/AssetsProvider";
import { UserCryptoWallet } from "../../../../../Models/UserWallet";
import AddSmSvg from "../../../../../Components/Extra/Svg/AddSmSvg";
import UtilsService from "../../../../../Services/UtilsService";

const AddGuaranteeForm = ({
    borrowing,
    min,
}: {
    borrowing: Borrowing;
    min: number;
}) => {
    const { translate } = translator();
    const { handleModal } = React.useContext(ModalContext);
    const { assets } = useAssets();
    const [processing, setProcessing] = useState<boolean>(false);
    const [amount, setAmount] = useState<number>(0);

    const available_balance = useMemo<number>(() => {
        const res = assets.find(
            (elt: UserCryptoWallet) =>
                elt.abbreviation === borrowing.crypto_guarantee.abbreviation,
        );
        if (!res) return 0;
        return res.wallet.solde;
    }, [assets]);

    const addGuarantee = (guarantee: IAddGuarantee) => {
        setProcessing(true);
        const create = BorrowingService.addGuarantee(guarantee);
        alert_message
            .promise(translate("BORROW", "Add_Guarantee_In_Progress"), create)
            .then(() => {
                handleModal();
            })
            .finally(() => {
                setProcessing(false);
            });
    };

    const handleSubmit = (e: any) => {
        e.preventDefault();

        if (borrowing) {
            const guarantee: IAddGuarantee = {
                id: borrowing.id,
                amount: amount,
            };

            addGuarantee(guarantee);
        }
    };

    return (
        <form onSubmit={(e) => handleSubmit(e)}>
            <div className="flex justify-center items text-center  w-full">
                <div className="text-2xl font-bold">
                    {translate("BORROW", "Add_Guarantee")}
                </div>
            </div>
            <div className="flex justify-center items  w-full">
                <div className="w-full mt-8 mx-4">
                    <div className="w-full">
                        <div className="intro-y w-full mt-1">
                            {translate("BORROW", "Available_balance")}:{" "}
                            <span className="font-bold">{`${UtilsService.formatAmountV2(
                                available_balance,
                                8,
                            )} ${
                                borrowing.crypto_guarantee.abbreviation
                            }`}</span>
                        </div>
                        <div className="intro-y w-full mt-1">
                            {translate("BORROW", "Minimum_guarantee_required")}:{" "}
                            <span className="font-bold">{`${UtilsService.formatAmountV2(
                                min,
                                8,
                            )} ${
                                borrowing.crypto_guarantee.abbreviation
                            }`}</span>
                        </div>
                        <div className="intro-y w-full mt-1">
                            {translate("BORROW", "Total_guarantee")}:{" "}
                            <span className="font-bold">{`${UtilsService.formatAmountV2(
                                borrowing.total_guarantee,
                                8,
                            )} ${
                                borrowing.crypto_guarantee.abbreviation
                            }`}</span>
                        </div>
                        <div className="intro-y w-full mt-3">
                            <NumberInput
                                val={amount}
                                onChange={setAmount}
                                decimal={8}
                                min={min}
                                className="border-1 peer block w-full appearance-none rounded-lg bg-transparent px-2.5 pb-2.5 pt-4 text-sm text-gray-900 focus:outline-none focus:ring-0"
                                required
                            />
                            <label
                                htmlFor="swap-amount"
                                className="absolute top-2 left-1 z-10 origin-[0] -translate-y-4 scale-75 bg-white focus:bg-transparent px-2 text-sm text-gray-500 duration-300 peer-placeholder-shown:top-1/2 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:scale-100 peer-focus:top-2 peer-focus:-translate-y-4 peer-focus:scale-75 peer-focus:px-2"
                            >
                                {translate("Swap", "Amount")}
                            </label>
                        </div>
                        <div className="intro-y w-full mt-3">
                            <div className="flex justify-end items md:right w-full mt-4">
                                <button
                                    type="submit"
                                    className="btn btn-perfect mt-2 mr-2"
                                    disabled={processing || amount < min}
                                >
                                    {translate("ColdWallet", "Validate")}
                                </button>
                                <button
                                    type="button"
                                    className="btn  mt-2"
                                    onClick={() => handleModal()}
                                    disabled={processing}
                                >
                                    {translate("Button", "Cancel")}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    );
};

const AddGuarantee = ({ borrowing }: { borrowing: Borrowing }) => {
    const { translate } = translator();
    const { handleModal } = React.useContext(ModalContext);
    let min = 0;

    if (
        borrowing.crypto_borrow?.current_price &&
        borrowing.crypto_guarantee.current_price
    ) {
        const necessary_guarantee_amount_usd =
            ((borrowing.borrow_amount * 100) / Number(borrowing.iltv_percent)) *
            borrowing.crypto_borrow?.current_price;

        const necessary_guarantee_amount_nedded = UtilsService.convertDevise(
            1,
            borrowing.crypto_guarantee.current_price,
            necessary_guarantee_amount_usd,
            8,
        );

        const res =
            necessary_guarantee_amount_nedded - borrowing.total_guarantee;

        min = res > 0 ? res : 0;
    }

    if (min == 0) {
        min = UtilsService.convertAmountSwapV2(
            10,
            1,
            borrowing.crypto_guarantee.current_price || 0,
        );
    }
    return (
        <div className="mt-2 text-center">
            <button
                type="button"
                className="btn btn-perfect mb-2"
                onClick={() => {
                    if (min > 0)
                        handleModal(
                            <AddGuaranteeForm
                                borrowing={borrowing}
                                min={min}
                            />,
                        );
                }}
                disabled={min === 0 || borrowing.status !== "pending"}
            >
                <AddSmSvg />
                {translate("BORROW", "Guarantee")}
            </button>
        </div>
    );
};

export default AddGuarantee;
