import CardsIllustration from "../../images/cards-illustration.png";
import cardbg from "../../images/choise-card.png";
import pricingbg from "../../images/new/pricing_bg.png";
import translator from "../../../Components/Extra/Translation/Translate";

export default function Section02() {
    const { translate } = translator();
    return (
        <div className="bg-slate-800 py-12 mb-10">
            <section className="flex justify-center items-center mx-4 lg:!mx-20">
                <div className="max-w-screen-xl mx-auto w-full">
                    <div className="flex flex-col md:flex-row justify-center items-center gap-5">
                        <div
                            data-aos="fade-down"
                            className="relative flex bg-cover bg-center bg-no-repeat justify-center py-8 px-4 h-full items-center w-full md:w-1/2"
                            style={{
                                backgroundImage: `url(${pricingbg})`,
                            }}
                        >
                            <div className="flex justify-center items-center mx-3">
                                <img
                                    src={cardbg}
                                    alt="hero_img1"
                                    className=" animate-move-up-down"
                                />
                            </div>
                        </div>
                        <div
                            data-aos="fade-up"
                            className="flex flex-col justify-start items-center md:!items-start w-full md:w-2/3 text-center md:!text-left"
                        >
                            <h2
                                className="text-white bg-clip-text mb-3 font-bold md:!text-5xl text-3xl leading-tight tracking-tighter"
                                data-aos="fade-up"
                                data-aos-anchor="[data-aos-id-2]"
                                data-aos-delay="100"
                            >
                                {translate("LANDING", "SECTION2_TITLE")}
                            </h2>
                            <p
                                className="text-lg text-slate-200 mb-8"
                                data-aos="fade-up"
                                data-aos-anchor="[data-aos-id-2]"
                                data-aos-delay="200"
                            >
                                {translate("LANDING", "SECTION2_DESC")}
                            </p>
                            <p
                                className="text-lg text-slate-200 mb-8"
                                data-aos="fade-up"
                                data-aos-anchor="[data-aos-id-2]"
                                data-aos-delay="200"
                            >
                                {translate("LANDING", "SECTION2_DESC2")}
                            </p>

                            {/* Button */}
                            <div
                                className="max-w-xs mx-auto sm:max-w-none mb-8"
                                data-aos="fade-up"
                                data-aos-anchor="[data-aos-id-2]"
                                data-aos-delay="300"
                            >
                                <div>
                                    <a
                                        className="btn-sm inline-flex text-base items-center text-black bg-kalypay-500 hover:bg-[#ffd67c] group shadow-sm"
                                        href="#cards"
                                    >
                                        {translate("LANDING", "COMPARE_CARD")}
                                        <span className="tracking-normal text-white group-hover:translate-x-0.5 transition-transform duration-150 ease-in-out ml-2">
                                            <svg
                                                className="fill-current"
                                                width={12}
                                                height={10}
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path d="M1 6.002h7.586L6.293 8.295a1 1 0 1 0 1.414 1.414l4-4a1 1 0 0 0 0-1.416l-4-4a1 1 0 0 0-1.414 1.416l2.293 2.293H1a1 1 0 1 0 0 2Z" />
                                            </svg>
                                        </span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
        // <section data-aos-id-2="">
        //     <div className="relative max-w-screen-xl my-20 mx-auto">
        //         {/* Bg */}
        //         <div
        //             className="absolute inset-0 md:!rounded-[50px] rounded-none mb-24 md:mb-0 bg-slate-800 pointer-events-none -z-10"
        //             aria-hidden="true"
        //         />

        //         <div className="mx-auto px-4 sm:px-6">
        //             <div className="pt-12 md:py-20">
        //                 {/* Section content */}
        //                 <div className="relative max-w-xl mx-auto md:max-w-none text-center md:text-left">
        //                     {/* Content */}
        //                     <div className="md:max-w-[34rem]">
        //                         {/* Copy */}
        //                         <h2
        //                             className="h2 md:min-h-[6rem] text-white mb-4"
        //                             data-aos="fade-up"
        //                             data-aos-anchor="[data-aos-id-2]"
        //                             data-aos-delay="100"
        //                         >
        //                             {translate("LANDING", "SECTION2_TITLE")}
        //                         </h2>
        //                         <p
        //                             className="text-lg text-slate-400 mb-8"
        //                             data-aos="fade-up"
        //                             data-aos-anchor="[data-aos-id-2]"
        //                             data-aos-delay="200"
        //                         >
        //                             {translate("LANDING", "SECTION2_DESC")}
        //                         </p>
        //                         <p
        //                             className="text-lg text-slate-400 mb-8"
        //                             data-aos="fade-up"
        //                             data-aos-anchor="[data-aos-id-2]"
        //                             data-aos-delay="200"
        //                         >
        //                             {translate("LANDING", "SECTION2_DESC2")}
        //                         </p>

        //                         {/* Button */}
        //                         <div
        //                             className="max-w-xs mx-auto sm:max-w-none mb-8"
        //                             data-aos="fade-up"
        //                             data-aos-anchor="[data-aos-id-2]"
        //                             data-aos-delay="300"
        //                         >
        //                             <div>
        //                                 <a
        //                                     className="btn-sm inline-flex items-center text-black bg-kalypay-500 hover:bg-[#ffd67c] group shadow-sm"
        //                                     href="#cards"
        //                                 >
        //                                     {translate(
        //                                         "LANDING",
        //                                         "COMPARE_CARD",
        //                                     )}
        //                                     <span className="tracking-normal text-white group-hover:translate-x-0.5 transition-transform duration-150 ease-in-out ml-2">
        //                                         <svg
        //                                             className="fill-current"
        //                                             width={12}
        //                                             height={10}
        //                                             xmlns="http://www.w3.org/2000/svg"
        //                                         >
        //                                             <path d="M1 6.002h7.586L6.293 8.295a1 1 0 1 0 1.414 1.414l4-4a1 1 0 0 0 0-1.416l-4-4a1 1 0 0 0-1.414 1.416l2.293 2.293H1a1 1 0 1 0 0 2Z" />
        //                                         </svg>
        //                                     </span>
        //                                 </a>
        //                             </div>
        //                         </div>

        //                         {/* Quote
        //                         <div
        //                             className="flex max-w-md mx-auto md:mx-0 text-left"
        //                             data-aos="fade-up"
        //                             data-aos-anchor="[data-aos-id-2]"
        //                             data-aos-delay="300"
        //                         >
        //                             <img
        //                                 className="rounded-full self-start shrink-0 mr-3"
        //                                 src={QuoteAuthor}
        //                                 width={32}
        //                                 height={32}
        //                                 alt="Quote author 01"
        //                             />
        //                             <div>
        //                                 <blockquote className="text-slate-400 m-0">
        //                                     “ We know the card market very well
        //                                     and this product provides the speed,
        //                                     flexible account model and API-first
        //                                     approach that no one else can. ”
        //                                 </blockquote>
        //                             </div>
        //                         </div>*/}
        //                     </div>

        //                     {/* img */}
        //                     <div className="md:absolute md:left-[550px] md:top-0 -mb-12 mt-8 md:mt-36 md:mb-0">
        //                         <div className="relative z-10 mx-0">
        //                             <img
        //                                 className="absolute top-1/2 left-1/2 -translate-y-1/2 opacity-50 -translate-x-1/2 mt-16 md:mt-0 pointer-events-none -z-10 max-w-none mix-blend-lighten"
        //                                 src={CardsIllustration}
        //                                 alt="Cards illustration"
        //                                 aria-hidden="true"
        //                             />
        //                             <div
        //                                 className="md:mt-[60px]"
        //                                 data-aos="fade-up"
        //                                 data-aos-anchor="[data-aos-id-2]"
        //                             >
        //                                 <img
        //                                     src={Features}
        //                                     className="md:max-w-none"
        //                                     width={750}
        //                                     alt="Features 01"
        //                                 />
        //                             </div>
        //                         </div>
        //                     </div>
        //                 </div>
        //             </div>
        //         </div>
        //     </div>
        // </section>
    );
}
