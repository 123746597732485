import { ModalProvider } from "../../../../../Components/Extra/Modal/ModalContext";
import Borrowing from "../../../../../Models/Borrowing";
import Detail from "./detail";

const DetailModal = ({ borrowing }: { borrowing: Borrowing }) => {
    return (
        <ModalProvider>
            <Detail borrowing={borrowing} />
        </ModalProvider>
    );
};

export default DetailModal;
