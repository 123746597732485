import axios from "../AxiosInstance";
import UtilsService from "../UtilsService";

export interface KpPayementInformation {
    payee_name: string;
    account: string;
    reference: string;
    amount: number;
    amount_usd: number;
    wallet: string;
    description: string;
}

export interface KpPayementAccess {
    wallet: {
        solde: number;
        abbreviation: string;
        type: string;
    };
    ref: string;
    access_token: string;
}

const getInformation = (ref: string): Promise<KpPayementInformation> => {
    return axios
        .get(`/payment/kp/get-payment-information/${ref}`)
        .then(({ data }) => {
            return data as KpPayementInformation;
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const authorisation = (payload: {
    account: string;
    password: string;
    ref: string;
}): Promise<KpPayementAccess> => {
    return axios
        .post(`/payment/kp/authorisation`, { ...payload })
        .then(({ data }) => {
            return data as KpPayementAccess;
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const processPayment = (access_token: string, ref: string): Promise<any> => {
    return axios
        .put(
            `/payment/kp/process-payment`,
            { ref },
            {
                headers: {
                    access_token: access_token,
                },
            },
        )
        .then(({ data }) => {
            return data as any;
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const cancelPayment = (ref: string): Promise<any> => {
    return axios
        .put(`/payment/kp/cancel-payment/${ref}`)
        .then(({ data }) => {
            return data as any;
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const KpPaymentService = {
    getInformation,
    authorisation,
    processPayment,
    cancelPayment,
};

export default KpPaymentService;
