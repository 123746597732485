import Crypto from "./Crypto";

export interface IBorrowing {
    loan_amount: number;
    to_borrow: string;
    guarantee_amount: number;
    to_guarantee: string;
    ltv: number;
}

export interface OBorrowing {
    id: number;
    uid: string;
    crypto_borrow: Crypto;
    crypto_guarantee: Crypto;
    borrow_amount: number;
    total_guarantee: number;
    iltv_percent: number;
    total_to_be_repaid: number;
    end_date: string;
    created_at: string;
    status: string;
}

export default class Borrowing {
    id: number;
    uid: string;
    crypto_borrow: Crypto;
    crypto_guarantee: Crypto;
    borrow_amount: number;
    total_guarantee: number;
    iltv_percent: number;
    total_to_be_repaid: number;
    end_date: string;
    created_at: string;
    status: string;

    total_already_repaid: number;
    initial_amount_guaranteed: number;

    guarantee_liquidate_amount: number;
    guarantee_added_amount: number;

    constructor(
        id: number,
        uid: string,
        crypto_borrow: Crypto,
        crypto_guarantee: Crypto,
        borrow_amount: number,
        total_guarantee: number,
        iltv_percent: number,
        total_to_be_repaid: number,
        end_date: string,
        created_at: string,
        status: string,

        total_already_repaid: number,
        initial_amount_guaranteed: number,
        guarantee_liquidate_amount: number,
        guarantee_added_amount: number,
    ) {
        this.id = id;
        this.uid = uid;
        this.crypto_borrow = crypto_borrow;
        this.crypto_guarantee = crypto_guarantee;
        this.borrow_amount = borrow_amount;
        this.total_guarantee = total_guarantee;
        this.iltv_percent = iltv_percent;
        this.total_to_be_repaid = total_to_be_repaid;
        this.end_date = end_date;
        this.created_at = created_at;
        this.status = status;
        this.total_already_repaid = total_already_repaid;
        this.initial_amount_guaranteed = initial_amount_guaranteed;
        this.guarantee_liquidate_amount = guarantee_liquidate_amount;
        this.guarantee_added_amount = guarantee_added_amount;
    }

    restToTay() {
        const rest = this.total_to_be_repaid - this.total_already_repaid;
        return rest < 0 ? 0 : rest;
    }
    getBorrowingRef() {
        return "LO" + this.id.toString().padStart(5, "0");
    }
}

export interface IAddGuarantee {
    id: number;
    amount: number;
}

export interface IRefund {
    id: number;
    amount: number;
}

export enum BorrowingStatus {
    PENDING = "pending",
    FINISHED = "finished",
    CANCELLED = "cancelled",
}
