import { useEffect, useState } from "react";
import { SupportPost, extractTitleTopicAndBody } from "./mdx-helpers";

const mdxContext = require.context(
    "../../pages/(support-pages)/supports",
    false,
    /\.mdx$/,
    "lazy",
);

export const allFiles = async (): Promise<SupportPost[]> => {
    const all_posts: SupportPost[] = [];

    const importPromises = mdxContext.keys().map(async (fileName) => {
        const filePath = await mdxContext(fileName);

        const response = await fetch(filePath);

        const content = await response.text();

        const data = extractTitleTopicAndBody(content);

        all_posts.push(data);
    });

    await Promise.all(importPromises);

    return all_posts;
};

const MdxFileLoader = () => {
    const [mdxFiles, setMdxFiles] = useState<any[]>([]);

    const getFiles = async () => {
        await allFiles();
    };

    useEffect(() => {
        getFiles();
    }, []);
    return <div></div>;
};

export default MdxFileLoader;
