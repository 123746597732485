import React, { useState } from "react";
import translator from "../../../../Components/Extra/Translation/Translate";
import UserTransaction from "./UserTransaction";
import UserNewWallet from "./UserNewWallet";
import DepositWithdrawCard from "./DepositWithdrawCard";

export interface UserTabsProps {
    id: any;
}

const UserTabs = () => {
    const { translate } = translator();
    const [active, setActive] = useState<string>("wallet");
    const toggleActive = (value: string) => {
        setActive(value);
    };

    return (
        <>
            <ul className="flex flex-wrap ul-bg-mode text-sm font-medium mt-4 md:w-max lg:w-max xl:w-max bg-white text-center text-gray-500 border-gray-200 rounded-t-lg">
                <li className="">
                    <button
                        type="button"
                        className={
                            active === "wallet"
                                ? "inline-block p-4 text-blue-600 bg-gray-100 rounded-t-lg nav-active"
                                : "inline-block p-4 text-blue-600 bg-gray-100 rounded-t-lg nav-not-active"
                        }
                        onClick={() => toggleActive("wallet")}
                    >
                        {translate("My_Transactions", "WALLETS")}
                    </button>
                </li>
                <li className="">
                    <button
                        type="button"
                        className={
                            active === "transaction"
                                ? "inline-block p-4 text-blue-600 bg-gray-100 rounded-t-lg nav-active"
                                : "inline-block p-4 text-blue-600 bg-gray-100 rounded-t-lg nav-not-active"
                        }
                        onClick={() => toggleActive("transaction")}
                    >
                        {translate("Admin_Users", "Transactions")}
                    </button>
                </li>
            </ul>
            <div className="intro-y col-span-12 box p-4">
                {active === "transaction" && (
                    <>
                        <DepositWithdrawCard />
                        <UserTransaction />
                    </>
                )}
                {active === "wallet" && <UserNewWallet />}
            </div>
        </>
    );
};

export default UserTabs;
