import { useNavigate } from "react-router";
import UtilsService from "../../../Services/UtilsService";

const Error = () => {
    const navigate = useNavigate();
    return (
        <div className="container">
            <div className="error-page flex h-screen flex-col items-center justify-center text-center lg:flex-row lg:text-left">
                <div className="-intro-x lg:mr-20">
                    <img
                        alt="Not found image"
                        className="h-48 lg:h-auto"
                        src={UtilsService.getPulicImage(
                            "/dist/image/error-illustration.svg",
                        )}
                    />
                </div>
                <div className="mt-10 text-white lg:mt-0">
                    <div className="intro-x text-8xl font-medium">404</div>
                    <div className="intro-x mt-5 text-xl font-medium lg:text-3xl">
                        Oops. This page has gone missing.
                    </div>
                    <div className="intro-x mt-3 text-lg">
                        You may have mistyped the address or the page may have
                        moved.
                    </div>
                    <button
                        onClick={() => navigate("/")}
                        className="intro-x btn mt-10 border-white py-3 px-4 text-white dark:text-slate-200"
                    >
                        Back to Home
                    </button>
                </div>
            </div>
        </div>
    );
};

export default Error;
