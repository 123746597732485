export enum StatusMailModeration {
    PENDING = "pending",
    REJECTED = "rejected",
    ACCEPTED_V1 = "accepted_v1",
    ACCEPTED = "accepted",
}

export default class MailModeration {
    id?: number;
    new_email?: string;
    old_email?: string;
    status?: StatusMailModeration;
    created_at?: Date;
    user?: any;
    constructor(id: number, new_email: string, old_email: string) {
        this.id = id;
        this.new_email = new_email;
        this.old_email = old_email;
        // this.status = status;
    }
}
