import { BadgeCheck, ClipboardList, UserPlus } from "lucide-react";
import translator from "../../../../Components/Extra/Translation/Translate";
import iban from "../../../images/iban.jpg";
export default function Section2() {
    const { translate } = translator();
    return (
        <section className="flex justify-center items-center mx-4 my-20 lg:!mx-20">
            <div className="max-w-screen-xl mx-auto w-full">
                <div className="relative md:rounded-[50px] rounded-xl font-[sans-serif] overflow-hidden">
                    <div
                        className="absolute inset-0 bg-cover bg-center bg-clip-content w-full bg-no-repeat brightness-75"
                        style={{ backgroundImage: `url(${iban})` }}
                    ></div>

                    <div className="relative z-10 max-w-6xl mx-auto py-16 px-4">
                        <div
                            className="text-center mb-12 md:mb-10"
                            data-aos="fade-up"
                        >
                            <h2 className="text-white bg-clip-text mb-3 font-bold md:!text-5xl text-3xl leading-tight tracking-tighter">
                                {translate("LANDING_IBAN", "section2_titre1")}
                            </h2>
                        </div>

                        <div className="grid grid-cols-1 md:grid-cols-2 lg:!grid-cols-3 gap-8 max-md:max-w-md mx-auto">
                            <div
                                data-aos="flip-down"
                                className="bg-slate-800/95 rounded-2xl overflow-hidden shadow-md hover:shadow-lg transition-all"
                            >
                                <div className="p-8">
                                    <UserPlus
                                        size={40}
                                        className="mb-5 text-white"
                                    />
                                    <h4 className="text-white text-xl font-bold mb-1">
                                        {translate(
                                            "LANDING_IBAN",
                                            "section2_titre2",
                                        )}
                                    </h4>
                                    <p className="text-slate-300 text-base">
                                        {translate(
                                            "LANDING_IBAN",
                                            "section2_titre2_p",
                                        )}
                                    </p>
                                </div>
                            </div>

                            <div
                                data-aos="flip-down"
                                className="bg-slate-800/95 rounded-2xl overflow-hidden shadow-md hover:shadow-lg transition-all"
                            >
                                <div className="p-8">
                                    <ClipboardList
                                        size={40}
                                        className="mb-5 text-white"
                                    />
                                    <h4 className="text-white text-xl font-bold mb-1">
                                        {translate(
                                            "LANDING_IBAN",
                                            "section2_titre3",
                                        )}
                                    </h4>
                                    <p className="text-slate-300 text-base">
                                        {translate(
                                            "LANDING_IBAN",
                                            "section2_titre3_p",
                                        )}
                                    </p>
                                </div>
                            </div>

                            <div
                                data-aos="flip-down"
                                className="bg-slate-800/95 rounded-2xl overflow-hidden shadow-md hover:shadow-lg transition-all"
                            >
                                <div className="p-8">
                                    <BadgeCheck
                                        size={40}
                                        className="mb-5 text-white"
                                    />
                                    <h4 className="text-white text-xl font-bold mb-1">
                                        {translate(
                                            "LANDING_IBAN",
                                            "section2_titre4",
                                        )}
                                    </h4>
                                    <p className="text-slate-300 text-base">
                                        {translate(
                                            "LANDING_IBAN",
                                            "section2_titre4_p",
                                        )}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        // <section className="flex justify-center items-center mx-5 my-20 lg:mx-20">
        //     <div className="max-w-screen-xl mx-auto w-full">
        //         <h1 className="text-4xl text-center !py-10 text-black dark:text-white">
        //             {translate("LANDING_IBAN", "section2_titre1")}
        //         </h1>
        //         <p className="mt-0 text-[#E4E4E4] p-6 md:px-36"></p>
        //         <div className="flex flex-col md:flex-row gap-4 md:!gap-8">
        //             <div className="md:w-1/3 flex  justify-center ">
        //                 <div
        //                     className="p-10 bg-kalyssi-800 rounded-xl bg-gradient-to-tr from-slate-800 to-[#0f0f0f]"
        //                     data-aos="flip-up"
        //                 >
        //                     <figure className="flex justify-center w-fit p-5 text-gray-700 bg-slate-300 m-auto rounded-lg ">
        //                         <UserPlus size={40} />
        //                     </figure>
        //                     <h4 className="text-center text-xl font-semibold p-[20px] text-slate-200">
        //                         {translate("LANDING_IBAN", "section2_titre2")}
        //                     </h4>
        //                     <p className="text-slate-300 text-center">
        //                         {translate("LANDING_IBAN", "section2_titre2_p")}
        //                     </p>
        //                 </div>
        //             </div>
        //             <div className="md:w-1/3 flex justify-center">
        //                 <div
        //                     className="p-10 bg-kalyssi-800  rounded-xl bg-gradient-to-tr from-slate-800 to-[#0f0f0f]"
        //                     data-aos="flip-up"
        //                 >
        //                     <figure className="flex justify-center text-gray-700 w-fit p-5 bg-slate-300 m-auto rounded-lg">
        //                         <ClipboardList size={40} />
        //                     </figure>

        //                     <h4 className="text-center text-xl font-semibold p-[20px] text-slate-200">
        //                         {translate("LANDING_IBAN", "section2_titre3")}
        //                     </h4>
        //                     <p className="text-slate-300 text-center">
        //                         {translate("LANDING_IBAN", "section2_titre3_p")}
        //                     </p>
        //                 </div>
        //             </div>
        //             <div className="md:w-1/3 flex justify-center">
        //                 <div
        //                     className="p-10 bg-kalyssi-800  rounded-xl bg-gradient-to-tr from-slate-800 to-[#0f0f0f]"
        //                     data-aos="flip-up"
        //                 >
        //                     <figure className="flex justify-center text-gray-700 w-fit p-5 bg-slate-300 m-auto rounded-lg ">
        //                         <BadgeCheck size={40} />
        //                     </figure>

        //                     <h4 className="text-center text-xl font-semibold p-[20px] text-slate-200">
        //                         {translate("LANDING_IBAN", "section2_titre4")}
        //                     </h4>
        //                     <p className="text-slate-300 text-center">
        //                         {translate("LANDING_IBAN", "section2_titre4_p")}
        //                     </p>
        //                 </div>
        //             </div>
        //         </div>
        //     </div>
        // </section>
    );
}
