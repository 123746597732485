import React, { useEffect, useState } from "react";
import Moderation, { AdressTypeModeraton } from "../../../Models/Moderation";
import translator from "../../../Components/Extra/Translation/Translate";
import { ModalContext } from "../../../Components/Extra/Modal/ModalContext";
import UtilsService from "../../../Services/UtilsService";
import MailModeration from "../../../Models/MailModeration";
import KpButton from "../../Components/Inputs/KpButton";
import SaveBouton from "../../../Components/Extra/Bouton/SaveBouton";
import MailModerationService from "../../../Services/MailModeration/MailModerationService";
import FormAddQuestion from "./FormAddQuestion";

const ResponseContent = ({ moderation }: { moderation: MailModeration }) => {
    const { translate } = translator();
    const [question, setQuestion] = useState("");
    const [questions, setQuestions] = useState<any>([]);

    const findByQuestionMailModeration = async () => {
        if (moderation.id) {
            const res =
                await MailModerationService.findByQuestionMailModeration(
                    moderation.id,
                );
            setQuestions(res);
        }
    };

    useEffect(() => {
        if (moderation?.id) {
            findByQuestionMailModeration();
        }
    }, []);

    return (
        <>
            {" "}
            <div className="text-center font-medium lg:mt-3 lg:text-left">
                {translate("Profil", "Questions reponses")}
            </div>
            {questions.length > 0 &&
                questions.map((elt: any, key: number) => (
                    <>
                        <div>
                            {" "}
                            <p className="font-bold">{elt.question}</p>
                        </div>
                        <div>
                            <p>{elt.response}</p>
                        </div>
                    </>
                ))}
        </>
    );
};

const ButtonViewResponse = ({ moderation }: { moderation: MailModeration }) => {
    const { translate } = translator();
    const { handleModal } = React.useContext(ModalContext);

    return (
        <KpButton
            mode="purple"
            type="button"
            onClick={() =>
                handleModal(<ResponseContent moderation={moderation} />)
            }
        >
            View response
        </KpButton>
    );
};

export default ButtonViewResponse;
