import Crypto from "./Crypto";
import Duration from "./Duration";
import Product from "./Product";

export enum EanringType {
    arbitrage = "arbitrage",
    basic = "basic",
    trading = "trading",
}

export interface IStacking {
    amount: number;
    duration: number;
    product?: number;
    crypto: number;
    uid?: string; // only for admin
    is_roi_at_end?: boolean;
    type: EanringType;
}

export default class Stacking {
    id?: number;
    amount!: number;
    usd_price!: number;
    stacking_date!: Date;
    unstacking_date?: Date;
    end_date!: Date;
    type: any;
    crypto!: Crypto;
    product?: Product;
    created_at?: Date;
    updated_at?: Date;
    flexible?: boolean;
    is_klc?: boolean;
    stopped?: Date;
    roi!: number;
    duration!: Duration;
    user?: any;
    gain_a_term?: number;
    is_roi_at_end?: boolean;
    earn_type?: EanringType;

    constructor(
        amount: number,
        usd_price: number,
        stacking_date: Date,
        end_date: Date,
        is_roi_at_end: boolean,
    ) {
        this.amount = amount;
        this.usd_price = usd_price;
        this.stacking_date = stacking_date;
        this.end_date = end_date;
        this.is_roi_at_end = is_roi_at_end;
    }
}
