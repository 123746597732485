import { useCallback, useEffect, useState } from "react";
import { useAppProvider } from "../../../../Provider/AppProvider";
import BorrowingHistory, {
    BorrowingType,
} from "../../../../../Models/BorrowingHistory";
import translator from "../../../../../Components/Extra/Translation/Translate";
import { DataTableColums } from "../../../../../Components/Extra/DataTable/DataTable";
import DataTableWithPaginatedData from "../../../../../Components/Extra/DataTable/DataTableWithPaginatedData/DataTableWithPaginatedData";
import BorrowingService from "../../../../../Services/Borrowing/BorrowingService";
import { DatatableFilter } from "../../../../../Models/DatatableFilter";
import Borrowing from "../../../../../Models/Borrowing";

const HistoriesDetailTable = ({ borrowing }: { borrowing: Borrowing }) => {
    const [histories, setHistories] = useState<Array<BorrowingHistory>>([]);
    const { setProcessing, finalizeProcessing } = useAppProvider();
    const [currentPage, setCurrentPage] = useState<number>(0);
    const [increment, setIncrementor] = useState<number>(0);
    const [direction, setDirection] = useState<"ASC" | "DESC">("DESC");
    const [filterIndex, setFilterIndex] = useState<number>(0);
    const [perPage, setPerPage] = useState<number>(10);
    const [nbPage, setNbPage] = useState<number>(0);
    const [search, setSearch] = useState<string>("");
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const { translate } = translator();

    const colums: DataTableColums[] = [
        {
            index: "date",
            title: "Date",
            content: (row: BorrowingHistory) => {
                return row.created_at;
            },
            filter: true,
            className: "whitespace-nowrap",
        },
        {
            index: "amount",
            title: "BORROW.Amount",
            content: (row: BorrowingHistory) => {
                const abbr =
                    row.type === BorrowingType.ADD_GUARANTEE
                        ? borrowing.crypto_guarantee.abbreviation
                        : borrowing.crypto_borrow.abbreviation;
                return `${row.amount} ${abbr}`;
            },
            filter: true,
            className: "whitespace-nowrap text-right",
        },
        {
            index: "amount_usd",
            title: "BORROW.Amount_usd",
            content: (row: BorrowingHistory) => {
                return `${row.amount_usd} USD`;
            },
            filter: true,
            className: "whitespace-nowrap text-right",
        },
        {
            index: "type",
            title: "BORROW.Type",
            content: (row: BorrowingHistory) => {
                return `${translate("BORROW", row.type)}`;
            },
            filter: true,
            className: "whitespace-nowrap",
        },
    ];

    const getBorrowingHistoriesPaginate = useCallback(async () => {
        setIsLoading(true);
        const filter: DatatableFilter = {
            page: currentPage,
            size: perPage,
            search: search,
        };

        try {
            const res = await BorrowingService.getUserBorrowingHistoryPaginate(
                borrowing.id,
                filter,
            );
            setHistories([...res.data]);
            setNbPage(res.lastPage);
        } catch (err: any) {
            console.log(err);
        } finally {
            setIsLoading(false);
        }
    }, [increment]);

    useEffect(() => {
        setIncrementor(increment + 1);
    }, [currentPage, perPage, search]);

    useEffect(() => {
        setCurrentPage(0);
        setIncrementor(increment + 1);
    }, [direction]);

    const handlePageChange = useCallback((page: number) => {
        setCurrentPage(page + 1);
    }, []);

    const handlePerRowsChange = useCallback((newPerPage: number) => {
        setPerPage(newPerPage);
    }, []);

    const onSearchInputChange = useCallback(async (txt: string) => {
        setSearch(txt);
    }, []);

    useEffect(() => {
        setProcessing(true);
        Promise.all([getBorrowingHistoriesPaginate()]).then(() => {
            finalizeProcessing();
        });
    }, [getBorrowingHistoriesPaginate]);

    return (
        <>
            {isLoading && <div>Loading...</div>}
            <DataTableWithPaginatedData
                className="table-report -mt-2 table "
                columns={colums}
                data={histories}
                handlePaginationChange={handlePageChange}
                handlePerRowsChange={handlePerRowsChange}
                onSearchInputChange={onSearchInputChange}
                nbPage={nbPage}
                direction={direction}
                setDirection={setDirection}
                filterIndex={filterIndex}
                setFilterIndex={setFilterIndex}
                exportCSV={false}
                exportPDF={false}
            />
        </>
    );
};

export default HistoriesDetailTable;
