import axios from "../AxiosInstance";
import UtilsService from "../UtilsService";
import { AmlCheckList, RiskAssessment, TransactionData } from "./type";

export const checkAml = async (seach: string) => {
    return await axios
        .post(`/aml-check/check`, {
            objectId: seach,
        })
        .catch((err) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        })
        .then((res) => {
            const { data } = res;

            return data.result as {
                objectId: string;
                score: Record<string, any>;
                check_id: number;
            };
        });
};

export const getOutgoinAnalysisByObjectId = async (
    id_aml: number,
): Promise<RiskAssessment> => {
    return await axios
        .get(`/aml-check/${id_aml}/outgoing-analysis`)
        .catch((err) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        })

        .then((res) => {
            const { data } = res;

            return data;
        });
};

export const getIncomingAnalysisByObjectId = async (
    id_aml: number,
): Promise<RiskAssessment> => {
    return await axios
        .get(`/aml-check/${id_aml}/incoming-analysis`)
        .catch((err) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        })
        .then((res) => {
            const { data } = res;

            return data;
        });
};

export const linkToAddress = async (
    id_aml: number,
    blockchain: string,
    hash: string,
) => {
    return await axios
        .patch(`/aml-check/${id_aml}`, {
            blockchain,
            hash,
        })
        .catch((err) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        })
        .then(() => {
            return `Addresse liée à l'AML avec succès`;
        });
};

export const getList = async (): Promise<AmlCheckList[]> => {
    return await axios
        .get(`${process.env.REACT_APP_API_URL}/aml-check/list`)
        .catch((err) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        })
        .then((res) => {
            return res.data.map((item: any) => ({
                id: item.id,
                objectId: item.objectId,
                blockchain: item.blockchain,
                address: item.address,
                createdAt: new Date(item.createdAt),
            }));
        });
};

export const getDetailsById = async (id_aml: number) => {
    return await axios
        .get(`/aml-check/${id_aml}`)
        .catch((err) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        })
        .then((res) => {
            const { data: item } = res;

            return {
                id: item.id,
                objectId: item.objectId,
                blockchain: item.blockchain,
                address: item.address,
                createdAt: new Date(item.createdAt),
            };
        });
};

export const getCoins = async (id_aml: number): Promise<TransactionData> => {
    return await axios
        .get(`${process.env.REACT_APP_API_URL}/aml-check/${id_aml}/coins`)
        .catch((err) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        })
        .then((res) => {
            return res.data as TransactionData;
        });
};
