import Borrowing, {
    BorrowingStatus,
    IAddGuarantee,
    IBorrowing,
    IRefund,
    OBorrowing,
} from "../../Models/Borrowing";
import BorrowingHistory from "../../Models/BorrowingHistory";
import { DatatableFilter } from "../../Models/DatatableFilter";
import axios from "../AxiosInstance";
import UtilsService from "../UtilsService";

const map = (item: any): Borrowing => {
    const borrowing = new Borrowing(
        item.id,
        item.uid,
        item.crypto_borrow,
        item.crypto_guarantee,
        item.borrow_amount,
        item.total_guarantee,
        item.iltv_percent,
        item.total_to_be_repaid,
        UtilsService.getBeautifulFormatedDate(item.end_date),
        UtilsService.getBeautifulFormatedDate(item.created_at),
        item.status,
        item.total_already_repaid,
        item.initial_amount_guaranteed,
        item.guarantee_liquidate_amount,
        item.guarantee_added_amount,
    );

    return borrowing;
};

const mapHistory = (item: any): BorrowingHistory => {
    const borrowing = new BorrowingHistory(
        item.id,
        item.amount,
        item.amount_usd,
        item.type,
        UtilsService.getBeautifulFormatedDate(item.created_at),
    );

    return borrowing;
};

const create = async (payload: IBorrowing): Promise<any> => {
    return axios
        .post("/borrowing", {
            ...payload,
        })
        .then((res) => {
            return Promise.resolve(res.data.message);
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const getUserBorrowingPaginate = async (
    filter: DatatableFilter,
): Promise<any> => {
    return axios
        .get(`/borrowing/get-pending-borrowing`, {
            params: { ...filter },
        })
        .then(({ data }) => {
            const borrowings: BorrowingHistory[] = data.data.map((item: any) =>
                map(item),
            );

            return {
                data: borrowings,
                count: data.count,
                currentPage: data.currentPage,
                nextPage: data.nextPage,
                prevPage: data.prevPage,
                lastPage: data.lastPage,
            };
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const addGuarantee = async (payload: IAddGuarantee): Promise<any> => {
    return axios
        .post("/borrowing/add-guarantee", {
            ...payload,
        })
        .then((res) => {
            return Promise.resolve(res.data.message);
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const refund = async (payload: IRefund): Promise<any> => {
    return axios
        .post("/borrowing/refund", {
            ...payload,
        })
        .then((res) => {
            return Promise.resolve(res.data.message);
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const getHistoryByBorrowingId = async (
    borrowing_id: number,
): Promise<BorrowingHistory[]> => {
    return axios
        .get(`/borrowing/history/${borrowing_id}`)
        .then(({ data }) => {
            const history: BorrowingHistory[] = data.map((item: any) =>
                mapHistory(item),
            );

            return history;
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const getAllBorrowingPaginate = async (
    filter: DatatableFilter,
): Promise<any> => {
    return axios
        .get(`/borrowing/get-pending-borrowing`, {
            params: { ...filter },
        })
        .then(({ data }) => {
            const borrowings: Borrowing[] = data.data.map((item: any) =>
                map(item),
            );

            return {
                data: borrowings,
                count: data.count,
                currentPage: data.currentPage,
                nextPage: data.nextPage,
                prevPage: data.prevPage,
                lastPage: data.lastPage,
            };
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const getBorrowingFilterByStatus = async (
    filter: DatatableFilter,
    status?: BorrowingStatus,
): Promise<any> => {
    const end_point = `/borrowing/get-all-by-status/${status}`;
    return axios
        .get(end_point, { params: filter })
        .then(({ data }) => {
            const borrowings: Borrowing[] = data.data.map((item: any) =>
                map(item),
            );

            return {
                data: borrowings,
                count: data.count,
                currentPage: data.currentPage,
                nextPage: data.nextPage,
                prevPage: data.prevPage,
                lastPage: data.lastPage,
            };
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const getUserBorrowingHistoryPaginate = async (
    id: number,
    filter: DatatableFilter,
): Promise<any> => {
    return axios
        .get(`/borrowing/get-borrowing-histories/${id}`, {
            params: { ...filter },
        })
        .then(({ data }) => {
            // const borrowings: Borrowing[] = data.data.map((item: any) =>
            //     map(item),
            // );
            const history: BorrowingHistory[] = data.data.map((item: any) =>
                mapHistory(item),
            );

            return {
                data: history,
                count: data.count,
                currentPage: data.currentPage,
                nextPage: data.nextPage,
                prevPage: data.prevPage,
                lastPage: data.lastPage,
            };
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const BorrowingService = {
    create,
    getUserBorrowingPaginate,
    addGuarantee,
    refund,
    getHistoryByBorrowingId,
    getBorrowingFilterByStatus,
    getUserBorrowingHistoryPaginate,
};

export default BorrowingService;
