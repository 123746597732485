import React, { useEffect, useState } from "react";
import translator from "../../../Components/Extra/Translation/Translate";
import KpBreadCrumb from "../../Components/KpBreadCrumb";
import ModerationList from "./ModerationList";
import { StatusModeration } from "../../../Models/Moderation";
import { useAppProvider } from "../../Provider/AppProvider";
import { StatusMailModeration } from "../../../Models/MailModeration";
import useAuthentication from "../../../Services/Authentication/useAuthentication";

const MailModerations = () => {
    const { translate } = translator();
    const { isAdmin, isAdvisor } = useAuthentication();
    const { setProcessing, finalizeProcessing } = useAppProvider();
    const [moderatonStatus, setModeratonStatus] = useState<string>(
        isAdvisor ? "pending" : "accepted_v1",
    );
    const changeStatus = (val: string) => {
        setModeratonStatus(val);
    };
    const HeaderModeration = () => {
        return (
            <div className="mb-5">
                <ul className="flex flex-wrap -m-1">
                    {isAdvisor && (
                        <li className="m-1">
                            <button
                                onClick={() => changeStatus("pending")}
                                className={
                                    moderatonStatus ==
                                    StatusMailModeration.PENDING
                                        ? "inline-flex items-center justify-center text-sm font-medium leading-5 rounded-full px-3 py-1 border border-transparent shadow-sm bg-kalypay-500 text-white duration-150 ease-in-out"
                                        : "inline-flex items-center justify-center text-sm font-medium leading-5 rounded-full px-3 py-1 border border-slate-200 dark:border-slate-700 hover:border-slate-300 dark:hover:border-slate-600 shadow-sm bg-white dark:bg-slate-800 text-slate-500 dark:text-slate-400 duration-150 ease-in-out"
                                }
                            >
                                {translate("MODERATION", "PENDING")}
                            </button>
                        </li>
                    )}
                    {isAdvisor && (
                        <li className="m-1">
                            <button
                                onClick={() => changeStatus("accepted_v1")}
                                className={
                                    moderatonStatus ==
                                    StatusMailModeration.ACCEPTED_V1
                                        ? "inline-flex items-center justify-center text-sm font-medium leading-5 rounded-full px-3 py-1 border border-transparent shadow-sm bg-kalypay-500 text-white duration-150 ease-in-out"
                                        : "inline-flex items-center justify-center text-sm font-medium leading-5 rounded-full px-3 py-1 border border-slate-200 dark:border-slate-700 hover:border-slate-300 dark:hover:border-slate-600 shadow-sm bg-white dark:bg-slate-800 text-slate-500 dark:text-slate-400 duration-150 ease-in-out"
                                }
                            >
                                {translate("MODERATION", "ACCEPTED_V1")}
                            </button>
                        </li>
                    )}
                    {isAdmin && (
                        <li className="m-1">
                            <button
                                onClick={() => changeStatus("accepted_v1")}
                                className={
                                    moderatonStatus ==
                                    StatusMailModeration.ACCEPTED_V1
                                        ? "inline-flex items-center justify-center text-sm font-medium leading-5 rounded-full px-3 py-1 border border-transparent shadow-sm bg-kalypay-500 text-white duration-150 ease-in-out"
                                        : "inline-flex items-center justify-center text-sm font-medium leading-5 rounded-full px-3 py-1 border border-slate-200 dark:border-slate-700 hover:border-slate-300 dark:hover:border-slate-600 shadow-sm bg-white dark:bg-slate-800 text-slate-500 dark:text-slate-400 duration-150 ease-in-out"
                                }
                            >
                                {translate("MODERATION", "PENDING")}
                            </button>
                        </li>
                    )}
                    <li className="m-1">
                        <button
                            onClick={() => changeStatus("accepted")}
                            className={
                                moderatonStatus == StatusMailModeration.ACCEPTED
                                    ? "inline-flex items-center justify-center text-sm font-medium leading-5 rounded-full px-3 py-1 border border-transparent shadow-sm bg-kalypay-500 text-white duration-150 ease-in-out"
                                    : "inline-flex items-center justify-center text-sm font-medium leading-5 rounded-full px-3 py-1 border border-slate-200 dark:border-slate-700 hover:border-slate-300 dark:hover:border-slate-600 shadow-sm bg-white dark:bg-slate-800 text-slate-500 dark:text-slate-400 duration-150 ease-in-out"
                            }
                        >
                            {translate("MODERATION", "ACCEPTED")}
                        </button>
                    </li>
                    <li className="m-1">
                        <button
                            onClick={() => changeStatus("rejected")}
                            className={
                                moderatonStatus == StatusMailModeration.REJECTED
                                    ? "inline-flex items-center justify-center text-sm font-medium leading-5 rounded-full px-3 py-1 border border-transparent shadow-sm bg-kalypay-500 text-white duration-150 ease-in-out"
                                    : "inline-flex items-center justify-center text-sm font-medium leading-5 rounded-full px-3 py-1 border border-slate-200 dark:border-slate-700 hover:border-slate-300 dark:hover:border-slate-600 shadow-sm bg-white dark:bg-slate-800 text-slate-500 dark:text-slate-400 duration-150 ease-in-out"
                            }
                        >
                            {translate("MODERATION", "REJECTED")}
                        </button>
                    </li>
                </ul>
            </div>
        );
    };

    useEffect(() => {
        finalizeProcessing();
    }, []);

    return (
        <main className="grow ">
            <div className="lg:relative lg:flex bg-white dark:bg-slate-900">
                <div className="px-6 sm:px-6 xl:!px-[80px] w-full  mx-auto">
                    <div className="sm:flex sm:justify-between sm:items-center mb-5">
                        <KpBreadCrumb
                            title={translate(
                                "Moderation",
                                "Demandes de changement de mail",
                            )}
                        ></KpBreadCrumb>
                    </div>
                    <HeaderModeration />
                    <div className="grid grid-cols-12 gap-6 mt-7 px-3 py-5 box">
                        <ModerationList
                            status={moderatonStatus}
                        ></ModerationList>
                    </div>
                </div>
                {/* <CardDetail></CardDetail> */}
            </div>
        </main>
    );
};

export default MailModerations;
