import { ModalProvider } from "../../../../../Components/Extra/Modal/ModalContext";
import Borrowing from "../../../../../Models/Borrowing";
import Refund from "./refund";

const RefundModal = ({ borrowing }: { borrowing: Borrowing }) => {
    return (
        <ModalProvider>
            <Refund borrowing={borrowing} />
        </ModalProvider>
    );
};

export default RefundModal;
