import { useLocation } from "react-router-dom";
import AssetBorrowingConfigForm from "./AssetBorrowingConfigForm";
import { useEffect, useState } from "react";
import AssetBorrowingConfigService from "../../../Services/assetBorrowingConfig/AssetBorrowingConfigService";
import AssetLtv from "../../../Models/AssetBorrowingConfig";

const AssetBorrowingConfig = () => {
    const location = useLocation();
    const { crypto } = location.state || {};
    const [assetLtv, setAssetLtv] = useState<AssetLtv[]>([]);
    const getAssetLtvByCryptoId = async () => {
        const data = await AssetBorrowingConfigService.getAssetLtvByCryptoId(
            crypto.id,
        );
        setAssetLtv(data);
        console.log(data);
    };
    useEffect(() => {
        getAssetLtvByCryptoId();
    }, []);
    return (
        <>
            {assetLtv.length === 0 ? (
                <AssetBorrowingConfigForm
                    label={"Ajouter"}
                    crypto={crypto}
                    add={true}
                    refresh={getAssetLtvByCryptoId}
                />
            ) : (
                assetLtv.map((item, index) => {
                    return (
                        <AssetBorrowingConfigForm
                            key={index}
                            label={"Modifier"}
                            crypto={crypto}
                            assetltv={item}
                            refresh={getAssetLtvByCryptoId}
                            // add={index === assetLtv.length - 1}
                        />
                    );
                })
            )}
            {assetLtv.length > 0 && (
                <AssetBorrowingConfigForm
                    label={"Ajouter"}
                    crypto={crypto}
                    add={true}
                    refresh={getAssetLtvByCryptoId}
                />
            )}
        </>
    );
};

export default AssetBorrowingConfig;
