import { NavLink } from "react-router-dom";
import translator from "../../../Components/Extra/Translation/Translate";
import useAuthentication from "../../../Services/Authentication/useAuthentication";
import RoutesNames from "../../../Services/RoutesNames";
import Features from "../../images/features-03.png";
import Section01 from "../fintech/section-01";
import Section02 from "../fintech/section-02";
import Section03 from "../fintech/section-03";
import Section04 from "../fintech/section-04";
import Section05 from "../fintech/section-05";
import { Section06 } from "../fintech/section-06";
import Section07 from "../fintech/section-07";
import Section08 from "../fintech/section-08";
import Faqs from "../fintech/faqs";
import Cta from "../fintech/cta";
import Caroussel from "../../../Components/Dashboard/Caroussel";
import Illustration from "../../images/hero-illustration-kp.png";

const KycAml = () => {
    const { translate } = translator();
    const { isAuthenticated } = useAuthentication();
    return (
        <>
            {/* Page Title */}
            <section className="relative z-10 h-[50vh] overflow-hidden">
                {/* Bg */}
                <div
                    className="absolute inset-0 rounded-bl-[100px] mb-28 md:mb-0 pointer-events-none -z-10"
                    aria-hidden="true"
                />

                <div className="max-w-[70%] mx-auto px-4 sm:px-6">
                    <div className="pt-36 pb-14">
                        {/* Page Title content */}
                        <div className="relative max-w-xl mx-auto md:max-w-none text-center md:text-left">
                            {/* Content */}
                            <div className="">
                                {/* Copy */}
                                <h1
                                    className="h1 text-white mb-6 text-center"
                                    data-aos="fade-up"
                                    data-aos-delay="100"
                                    dangerouslySetInnerHTML={{
                                        __html: translate(
                                            "INFORMATIONS",
                                            "KYCAML_TITLE",
                                        ),
                                    }}
                                />
                            </div>

                            {/* Image */}
                            <div className="max-w-sm mx-auto md:max-w-none md:absolute md:left-[90%] md:top-0 -mb-12 md:-mt-12 md:mb-0">
                                <div className="relative md:-ml-3 md:-mr-24 md:mx-0">
                                    <img
                                        className="absolute opacity-50 top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 mt-16 md:mt-0 pointer-events-none -z-10 max-w-none mix-blend-lighten"
                                        src={Illustration}
                                        alt="Hero illustration"
                                        aria-hidden="true"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* Contenu Page */}
            <section className="-mt-36 text-white" id="transfert" data-aos-id-3>
                <div className="relative max-w-8xl mx-auto">
                    {/* Bg */}
                    <div
                        className="absolute inset-0 md:rounded-tl-[100px] mb-24 md:mb-0  pointer-events-none -z-10"
                        aria-hidden="true"
                    />

                    <div className="max-w-6xl mx-auto px-4 sm:px-6">
                        <div className="pb-6 pt-12 md:pt-20">
                            <div className="mb-12 md:mb-20" data-aos="fade-up">
                                <div
                                    style={{ textAlign: "left" }}
                                    className="text-base"
                                >
                                    <p className="mb-4">
                                        {translate(
                                            "KycAml",
                                            "Kalyssi_Guinea_is_committed_to_respecting_the_",
                                        )}
                                    </p>
                                    <p className="mb-4 mt-10">
                                        <b>
                                            {translate(
                                                "KycAml",
                                                "Money_laundering",
                                            )}
                                        </b>{" "}
                                        :{" "}
                                        {translate(
                                            "KycAml",
                                            "The_fund_conversion_process_from_illegal_",
                                        )}
                                    </p>
                                    <p className="mb-4">
                                        {translate(
                                            "KycAml",
                                            "The_money_laundering_process_",
                                        )}{" "}
                                        :
                                    </p>
                                    <p className="mb-4 ml-8">
                                        –{" "}
                                        {translate(
                                            "KycAml",
                                            "Placement_at_this_stage_the_funds_",
                                        )}
                                    </p>
                                    <p className="mb-4 ml-8">
                                        –{" "}
                                        {translate(
                                            "KycAml",
                                            "Layers_the_funds_are_transferred_or_",
                                        )}
                                    </p>
                                    <p className="mb-4 ml-8">
                                        –{" "}
                                        {translate(
                                            "KycAml",
                                            "Integration_funds_are_submitted_",
                                        )}
                                    </p>
                                    <p className="mb-4">
                                        {translate(
                                            "KycAml",
                                            "Kalyssi_Guinea_his_adheres_to_principles_",
                                        )}
                                    </p>
                                    <p className="mb-4">
                                        {translate(
                                            "KycAml",
                                            "In_order_to_prevent_money_laundering_",
                                        )}
                                    </p>
                                    <p className="mb-4 mt-10">
                                        <b>
                                            {translate(
                                                "KycAml",
                                                "Company_procedures",
                                            )}
                                        </b>
                                    </p>
                                    <p className="mb-4">
                                        {translate(
                                            "KycAml",
                                            "Kalyssi_Guinea_SA_ensures_that_it_is_a_",
                                        )}{" "}
                                        :
                                    </p>
                                    <p className="mb-4 ml-8">
                                        –{" "}
                                        {translate(
                                            "KycAml",
                                            "Know_policy_and_diligence_of_your_customer",
                                        )}
                                    </p>
                                    <p className="mb-4 ml-8">
                                        –{" "}
                                        {translate(
                                            "KycAml",
                                            "Monitor_customer_activity",
                                        )}
                                    </p>
                                    <p className="mb-4 ml-8">
                                        –{" "}
                                        {translate(
                                            "KycAml",
                                            "Register_maintenance",
                                        )}
                                    </p>
                                    <p className="mb-4">
                                        {translate(
                                            "KycAml",
                                            "Customer_knowledge_due_to_Society_s_",
                                        )}
                                    </p>
                                    <p className="mb-4">
                                        {translate(
                                            "KycAml",
                                            "Individual_customers_during_the_",
                                        )}{" "}
                                        :
                                    </p>
                                    <p className="mb-4">
                                        {translate(
                                            "KycAml",
                                            "His_full_name_his_date_of_birth_",
                                        )}
                                    </p>
                                    <p className="mb-4">
                                        {translate(
                                            "KycAml",
                                            "The_following_documents_are_necessary_to_",
                                        )}{" "}
                                        :
                                    </p>
                                    <p className="mb-4 ml-8">
                                        –{" "}
                                        {translate(
                                            "KycAml",
                                            "Passport_in_process_of_validation_",
                                        )}
                                    </p>
                                    <p className="mb-4 ml-8">
                                        –{" "}
                                        {translate(
                                            "KycAml",
                                            "Driving_license_with_photo_or",
                                        )}
                                    </p>
                                    <p className="mb-4 ml-8">
                                        –{" "}
                                        {translate(
                                            "KycAml",
                                            "National_identity_card_",
                                        )}{" "}
                                        ;
                                    </p>
                                    <p className="mb-4 ml-8">
                                        –{" "}
                                        {translate(
                                            "KycAml",
                                            "Documents_proving_the_address_",
                                        )}
                                    </p>
                                    <p className="mb-4 mt-10">
                                        <b>
                                            {translate(
                                                "KycAml",
                                                "Client_companies",
                                            )}
                                        </b>
                                    </p>
                                    <p className="mb-4">
                                        {translate(
                                            "KycAml",
                                            "If_the_applicant_company_is_listed_",
                                        )}{" "}
                                        :
                                    </p>
                                    <p className="mb-4 ml-8">
                                        –{" "}
                                        {translate(
                                            "KycAml",
                                            "Certificate_of_constitution_or_",
                                        )}{" "}
                                        ;
                                    </p>
                                    <p className="mb-4 ml-8">
                                        –{" "}
                                        {translate(
                                            "KycAml",
                                            "Constitutive_act_and_statutes_and_",
                                        )}{" "}
                                        ;
                                    </p>
                                    <p className="mb-4 ml-8">
                                        –{" "}
                                        {translate(
                                            "KycAml",
                                            "Certificate_of_honorability_or_other_",
                                        )}{" "}
                                        ;
                                    </p>
                                    <p className="mb-4 ml-8">
                                        –{" "}
                                        {translate(
                                            "KycAml",
                                            "Resolution_of_the_Board_of_Directors_",
                                        )}
                                        ;
                                    </p>
                                    <p className="mb-4 ml-8">
                                        –{" "}
                                        {translate(
                                            "KycAml",
                                            "Copies_of_proxies_or_other_",
                                        )}{" "}
                                        ;
                                    </p>
                                    <p className="mb-4 ml-8">
                                        –{" "}
                                        {translate(
                                            "KycAml",
                                            "Proof_of_the_identity_of_administrators_",
                                        )}{" "}
                                        ;
                                    </p>
                                    <p className="mb-4 ml-8">
                                        –{" "}
                                        {translate(
                                            "KycAml",
                                            "The_proof_of_the_identity_of_",
                                        )}
                                    </p>
                                    <p className="mb-4 mt-10">
                                        <b>
                                            {translate(
                                                "KycAml",
                                                "Customer_activity_monitoring",
                                            )}
                                        </b>
                                    </p>
                                    <p className="mb-4">
                                        {translate(
                                            "KycAml",
                                            "In_addition_to_collecting_information_",
                                        )}
                                    </p>
                                    <p className="mb-4 mt-10">
                                        <b>{translate("KycAml", "Register")}</b>
                                    </p>
                                    <p className="mb-4">
                                        {translate(
                                            "KycAml",
                                            "All_data_relating_to_transactions_",
                                        )}
                                    </p>
                                    <p className="mb-4 mt-10">
                                        <b>
                                            {translate(
                                                "KycAml",
                                                "Taken_measures",
                                            )}
                                        </b>
                                    </p>
                                    <p className="mb-4">
                                        {translate(
                                            "KycAml",
                                            "In_the_event_of_an_attempted_execution_",
                                        )}
                                    </p>
                                    <p className="mb-4">
                                        {translate(
                                            "KycAml",
                                            "Kalyssi_Guinea_SA_reserves_the_right_",
                                        )}{" "}
                                        :{" "}
                                        <a
                                            href="mailto:contact@kalyssi.com"
                                            style={{ color: "#EEA913" }}
                                        >
                                            {" "}
                                            contact@kalyssi.com
                                        </a>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <Section03 />
            <Section04 />
            <Section05 />
            <Section06 />
            <Section07 />
            <Section08 />
            <Faqs /> */}
            <Cta />
        </>
    );
};

export default KycAml;
