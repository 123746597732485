import translator from "../../../Components/Extra/Translation/Translate";
import about1 from "../../images/about.jpg";

export default function Section2v1() {
    const { translate } = translator();
    return (
        <div>
            {/* <!-- ======== 1.2. hero section ======== --> */}
            <section className="flex justify-center items-center lg:!mx-20 mx-4 md:!my-20 ">
                <div className=" w-full max-w-screen-xl mx-auto ">
                    <div className="flex flex-col  items-stretch pt-20 md:flex-row  justify-center  gap-5 sm:gap-4 md:!gap-12 text-left">
                        <div
                            data-aos="fade-up"
                            className="flex flex-col justify-center gap-4 items-start md:items-start w-full md:w-1/2"
                        >
                            <h4 className="text-white bg-clip-text mb-3 font-bold md:!text-4xl text-3xl leading-tight tracking-tighter">
                                {translate("LANDING_ABOUT", "section1_title4")}
                            </h4>
                            <div className="text-slate-300 text-base font-light space-y-8 leading-normal ">
                                <p>
                                    {translate(
                                        "LANDING_ABOUT",
                                        "section1_title4_p1",
                                    )}{" "}
                                </p>
                                <p>
                                    {translate(
                                        "LANDING_ABOUT",
                                        "section1_title4_p2",
                                    )}
                                </p>
                                {/* <p>
                                    {translate(
                                        "LANDING_ABOUT",
                                        "section1_title4_p3",
                                    )}
                                </p> */}
                            </div>
                        </div>
                        <div
                            data-aos="fade-down"
                            className="relative flex justify-center items-center w-full md:w-1/2 md:bg-left"
                        >
                            <img
                                src={about1}
                                alt="hero_img2"
                                className="md:!rounded-[50px] rounded-2xl h-auto object-cover md:!min-h-[400px] w-full"
                            />
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}
