import { DatatableFilter } from "../../../Models/DatatableFilter";
import {
    ManualDepositTransaction,
    OV2WalletHistory,
} from "../../../Models/WalletHistory";
import axios from "../../AxiosInstance";
import UtilsService from "../../UtilsService";

const getAllDeposit = (): Promise<Array<OV2WalletHistory>> => {
    return axios
        .get("/deposit")
        .then((res) => {
            const data = res.data as OV2WalletHistory[];

            return data;
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const checkDeposit = (historyId: number): Promise<string> => {
    return axios
        .post("/deposit/check", { historyId: historyId })
        .then((res) => {
            return Promise.resolve(res.data.message);
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const checkReference = (ref: string, type: string) => {
    return axios.post("/deposit/check-ref", { ref: ref, type: type });
};

const getAllManualDeposit = (): Promise<Array<ManualDepositTransaction>> => {
    return axios
        .get("/manual-deposit")
        .then((res) => {
            const data = res.data as ManualDepositTransaction[];

            return data;
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const cancel = async (id: number): Promise<string> => {
    return axios
        .put(`/manual-deposit/cancel/${id}`)
        .then(({ data }) => {
            return data.message;
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const complete = async (id: number, deduct: string): Promise<string> => {
    return axios
        .put(`/manual-deposit/complete/${id}`, { deduct })
        .then(({ data }) => {
            return data.message;
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const getAllDepositPaginate = async (filter: DatatableFilter): Promise<any> => {
    return axios
        .get(`/manual-deposit/get-all-deposit`, {
            params: filter,
        })
        .then(({ data }) => {
            const res = data.data as ManualDepositTransaction[];

            return {
                data: res,
                count: data.count,
                currentPage: data.currentPage,
                nextPage: data.nextPage,
                prevPage: data.prevPage,
                lastPage: data.lastPage,
            };
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const getAllDepositPaginateFA = async (
    filter: DatatableFilter,
): Promise<any> => {
    return axios
        .get(`/manual-deposit/get-all-deposit-fa`, {
            params: filter,
        })
        .then(({ data }) => {
            const res = data.data as ManualDepositTransaction[];

            return {
                data: res,
                count: data.count,
                currentPage: data.currentPage,
                nextPage: data.nextPage,
                prevPage: data.prevPage,
                lastPage: data.lastPage,
            };
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const pending = async (id: number): Promise<string> => {
    return axios
        .put(`/manual-deposit/pending/${id}`)
        .then(({ data }) => {
            return data.message;
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const DepositService = {
    getAllDeposit,
    checkDeposit,
    checkReference,
    getAllManualDeposit,
    cancel,
    complete,
    getAllDepositPaginate,
    getAllDepositPaginateFA,
    pending,
};

export default DepositService;
