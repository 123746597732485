import { NavLink } from "react-router-dom";
import translator from "../../Components/Extra/Translation/Translate";
import useAuthentication from "../../Services/Authentication/useAuthentication";
import RoutesNames from "../../Services/RoutesNames";
import Hero from "./fintech/hero";
import Section01 from "./fintech/section-01";
import Section02 from "./fintech/section-02";
import Section03 from "./fintech/section-03";
import Section04 from "./fintech/section-04";
import Section05 from "./fintech/section-05";
import { Section06 } from "./fintech/section-06";
import Section07 from "./fintech/section-07";
import Section08 from "./fintech/section-08";
import Faqs from "./fintech/faqs";
import Cta from "./fintech/cta";
import Caroussel from "../../Components/Dashboard/Caroussel";

const Home = () => {
    const { translate } = translator();
    const { isAuthenticated } = useAuthentication();
    return (
        <>
            <Hero />
            {/* <Section01 /> */}
            <Section02 />
            <Section03 />
            <Section04 />
            <Section05 />
            <Section06 />
            <Section07 />
            <Section08 />
            <section className="px-0 relative">
                <div className="xs:py-20 md:py-12 md:pb-5 xs:pb-5">
                    <Caroussel />
                </div>
            </section>
            <Faqs />
            <Cta />
        </>
    );
};

export default Home;
