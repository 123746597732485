import translator from "../../../Components/Extra/Translation/Translate";
import KpBreadCrumb from "../../Components/KpBreadCrumb";
import BorrowingForm from "./BorrowingForm";
import BorrowingTable from "./BorrowingTable";

const Borrowing = () => {
    const { translate } = translator();
    return (
        <main className="grow mb-20 md:mb-5">
            <div className="px-6 sm:px-6 xl:!px-[80px] w-full  mx-auto">
                <KpBreadCrumb
                    title={translate("ASSETS_ACTION", "Borrowing")}
                ></KpBreadCrumb>

                <div className="grid grid-cols-12 gap-6 mt-7">
                    <BorrowingForm />
                </div>

                <div className="intro-y box">
                    <div className="grid grid-cols-12 gap-6 mt-7 p-3">
                        <BorrowingTable />
                    </div>
                </div>
            </div>
        </main>
    );
};

export default Borrowing;
