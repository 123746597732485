import { useEffect } from "react";
import { useNavigate } from "react-router";
import translator from "../../../Components/Extra/Translation/Translate";
import DepositService from "../../../Services/Wallets/Transaction/DepositService";
import Head from "../../../Layouts/Account/Head";
import VisaTransactionService from "../../../Services/Wallets/Transaction/VisaTransactionService";
import RoutesNames from "../../../Services/RoutesNames";

const PaymentError = () => {
    const navigate = useNavigate();
    const { translate } = translator();

    const query = new URLSearchParams(window.location.search);

    const read = query.get("read");
    const ref = query.get("ref");
    const refk = query.get("refk");
    const transaction_id = query.get("transaction_id");

    useEffect(() => {
        if (ref) {
            VisaTransactionService.checkRef(ref).catch((err) => {});
        }
    }, [ref]);
    useEffect(() => {
        if (transaction_id) {
            VisaTransactionService.checkRefInstant(transaction_id).catch(
                (err) => {},
            );
        }
    }, [transaction_id]);

    useEffect(() => {
        if (refk) {
            DepositService.checkReference(refk, "k").catch((err) => {});
        }
    }, [refk]);

    return (
        <div>
            <Head />
            <div className="container">
                <div className="error-page flex h-screen flex-col items-center justify-center text-center lg:flex-row lg:text-left">
                    <div className="mt-10 text-white lg:mt-0">
                        <div className="intro-x text-3xl font-medium">
                            {translate("Payment", "Error")}
                        </div>
                        <div className="intro-x mt-5 text-xl font-medium lg:text-3xl"></div>
                        <div className="intro-x mt-3 text-lg">
                            {translate("Payment", "Error_message")}
                        </div>
                        {!read && (
                            <button
                                onClick={() => navigate(RoutesNames.Assets)}
                                className="intro-x btn mt-10 border-white py-3 px-4 text-white dark:text-slate-200"
                            >
                                {translate("App", "Back_to_account")}
                            </button>
                        )}
                    </div>
                </div>
            </div>{" "}
        </div>
    );
};

export default PaymentError;
