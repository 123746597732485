import { AirtimeData, WithdrawData } from "../../../Models/Gateway";
import { ConfigurationData } from "../../../Models/Mobile/configuration.data";

import axios from "../../AxiosInstance";

const processWithdrawal = async (payload: WithdrawData): Promise<any> => {
    try {
        const { data } = await axios.post("/withdrawal", payload);
        return data;
    } catch (err: any) {
        const error =
            err.response && err.response.data && err.response.data.message
                ? err.response.data.message
                : err.message;

        return Promise.reject(error);
    }
};

const processAirtime = async (payload: AirtimeData): Promise<any> => {
    try {
        const { data } = await axios.post("/withdrawal/airtime", payload);
        return data.message;
    } catch (err: any) {
        const error =
            err.response && err.response.data && err.response.data.message
                ? err.response.data.message
                : err.message;

        return Promise.reject(error);
    }
};

const getAirtimeConfiguration = async (): Promise<ConfigurationData> => {
    try {
        const { data } = await axios.get("/withdrawal/airtime");
        return data as ConfigurationData;
    } catch (err: any) {
        const error =
            err.response && err.response.data && err.response.data.message
                ? err.response.data.message
                : err.message;

        return Promise.reject(error);
    }
};

const WithdrawalService = {
    processWithdrawal,
    getAirtimeConfiguration,
    processAirtime,
};

export default WithdrawalService;
