import { NavLink } from "react-router-dom";
import hero from "../../images/hero.png";
import herowatch from "../../images/new/hero_watch.png";
import herostar from "../../images/new/hero_star.png";
import bghero from "../../images/new/hero_bg.png";
import translator from "../../../Components/Extra/Translation/Translate";
import RoutesNames from "../../../Services/RoutesNames";
import useAuthentication from "../../../Services/Authentication/useAuthentication";

export default function Hero() {
    const { isAuthenticated } = useAuthentication();
    const { translate } = translator();
    return (
        <div>
            {/* <!-- ======== 1.2. hero section ======== --> */}
            <section className="flex justify-center items-center z-10  mx-4 lg:!mx-20 md:!mt-0 mt-20 min-h-screen">
                <div className="max-w-screen-xl mx-auto w-full">
                    <div className="flex flex-col md:flex-row justify-center items-center gap-5 sm:gap-4 md:gap-0 text-center md:text-left">
                        <div className="flex flex-col justify-center items-center md:!items-start w-full md:w-1/2">
                            <h1
                                className="h1 text-white text-center mb-6 md:!mx-0 mx-8 md:!text-left leading-normal"
                                data-aos="fade-up"
                                data-aos-delay="100"
                                dangerouslySetInnerHTML={{
                                    __html: translate("LANDING", "TITLE", {
                                        kalypay: `<span class="inline-flex items-center justify-center"><svg class="absolute -z-10" width="246" height="76" xmlns="http://www.w3.org/2000/svg"><path d="M55.224 10.087c-13.986 3.38-25.552 7.614-33.97 12.438-4.171 2.412-7.508 4.953-9.953 7.58-2.395 2.628-3.807 5.332-4.21 8.058-.266 1.99.075 3.985 1.02 5.955.922 1.973 2.37 3.919 4.327 5.819 7.028 6.749 20.696 12.657 39.108 16.904 18.475 4.28 40.791 6.693 63.89 6.91 20.527.186 40.83-1.353 58.737-4.452 11.396-1.964 21.73-4.463 30.631-7.407 8.905-2.941 16.508-6.232 22.611-9.788 3.663-2.222 4.978-1.73 3.59.491-1.13 1.509-2.83 2.971-5.067 4.357-3.235 1.976-7.254 3.82-11.962 5.49-4.686 1.628-9.745 3.15-15.139 4.553a273.749 273.749 0 0 1-17.309 3.752 339.58 339.58 0 0 1-19.111 2.822c-3.367.35-6.676.738-10.087 1.025-3.412.286-6.868.546-10.339.75-13.955.815-28.266.87-42.283.165-13.996-.735-27.452-2.236-39.729-4.435-14.867-2.672-27.78-6.263-37.927-10.548-10.21-4.343-17.115-9.34-20.204-14.618C.15 43.028-.38 40.095.268 37.176c.295-1.462.868-2.917 1.713-4.357.883-1.432 2.027-2.847 3.427-4.239 2.819-2.783 6.622-5.463 11.342-7.99 4.626-2.528 10.101-4.9 16.335-7.074C48.423 8.116 68.15 4.072 90.24 1.802A371.99 371.99 0 0 1 115.924.135c54.806-1.437 105.87 8.691 124.34 24.662 1.911 1.728 3.392 3.498 4.431 5.295 1.352 2.388 1.655 4.82.901 7.234-.223 1.092-1.189 2.158-2.836 3.127-.493.309-1.076.603-1.742.88-.916.272-1.27-.27-1.344-1.462-.074-1.193 0-3.05-.429-5.409-.722-3.525-3.213-6.994-7.384-10.284-4.32-3.334-10.299-6.44-17.723-9.206-7.488-2.813-16.364-5.247-26.304-7.211-9.952-1.996-20.87-3.493-32.344-4.434-17.147-1.405-35.144-1.505-52.444-.292-8.673.62-17.094 1.537-25.108 2.732-7.997 1.207-15.556 2.672-22.552 4.37l-.162-.05Z" fill="#EEA913" fill-rule="nonzero"></path></svg>KALYPAY</span><br>`,
                                    }),
                                }}
                            />
                            <p
                                className="text-lg text-blue-100 mb-8 md:!mx-0 mx-10 md:!text-left text-center"
                                data-aos="fade-up"
                                data-aos-delay="200"
                                dangerouslySetInnerHTML={{
                                    __html: translate(
                                        "LANDING",
                                        "DESCRIPTION",
                                        {
                                            break: `<br className="hidden md:block" />`,
                                        },
                                    ),
                                }}
                            />

                            {/* Buttons */}
                            <div className="flex flex-row items-center justify-star space-x-4">
                                <div>
                                    {isAuthenticated ? (
                                        <NavLink
                                            className="btn-sm w-full text-base inline-flex items-center text-slate-100 bg-slate-800 hover:bg-slate-900 group shadow-sm"
                                            to={RoutesNames.Register}
                                        >
                                            {translate("GENERAL", "MY_ACCOUNT")}
                                            <span className="tracking-normal text-[#EEA913] group-hover:translate-x-0.5 transition-transform duration-150 ease-in-out ml-2">
                                                <svg
                                                    className="fill-current"
                                                    width="12"
                                                    height="10"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path d="M1 6.002h7.586L6.293 8.295a1 1 0 1 0 1.414 1.414l4-4a1 1 0 0 0 0-1.416l-4-4a1 1 0 0 0-1.414 1.416l2.293 2.293H1a1 1 0 1 0 0 2Z" />
                                                </svg>
                                            </span>
                                        </NavLink>
                                    ) : (
                                        <NavLink
                                            className="btn-sm w-full inline-flex items-center text-base text-slate-100 bg-slate-800 hover:bg-slate-900 group shadow-sm"
                                            to={RoutesNames.Register}
                                        >
                                            {translate("LANDING", "SIGNUP")}
                                            <span className="tracking-normal text-[#EEA913] group-hover:translate-x-0.5 transition-transform duration-150 ease-in-out ml-2">
                                                <svg
                                                    className="fill-current"
                                                    width="12"
                                                    height="10"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path d="M1 6.002h7.586L6.293 8.295a1 1 0 1 0 1.414 1.414l4-4a1 1 0 0 0 0-1.416l-4-4a1 1 0 0 0-1.414 1.416l2.293 2.293H1a1 1 0 1 0 0 2Z" />
                                                </svg>
                                            </span>
                                        </NavLink>
                                    )}
                                </div>
                                <div>
                                    <NavLink
                                        className="btn-sm w-full !inline-flex items-center text-base text-black bg-gradient-to-tr from-[#ffd67c] hover:bg-[#EEA913] shadow-sm relative before:absolute before:inset-0 before:bg-[#ffd67c] before:bg-opacity-60 before:-z-10 "
                                        to="/support/to-start/getting-started-with-kalypay"
                                    >
                                        {translate("LANDING", "READ_FAQ")}
                                    </NavLink>
                                </div>
                            </div>
                        </div>

                        <div
                            data-aos="fade-down"
                            className="relative flex justify-center items-center w-full md:w-1/2 sm:mt-5 bg-cover bg-center md:bg-left py-[78px]"
                            style={{ backgroundImage: `url(${bghero})` }}
                        >
                            <div className="flex justify-center items-center mx-5">
                                <img
                                    src={herostar}
                                    alt="hero_icon"
                                    className="absolute md:-left-8 left-0 mb-[450px]"
                                />
                                <img
                                    src={hero}
                                    alt="hero_img1"
                                    className="px-2 animate-move-up-down opacity-98"
                                />
                            </div>
                            <img
                                src={herowatch}
                                alt="hero_img2"
                                className="absolute bottom-20 left-7"
                            />
                        </div>
                    </div>
                </div>
            </section>
        </div>
        // <section className="relative z-10 min-h-screen  flex items-center">
        //     {/* Bg */}
        //     <div className="max-w-screen-xl mx-auto px-4 sm:px-6 w-full">
        //         {/* Hero content */}
        //         <div className="relative grid md:!grid-cols-2 grid-cols-1 max-w-xl  mx-auto md:max-w-none text-center md:text-left py-20">
        //             {/* Content */}
        //             <div className="md:w-[650px]">
        //                 {/* Copy */}
        //                 <h1
        //                     className="h1 text-white text-left mb-6"
        //                     data-aos="fade-up"
        //                     data-aos-delay="100"
        //                     dangerouslySetInnerHTML={{
        //                         __html: translate("LANDING", "TITLE", {
        //                             kalypay: `<span class="relative inline-flex items-center justify-center"><svg class="absolute -z-10" width="246" height="76" xmlns="http://www.w3.org/2000/svg"><path d="M55.224 10.087c-13.986 3.38-25.552 7.614-33.97 12.438-4.171 2.412-7.508 4.953-9.953 7.58-2.395 2.628-3.807 5.332-4.21 8.058-.266 1.99.075 3.985 1.02 5.955.922 1.973 2.37 3.919 4.327 5.819 7.028 6.749 20.696 12.657 39.108 16.904 18.475 4.28 40.791 6.693 63.89 6.91 20.527.186 40.83-1.353 58.737-4.452 11.396-1.964 21.73-4.463 30.631-7.407 8.905-2.941 16.508-6.232 22.611-9.788 3.663-2.222 4.978-1.73 3.59.491-1.13 1.509-2.83 2.971-5.067 4.357-3.235 1.976-7.254 3.82-11.962 5.49-4.686 1.628-9.745 3.15-15.139 4.553a273.749 273.749 0 0 1-17.309 3.752 339.58 339.58 0 0 1-19.111 2.822c-3.367.35-6.676.738-10.087 1.025-3.412.286-6.868.546-10.339.75-13.955.815-28.266.87-42.283.165-13.996-.735-27.452-2.236-39.729-4.435-14.867-2.672-27.78-6.263-37.927-10.548-10.21-4.343-17.115-9.34-20.204-14.618C.15 43.028-.38 40.095.268 37.176c.295-1.462.868-2.917 1.713-4.357.883-1.432 2.027-2.847 3.427-4.239 2.819-2.783 6.622-5.463 11.342-7.99 4.626-2.528 10.101-4.9 16.335-7.074C48.423 8.116 68.15 4.072 90.24 1.802A371.99 371.99 0 0 1 115.924.135c54.806-1.437 105.87 8.691 124.34 24.662 1.911 1.728 3.392 3.498 4.431 5.295 1.352 2.388 1.655 4.82.901 7.234-.223 1.092-1.189 2.158-2.836 3.127-.493.309-1.076.603-1.742.88-.916.272-1.27-.27-1.344-1.462-.074-1.193 0-3.05-.429-5.409-.722-3.525-3.213-6.994-7.384-10.284-4.32-3.334-10.299-6.44-17.723-9.206-7.488-2.813-16.364-5.247-26.304-7.211-9.952-1.996-20.87-3.493-32.344-4.434-17.147-1.405-35.144-1.505-52.444-.292-8.673.62-17.094 1.537-25.108 2.732-7.997 1.207-15.556 2.672-22.552 4.37l-.162-.05Z" fill="#EEA913" fill-rule="nonzero"></path></svg>KALYPAY</span><br>`,
        //                         }),
        //                     }}
        //                 />
        //                 <p
        //                     className="text-lg text-left text-blue-200 mb-8"
        //                     data-aos="fade-up"
        //                     data-aos-delay="200"
        //                     dangerouslySetInnerHTML={{
        //                         __html: translate("LANDING", "DESCRIPTION", {
        //                             break: `<br className="hidden md:block" />`,
        //                         }),
        //                     }}
        //                 />

        //                 {/* Buttons */}
        //                 <div className="flex flex-row items-center justify-star space-x-4">
        //                     <div>
        //                         {isAuthenticated ? (
        //                             <NavLink
        //                                 className="btn-sm w-full inline-flex items-center text-slate-100 bg-slate-800 hover:bg-slate-900 group shadow-sm"
        //                                 to={RoutesNames.Register}
        //                             >
        //                                 {translate("GENERAL", "MY_ACCOUNT")}
        //                                 <span className="tracking-normal text-[#EEA913] group-hover:translate-x-0.5 transition-transform duration-150 ease-in-out ml-2">
        //                                     <svg
        //                                         className="fill-current"
        //                                         width="12"
        //                                         height="10"
        //                                         xmlns="http://www.w3.org/2000/svg"
        //                                     >
        //                                         <path d="M1 6.002h7.586L6.293 8.295a1 1 0 1 0 1.414 1.414l4-4a1 1 0 0 0 0-1.416l-4-4a1 1 0 0 0-1.414 1.416l2.293 2.293H1a1 1 0 1 0 0 2Z" />
        //                                     </svg>
        //                                 </span>
        //                             </NavLink>
        //                         ) : (
        //                             <NavLink
        //                                 className="btn-sm w-full inline-flex items-center text-slate-100 bg-slate-800 hover:bg-slate-900 group shadow-sm"
        //                                 to={RoutesNames.Register}
        //                             >
        //                                 {translate("LANDING", "SIGNUP")}
        //                                 <span className="tracking-normal text-[#EEA913] group-hover:translate-x-0.5 transition-transform duration-150 ease-in-out ml-2">
        //                                     <svg
        //                                         className="fill-current"
        //                                         width="12"
        //                                         height="10"
        //                                         xmlns="http://www.w3.org/2000/svg"
        //                                     >
        //                                         <path d="M1 6.002h7.586L6.293 8.295a1 1 0 1 0 1.414 1.414l4-4a1 1 0 0 0 0-1.416l-4-4a1 1 0 0 0-1.414 1.416l2.293 2.293H1a1 1 0 1 0 0 2Z" />
        //                                     </svg>
        //                                 </span>
        //                             </NavLink>
        //                         )}
        //                     </div>
        //                     <div>
        //                         <NavLink
        //                             className="btn-sm w-full !inline-flex items-center text-black bg-gradient-to-tr from-[#ffd67c] hover:bg-[#EEA913] shadow-sm relative before:absolute before:inset-0 before:bg-[#ffd67c] before:bg-opacity-60 before:-z-10 "
        //                             to="/support/to-start/getting-started-with-kalypay"
        //                         >
        //                             {translate("LANDING", "READ_FAQ")}
        //                         </NavLink>
        //                     </div>
        //                 </div>
        //             </div>

        //             {/* Image */}
        //             <div className="max-w-sm mx-auto md:max-w-none md:absolute md:right-0 md:top-1/2 md:-translate-y-1/2 mt-12 md:!mt-0">
        //                 <div className="relative  md:mx-0">
        //                     <img
        //                         className="absolute  opacity-30 md:!w-2/3 w-full object-cover md:opacity-100 top-[200px] left-1/2 -translate-y-1/2 -translate-x-1/2 mt-16 md:mt-0 pointer-events-none -z-10 max-w-none mix-blend-lighten"
        //                         src={Illustration}
        //                         alt="Hero illustration"
        //                         aria-hidden="true"
        //                     />
        //                     <img
        //                         src={HeroImage}
        //                         className="md:max-w-none mt-5 skew-x-[-2deg] md:-rotate-1 w-full md:!w-3/5 object-cover float-end"
        //                         alt="Credit card"
        //                         data-aos="fade-up"
        //                     />
        //                 </div>
        //             </div>
        //         </div>
        //     </div>
        // </section>
    );
}
