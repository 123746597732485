import { createContext, useContext, useEffect, useState } from "react";
import {
    AgentAdvantage,
    OrderCardRequestType,
    SettingKey,
} from "../../Models/AgentAdvantage";
import FidelityModel, {
    AdvantageData,
    LoyaltyReduction,
} from "../../Models/Fidelity";
import useAuthentication from "../../Services/Authentication/useAuthentication";
import FidelityService from "../../Services/FidelityService/FidelityService";

interface ContextProps {
    getReduction: (type: LoyaltyReduction | undefined) => number;
    currentLoyalty: FidelityModel | undefined;
}

const FidelityContext = createContext<ContextProps>({
    getReduction: () => 0,
    currentLoyalty: undefined,
});

export default function FidelityProvider({
    children,
}: {
    children: React.ReactNode;
}) {
    const { isAgent, isPos, isCommercial } = useAuthentication();
    const settingKey = isPos
        ? SettingKey.AgentAdvantage
        : isCommercial
        ? SettingKey.RegionalManager
        : SettingKey.CountryManager;
    const [currentLoyalty, setCurrentLoyalty] = useState<
        FidelityModel | undefined
    >();
    const [advantageData, setAdvantageData] = useState<AgentAdvantage>({
        free_cb: OrderCardRequestType.RED,
        fiat_feed: "",
        min_klc: 0,
        red_fiat_load_fee: 0,
        red_crypto_purchase_fee: 0,
        red_crypto_sell_fee: 0,
        red_active_swap_fee: 0,
        red_external_transfer_fiat_fee: 0,
        red_merchand_payment_fee: 0,
        cashback_klc_payment_card: 0,
        card_atm_daily_free_withdraw: 0,
        card_atm_monthly_free_withdraw: 0,
        monthly_free_payment_by_card: 0,
        roi_boost_earning: 0,
        fiat_cm_reload_com: 0,
        fiat_rm_reload_com: 0,
        fiat_agent_reload_com: 0,
        fiat_cm_withdraw_com: 0,
        fiat_rm_withdraw_com: 0,
        fiat_agent_withdraw_com: 0,
        reload_direct_aff_fiat_com: 0,
        direct_active_aff_swap_com: 0,
        external_fiat_transfer_direct_aff_com: 0,
        red_crypto_interest_rate: 0,
        obt_iban_euro: 0,
        obt_iban_pound: 0,
        obt_iban_dollar: 0,
    });

    const getLoyalty = async () => {
        if (isAgent) {
            try {
                const res = await FidelityService.getAgentAdvantage();
                if (res) {
                    setAdvantageData(res);
                }
            } catch (err: any) {}
        } else {
            try {
                const res = await FidelityService.getLoyaltyData();
                setCurrentLoyalty(res.current);
            } catch (err: any) {}
        }
    };

    const formatAd = (item: AgentAdvantage) => {
        const res: AdvantageData = {
            [LoyaltyReduction.DEPOSIT_FIAT_FEE_REDUCTION]:
                item.red_fiat_load_fee,
            [LoyaltyReduction.BUY_CRYPTO_FEE_REDUCTION]:
                item.red_crypto_purchase_fee,
            [LoyaltyReduction.SELL_CRYPTO_FEE_REDUCTION]:
                item.red_crypto_sell_fee,
            [LoyaltyReduction.EXCHANGE_FEE_REDUCTION]: item.red_active_swap_fee,
            [LoyaltyReduction.PAIEMENT_MARCHAND_FEE_REDUCTION]:
                item.red_merchand_payment_fee,
            [LoyaltyReduction.CB_CASHBACK_KLC]: item.cashback_klc_payment_card,
            [LoyaltyReduction.BOOST_EARNING_ROI]: item.roi_boost_earning,
            [LoyaltyReduction.AFFILIATION_COMMISSION_DEPOSIT_FIAT]:
                item.reload_direct_aff_fiat_com,
            [LoyaltyReduction.AFFILIATION_COMMISSION_EXCHANGE]:
                item.direct_active_aff_swap_com,
            [LoyaltyReduction.AFFILIATION_COMMISSION_EXTERNAL_TRANSFERT_FIAT]:
                item.external_fiat_transfer_direct_aff_com,
            [LoyaltyReduction.AFFILIATION_COMMISSION_EARNING]: 0,
            [LoyaltyReduction.LOAN_INTEREST_REDUCTION]:
                item.red_crypto_interest_rate,
            [LoyaltyReduction.EURO_IBAN]: item.obt_iban_euro,
            [LoyaltyReduction.STERLING_IBAN]: item.obt_iban_pound,
            [LoyaltyReduction.DOLLAR_IBAN]: item.obt_iban_dollar,
            [LoyaltyReduction.EXTERNAL_TRANSFERT_FIAT_FEE_REDUCTION]:
                item.red_external_transfer_fiat_fee,
        };

        return res;
    };

    const getReduction = (type: LoyaltyReduction | undefined): number => {
        if (!type) {
            return 0;
        }

        if (isAgent) {
            const data = formatAd(advantageData);
            return data[type] || 0;
        }

        if (!isAgent && currentLoyalty) {
            const configuration = JSON.parse(currentLoyalty.configuration);
            const config_list = configuration.config_list;
            const find_configuration = config_list.find(
                (advantage: any) => advantage.type === type,
            );

            if (find_configuration && find_configuration.status) {
                return Number(find_configuration.percentage) || 0;
            }
        }

        return 0;
    };

    useEffect(() => {
        getLoyalty();
    }, []);

    return (
        <FidelityContext.Provider
            value={{
                getReduction,
                currentLoyalty,
            }}
        >
            {children}
        </FidelityContext.Provider>
    );
}

export const useFidelity = () => useContext(FidelityContext);
