import { countryList } from "../Models/CountryList";
import { EanringType } from "../Models/Stacking";
import User, { PublicUserDta } from "../Models/User";
import { UserCryptoWallet } from "../Models/UserWallet";
/**
 * get the url of  the photo stored in server
 *
 * @param url
 * @returns
 */
const getPhotoUrl = (url: string): string => {
    if (!url) return "";
    if (url.includes("http://") || url.includes("https://")) return url;
    return process.env.REACT_APP_API_URL + "/" + url;
};

const creatUrl = (url: string) => {
    return process.env.REACT_APP_REACT_APP_PUBLIC_URL + "/" + url;
};

const getFrontLink = (url: string) => {
    return process.env.REACT_APP_BASE_NAME == "/" ||
        !process.env.REACT_APP_BASE_NAME
        ? process.env.REACT_APP_REACT_APP_PUBLIC_URL + url
        : process.env.REACT_APP_REACT_APP_PUBLIC_URL +
              process.env.REACT_APP_BASE_NAME +
              url;
};

const getAxiosErrorMessage = (err: any, code = false): string => {
    const error =
        err.response && err.response.data && err.response.data.message
            ? code
                ? err.response.data.code
                : err.response.data.message
            : err.message;

    return error;
};

const getProfilPicsUrl = (user: User): string => {
    if (user.photo) {
        return getPhotoUrl(user.photo);
    } else {
        return process.env.REACT_APP_PUBLIC_URL + "/dist/image/profile-14.jpg";
    }
};

const getProfilPicsUrlV2 = (user?: PublicUserDta): string => {
    if (user && user.photo) {
        return getPhotoUrl(user.photo);
    } else {
        return process.env.REACT_APP_PUBLIC_URL + "/dist/image/profile-14.jpg";
    }
};

const getPulicImage = (path: string): string => {
    return process.env.REACT_APP_PUBLIC_URL + path;
};

const getPulicImageV2 = (path: string): string => {
    return (
        process.env.REACT_APP_PUBLIC_URL +
        (process.env.REACT_APP_BASE_NAME &&
        process.env.REACT_APP_BASE_NAME !== "/"
            ? process.env.REACT_APP_BASE_NAME
            : "") +
        path
    );
};

export const getBeautifulFormatedDateWithTime = (date: Date) => {
    const d = new Date(date);
    const ye = new Intl.DateTimeFormat("en", { year: "numeric" }).format(d);
    const mo = new Intl.DateTimeFormat("en", { month: "short" }).format(d);
    const da = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(d);
    const hour = new Intl.DateTimeFormat("en", {
        hour: "numeric",
        hourCycle: "h23",
    }).format(d);
    let minute = new Intl.DateTimeFormat("en", { minute: "numeric" }).format(d);
    minute = parseInt(minute) < 10 ? "0" + minute : minute;

    return `${da} ${mo} ${ye} ${hour}:${minute}`;
};

export const getDateHourAndMinutes = (date: Date) => {
    const d = new Date(date);

    const hour = new Intl.DateTimeFormat("en", {
        hour: "numeric",
        hourCycle: "h23",
    }).format(d);
    let minute = new Intl.DateTimeFormat("en", { minute: "numeric" }).format(d);
    minute = parseInt(minute) < 10 ? "0" + minute : minute;

    return `${hour}:${minute}`;
};

export const getDateFormat_D_M_YY = (date: Date) => {
    const d = new Date(date);
    const ye = new Intl.DateTimeFormat("en", { year: "numeric" }).format(d);
    const mo = new Intl.DateTimeFormat("en", { month: "2-digit" }).format(d);
    const da = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(d);

    return `${da}/${mo}/${ye}`;
};
export const getBeautifulFormatedDate = (date: Date) => {
    const d = new Date(date);
    const ye = new Intl.DateTimeFormat("en", { year: "numeric" }).format(d);
    const mo = new Intl.DateTimeFormat("en", { month: "short" }).format(d);
    const da = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(d);

    return `${da} ${mo} ${ye}`;
};

export const getBeautifulFormatedDateFr = (date: Date) => {
    const d = new Date(date);
    const ye = new Intl.DateTimeFormat("en", { year: "2-digit" }).format(d);
    const mo = new Intl.DateTimeFormat("en", { month: "2-digit" }).format(d);
    const da = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(d);

    return `${da}/${mo}/${ye}`;
};

export const getBeautifulFormatedDateFr2 = (date: Date) => {
    const d = new Date(date);
    const day = d.getDate().toString().padStart(2, "0");
    const month = (d.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-based
    const year = d.getFullYear().toString();
    return `${day}-${month}-${year}`;
};

export const isFunction = (name: string) => {
    return name && {}.toString.call(name) === "[object Function]";
};

export const getPercentValue = (value: number, total: number): number => {
    if (isNaN(value) || isNaN(total)) return 0;
    const res = value > 0 ? (value * 100) / total : 0;
    return formatDecimal(res, 2);
};

export const formatDecimal = (value: number, decimal: number): number => {
    if (isNaN(value)) return 0;
    return Number(value.toFixed(decimal));
};
export const formatAmount = (value: number, decimal = 8): string => {
    if (isNaN(value)) return "0";
    return value.toLocaleString("fr", { maximumFractionDigits: decimal });
};

export const formatAmountV2 = (value: number, decimal = 8): string => {
    if (isNaN(value)) return "0";
    return value.toLocaleString("fr", {
        maximumFractionDigits: decimal,
        minimumFractionDigits: decimal,
    });
};

export const formatValue = (value: number, type?: string): string => {
    const decimal = type === "fiat" ? 2 : 6;
    return formatAmountV2(value, decimal);
};

export const revertFormatAmount = (value: string): number => {
    return Number(value.replace(/\s/g, "").replace(",", "."));
};

export const getExtension = (filename: string) => {
    const parts = filename.split(".");

    return parts[parts.length - 1];
};
const capitalizeChar = (text: string, separator: string): string => {
    const arr = text.split(separator);

    for (let i = 0; i < arr.length; i++) {
        arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
    }

    const str2 = arr.join(" ");

    return str2;
};

const getAbsoluteValue = (num: number): number => {
    return Math.abs(num);
};

const capitalizeFirstLetter = (str: string): string => {
    if (str.length === 0) {
        return str;
    }

    const firstChar = str.charAt(0).toUpperCase();
    const restOfString = str.slice(1);

    return firstChar + restOfString;
};

const getFee = (amount: number, percent_fee: number): number => {
    if (percent_fee <= 0) return 0;
    return (amount * percent_fee) / 100;
};

const convertAmount = (amount: number, price: number): number => {
    if (price <= 0) return 0;
    return amount / price;
};

const convertAmountSwap = (
    amount: number,
    debited: UserCryptoWallet,
    credited: UserCryptoWallet,
): number => {
    if (amount <= 0) return 0;

    const usd_amount = amount * debited.current_price;

    const test = usd_amount / credited.current_price;

    return test;
};

const convertAmountSwapV2 = (
    amount: number,
    from_price: number,
    to_price: number,
): number => {
    if (amount <= 0) return 0;

    const usd_amount = amount * from_price;

    const test = usd_amount / to_price;

    return test;
};

const getCountryName = (countryCode: string): string => {
    if (!countryCode) return "";
    const findCountry = countryList.find(
        (cnt) => cnt.value.toUpperCase() === countryCode.toUpperCase(),
    );

    return findCountry?.label || countryCode;
};

const roundValue = (value: number | undefined, decimal = 2): number => {
    if (Number(value) <= 0 || value === undefined) return 0;
    return parseFloat(value.toFixed(decimal));
};

const convertDevise = (
    fromUsdPrice: number,
    toUsdPrice: number,
    amount: number,
    decimal = 6,
): number => {
    return roundValue((fromUsdPrice * amount) / toUsdPrice, decimal);
};

const getFeeReduction = (fee: number, percent_reduction: number): number => {
    if (percent_reduction <= 0) return 0;
    return (fee * percent_reduction) / 100;
};

const getRealAssetName = (asset_name: string) => {
    return asset_name.replace("_V", "").replace("_T", "");
};

const getStakingType = (asset_name: string): EanringType => {
    if (asset_name.includes("_V")) return EanringType.arbitrage;
    if (asset_name.includes("_T")) return EanringType.trading;

    return EanringType.basic;
};

const UtilsService = {
    getStakingType,
    getRealAssetName,
    getAxiosErrorMessage,
    getPhotoUrl,
    getProfilPicsUrl,
    getBeautifulFormatedDateWithTime,
    getPulicImage,
    getPulicImageV2,
    isFunction,
    getBeautifulFormatedDate,
    getPercentValue,
    formatDecimal,
    getProfilPicsUrlV2,
    formatAmount,
    creatUrl,
    getFrontLink,
    getExtension,
    capitalizeChar,
    getAbsoluteValue,
    capitalizeFirstLetter,
    getFee,
    convertAmount,
    formatAmountV2,
    convertAmountSwap,
    getBeautifulFormatedDateFr,
    formatValue,
    getCountryName,
    getDateFormat_D_M_YY,
    getBeautifulFormatedDateFr2,
    getDateHourAndMinutes,
    convertDevise,
    getFeeReduction,
    convertAmountSwapV2,
};
export default UtilsService;
