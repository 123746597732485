import { ModalProvider } from "../../../../../Components/Extra/Modal/ModalContext";
import Borrowing from "../../../../../Models/Borrowing";
import AddGuarantee from "./addGuarantee";

const AddGuaranteeModal = ({ borrowing }: { borrowing: Borrowing }) => {
    return (
        <ModalProvider>
            <AddGuarantee borrowing={borrowing} />
        </ModalProvider>
    );
};

export default AddGuaranteeModal;
