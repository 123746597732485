import translator from "../../../Components/Extra/Translation/Translate";
import useAuthentication from "../../../Services/Authentication/useAuthentication";
import Cta from "../fintech/cta";
import Illustration from "../../images/hero-illustration-kp.png";

const MentionLegale = () => {
    const { translate } = translator();
    const { isAuthenticated } = useAuthentication();
    return (
        <>
            {/* Page Title */}
            <section className="relative z-10 h-[50vh] overflow-hidden">
                {/* Bg */}
                <div
                    className="absolute inset-0 rounded-bl-[100px] mb-28 md:mb-0 pointer-events-none -z-10"
                    aria-hidden="true"
                />

                <div className="max-w-[70%] mx-auto px-4 sm:px-6">
                    <div className="pt-36 pb-14">
                        {/* Page Title content */}
                        <div className="relative max-w-xl mx-auto md:max-w-none text-center md:text-left">
                            {/* Content */}
                            <div className="">
                                {/* Copy */}
                                <h1
                                    className="h1 text-white mb-6 text-center"
                                    data-aos="fade-up"
                                    data-aos-delay="100"
                                    dangerouslySetInnerHTML={{
                                        __html: translate(
                                            "INFORMATIONS",
                                            "MENTION_LEGALE_TITLE",
                                        ),
                                    }}
                                />
                            </div>

                            {/* Image */}
                            <div className="max-w-sm mx-auto md:max-w-none md:absolute md:left-[90%] md:top-0 -mb-12 md:-mt-12 md:mb-0">
                                <div className="relative md:-ml-3 md:-mr-24 md:mx-0">
                                    <img
                                        className="absolute opacity-50 top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 mt-16 md:mt-0 pointer-events-none -z-10 max-w-none mix-blend-lighten"
                                        src={Illustration}
                                        alt="Hero illustration"
                                        aria-hidden="true"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* Contenu Page */}
            <section className="-mt-36 text-white" id="transfert" data-aos-id-3>
                <div className="relative max-w-8xl mx-auto">
                    {/* Bg */}
                    <div
                        className="absolute inset-0 md:rounded-tl-[100px] mb-24 md:mb-0  pointer-events-none -z-10"
                        aria-hidden="true"
                    />

                    <div className="max-w-6xl mx-auto px-4 sm:px-6">
                        <div className="pb-6 pt-12 md:pt-20">
                            <div className="mb-12 md:mb-20" data-aos="fade-up">
                                <div className="text-base">
                                    <p>
                                        <strong>
                                            <span>
                                                1.{" "}
                                                {translate(
                                                    "Legal_notice",
                                                    "Presentation_of_the_site",
                                                )}
                                            </span>
                                        </strong>
                                        <br />
                                        {translate(
                                            "Legal_notice",
                                            "In_accordance_with_",
                                        )}{" "}
                                        <span style={{ color: "#EEA913" }}>
                                            <a
                                                href="https://kalypay.com"
                                                style={{ color: "#EEA913" }}
                                            >
                                                https://kalypay.com
                                            </a>
                                        </span>{" "}
                                        {translate(
                                            "Legal_notice",
                                            "The_identity_of_",
                                        )}
                                    </p>
                                    <p>&nbsp;</p>
                                    <div style={{ textAlign: "left" }}>
                                        <span>
                                            <strong>
                                                {translate(
                                                    "Legal_notice",
                                                    "Owner",
                                                )}
                                                :
                                            </strong>
                                            {"  "}
                                        </span>
                                        <span>
                                            <strong>KALYSSI Europe</strong>
                                        </span>
                                    </div>
                                    <div style={{ textAlign: "left" }}>
                                        <span>
                                            <strong>
                                                {translate(
                                                    "Legal_notice",
                                                    "Register",
                                                )}
                                                :{" "}
                                            </strong>
                                            21033421
                                        </span>
                                        <span></span>
                                    </div>
                                    <div style={{ textAlign: "left" }}>
                                        <strong>
                                            {translate(
                                                "Legal_notice",
                                                "Contact_email",
                                            )}
                                            :{" "}
                                        </strong>
                                        contact@kalypay.com
                                    </div>
                                    <pre style={{ textAlign: "left" }}>
                                        <span>
                                            <strong></strong>
                                        </span>
                                    </pre>
                                    <p style={{ textAlign: "left" }}>
                                        <strong>
                                            {translate(
                                                "Legal_notice",
                                                "Address_of_headquarters",
                                            )}
                                            :
                                        </strong>
                                        <br />
                                        Cimburkova 916/8,
                                        <br />
                                        130 00 Praha 3,
                                        <br />
                                        République Tchèque
                                    </p>
                                    <p>&nbsp;</p>
                                    <p>
                                        <span></span>
                                        <strong>
                                            {translate("Legal_notice", "Host")}:
                                        </strong>{" "}
                                        Planet Hoster
                                        <br />
                                        <span>4416 Louis-B.-Mayer</span>
                                        <br />
                                        <span>Laval, Québec</span>
                                        <br />
                                        <span>Canada</span>
                                        <br />
                                        <span>H7P 0G1</span>
                                    </p>
                                    <p>
                                        <span></span>
                                    </p>
                                    <p>
                                        <strong>
                                            <span>
                                                2.{" "}
                                                {translate(
                                                    "Legal_notice",
                                                    "General_conditions",
                                                )}
                                            </span>
                                        </strong>
                                        <br />
                                        <span>
                                            {translate(
                                                "Legal_notice",
                                                "Use_of_the_site_",
                                            )}
                                        </span>
                                    </p>
                                    <p>
                                        <span>
                                            {translate(
                                                "Legal_notice",
                                                "This_site_is_normally_",
                                            )}
                                        </span>
                                    </p>
                                    <p>
                                        <span>
                                            {translate(
                                                "Legal_notice",
                                                "The_website_",
                                            )}
                                        </span>
                                    </p>
                                    <p>
                                        <span></span>
                                    </p>
                                    <p>&nbsp;</p>
                                    <p>
                                        <strong>
                                            <span>
                                                3.
                                                {translate(
                                                    "Legal_notice",
                                                    "Description_of_services_provided",
                                                )}
                                            </span>
                                        </strong>
                                        <br />
                                        <span>
                                            {translate(
                                                "Legal_notice",
                                                "The_site_https_",
                                            )}
                                        </span>
                                    </p>
                                    <p>
                                        <span>
                                            {translate(
                                                "Legal_notice",
                                                "Kalyssi_strives_to_provide_",
                                            )}
                                        </span>
                                    </p>
                                    <p>
                                        <span>
                                            {translate(
                                                "Legal_notice",
                                                "All_information_on_the_site_",
                                            )}
                                        </span>
                                    </p>
                                    <p>
                                        <strong>
                                            <span></span>
                                        </strong>
                                    </p>
                                    <p>&nbsp;</p>
                                    <p>
                                        <strong>
                                            <span>
                                                4.{" "}
                                                {translate(
                                                    "Legal_notice",
                                                    "Contractual_limitations_on_technical_data",
                                                )}
                                            </span>
                                        </strong>
                                        <br />
                                        <span>
                                            {translate(
                                                "Legal_notice",
                                                "The_site_uses_JavaScript_technology",
                                            )}
                                        </span>
                                    </p>
                                    <p>
                                        <span>
                                            {translate(
                                                "Legal_notice",
                                                "The_site_cannot_be_held_responsible",
                                            )}
                                        </span>
                                    </p>
                                    <p>
                                        <strong>
                                            <span></span>
                                        </strong>
                                    </p>
                                    <p>&nbsp;</p>
                                    <p>
                                        <strong>
                                            <span>
                                                5.
                                                {translate(
                                                    "Legal_notice",
                                                    "Intellectual_property_and_counterfeits",
                                                )}
                                            </span>
                                        </strong>
                                        <br />
                                        <span>
                                            {translate(
                                                "Legal_notice",
                                                "Kalyssi_SA_is_the_owner_of_the_intellectual_",
                                            )}
                                        </span>
                                    </p>
                                    <p>
                                        <span>
                                            {translate(
                                                "Legal_notice",
                                                "Any_reproduction_representation_",
                                            )}
                                        </span>
                                    </p>
                                    <p>
                                        <span>
                                            {translate(
                                                "Legal_notice",
                                                "Any_unauthorized_use_of_the_site_or_any",
                                            )}
                                        </span>
                                    </p>
                                    <p>
                                        <strong>
                                            <span></span>
                                        </strong>
                                    </p>
                                    <p>&nbsp;</p>
                                    <p>
                                        <strong>
                                            <span>
                                                6.
                                                {translate(
                                                    "Legal_notice",
                                                    "Limitations_of_Liability",
                                                )}
                                            </span>
                                        </strong>
                                        <br />
                                        <span>
                                            {translate(
                                                "Legal_notice",
                                                "Kalyssi_cannot_be_held_responsible_for_any_",
                                            )}
                                        </span>
                                    </p>
                                    <p>
                                        <span>
                                            {translate(
                                                "Legal_notice",
                                                "Kalyssi_cannot_be_held_responsible_for_consequential_",
                                            )}
                                        </span>
                                    </p>
                                    <p>
                                        <span>
                                            {translate(
                                                "Legal_notice",
                                                "Interactive_spaces_",
                                            )}
                                        </span>
                                    </p>
                                    <p>
                                        <strong>
                                            <span></span>
                                        </strong>
                                    </p>
                                    <p>&nbsp;</p>
                                    <p>
                                        <strong>
                                            <span>
                                                &nbsp;7.{" "}
                                                {translate(
                                                    "Legal_notice",
                                                    "Applicable_law",
                                                )}
                                            </span>
                                        </strong>
                                    </p>
                                    <p>
                                        <span>
                                            {translate(
                                                "Legal_notice",
                                                "In_the_event_of_disputes_",
                                            )}
                                            <strong>
                                                KALYSSI Europe s.r.o.
                                            </strong>
                                        </span>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <Section03 />
            <Section04 />
            <Section05 />
            <Section06 />
            <Section07 />
            <Section08 />
            <Faqs /> */}
            <Cta />
        </>
    );
};

export default MentionLegale;
