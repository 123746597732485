import { ClipboardPen, Globe, ScanEye } from "lucide-react";
import translator from "../../../Components/Extra/Translation/Translate";
import echange from "../../images/exchange.jpg";
import Logos from "../../images/logos.png";

export default function Section05() {
    const { translate } = translator();
    return (
        // <section id="swap">
        //     <div className="relative w-full mx-auto">
        //         <div className="max-w-6xl mx-auto px-4 sm:px-6">
        //             <div className="py-12 md:py-20">
        //                 {/* Section content */}
        //                 <div className="relative max-w-xl mx-auto md:max-w-none text-center md:text-left">
        //                     {/* Section header */}
        //                     <div
        //                         className="text-left md:max-w-4xl mb-12 md:mb-10"
        //                         data-aos="fade-up"
        //                     >
        //                         <h2 className="h2 text-white mb-4">
        //                             {translate("LANDING", "SECTION5_TITLE")}
        //                         </h2>
        //                         <p className="text-lg text-blue-200 mb-2">
        //                             {translate("LANDING", "SECTION5_DESC")}
        //                         </p>
        //                         <p className="text-lg text-blue-200 mb-2">
        //                             {translate("LANDING", "SECTION5_DESC2")}
        //                         </p>
        //                         <p className="text-lg text-blue-200 mb-2">
        //                             {translate("LANDING", "SECTION5_DESC3")}
        //                         </p>
        //                     </div>

        //                     {/* img */}
        //                     <div
        //                         className="flex justify-center mb-6"
        //                         data-aos="fade-up"
        //                         data-aos-delay="100"
        //                     >
        //                         <div className="relative">
        //                             <img
        //                                 className="absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 opacity-75 pointer-events-none -z-10 max-w-none mix-blend-lighten"
        //                                 src={LogosIllustration}
        //                                 alt="Logos illustration"
        //                                 aria-hidden="true"
        //                             />
        //                             <img
        //                                 src={Logos}
        //                                 width={720}
        //                                 height={283}
        //                                 alt="Logos"
        //                             />
        //                         </div>
        //                     </div>

        //                     {/* Items */}
        //                     <div
        //                         className="max-w-sm mx-auto grid gap-12 md:grid-cols-3 md:-mx-9 md:gap-0 items-start md:max-w-none text-left"
        //                         data-aos="fade-up"
        //                         data-aos-delay="200"
        //                     >
        //                         {/* 1st item */}
        //                         <div className="relative md:px-9 after:hidden md:after:block after:absolute after:right-0 after:top-1/2 after:-translate-y-1/2 after:w-px after:h-16 after:bg-kalypay-500 last:after:hidden">
        //                             <div className="mb-3">
        //                                 <div className="flex items-center justify-center font-bold text-black bg-kalypay-500 h-11 w-11 rounded-full">
        //                                     1
        //                                 </div>
        //                             </div>
        //                             <h4 className="text-white text-xl font-bold mb-1">
        //                                 {translate("LANDING", "S5_STEP1_TITLE")}
        //                             </h4>
        //                             <p className="text-blue-200">
        //                                 {translate("LANDING", "S5_STEP1_DESC")}
        //                             </p>
        //                         </div>

        //                         {/* 2nd item */}
        //                         <div className="relative md:px-9 after:hidden md:after:block after:absolute after:right-0 after:top-1/2 after:-translate-y-1/2 after:w-px after:h-16 after:bg-kalypay-500 last:after:hidden">
        //                             <div className="mb-3">
        //                                 <div className="flex items-center justify-center font-bold text-black bg-kalypay-500 h-11 w-11 rounded-full">
        //                                     2
        //                                 </div>
        //                             </div>
        //                             <h4 className="text-white text-xl font-bold mb-1">
        //                                 {translate("LANDING", "S5_STEP2_TITLE")}
        //                             </h4>
        //                             <p className="text-blue-200">
        //                                 {translate("LANDING", "S5_STEP2_DESC")}
        //                             </p>
        //                         </div>

        //                         {/* 3rd item */}
        //                         <div className="relative md:px-9 after:hidden md:after:block after:absolute after:right-0 after:top-1/2 after:-translate-y-1/2 after:w-px after:h-16 after:bg-kalypay-500 last:after:hidden">
        //                             <div className="mb-3">
        //                                 <div className="flex items-center justify-center font-bold text-black bg-kalypay-500 h-11 w-11 rounded-full">
        //                                     3
        //                                 </div>
        //                             </div>
        //                             <h4 className="text-white text-xl font-bold mb-1">
        //                                 {translate("LANDING", "S5_STEP3_TITLE")}
        //                             </h4>
        //                             <p className="text-blue-200">
        //                                 {translate("LANDING", "S5_STEP3_DESC")}
        //                             </p>
        //                         </div>
        //                     </div>
        //                 </div>
        //             </div>
        //         </div>
        //     </div>
        // </section>
        <section className="relative flex justify-center items-center mx-4 my-20 lg:!mx-20">
            <div className="max-w-screen-xl mx-auto w-full">
                {/* Background Image Section */}
                <div className="relative md:rounded-[50px] rounded-xl py-14 font-[sans-serif] overflow-hidden">
                    <div
                        className="absolute inset-0 bg-cover bg-center  bg-no-repeat brightness-50"
                        style={{ backgroundImage: `url(${echange})` }}
                    ></div>
                    <div className="relative z-10 max-w-6xl mx-auto py-16 px-4">
                        <div
                            className="text-center mb-12 md:mb-10"
                            data-aos="fade-up"
                        >
                            <h2 className="h2 text-white mb-4">
                                {translate("LANDING", "SECTION5_TITLE")}
                            </h2>
                            <p className="text-lg text-slate-100 mb-2">
                                {translate("LANDING", "SECTION5_DESC")}
                            </p>
                            <p className="text-lg text-slate-100 mb-2">
                                {translate("LANDING", "SECTION5_DESC2")}
                            </p>
                            <p className="text-lg text-slate-100 mb-2">
                                {translate("LANDING", "SECTION5_DESC3")}
                            </p>
                        </div>
                    </div>
                </div>

                {/* Floating Cards */}
                <div className="relative -mt-20">
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:!grid-cols-3 gap-8 max-w-6xl mx-auto">
                        {/* Card 1 */}
                        <div
                            data-aos="flip-down"
                            className="bg-slate-800 rounded-2xl overflow-hidden shadow-md hover:shadow-lg transition-all"
                        >
                            <div className="p-8">
                                <ClipboardPen
                                    size={40}
                                    className="text-white mb-5"
                                />
                                <h4 className="text-white text-xl font-bold mb-1">
                                    {translate("LANDING", "S5_STEP1_TITLE")}
                                </h4>
                                <p className="text-gray-200 pt-2 text-base">
                                    {translate("LANDING", "S5_STEP1_DESC")}
                                </p>
                            </div>
                        </div>

                        {/* Card 2 */}
                        <div
                            data-aos="flip-down"
                            className="bg-slate-800 rounded-2xl overflow-hidden shadow-md hover:shadow-lg transition-all"
                        >
                            <div className="p-8">
                                <ScanEye
                                    size={40}
                                    className="text-white mb-5"
                                />
                                <h4 className="text-white text-xl font-bold mb-1">
                                    {translate("LANDING", "S5_STEP2_TITLE")}
                                </h4>
                                <p className="text-gray-200 pt-2 text-base">
                                    {translate("LANDING", "S5_STEP2_DESC")}
                                </p>
                            </div>
                        </div>

                        {/* Card 3 */}
                        <div
                            data-aos="flip-down"
                            className="bg-slate-800 rounded-2xl overflow-hidden shadow-md hover:shadow-lg transition-all"
                        >
                            <div className="p-8">
                                <Globe size={40} className="text-white mb-5" />
                                <h4 className="text-white text-xl font-bold mb-1">
                                    {translate("LANDING", "S5_STEP3_TITLE")}
                                </h4>
                                <p className="text-gray-200 pt-2 text-base">
                                    {translate("LANDING", "S5_STEP3_DESC")}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
