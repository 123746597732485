export default class BorrowingHistory {
    id: number;
    amount: number;
    amount_usd: number;
    type: BorrowingType;
    created_at: string;
    constructor(
        id: number,
        amount: number,
        amount_usd: number,
        type: BorrowingType,
        created_at: string,
    ) {
        this.id = id;
        this.amount = amount;
        this.amount_usd = amount_usd;
        this.type = type;
        this.created_at = created_at;
    }
}

export enum BorrowingType {
    ADD_GUARANTEE = "add_guarantee",
    REFUND = "refund",
}
