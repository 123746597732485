import { NavLink } from "react-router-dom";
import User from "../../../../Models/User";

const ProfilComponent = ({ user, role }: { user: User; role: string }) => {
    return (
        <NavLink
            className="mr-3 flex items-center"
            to={`/profil/${user.uid}`}
            state={{ user, role }}
        >
            <i data-lucide="check-square" className="mr-1 h-4 w-4"></i>
            Profil
        </NavLink>
    );
};

export default ProfilComponent;
