import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import BreadCrumbV2, {
    Ariane,
} from "../../../Layouts/Account/BreadCrumb/BreadCrumbV2";
import AssetBorrowingConfig from "./config";
const ConfigAssetBorrowing = () => {
    const navigate = useNavigate();
    const { abbr } = useParams();

    const Arianes: Ariane[] = [
        {
            path: "#",
            name: "Admin",
        },
        {
            path: "/admin/assets/configuration",
            name: "Assets",
        },
        // {
        //     path: "",
        //     name: translate("Fiat", "Configure"),
        // },
    ];

    return (
        <div>
            <BreadCrumbV2 arianes={Arianes} />
            <h2 className="intro-y mt-5 text-lg font-medium bg-white p-2 rounded">
                Configure {abbr?.toUpperCase()}
            </h2>
            <div className="mt-5 grid grid-cols-12 gap-6">
                <div className="intro-y col-span-12 mt-2 flex flex-wrap items-center sm:flex-nowrap">
                    <button
                        className="btn btn-perfect mr-2"
                        onClick={() =>
                            navigate("/admin/asset-borrowing-config")
                        }
                    >
                        Liste
                    </button>
                </div>
                <div className="intro-y col-span-12 lg:col-span-12">
                    <AssetBorrowingConfig />
                </div>
            </div>
        </div>
    );
};

export default ConfigAssetBorrowing;
