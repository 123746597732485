import { ListCheck, FileText, CheckCircle } from "lucide-react";
import translator from "../../../../Components/Extra/Translation/Translate";
import think from "../../../images/discover/think.jpg";

export default function Section4() {
    const { translate } = translator();

    return (
        <>
            <div className="p-4 py-16">
                <div className="max-w-8xl mx-auto">
                    <div className="flex flex-col md:flex-row gap-16">
                        <div className="flex-1 flex items-center justify-center">
                            <img
                                src={think}
                                alt="Business image"
                                className="rounded-lg object-cover w-full h-full"
                                data-aos="fade-down"
                            />
                        </div>
                        <div
                            className="flex-1 flex flex-col justify-center"
                            data-aos="fade-up"
                        >
                            <h2 className="text-kalypay-100 text-2xl font-bold mb-4">
                                {translate(
                                    "LANDING_DISCOVER",
                                    "howToSection_title",
                                )}
                            </h2>
                            <ol className="list-none space-y-6 mt-6">
                                <li className="flex items-center space-x-4 border rounded-lg p-3">
                                    <span className="bg-slate-800 rounded-md p-2">
                                        <ListCheck className="size-8 mt-1" />
                                    </span>
                                    <div>
                                        <h3 className="text-lg font-semibold">
                                            {translate(
                                                "LANDING_DISCOVER",
                                                "howToSection_title1",
                                            )}
                                        </h3>
                                        <p className="mt-2 text-slate-300">
                                            {translate(
                                                "LANDING_DISCOVER",
                                                "howToSection_description1",
                                            )}
                                        </p>
                                    </div>
                                </li>

                                <li className="flex items-center space-x-4 border rounded-lg p-3">
                                    <span className="bg-slate-800 rounded-md p-2">
                                        <FileText className="size-8 mt-1" />
                                    </span>
                                    <div>
                                        <h3 className="text-lg font-semibold">
                                            {translate(
                                                "LANDING_DISCOVER",
                                                "howToSection_title2",
                                            )}
                                        </h3>
                                        <p className="mt-2 text-slate-300">
                                            {translate(
                                                "LANDING_DISCOVER",
                                                "howToSection_description2",
                                            )}
                                        </p>
                                    </div>
                                </li>

                                <li className="flex items-center space-x-4 border rounded-lg p-3">
                                    <span className="bg-slate-800 rounded-md p-2">
                                        <CheckCircle className="size-8 mt-1" />
                                    </span>
                                    <div>
                                        <h3 className="text-lg font-semibold">
                                            {translate(
                                                "LANDING_DISCOVER",
                                                "howToSection_title3",
                                            )}
                                        </h3>
                                        <p className="mt-2 text-slate-300">
                                            {translate(
                                                "LANDING_DISCOVER",
                                                "howToSection_description3",
                                            )}
                                        </p>
                                    </div>
                                </li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
