import React from "react";
import { useThemeProvider } from "../../utils/ThemeContext";
import useTheme from "../../../Services/Hooks/useTheme";
import { AppMode } from "../../../globalState/reducers/Theme.reducer";
import { SunMedium } from "lucide-react";

export default function ThemeToggle() {
    // const { currentTheme, changeCurrentTheme } = useThemeProvider();

    const { mode, setAppMode } = useTheme();

    const changeTheme = () => {
        const newTheme = mode === AppMode.LIGHT ? AppMode.DARK : AppMode.LIGHT;
        setAppMode(newTheme);
    };

    return (
        <div>
            <input
                type="checkbox"
                name="light-switch"
                id="light-switch"
                className="light-switch sr-only"
                checked={mode === "light"}
                onChange={() => changeTheme()}
            />
            <label
                className="flex items-center justify-center cursor-pointer w-8 h-8 "
                htmlFor="light-switch"
            >
                <SunMedium size={30} className="text-white dark:hidden" />
                <svg
                    className="w-4 h-4 hidden dark:block"
                    width="16"
                    height="16"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        className="fill-current text-slate-400"
                        d="M6.2 2C3.2 2.8 1 5.6 1 8.9 1 12.8 4.2 16 8.1 16c3.3 0 6-2.2 6.9-5.2C9.7 12.2 4.8 7.3 6.2 2Z"
                    />
                    <path
                        className="fill-current text-slate-500"
                        d="M12.5 6a.625.625 0 0 1-.625-.625 1.252 1.252 0 0 0-1.25-1.25.625.625 0 1 1 0-1.25 1.252 1.252 0 0 0 1.25-1.25.625.625 0 1 1 1.25 0c.001.69.56 1.249 1.25 1.25a.625.625 0 1 1 0 1.25c-.69.001-1.249.56-1.25 1.25A.625.625 0 0 1 12.5 6Z"
                    />
                </svg>
                <span className="sr-only">Switch to light / dark version</span>
            </label>
        </div>
    );
}
