import React from "react";
import { ModalContext } from "../../../../../Components/Extra/Modal/ModalContext";
import translator from "../../../../../Components/Extra/Translation/Translate";
import Borrowing from "../../../../../Models/Borrowing";
import EyeSvg from "../../../../../Components/Extra/Svg/EyeSvg";
import UtilsService from "../../../../../Services/UtilsService";
import HistoriesDetailTable from "./HistoriesDetailTable";

const ViewDetail = ({ borrowing }: { borrowing: Borrowing }) => {
    const { translate } = translator();
    const { handleModal } = React.useContext(ModalContext);

    return (
        <div className="grid grid-cols-12">
            <div className="col-span-12 text-center text-2xl font-bold mb-4">
                {translate("BORROW", "Detail", {
                    ref: borrowing.getBorrowingRef(),
                })}
            </div>
            <div className="col-span-6">
                <div className="grid grid-cols-12 gap-2">
                    <div className="col-span-12">
                        {translate("BORROW", "Start_date")}:{" "}
                        <span className="font-bold">
                            {borrowing.created_at}
                        </span>
                    </div>
                    <div className="col-span-12">
                        {translate("BORROW", "End_Date")}:{" "}
                        <span className="font-bold">{borrowing.end_date}</span>
                    </div>
                    <div className="col-span-12">
                        {translate("Transaction", "Status")}:{" "}
                        <span className="font-bold">
                            {translate("BORROW", borrowing.status)}
                        </span>
                    </div>
                </div>
            </div>
            <div className="col-span-6">
                <div className="grid grid-cols-12 gap-2">
                    <div className="col-span-12">
                        {translate("BORROW", "Amount")}:{" "}
                        <span className="font-bold">{`${UtilsService.formatAmountV2(
                            borrowing.borrow_amount,
                            2,
                        )} ${borrowing.crypto_borrow.abbreviation}`}</span>
                    </div>
                    <div className="col-span-12">
                        {translate("BORROW", "Total_to_be_repaid")}:{" "}
                        <span className="font-bold">{`${UtilsService.formatAmountV2(
                            borrowing.total_to_be_repaid,
                            2,
                        )} ${borrowing.crypto_borrow.abbreviation}`}</span>
                    </div>
                    <div className="col-span-12">
                        {translate("BORROW", "Initial_guarantee")}:{" "}
                        <span className="font-bold">{`${UtilsService.formatAmountV2(
                            borrowing.initial_amount_guaranteed,
                            8,
                        )} ${borrowing.crypto_guarantee.abbreviation}`}</span>
                    </div>
                    <div className="col-span-12">
                        {translate("BORROW", "Guarantee_added")}:{" "}
                        <span className="font-bold">{`${UtilsService.formatAmountV2(
                            borrowing.guarantee_added_amount,
                            8,
                        )} ${borrowing.crypto_guarantee.abbreviation}`}</span>
                    </div>
                    <div className="col-span-12">
                        {translate("BORROW", "Guarantee_liquidate")}:{" "}
                        <span className="font-bold">{`${UtilsService.formatAmountV2(
                            borrowing.guarantee_liquidate_amount,
                            8,
                        )} ${borrowing.crypto_guarantee.abbreviation}`}</span>
                    </div>
                </div>
            </div>{" "}
            <div className="col-span-12 text-center text-2xl font-bold my-4">
                {translate("BORROW", "History")}
            </div>
            <div className="col-span-12 text-center mb-4">
                <HistoriesDetailTable borrowing={borrowing} />
            </div>
            <div className="col-span-12 text-right mt-4">
                <button
                    type="button"
                    className="btn  mt-2"
                    onClick={() => handleModal()}
                >
                    {translate("Button", "Close")}
                </button>
            </div>
        </div>
    );
};

const Detail = ({ borrowing }: { borrowing: Borrowing }) => {
    const { handleModal } = React.useContext(ModalContext);

    return (
        <div className="mt-2 text-center">
            <button
                type="button"
                className="btn btn-info mb-2"
                onClick={() => {
                    handleModal(<ViewDetail borrowing={borrowing} />);
                }}
            >
                <EyeSvg />
            </button>
        </div>
    );
};

export default Detail;
