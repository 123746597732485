import { NavLink } from "react-router-dom";
import translator from "../../../Components/Extra/Translation/Translate";
import useAuthentication from "../../../Services/Authentication/useAuthentication";

import Cta from "../fintech/cta";
import Illustration from "../../images/hero-illustration-kp.png";

const LegalWarning = () => {
    const { translate } = translator();
    const { isAuthenticated } = useAuthentication();
    return (
        <>
            {/* Page Title */}
            <section className="relative z-10 bg-black mdg-bg-hero overflow-hidden">
                {/* Bg */}
                <div
                    className="absolute inset-0 rounded-bl-[100px] mb-28 md:mb-0 pointer-events-none -z-10"
                    aria-hidden="true"
                />

                <div className="max-w-[70%] mx-auto px-4 sm:px-6">
                    <div className="pt-36 pb-14">
                        {/* Page Title content */}
                        <div className="relative max-w-xl mx-auto md:max-w-none text-center md:text-left">
                            {/* Content */}
                            <div className="">
                                {/* Copy */}
                                <h1
                                    className="h1 text-white mb-6 text-center"
                                    data-aos="fade-up"
                                    data-aos-delay="100"
                                    dangerouslySetInnerHTML={{
                                        __html: translate(
                                            "LEGAL_WARNING",
                                            "PAGE_TITLE",
                                        ),
                                    }}
                                />
                            </div>

                            {/* Image */}
                            <div className="max-w-sm mx-auto md:max-w-none md:absolute md:left-[90%] md:top-0 -mb-12 md:-mt-12 md:mb-0">
                                <div className="relative md:-ml-3 md:-mr-24 md:mx-0">
                                    <img
                                        className="absolute opacity-50 top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 mt-16 md:mt-0 pointer-events-none -z-10 max-w-none mix-blend-lighten"
                                        src={Illustration}
                                        alt="Hero illustration"
                                        aria-hidden="true"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* Contenu Page */}
            <section
                className="md:mt-20 text-white"
                id="transfert"
                data-aos-id-3
            >
                <div className="relative max-w-8xl mx-auto">
                    {/* Bg */}
                    <div
                        className="absolute inset-0 md:rounded-tl-[100px] mb-24 md:mb-0  -z-10"
                        aria-hidden="true"
                    />

                    <div className="max-w-6xl mx-auto px-4 sm:px-6">
                        <div className="pb-6 pt-12 md:pt-20">
                            <div className="mb-12 md:mb-20" data-aos="fade-up">
                                <div className="text-base">
                                    <p
                                        style={{ textAlign: "left" }}
                                        className="mb-4"
                                    >
                                        <strong>
                                            {translate(
                                                "LEGAL_WARNING",
                                                "ARTICLE_1",
                                            )}
                                        </strong>
                                    </p>
                                    <p
                                        style={{ textAlign: "left" }}
                                        className="mb-4"
                                    >
                                        {translate(
                                            "LEGAL_WARNING",
                                            "ARTICLE_1_DESCRIPTION",
                                        )}
                                    </p>
                                </div>
                                <div className="text-base mt-10">
                                    <p
                                        style={{ textAlign: "left" }}
                                        className="mb-4"
                                    >
                                        <strong>
                                            {translate(
                                                "LEGAL_WARNING",
                                                "ARTICLE_2",
                                            )}
                                        </strong>
                                    </p>
                                    <p
                                        style={{ textAlign: "left" }}
                                        className="mb-4"
                                    >
                                        {translate(
                                            "LEGAL_WARNING",
                                            "ARTICLE_2_DESCRIPTION",
                                        )}
                                    </p>
                                </div>
                                <div className="text-base mt-10">
                                    <p
                                        style={{ textAlign: "left" }}
                                        className="mb-4"
                                    >
                                        <strong>
                                            {translate(
                                                "LEGAL_WARNING",
                                                "ARTICLE_3",
                                            )}
                                        </strong>
                                    </p>
                                    <p
                                        style={{ textAlign: "left" }}
                                        className="mb-4"
                                    >
                                        {translate(
                                            "LEGAL_WARNING",
                                            "ARTICLE_3_DESCRIPTION",
                                        )}
                                    </p>
                                </div>{" "}
                                <div className="text-base mt-20">
                                    <p
                                        style={{ textAlign: "left" }}
                                        className="mb-4"
                                    >
                                        {translate(
                                            "LEGAL_WARNING",
                                            "CONCLUSION",
                                        )}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Cta />
        </>
    );
};

export default LegalWarning;
