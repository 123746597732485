import translator from "../../../../Components/Extra/Translation/Translate";
import support from "../../../images/support.jpg";
export default function Section3() {
    const { translate } = translator();
    return (
        <section className="flex justify-center items-center mx-4 lg:!mx-20 my-20">
            <div className="max-w-screen-xl mx-auto w-full ">
                <div className="grid sm:grid-cols-2 items-center gap-10 py-10">
                    <div data-aos="fade-up" className="text-left">
                        <h3 className="text-white bg-clip-text mb-3 font-bold md:!text-5xl text-3xl leading-tight tracking-tighter">
                            {translate("LANDING_IBAN", "section3_titre")}
                        </h3>

                        <div className="text-slate-300 text-base font-light mt-4 space-y-8 leading-normal ">
                            {" "}
                            <p>{translate("LANDING_IBAN", "section3_p1")}</p>
                            <p>{translate("LANDING_IBAN", "section3_p2")}</p>
                            <p className="text-sm">
                                {translate("LANDING_IBAN", "section3_p3")}
                            </p>
                        </div>
                    </div>

                    <div data-aos="fade-down" className=" w-full h-max">
                        <img
                            src={support}
                            className="w-full md:!rounded-[50px] rounded-2xl object-cover"
                            alt="img"
                        />
                    </div>
                </div>
            </div>
        </section>
    );
}
