import { createContext, useContext, useEffect, useMemo, useState } from "react";
import Transaction, { ValidTransactionData } from "../../Models/Transaction";
import alert_message from "../../Services/Alert/AlertMessage";
import TransactionService from "../../Services/Transaction/TransactionService";
import { useAppProvider } from "./AppProvider";

interface TransactionDataContextProps {
    transactionData: ValidTransactionData | undefined;
    setTransactionData: (transactionData: ValidTransactionData) => void;
    refreshAuthorisation: () => void;
    allowTransaction: boolean;
}

const TransactionDataContext = createContext<
    TransactionDataContextProps | undefined
>(undefined);

export const TransactionDataProvider = ({
    children,
}: {
    children: React.ReactNode;
}) => {
    const { setProcessing, finalizeProcessing } = useAppProvider();
    const [transactionData, setTransactionData] =
        useState<ValidTransactionData>();
    const allowTransaction = useMemo<boolean>(() => {
        if (!transactionData) return false;

        if (!!transactionData.kyc) return true;

        if (
            transactionData.user_transaction >=
                transactionData.max_transaction_without_kyc &&
            !transactionData.kyc
        )
            return false;

        return true;
    }, [transactionData]);

    const getTransactionData = async () => {
        setProcessing(true);
        try {
            const data = await TransactionService.getTransactionData();

            if (data) setTransactionData(data);
        } catch (err: any) {
            alert_message.error(err);
        }
        finalizeProcessing();
    };

    useEffect(() => {
        getTransactionData();
    }, []);

    const refreshAuthorisation = () => {
        getTransactionData();
    };

    return (
        <TransactionDataContext.Provider
            value={{
                transactionData,
                setTransactionData,
                allowTransaction,
                refreshAuthorisation,
            }}
        >
            {children}
        </TransactionDataContext.Provider>
    );
};

export const useTransactionData = () => {
    const context = useContext(TransactionDataContext);
    if (!context) {
        throw new Error(
            "useTransaction must be used within a TransactionProvider",
        );
    }
    return context;
};
