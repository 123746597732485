import { BookCheck, ClipboardPen, CreditCard } from "lucide-react";
import translator from "../../../../Components/Extra/Translation/Translate";
import abonnement from "../../../images/abonnement.jpg";
export default function Section5() {
    const { translate } = translator();

    return (
        <section
            className="flex justify-center items-center my-20 "
            id="subscription"
        >
            <div className="w-full">
                <div className=" bg-slate-800 py-14 px-4 lg:!px-20  font-[sans-serif]">
                    <div className="max-w-screen-xl mx-auto w-full">
                        <div className="grid md:!grid-cols-2 items-center max-md:max-w-lg mx-auto gap-8">
                            <div>
                                <h2 className="text-white bg-clip-text mb-3 font-bold md:!text-5xl text-3xl leading-tight tracking-tighter">
                                    {translate(
                                        "LANDING_IBAN",
                                        "section5_titre",
                                    )}
                                    <h2 className="text-gray-300 md:text-2xl font-light text-lg text-left my-10">
                                        {translate(
                                            "LANDING_IBAN",
                                            "secction5_p",
                                        )}
                                    </h2>
                                </h2>
                            </div>
                            <div className="space-y-5">
                                <div
                                    data-aos="flip-left"
                                    className="p-6 border border-gray-200  rounded-2xl text-left "
                                >
                                    <div className="flex flex-row items-center gap-3">
                                        <span className="rounded-lg px-4 py-2.5 text-slate-300 bg-gray-200">
                                            <ClipboardPen
                                                size={40}
                                                className="text-black"
                                            />
                                        </span>
                                        <div className="flex flex-col text-slate-300">
                                            <h3 className="text-xl font-bold mb-4">
                                                {translate(
                                                    "LANDING_IBAN",
                                                    "section5_card1_titre",
                                                )}
                                            </h3>
                                            <p className="text-base">
                                                {translate(
                                                    "LANDING_IBAN",
                                                    "secction5_card1_p",
                                                )}
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div
                                    data-aos="flip-left"
                                    className="p-6 rounded-2xl border border-gray-200  text-left  "
                                >
                                    <div className="flex flex-row items-center gap-3">
                                        <span className="rounded-lg px-4 py-2.5 text-slate-300 bg-gray-200">
                                            <CreditCard
                                                size={40}
                                                className="text-black"
                                            />
                                        </span>
                                        <div className="flex flex-col text-slate-300">
                                            <h3 className=" text-xl font-bold mb-4">
                                                {" "}
                                                {translate(
                                                    "LANDING_IBAN",
                                                    "section5_card2_titre",
                                                )}
                                            </h3>
                                            <p className="text-base">
                                                {translate(
                                                    "LANDING_IBAN",
                                                    "secction5_card2_p",
                                                )}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    data-aos="flip-left"
                                    className="p-6 rounded-2xl border border-gray-200  text-left"
                                >
                                    <div className="flex flex-row items-center gap-3">
                                        <span className="rounded-lg px-4 py-2.5 text-slate-300 bg-gray-200">
                                            <BookCheck
                                                size={40}
                                                className="text-black"
                                            />
                                        </span>
                                        <div className="flex flex-col text-slate-300">
                                            <h3 className="text-xl font-bold mb-4">
                                                {" "}
                                                {translate(
                                                    "LANDING_IBAN",
                                                    "section5_card3_titre",
                                                )}
                                            </h3>
                                            <p className="text-base">
                                                {translate(
                                                    "LANDING_IBAN",
                                                    "secction5_card3_p",
                                                )}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="relative h-[50vh] md:!rounded-[50px] rounded-2xl overflow-hidden my-20 mx-4 max-w-screen-xl lg:!mx-auto">
                    <div
                        className="absolute inset-0 brightness-50 backdrop-blur-2xl bg-cover bg-center "
                        style={{ backgroundImage: `url(${abonnement})` }}
                    ></div>

                    <div
                        data-aos="zoom-in-down"
                        className="relative z-10 flex items-center justify-center h-full text-center"
                    >
                        <div className="sm:p-12 p-4">
                            <h2 className="text-white bg-clip-text mb-3 font-bold md:!text-5xl text-3xl leading-tight tracking-tighter">
                                {translate("LANDING_IBAN", "section5_titre2")}
                            </h2>
                            <div className="md:!text-lg text-sm space-y-4 leading-normal text-slate-50 mt-4">
                                <p>
                                    {translate(
                                        "LANDING_IBAN",
                                        "section5_titre2_p1",
                                    )}
                                </p>
                                <p>
                                    {translate(
                                        "LANDING_IBAN",
                                        "section5_titre2_p2",
                                    )}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
