import {
    createContext,
    Dispatch,
    SetStateAction,
    useContext,
    useEffect,
    useState,
} from "react";
import FidelityModel from "../../Models/Fidelity";
import UserCard, { CARD_TYPE, USER_CARD_STATUS } from "../../Models/UserCard";
import FidelityService from "../../Services/FidelityService/FidelityService";
import UserCardService from "../../Services/UserCard/UserCardService";

interface ContextProps {
    kalypayCards: FidelityModel[];
    refreshKalypayCards: () => any;
    userCards: UserCard[];
    refreshUserCards: () => any;
    getActiveCard: () => any;
    getActiveKpCard: () => any;
    selectedCard: UserCard | null;
    setSelectedCard: Dispatch<SetStateAction<UserCard | null>>;
    cardFilter: USER_CARD_STATUS | null;
    setCardFilter: Dispatch<SetStateAction<USER_CARD_STATUS | null>>;
}

const CardContext = createContext<ContextProps>({
    kalypayCards: [],
    refreshKalypayCards: (): any => false,
    userCards: [],
    refreshUserCards: (): any => false,
    getActiveCard: (): UserCard | undefined => undefined,
    getActiveKpCard: (): FidelityModel | undefined => undefined,
    selectedCard: null,
    setSelectedCard: () => {
        return false;
    },
    cardFilter: null,
    setCardFilter: () => {
        return false;
    },
});

export default function CardProvider({
    children,
}: {
    children: React.ReactNode;
}) {
    const [kalypayCards, setKalypayCards] = useState<FidelityModel[]>([]);
    const [userCards, setUserCards] = useState<UserCard[]>([]);
    const [selectedCard, setSelectedCard] = useState<UserCard | null>(null);
    const [cardFilter, setCardFilter] = useState<USER_CARD_STATUS | null>(null);

    const refreshKalypayCards = async () => {
        try {
            const res = await FidelityService.getAll();
            setKalypayCards(res);
        } catch (err: any) {}
    };

    const getUserCard = async () => {
        try {
            const res = await UserCardService.getUserCard();
            setUserCards(res);
            if (res.length > 0) setSelectedCard(res[0]);
        } catch (err: any) {}
    };

    const getActiveCard = () => {
        // if (selectedCard && selectedCard.status === USER_CARD_STATUS.ACTIVE)
        //     return selectedCard;

        const black = userCards.find(
            (card) =>
                card.status === USER_CARD_STATUS.ACTIVE &&
                card.card_type === CARD_TYPE.BLACK,
        );

        if (black) return black;

        const gold = userCards.find(
            (card) =>
                card.status === USER_CARD_STATUS.ACTIVE &&
                card.card_type === CARD_TYPE.GOLD,
        );

        if (gold) return gold;

        const red = userCards.find(
            (card) =>
                card.status === USER_CARD_STATUS.ACTIVE &&
                card.card_type === CARD_TYPE.RED,
        );

        return red;
    };

    const getActiveKpCard = () => {
        const card = getActiveCard();
        const type = card ? card.card_type : CARD_TYPE.RED;
        return kalypayCards.find(
            (card) => card.name.toUpperCase() === type.toUpperCase(),
        );
    };

    const refreshUserCards = async () => {
        getUserCard();
    };

    useEffect(() => {
        refreshKalypayCards();
        getUserCard();
    }, []);

    return (
        <CardContext.Provider
            value={{
                kalypayCards,
                refreshKalypayCards,
                userCards,
                selectedCard,
                setSelectedCard,
                cardFilter,
                setCardFilter,
                refreshUserCards,
                getActiveCard,
                getActiveKpCard,
            }}
        >
            {children}
        </CardContext.Provider>
    );
}

export const useCardContext = () => useContext(CardContext);
