import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import translator from "../../../../Components/Extra/Translation/Translate";
import ProfilProvider, { useProfil } from "../../../Provider/ProfilProvider";
import SaveBouton from "../../../../Components/Extra/Bouton/SaveBouton";
import UserTabs from "./UserTabs";
import { UserType } from "../../../../Models/User";
import { useAppProvider } from "../../../Provider/AppProvider";
import { FlyoutProvider } from "../../../Provider/FlyOutProvider";
import { TransactionDetailProvider } from "../../../Provider/TransactionProvider";

const ProfilForm2 = () => {
    const { translate } = translator();
    const location = useLocation();
    const { user, role } = location.state || {};
    const [isCompany, setIsCompany] = useState<boolean>(false);

    const { setUID, setRole, gettedUser } = useProfil();

    const handleSubmit = (e: any) => {
        e.preventDefault();
    };
    const { finalizeProcessing } = useAppProvider();

    const getIsCompany = () => {
        if (gettedUser) setIsCompany(gettedUser.type === UserType.ENTERPRISE);
    };
    useEffect(() => {
        setUID(user.uid);
        setRole(role);
        finalizeProcessing();
    }, []);

    useEffect(() => {
        getIsCompany();
    }, [gettedUser]);

    return (
        <div>
            <div className="mt-5 grid grid-cols-12 gap-6">
                <div className="intro-y col-span-12 lg:col-span-12">
                    <form onSubmit={(e) => handleSubmit(e)}>
                        <div className="grid grid-cols-12 gap-6">
                            <div className="col-span-12 lg:col-span-12">
                                <div className="intro-y box">
                                    <div className="p-5">
                                        <div className="grid md:grid-cols-2 md:gap-6 gap-3">
                                            {isCompany && (
                                                <div className="relative w-full group w-selects">
                                                    <input
                                                        type="text"
                                                        id="company-name"
                                                        className="border-1 peer block w-full appearance-none rounded-lg bg-transparent px-2.5 pb-2.5 pt-4 text-sm text-gray-900 focus:outline-none focus:ring-0"
                                                        placeholder=" "
                                                        value={String(
                                                            gettedUser?.entreprise ||
                                                                "",
                                                        )}
                                                        required
                                                        disabled
                                                    />
                                                    <label
                                                        htmlFor="company-name"
                                                        className="!peer-focus:text-[#053B3B] absolute top-2 left-1 z-10 origin-[0] -translate-y-4 scale-75 bg-white focus:bg-transparent px-2 text-sm text-gray-500 duration-300 peer-placeholder-shown:top-1/2 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:scale-100 peer-focus:top-2 peer-focus:-translate-y-4 peer-focus:scale-75 peer-focus:px-2"
                                                    >
                                                        {translate(
                                                            "Admin_Users",
                                                            "Nom de la société",
                                                        )}
                                                    </label>
                                                </div>
                                            )}
                                            <div className="relative w-full group w-selects">
                                                <input
                                                    type="text"
                                                    id="id-kalypay"
                                                    className="border-1 peer block w-full appearance-none rounded-lg bg-transparent px-2.5 pb-2.5 pt-4 text-sm text-gray-900 focus:outline-none focus:ring-0"
                                                    placeholder=" "
                                                    value={gettedUser?.uid}
                                                    required
                                                    disabled
                                                />
                                                <label
                                                    htmlFor="id-kalypay"
                                                    className="!peer-focus:text-[#053B3B] absolute top-2 left-1 z-10 origin-[0] -translate-y-4 scale-75 bg-white focus:bg-transparent px-2 text-sm text-gray-500 duration-300 peer-placeholder-shown:top-1/2 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:scale-100 peer-focus:top-2 peer-focus:-translate-y-4 peer-focus:scale-75 peer-focus:px-2"
                                                >
                                                    {translate(
                                                        "Admin_Users",
                                                        "ID Kalypay",
                                                    )}
                                                </label>
                                            </div>
                                            {isCompany && (
                                                <div className="relative z-0 w-full group">
                                                    <input
                                                        type="text"
                                                        id="id-kalypay"
                                                        className="border-1 peer block w-full appearance-none rounded-lg bg-transparent px-2.5 pb-2.5 pt-4 text-sm text-gray-900 focus:outline-none focus:ring-0"
                                                        placeholder=" "
                                                        value={String(
                                                            gettedUser?.last_name ||
                                                                "",
                                                        )}
                                                        required
                                                        disabled
                                                    />
                                                    <label
                                                        htmlFor="gerant-name"
                                                        className="!peer-focus:text-[#053B3B] absolute top-2 left-1 z-10 origin-[0] -translate-y-4 scale-75 bg-white focus:bg-transparent px-2 text-sm text-gray-500 duration-300 peer-placeholder-shown:top-1/2 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:scale-100 peer-focus:top-2 peer-focus:-translate-y-4 peer-focus:scale-75 peer-focus:px-2"
                                                    >
                                                        {translate(
                                                            "Admin_Users",
                                                            "Nom du Gérant",
                                                        )}
                                                    </label>
                                                </div>
                                            )}
                                            <div className="relative z-0 w-full group">
                                                <input
                                                    type="text"
                                                    id="client-number"
                                                    className="border-1 peer block w-full appearance-none rounded-lg bg-transparent px-2.5 pb-2.5 pt-4 text-sm text-gray-900 focus:outline-none focus:ring-0"
                                                    placeholder=" "
                                                    value={0}
                                                    required
                                                    disabled
                                                />
                                                <label
                                                    htmlFor="client-number"
                                                    className="!peer-focus:text-[#053B3B] absolute top-2 left-1 z-10 origin-[0] -translate-y-4 scale-75 bg-white focus:bg-transparent px-2 text-sm text-gray-500 duration-300 peer-placeholder-shown:top-1/2 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:scale-100 peer-focus:top-2 peer-focus:-translate-y-4 peer-focus:scale-75 peer-focus:px-2"
                                                >
                                                    {translate(
                                                        "Admin_Users",
                                                        "Nombre de client",
                                                    )}
                                                </label>
                                            </div>
                                            {isCompany && (
                                                <div className="relative z-0 w-full group">
                                                    <input
                                                        type="text"
                                                        id="gerant-last-name"
                                                        className="border-1 peer block w-full appearance-none rounded-lg bg-transparent px-2.5 pb-2.5 pt-4 text-sm text-gray-900 focus:outline-none focus:ring-0"
                                                        placeholder=" "
                                                        value={String(
                                                            gettedUser?.first_name ||
                                                                "",
                                                        )}
                                                        required
                                                        disabled
                                                    />
                                                    <label
                                                        htmlFor="gerant-last-name"
                                                        className="!peer-focus:text-[#053B3B] absolute top-2 left-1 z-10 origin-[0] -translate-y-4 scale-75 bg-white focus:bg-transparent px-2 text-sm text-gray-500 duration-300 peer-placeholder-shown:top-1/2 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:scale-100 peer-focus:top-2 peer-focus:-translate-y-4 peer-focus:scale-75 peer-focus:px-2"
                                                    >
                                                        {translate(
                                                            "Admin_Users",
                                                            "Prénom du Gérant",
                                                        )}
                                                    </label>
                                                </div>
                                            )}
                                            <div className="relative z-0 w-full group">
                                                <input
                                                    type="text"
                                                    id="phone"
                                                    className="border-1 peer block w-full appearance-none rounded-lg bg-transparent px-2.5 pb-2.5 pt-4 text-sm text-gray-900 focus:outline-none focus:ring-0"
                                                    placeholder=" "
                                                    value={
                                                        gettedUser?.phone || ""
                                                    }
                                                    required
                                                    disabled
                                                />
                                                <label
                                                    htmlFor="phone"
                                                    className="!peer-focus:text-[#053B3B] absolute top-2 left-1 z-10 origin-[0] -translate-y-4 scale-75 bg-white focus:bg-transparent px-2 text-sm text-gray-500 duration-300 peer-placeholder-shown:top-1/2 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:scale-100 peer-focus:top-2 peer-focus:-translate-y-4 peer-focus:scale-75 peer-focus:px-2"
                                                >
                                                    {translate(
                                                        "Admin_Users",
                                                        "Téléphone",
                                                    )}
                                                </label>
                                            </div>
                                            <div className="relative z-0 w-full group">
                                                <input
                                                    type="text"
                                                    id="address"
                                                    className="border-1 peer block w-full appearance-none rounded-lg bg-transparent px-2.5 pb-2.5 pt-4 text-sm text-gray-900 focus:outline-none focus:ring-0"
                                                    placeholder=" "
                                                    value={String(
                                                        gettedUser?.address ||
                                                            "",
                                                    )}
                                                    required
                                                    disabled
                                                />
                                                <label
                                                    htmlFor="address"
                                                    className="!peer-focus:text-[#053B3B] absolute top-2 left-1 z-10 origin-[0] -translate-y-4 scale-75 bg-white focus:bg-transparent px-2 text-sm text-gray-500 duration-300 peer-placeholder-shown:top-1/2 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:scale-100 peer-focus:top-2 peer-focus:-translate-y-4 peer-focus:scale-75 peer-focus:px-2"
                                                >
                                                    {translate(
                                                        "Admin_Users",
                                                        "Addresse",
                                                    )}
                                                </label>
                                            </div>
                                            <div className="relative z-0 w-full group">
                                                <input
                                                    type="text"
                                                    id="session-id"
                                                    className="border-1 peer block w-full appearance-none rounded-lg bg-transparent px-2.5 pb-2.5 pt-4 text-sm text-gray-900 focus:outline-none focus:ring-0"
                                                    placeholder=" "
                                                    value={String(
                                                        gettedUser?.kyc || "",
                                                    )}
                                                    required
                                                    disabled
                                                />
                                                <label
                                                    htmlFor="session-id"
                                                    className="!peer-focus:text-[#053B3B] absolute top-2 left-1 z-10 origin-[0] -translate-y-4 scale-75 bg-white focus:bg-transparent px-2 text-sm text-gray-500 duration-300 peer-placeholder-shown:top-1/2 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:scale-100 peer-focus:top-2 peer-focus:-translate-y-4 peer-focus:scale-75 peer-focus:px-2"
                                                >
                                                    {translate(
                                                        "Admin_Users",
                                                        "KYC / KYB Session ID",
                                                    )}
                                                </label>
                                            </div>
                                            <div className="relative z-0 w-full group hidden">
                                                <SaveBouton
                                                    htmlType="button"
                                                    disabled={true}
                                                >
                                                    {translate(
                                                        "Admin_Users",
                                                        "Reset KYC session",
                                                    )}
                                                </SaveBouton>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <UserTabs />
        </div>
    );
};

const ProfilForm = () => {
    return (
        <ProfilProvider>
            <FlyoutProvider>
                <TransactionDetailProvider>
                    <main className="grow ">
                        <div className="lg:relative lg:flex bg-white dark:bg-slate-900">
                            <div className="px-6 sm:px-6 xl:!px-[80px] w-full  mx-auto">
                                <div className="sm:flex sm:justify-between sm:items-center mb-5"></div>
                                <ProfilForm2></ProfilForm2>
                            </div>
                        </div>
                    </main>
                </TransactionDetailProvider>
            </FlyoutProvider>
        </ProfilProvider>
    );
};

export default ProfilForm;
