import translator from "../../../../Components/Extra/Translation/Translate";

export default function Section3() {
    const { translate } = translator();
    return (
        <div className="max-w-8xl mx-auto px-3 py-10">
            <div
                className="border-t border-b rounded-sm text-center p-6"
                data-aos="zoom-out-down"
            >
                <p className="mb-4 text-sm">
                    {translate("LANDING_DISCOVER", "contactSection_mainText")}
                </p>
                <p className="mb-4 text-sm">
                    {translate("LANDING_DISCOVER", "contactus")}{" "}
                    <span className="text-kalypay-100 font-bold">
                        contact@kalypay.com
                    </span>
                </p>
                <p className="text-sm">
                    {translate(
                        "LANDING_DISCOVER",
                        "contactSection_closingText",
                    )}
                </p>
            </div>
        </div>
    );
}
