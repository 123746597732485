import { useEffect, useMemo, useState } from "react";
import { useFormContext } from "react-hook-form";
import PhoneInput from "react-phone-input-2";
import translator from "../../../../Components/Extra/Translation/Translate";
import { ConfigurationData } from "../../../../Models/Mobile/configuration.data";
import alert_message from "../../../../Services/Alert/AlertMessage";
import WithdrawalService from "../../../../Services/Wallets/Transaction/WithdrawalService";
import KpSelect, { KpSelectOption } from "../../../Components/Inputs/KpSelect";

const AirtimeForm = () => {
    const { watch, setValue } = useFormContext();
    const { translate } = translator();
    const [configuration, setConfiguration] = useState<ConfigurationData>();

    const country = watch("country");
    const operator_selected = watch("operator");

    const [phone, setPhone] = useState<string>("");

    const getConfiguration = async () => {
        try {
            const response = await WithdrawalService.getAirtimeConfiguration();
            setConfiguration(response);
        } catch (error: any) {
            alert_message.error(error);
        }
    };

    const opeartorList = useMemo<KpSelectOption[]>(() => {
        const country_code = country ? country.value.toLowerCase() : "";
        const options: KpSelectOption[] = [];
        setValue("operator", null);
        if (!configuration) return options;
        const country_data = configuration[country_code];

        if (country_data) {
            const operators = country_data.operators;

            const keys = Object.keys(operators);

            for (const key of keys) {
                const operateur = operators[key];

                const config = operateur.airtime;

                if (config.active && config.status) {
                    options.push({
                        // value: config.key,
                        value: key,
                        label: config.name,
                    });
                }
            }
        }

        if (options.length > 0) {
            setValue("operator", options[0]);
        }

        return options;
    }, [country?.value, configuration]);

    useEffect(() => {
        getConfiguration();
    }, []);

    useEffect(() => {
        const country_code = country ? country.value.toLowerCase() : "";
        if (!configuration || !operator_selected) return;
        const country_data = configuration[country_code];

        if (country_data) {
            const operators = country_data.operators;

            const keys = Object.keys(operators);

            const find_operateur = operators[operator_selected.value];
            if (find_operateur) {
                setValue("fee", find_operateur.airtime.fee);
            }
        }
    }, [operator_selected]);
    return (
        <>
            <div className="col-span-12 w-full">
                <KpSelect
                    options={opeartorList}
                    name="operator"
                    label={translate("Deposit", "Operator")}
                    required
                    placeholder={translate("Deposit", "Select_Operator")}
                ></KpSelect>
            </div>
            <div className="col-span-12 w-full">
                <div className="relative w-full mt-3">
                    <PhoneInput
                        specialLabel={"Numero"}
                        country={(country?.value || "").toLowerCase()}
                        excludeCountries={[
                            "af",
                            "ir",
                            "iq",
                            "kp",
                            "ly",
                            "ss",
                            "sd",
                            "sy",
                            "ye",
                        ]}
                        countryCodeEditable={false}
                        enableLongNumbers={15}
                        onChange={(value: any, data: any) => {
                            if (data) {
                                const dialCode = (data as any).dialCode;
                                if (dialCode) {
                                    const number = value
                                        .replace("+", "")
                                        .replace(dialCode, "");

                                    setPhone(number);
                                    setValue("phone", number);
                                }
                            }
                        }}
                    ></PhoneInput>
                    <label
                        htmlFor="select"
                        className="!peer-focus:text-[#053B3B] absolute top-2 left-1  origin-[0] -translate-y-4 scale-75 bg-white focus:bg-transparent px-2 text-sm text-gray-500 duration-300 peer-placeholder-shown:top-1/2 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:scale-100 peer-focus:top-2 peer-focus:-translate-y-4 peer-focus:scale-75 peer-focus:px-2"
                    >
                        {translate("Profil", "Phone_number")}
                    </label>
                </div>

                <input type="hidden" name="phone" value={phone}></input>
            </div>
        </>
    );
};

export default AirtimeForm;
