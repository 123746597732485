import { NavLink } from "react-router-dom";
import translator from "../../../Components/Extra/Translation/Translate";
import useAuthentication from "../../../Services/Authentication/useAuthentication";
import RoutesNames from "../../../Services/RoutesNames";
import Features from "../../images/features-03.png";
import Section01 from "../fintech/section-01";
import Section02 from "../fintech/section-02";
import Section03 from "../fintech/section-03";
import Section04 from "../fintech/section-04";
import Section05 from "../fintech/section-05";
import { Section06 } from "../fintech/section-06";
import Section07 from "../fintech/section-07";
import Section08 from "../fintech/section-08";
import Faqs from "../fintech/faqs";
import Cta from "../fintech/cta";
import Caroussel from "../../../Components/Dashboard/Caroussel";
import Illustration from "../../images/hero-illustration-kp.png";

const Branding = () => {
    const { translate } = translator();
    const { isAuthenticated } = useAuthentication();
    return (
        <>
            {/* Page Title */}
            <section className="relative z-10 bg-black mdg-bg-hero overflow-hidden">
                {/* Bg */}
                <div
                    className="absolute inset-0 rounded-bl-[100px] mb-28 md:mb-0 pointer-events-none -z-10"
                    aria-hidden="true"
                />

                <div className="max-w-[70%] mx-auto px-4 sm:px-6">
                    <div className="pt-36 pb-14">
                        {/* Page Title content */}
                        <div className="relative max-w-xl mx-auto md:max-w-none text-center md:text-left">
                            {/* Content */}
                            <div className="">
                                {/* Copy */}
                                <h1
                                    className="h1 text-white mb-6 text-center"
                                    data-aos="fade-up"
                                    data-aos-delay="100"
                                    dangerouslySetInnerHTML={{
                                        __html: translate("BRANDING", "TITLE"),
                                    }}
                                />
                                <p
                                    className="text-lg text-blue-200 mb-8 text-center"
                                    data-aos="fade-up"
                                    data-aos-delay="200"
                                    dangerouslySetInnerHTML={{
                                        __html: translate(
                                            "BRANDING",
                                            "DESCRIPTION",
                                        ),
                                    }}
                                />
                            </div>

                            {/* Image */}
                            <div className="max-w-sm mx-auto md:max-w-none md:absolute md:left-[90%] md:top-0 -mb-12 md:-mt-12 md:mb-0">
                                <div className="relative md:-ml-3 md:-mr-24 md:mx-0">
                                    <img
                                        className="absolute opacity-50 top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 mt-16 md:mt-0 pointer-events-none -z-10 max-w-none mix-blend-lighten"
                                        src={Illustration}
                                        alt="Hero illustration"
                                        aria-hidden="true"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="md:mt-20" id="transfert" data-aos-id-3>
                <div className="relative max-w-7xl mx-auto">
                    {/* Bg */}
                    <div
                        className="absolute inset-0 md:rounded-tl-[100px] mb-24 md:mb-0 bg-gradient-to-b dark:from-slate-800 from-slate-100 pointer-events-none -z-10"
                        aria-hidden="true"
                    />

                    <div className="max-w-6xl mx-auto px-4 sm:px-6">
                        <div className="pb-6 pt-12 md:pt-20">
                            <div className="mb-12 md:mb-20" data-aos="fade-up">
                                <h2 className="h2 mb-4 text-kalypay-500 text-center">
                                    Charte graphique
                                </h2>
                                <p className="text-lg text-slate-500 mb-4 text-center">
                                    Les ressources graphiques fournis ne doivent
                                    pas être modifiés ou déformés, vous devez
                                    les conserver dans le même format.
                                </p>
                                <p className="text-lg text-slate-500 mb-8 text-center">
                                    Les formats “Web” sont destinés pour les
                                    sites web et les réseaux sociaux. <br />
                                    Les formats “impression” sont uniquement
                                    destinés pour l’impréssions sur tous
                                    supports.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <Section03 />
            <Section04 />
            <Section05 />
            <Section06 />
            <Section07 />
            <Section08 />
            <Faqs /> */}
            <Cta />
        </>
    );
};

export default Branding;
