import translator from "../../../Components/Extra/Translation/Translate";
import useAuthentication from "../../../Services/Authentication/useAuthentication";
import RoutesNames from "../../../Services/RoutesNames";
import { NavLink } from "react-router-dom";

export default function Cta() {
    const { isAuthenticated } = useAuthentication();
    const { translate } = translator();
    return (
        <section className="relative">
            {/* Bg */}
            <div
                className="absolute inset-0 bg-slate-800 -z-10"
                aria-hidden="true"
            />

            <div className="max-w-6xl mx-auto px-4 sm:px-6">
                <div className="py-12 md:py-20">
                    <div className="flex flex-col justify-between items-center">
                        {/* CTA content */}
                        <div className="mb-6  text-center">
                            <p className="text-3xl font-semibold text-[#EEA913] mb-3">
                                {translate("LANDING", "YOUR_WALLET")}{" "}
                                {translate("LANDING", "CRYPTO_FIAT")}
                            </p>
                        </div>

                        {/* Buttons */}
                        <div className="shrink-0">
                            <div className="max-w-xs mx-auto sm:max-w-none sm:flex sm:justify-center md:justify-start space-y-4 sm:space-y-0 sm:space-x-4 mb-12 md:mb-0">
                                <div>
                                    {isAuthenticated ? (
                                        <NavLink
                                            className="btn-sm w-full text-base inline-flex items-center text-black bg-kalypay-500 hover:bg-[#ffd67c] group shadow-sm"
                                            to={RoutesNames.Register}
                                        >
                                            {translate("GENERAL", "MY_ACCOUNT")}
                                            <span className="tracking-normal text-white group-hover:translate-x-0.5 transition-transform duration-150 ease-in-out ml-2">
                                                <svg
                                                    className="fill-current"
                                                    width="12"
                                                    height="10"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path d="M1 6.002h7.586L6.293 8.295a1 1 0 1 0 1.414 1.414l4-4a1 1 0 0 0 0-1.416l-4-4a1 1 0 0 0-1.414 1.416l2.293 2.293H1a1 1 0 1 0 0 2Z" />
                                                </svg>
                                            </span>
                                        </NavLink>
                                    ) : (
                                        <NavLink
                                            className="btn-sm w-full text-base inline-flex items-center text-black bg-kalypay-500 hover:bg-[#ffd67c] group shadow-sm"
                                            to={RoutesNames.Register}
                                        >
                                            {translate("LANDING", "SIGNUP")}
                                            <span className="tracking-normal text-white group-hover:translate-x-0.5 transition-transform duration-150 ease-in-out ml-2">
                                                <svg
                                                    className="fill-current"
                                                    width="12"
                                                    height="10"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path d="M1 6.002h7.586L6.293 8.295a1 1 0 1 0 1.414 1.414l4-4a1 1 0 0 0 0-1.416l-4-4a1 1 0 0 0-1.414 1.416l2.293 2.293H1a1 1 0 1 0 0 2Z" />
                                                </svg>
                                            </span>
                                        </NavLink>
                                    )}
                                </div>
                                <div>
                                    <NavLink
                                        className="btn-sm w-full text-base inline-flex items-center text-white bg-slate-700 hover:bg-slate-800 shadow-sm relative before:absolute before:inset-0 before:bg-blue-400 before:bg-opacity-60 before:-z-10 before:rounded-full"
                                        to={RoutesNames.Contact}
                                    >
                                        {translate("LANDING", "GET_IN_TOUCH")}
                                    </NavLink>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
