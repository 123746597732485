import { useEffect, useState } from "react";
import DataTableFilter from "./filter/DataTableFilter";
import DataTablePagination from "./pagination/DataTablePagination";
import translator from "../../Translation/Translate";
import { FilterColumn } from "../../Filter/Filter";
import { HeaderFilter } from "../../../../Models/User";

export interface DataTableColums {
    index: string;
    title: string;
    content: (data: any) => React.ReactNode;
    filter?: boolean;
    className?: string;
    order?: boolean;
    sort?: () => void;
    headerFilter?: boolean;
}
interface DataTableProps {
    columns: DataTableColums[];
    data: any[];
    pageLength?: number;
    className?: string;
    tableButton?: React.ReactNode;
    handlePaginationChange?: (page: number) => void;
    handlePerRowsChange?: (newPerPage: number) => void;
    onSearchInputChange?: (findText: string) => void;
    onClickRowEvent?: (data: any) => void;
    nbPage: number;
    direction?: "ASC" | "DESC";
    setDirection?: (dir: "ASC" | "DESC") => void;
    filterIndex?: number;
    setFilterIndex?: (x: number) => void;
    searchByDate?: (startDate?: string, endDate?: string) => void;
    exportCSV?: boolean;
    exportUserCSV?: boolean;
    tableHeaderFilter?: HeaderFilter;
    exportPDF?: boolean;
    handleChangeFilter?: (filterConfig: HeaderFilter, index: string) => void;
    // csv?: (csv: string) => void;
    show_filter_mobile?: boolean;
}

const DataTableHeader = ({
    columns,
    direction,
    setDirection,
    filterIndex,
    setFilterIndex,
    tableHeaderFilter,
    handleChangeFilter,
}: {
    columns: DataTableColums[];
    direction?: "ASC" | "DESC";
    setDirection?: (dir: "ASC" | "DESC") => void;
    filterIndex?: number;
    setFilterIndex?: (x: number) => void;
    tableHeaderFilter?: HeaderFilter;
    handleChangeFilter?: (filterConfig: HeaderFilter, index: string) => void;
}) => {
    const { translate } = translator();
    return (
        <thead className="thead-mobil">
            <tr>
                {columns.map((column: DataTableColums, i: number) => {
                    return (
                        <th className={column.className} key={`th-${i}`}>
                            <div className="flex">
                                <span className="mr-2">
                                    {translate(".", column.title)}
                                </span>
                                {column.order && (
                                    <FilterColumn
                                        value={false}
                                        changeValue={(value: boolean) => {
                                            if (
                                                setDirection &&
                                                setFilterIndex
                                            ) {
                                                setDirection(
                                                    value ? "ASC" : "DESC",
                                                );
                                                setFilterIndex(i);
                                            }
                                        }}
                                    />
                                )}
                                {column.headerFilter && (
                                    <FilterColumn
                                        value={
                                            tableHeaderFilter
                                                ? !tableHeaderFilter[
                                                      column.index
                                                  ].is_asc
                                                : false
                                        }
                                        changeValue={(value: boolean) => {
                                            if (
                                                tableHeaderFilter &&
                                                handleChangeFilter
                                            ) {
                                                tableHeaderFilter[
                                                    column.index
                                                ].is_asc = !value;
                                                handleChangeFilter(
                                                    tableHeaderFilter,
                                                    column.index,
                                                );
                                            }
                                        }}
                                    />
                                )}
                            </div>
                        </th>
                    );
                })}
            </tr>
        </thead>
    );
};

const DatatableBody = ({
    data,
    columns,
    onClickRowEvent,
}: {
    data: any;
    columns: DataTableColums[];
    onClickRowEvent?: (data: any) => void;
}) => {
    const { translate } = translator();
    return (
        <tbody className="tbody-mobil">
            {data.length === 0 && (
                <tr>
                    <td colSpan={columns.length} className="text-center">
                        Rien à afficher{" "}
                    </td>
                </tr>
            )}
            {data.map((item: any, i: number) => {
                return (
                    <tr
                        key={`tr-${i}`}
                        className={
                            onClickRowEvent
                                ? "data-tbody cursor-pointer"
                                : "data-tbody"
                        }
                        onClick={(event) => {
                            if (onClickRowEvent) {
                                onClickRowEvent(item);
                            }
                        }}
                    >
                        {columns.map((column, index) => (
                            <td
                                key={`td-${index}`}
                                data-label={translate(".", column.title)}
                            >
                                {" "}
                                {column.content(item)}
                            </td>
                        ))}
                    </tr>
                );
            })}
        </tbody>
    );
};

const DataTableWithPaginatedData = ({
    columns,
    data,
    pageLength,
    className,
    tableButton,
    handlePaginationChange,
    handlePerRowsChange,
    onSearchInputChange,
    nbPage,
    onClickRowEvent,
    direction,
    setDirection,
    filterIndex,
    setFilterIndex,
    // csv,
    searchByDate,
    exportCSV,
    exportUserCSV,
    exportPDF,
    tableHeaderFilter,
    handleChangeFilter,
    show_filter_mobile = false,
}: DataTableProps) => {
    const [perPage, setPerPage] = useState<number>(
        pageLength ? pageLength : 10,
    );

    const [currentPage, setCurrentPage] = useState<number>(0);
    const [currentData, setCurrentData] = useState<any[]>(data);
    const [tempData, setTempData] = useState<any[]>(data);

    useEffect(() => {
        setCurrentData(data);
    }, [data]);

    return (
        <>
            <DataTableFilter
                currentData={currentData}
                setPerPage={setPerPage}
                tableButton={tableButton}
                perPage={perPage}
                handlePerRowsChange={handlePerRowsChange}
                onSearchInputChange={onSearchInputChange}
                search={searchByDate}
                exportCSV={exportCSV}
                exportPDF={exportPDF}
                exportUserCSV={exportUserCSV}
                show_filter_mobile={show_filter_mobile}
            />

            <div className="intro-y col-span-12 overflow-auto lg:overflow-visible">
                <table className={className}>
                    <DataTableHeader
                        columns={columns}
                        direction={direction}
                        setDirection={setDirection}
                        filterIndex={filterIndex}
                        setFilterIndex={setFilterIndex}
                        tableHeaderFilter={tableHeaderFilter}
                        handleChangeFilter={handleChangeFilter}
                    />
                    <DatatableBody
                        data={currentData}
                        columns={columns}
                        onClickRowEvent={onClickRowEvent}
                    />
                </table>
            </div>
            <DataTablePagination
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                handlePaginationChange={handlePaginationChange}
                nbPage={nbPage}
            />
        </>
    );
};

export default DataTableWithPaginatedData;
