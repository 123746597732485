import React from "react";
import useScrollToHash from "../../../Hooks/useScrollToHash ";
import Developper from "./developper/developper";
import Ambassador from "./ambassador/ambassador";
import Head from "./head";

const Discover: React.FC = () => {
    useScrollToHash();

    return (
        <div className="mt-20">
            <Head />
            <Developper />
            <Ambassador />
        </div>
    );
};

export default Discover;
