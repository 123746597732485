import LockSvg from "../../Components/Extra/Svg/LockSvg";
import translator from "../../Components/Extra/Translation/Translate";

const HostInfo = () => {
    const { translate } = translator();

    return (
        <>
            <div className="intro-x flex justify-center text-slate-600">
                {translate("SignIn", "Please_make_official_site")}
            </div>
            <div className="intro-x w-full mb-4 text-sm flex justify-center">
                <span className="flex items-center justify-center to-bg-slate-200 rounded sm:w-[100%] lg:w-[55%] xl:w-[55%] 2xl:w-[55%] font-medium">
                    <LockSvg />
                    <span className="mt-1">
                        <span className="text-[#00AA4D]">https://</span>
                        kalypay.com
                    </span>
                </span>
            </div>
        </>
    );
};

export default HostInfo;
