import QuoteAuthor from "../../images/quote-author-01.jpg";
import { NavLink } from "react-router-dom";
import EpargneIllustration from "../../images/epargne-illustration.png";
import Features from "../../images/revenu.jpg";
import translator from "../../../Components/Extra/Translation/Translate";
import RoutesNames from "../../../Services/RoutesNames";

export default function Section02() {
    const { translate } = translator();
    return (
        <section className="flex justify-center items-center mx-4 lg:!mx-20 my-20">
            <div className="max-w-screen-xl mx-auto w-full ">
                <div className="relative h-[50vh] rounded-[50px] overflow-hidden my-10">
                    <div
                        className="absolute inset-0 brightness-50  bg-cover bg-center "
                        style={{ backgroundImage: `url(${Features})` }}
                    ></div>

                    <div
                        data-aos="zoom-in"
                        className="relative z-10 flex items-center justify-center h-full text-center"
                    >
                        <div className="sm:p-12">
                            <h2 className="text-white bg-clip-text mb-3 font-bold md:!text-5xl text-3xl leading-tight tracking-tighter">
                                {translate("LANDING", "SECTION8_TITLE")}
                            </h2>
                            <div className="md:!text-xl text-base space-y-4 leading-normal text-slate-50 mt-4">
                                <p
                                    className="text-xl text-slate-100 mb-8"
                                    dangerouslySetInnerHTML={{
                                        __html: translate(
                                            "LANDING",
                                            "SECTION8_DESC",
                                        ),
                                    }}
                                />
                                <div
                                    className="max-w-xs mx-auto sm:max-w-none mb-8"
                                    data-aos="fade-up"
                                    data-aos-anchor="[data-aos-id-3]"
                                    data-aos-delay="300"
                                >
                                    <div>
                                        <NavLink
                                            className="btn-sm inline-flex items-center text-base text-black bg-kalypay-500 hover:bg-[#ffd67c] group shadow-sm"
                                            to={RoutesNames.Cards}
                                        >
                                            {translate("LANDING", "GET_CARDS")}
                                            <span className="tracking-normal text-white group-hover:translate-x-0.5 transition-transform duration-150 ease-in-out ml-2">
                                                <svg
                                                    className="fill-current"
                                                    width="12"
                                                    height="10"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path d="M1 6.002h7.586L6.293 8.295a1 1 0 1 0 1.414 1.414l4-4a1 1 0 0 0 0-1.416l-4-4a1 1 0 0 0-1.414 1.416l2.293 2.293H1a1 1 0 1 0 0 2Z" />
                                                </svg>
                                            </span>
                                        </NavLink>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        // <section data-aos-id-2="" id="earning">
        //     <div className="relative max-w-7xl mt-8 mx-auto md:!pb-0 pb-32">
        //         {/* Bg */}
        //         <div
        //             className="absolute inset-0 md:!rounded-xl rounded-none  mb-24 md:mb-0  pointer-events-none -z-10"
        //             aria-hidden="true"
        //         />

        //         <div className="max-w-6xl mx-auto px-4 sm:px-6">
        //             <div className="py-6 md:pt-20">
        //                 {/* Section content */}
        //                 <div className="relative max-w-xl mx-auto md:max-w-none text-center md:text-left flex flex-col md:flex-row items-center justify-end">
        //                     {/* Content */}
        //                     <div className="w-[512px] max-w-full text-right shrink-0 md:order-1">
        //                         {/* Copy */}
        //                         <h2
        //                             className="text-white h2 mb-4"
        //                             data-aos="fade-up"
        //                             data-aos-anchor="[data-aos-id-3]"
        //                             data-aos-delay="100"
        //                         >
        //                             {translate("LANDING", "SECTION8_TITLE")}
        //                         </h2>
        //                         <p
        //                             className="text-lg text-slate-400 mb-8"
        //                             data-aos="fade-up"
        //                             data-aos-anchor="[data-aos-id-3]"
        //                             data-aos-delay="200"
        //                             dangerouslySetInnerHTML={{
        //                                 __html: translate(
        //                                     "LANDING",
        //                                     "SECTION8_DESC",
        //                                 ),
        //                             }}
        //                         />

        //                         {/* Button */}
        //                         <div
        //                             className="max-w-xs mx-auto sm:max-w-none mb-8"
        //                             data-aos="fade-up"
        //                             data-aos-anchor="[data-aos-id-3]"
        //                             data-aos-delay="300"
        //                         >
        //                             <div>
        //                                 <NavLink
        //                                     className="btn-sm inline-flex items-center text-black bg-kalypay-500 hover:bg-[#ffd67c] group shadow-sm"
        //                                     to={RoutesNames.Cards}
        //                                 >
        //                                     {translate("LANDING", "GET_CARDS")}
        //                                     <span className="tracking-normal text-white group-hover:translate-x-0.5 transition-transform duration-150 ease-in-out ml-2">
        //                                         <svg
        //                                             className="fill-current"
        //                                             width="12"
        //                                             height="10"
        //                                             xmlns="http://www.w3.org/2000/svg"
        //                                         >
        //                                             <path d="M1 6.002h7.586L6.293 8.295a1 1 0 1 0 1.414 1.414l4-4a1 1 0 0 0 0-1.416l-4-4a1 1 0 0 0-1.414 1.416l2.293 2.293H1a1 1 0 1 0 0 2Z" />
        //                                         </svg>
        //                                     </span>
        //                                 </NavLink>
        //                             </div>
        //                         </div>

        //                         {/* Quote
        //                         <div
        //                             className="flex max-w-md mx-auto md:mx-0 text-left"
        //                             data-aos="fade-up"
        //                             data-aos-anchor="[data-aos-id-3]"
        //                             data-aos-delay="300"
        //                         >
        //                             <img
        //                                 className="rounded-full self-start shrink-0 mr-3"
        //                                 src={QuoteAuthor}
        //                                 width={32}
        //                                 height={32}
        //                                 alt="Quote author 02"
        //                             />
        //                             <div>
        //                                 <blockquote className="text-slate-400 m-0">
        //                                     “ We know the card market very well
        //                                     and this product provides the speed,
        //                                     flexible account model and API-first
        //                                     approach that no one else can. ”
        //                                 </blockquote>
        //                             </div>
        //                         </div>*/}
        //                     </div>

        //                     {/* img */}
        //                     <div className="w-full max-w-sm md:max-w-none md:mr-8 mt-8 md:mt-0">
        //                         <div className="relative z-10 -mx-8 md:mx-0">
        //                             <img
        //                                 className="absolute top-1/2 left-1/2 -translate-y-1/2 opacity-50 -translate-x-1/2 mt-16 md:mt-0 pointer-events-none -z-10 max-w-full mix-blend-lighten"
        //                                 src={EpargneIllustration}
        //                                 alt="Cards illustration"
        //                                 aria-hidden="true"
        //                             />
        //                             <div
        //                                 data-aos="fade-up"
        //                                 data-aos-anchor="[data-aos-id-2]"
        //                             >
        //                                 <img
        //                                     src={Features}
        //                                     className="md:max-w-full"
        //                                     width={750}
        //                                     alt="Features 08"
        //                                 />
        //                             </div>
        //                         </div>
        //                     </div>
        //                 </div>
        //             </div>
        //         </div>
        //     </div>
        // </section>
    );
}
