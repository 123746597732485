import QuoteAuthor from "../../images/quote-author-02.jpg";
import Features from "../../images/features-03.png";
import DownAndroid from "../../images/download-button-android.png";
import translator from "../../../Components/Extra/Translation/Translate";

export default function Section03() {
    const { translate } = translator();
    return (
        <section className="flex justify-center items-center mx-4 lg:!mx-20 md:!my-20 mt-0 ">
            <div className=" w-full max-w-screen-xl mx-auto">
                <div className="flex flex-col  items-stretch pt-20 md:flex-row justify-center gap-5 sm:gap-4 md:!gap-12 text-left">
                    <div
                        data-aos="fade-up"
                        className="flex flex-col justify-center gap-4 items-start md:items-start w-full md:w-1/2"
                    >
                        <h4 className="text-white bg-clip-text font-bold md:!text-5xl text-3xl leading-tight tracking-tighter">
                            {translate("LANDING", "SECTION3_TITLE")}
                        </h4>
                        <div className="text-slate-200 text-base font-light space-y-8 leading-normal ">
                            <p
                                className="text-lg  mb-8"
                                dangerouslySetInnerHTML={{
                                    __html: translate(
                                        "LANDING",
                                        "SECTION3_DESC",
                                    ),
                                }}
                            />
                            <p
                                className="text-lg  mb-8"
                                dangerouslySetInnerHTML={{
                                    __html: translate(
                                        "LANDING",
                                        "SECTION3_DESC2",
                                    ),
                                }}
                            />
                        </div>
                    </div>
                    <div
                        data-aos="fade-down"
                        className="relative flex justify-center items-center w-full md:w-1/2 md:bg-left"
                    >
                        <img
                            src={Features}
                            alt="hero_img2"
                            className="md:!rounded-[50px] rounded-2xl h-auto object-cover md:!min-h-[400px] w-full"
                        />
                    </div>
                </div>
            </div>
        </section>

        // <section className="mt-12 md:mt-20" id="transfert" data-aos-id-3>
        //     <div className="relative w-full mx-auto">
        //         {/* Bg */}
        //         <div
        //             className="absolute inset-0 mb-24 md:mb-0 bg-gradient-to-b dark:from-slate-800 from-slate-100 pointer-events-none -z-10"
        //             aria-hidden="true"
        //         />

        //         <div className="max-w-6xl mx-auto px-4 sm:px-6">
        //             <div className="pb-6 pt-12 md:pt-20">
        //                 {/* Section content */}
        //                 <div className="relative max-w-xl mx-auto md:max-w-none text-center md:text-left flex flex-col md:flex-row items-center justify-end">
        //                     {/* Content */}
        //                     <div className="w-[512px] max-w-full shrink-0 md:order-1">
        //                         {/* Copy */}
        //                         <h2
        //                             className="h2 mb-4"
        //                             data-aos="fade-up"
        //                             data-aos-anchor="[data-aos-id-3]"
        //                             data-aos-delay="100"
        //                         >
        //                             {translate("LANDING", "SECTION3_TITLE")}
        //                         </h2>
        //                         <p
        //                             className="text-lg text-slate-500 mb-8"
        //                             data-aos="fade-up"
        //                             data-aos-anchor="[data-aos-id-3]"
        //                             data-aos-delay="200"
        //                             dangerouslySetInnerHTML={{
        //                                 __html: translate(
        //                                     "LANDING",
        //                                     "SECTION3_DESC",
        //                                 ),
        //                             }}
        //                         />
        //                         <p
        //                             className="text-lg text-slate-500 mb-8"
        //                             data-aos="fade-up"
        //                             data-aos-anchor="[data-aos-id-3]"
        //                             data-aos-delay="200"
        //                             dangerouslySetInnerHTML={{
        //                                 __html: translate(
        //                                     "LANDING",
        //                                     "SECTION3_DESC2",
        //                                 ),
        //                             }}
        //                         />

        //                         {/* Button */}
        //                         {/* <div
        //                             className="max-w-xs mx-auto sm:max-w-none mb-8"
        //                             data-aos="fade-up"
        //                             data-aos-anchor="[data-aos-id-3]"
        //                             data-aos-delay="300"
        //                         >
        //                             <h5
        //                                 className="font-bold mb-4"
        //                                 data-aos="fade-up"
        //                                 data-aos-anchor="[data-aos-id-3]"
        //                                 data-aos-delay="100"
        //                             >
        //                                 Téléchargez l’application Android
        //                             </h5>
        //                             <div>
        //                                 <a
        //                                     className="inline-flex items-center text-black group"
        //                                     href="#"
        //                                 >
        //                                     <span className="tracking-normal text-white group-hover:translate-x-0.5 transition-transform duration-150 ease-in-out ml-2">
        //                                         <img
        //                                             src={DownAndroid}
        //                                             className="md:max-w-none"
        //                                             width={200}
        //                                             height={200}
        //                                             alt="android"
        //                                             data-aos="fade-up"
        //                                             data-aos-anchor="[data-aos-id-3]"
        //                                         />
        //                                     </span>
        //                                 </a>
        //                             </div>
        //                         </div> */}

        //                         {/* Quote
        //                         <div
        //                             className="flex max-w-md mx-auto md:mx-0 text-left"
        //                             data-aos="fade-up"
        //                             data-aos-anchor="[data-aos-id-3]"
        //                             data-aos-delay="300"
        //                         >
        //                             <img
        //                                 className="rounded-full self-start shrink-0 mr-3"
        //                                 src={QuoteAuthor}
        //                                 width={32}
        //                                 height={32}
        //                                 alt="Quote author 02"
        //                             />
        //                             <div>
        //                                 <blockquote className="text-slate-500 m-0">
        //                                     “ We know the card market very well
        //                                     and this product provides the speed,
        //                                     flexible account model and API-first
        //                                     approach that no one else can. ”
        //                                 </blockquote>
        //                             </div>
        //                         </div>*/}
        //                     </div>

        //                     {/* img */}
        //                     <div className="w-full max-w-sm md:max-w-none md:mr-8 mt-8 md:mt-0">
        //                         <div className="relative mdg-features-03 -mx-8 md:mx-0">
        //                             <img
        //                                 src={Features}
        //                                 className="md:max-w-none"
        //                                 width={500}
        //                                 height={500}
        //                                 alt="Features 03"
        //                                 data-aos="fade-up"
        //                                 data-aos-anchor="[data-aos-id-3]"
        //                             />
        //                         </div>
        //                     </div>
        //                 </div>
        //             </div>
        //         </div>
        //     </div>
        // </section>
    );
}
