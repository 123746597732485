import loadable from "@loadable/component";

import Base from "../Layouts/Account/Base";

import Ecosystem from "../Front/Pages/ecosytem/EcosystemBase";
import Logout from "../Landing/pages/Logout";
import PrivateRouteProvider from "./RouteProvider/PrivateRouteProvider";

const Dashboard = loadable(() => import("../Components/Dashboard/Dashboard"));

const PnlAnalysisDashboard = loadable(
    () => import("../Components/Dashboard/PnlAnalysis/PnlAnalysisDashboard"),
);

const Profile = loadable(() => import("../Components/Profil/Profile"));

const ProfileDetail = loadable(
    () => import("../Components/Profil/TabElements/ProfileDetail"),
);
const ProfileEdit = loadable(
    () => import("../Components/Profil/TabElements/ProfileEdit"),
);
const Affiliation = loadable(
    () => import("../Components/Profil/TabElements/Affiliation"),
);
const ProfileSecurity = loadable(
    () => import("../Components/Profil/TabElements/ProfileSecurity"),
);

const Template = loadable(
    () => import("../Components/TemplateWallet/Template"),
);

const Commande = loadable(() => import("../Components/Order/PackboosterOrder"));

const ListTransactions = loadable(
    () => import("../Components/Transactions/ListTransactions"),
);

const Swap = loadable(() => import("../Components/Wallets/Swap"));

const StackingNew = loadable(
    () => import("../Components/Stacking/StackingNew"),
);

const SwpTransfert = loadable(
    () => import("../Components/Wallets/Transaction/SwpTransfert"),
);

const KycIdentity = loadable(() => import("../Components/Kyc/Kyc"));

const Withdraw = loadable(() => import("../Components/Wallets/Withdraw"));

const Deposit = loadable(() => import("../Components/Wallets/Deposit"));

const Detailsrank = loadable(
    () => import("../Components/Advantage/DetailRank/Detailsrank"),
);

const AffiliationUser = loadable(
    () => import("../Components/Affiliation/Affiliation"),
);

const Evenment = loadable(() => import("../Components/Evenement/Evenment"));
const Marketing = loadable(() => import("../Components/Marketing/Marketing"));
const Visa = loadable(() => import("../Components/Materiel/Visa"));
const ColdWallet = loadable(() => import("../Components/Materiel/ColdWallet"));

const Fidelity = loadable(() => import("../Components/Fidelity/Fidelity"));
const NewWallet = loadable(() => import("../Components/Wallets/NewWallet"));
const Achat = loadable(() => import("../Components/Wallets/Achat"));

const IndexSecurity = loadable(
    () => import("../Components/SettingSecurity/Security/IndexSecurity"),
);
const IndexSetting = loadable(
    () => import("../Components/SettingSecurity/Setting/IndexSetting"),
);
const SettingSecurity = loadable(
    () => import("../Components/SettingSecurity/SettingSecurity"),
);
const NewStaking = loadable(() => import("../Components/Stacking/NewStaking"));

const Earn = loadable(() => import("../Front/Pages/Earn/Earn"));

const Gouvernance = loadable(
    () => import("../Components/Gouvernance/Gouvernance"),
);
const FindAgeng = loadable(() => import("../Components/Agent/FindAgent"));
const PositionSetting = loadable(
    () => import("../Components/SettingSecurity/Position/Position"),
);

const PerfectNodes = loadable(
    () => import("../Front/Pages/ecosytem/Perfectnodes"),
);

const AccountRoutes: any = [
    {
        element: <PrivateRouteProvider />,
        children: [
            {
                path: "logout",
                element: <Logout />,
            },
            {
                element: <Base />,
                path: "/model",
                children: [
                    {
                        path: "dashboard",
                        element: <Dashboard />,
                        children: [],
                    },
                    {
                        path: "pnl-analysis",
                        element: <PnlAnalysisDashboard />,
                    },
                    {
                        path: "settingss",
                        children: [
                            {
                                element: <Profile />,
                                children: [
                                    {
                                        path: "profile",
                                        children: [
                                            {
                                                path: "detail",
                                                element: <ProfileDetail />,
                                            },
                                            {
                                                path: "edit",
                                                element: <ProfileEdit />,
                                            },
                                            {
                                                path: "security",
                                                element: <ProfileSecurity />,
                                            },
                                            {
                                                path: "affiliate",
                                                element: <Affiliation />,
                                            },
                                        ],
                                    },
                                ],
                            },
                            {
                                element: <KycIdentity />,
                                path: "verification-identity",
                                children: [],
                            },
                        ],
                    },
                    {
                        path: "",
                        element: <SettingSecurity />,
                        children: [
                            {
                                path: "settings/general",
                                element: <IndexSetting />,
                            },
                            {
                                path: "settings/security",
                                element: <IndexSecurity />,
                            },
                            {
                                path: "settings/referrals",
                                element: <Affiliation />,
                            },
                            {
                                path: "settings/ecosystem",
                                element: <Evenment />,
                            },
                            {
                                path: "settings/position",
                                element: <PositionSetting />,
                            },
                        ],
                    },
                    {
                        path: "",
                        element: <Ecosystem />,
                        children: [
                            {
                                path: "ecosytem/perfectnodes",
                                element: <PerfectNodes />,
                            },
                        ],
                    },
                    {
                        path: "",
                        children: [
                            {
                                path: "",
                                children: [
                                    {
                                        index: true,
                                        path: "wallets",
                                        element: <NewWallet />, // <Wallets />,
                                    },
                                    {
                                        path: "exchange/:origin",
                                        element: <Swap />,
                                    },
                                    {
                                        path: "exchange/",
                                        element: <Swap />,
                                    },
                                    {
                                        path: "deposit/:origin",
                                        element: <Deposit />,
                                    },
                                    {
                                        path: "deposit/",
                                        element: <Deposit />,
                                    },
                                    {
                                        path: "buy/:origin",
                                        element: <Achat />,
                                    },
                                    {
                                        path: "buy/",
                                        element: <Achat />,
                                    },
                                    {
                                        path: "withdraw/:origin",
                                        element: <Withdraw />,
                                    },
                                    {
                                        path: "withdraw/",
                                        element: <Withdraw />,
                                    },
                                    {
                                        path: "swap-transaction",
                                        element: <SwpTransfert />,
                                    },
                                ],
                            },
                            {
                                path: "transactions",
                                element: <ListTransactions />,
                            },
                        ],
                    },
                    {
                        path: "earning",
                        children: [
                            {
                                index: true,
                                path: "",
                                element: <Earn />,
                            },
                            {
                                path: "new",
                                element: <StackingNew />,
                            },
                        ],
                    },
                    {
                        path: "loyalty",
                        element: <Fidelity />,
                    },
                    {
                        path: "wallets-v2",
                        element: <Template />,
                    },
                    {
                        path: "orders",
                        element: <Commande />,
                    },
                    {
                        path: "services",
                        element: <NewStaking />,
                    },
                    {
                        path: "governances",
                        element: <Gouvernance />,
                    },

                    {
                        path: "find-agent",
                        element: <FindAgeng />,
                    },
                    {
                        path: "earn",
                        element: <Earn />,
                    },
                    {
                        path: "affiliation",
                        element: <AffiliationUser />,
                    },
                    {
                        path: "marketing",
                        element: <Marketing />,
                    },
                    {
                        path: "ecosystem",
                        element: <Evenment />,
                    },
                    // {
                    //     path: "support",
                    //     element: <Support />,
                    // },
                    {
                        path: "wallet-online",
                        children: [
                            {
                                index: true,
                                path: "",
                                element: <NewWallet />,
                            },
                            {
                                path: "swap/:origin",
                                element: <Swap />,
                            },
                            {
                                path: "deposit/:origin",
                                element: <Deposit />,
                            },
                            {
                                path: "achat/:origin",
                                element: <Achat />,
                            },
                            {
                                path: "withdraw/:origin",
                                element: <Withdraw />,
                            },
                            {
                                path: "swap-transaction",
                                element: <SwpTransfert />,
                            },
                        ],
                    },
                    {
                        path: "material",
                        children: [
                            {
                                path: "cold-wallet",
                                element: <ColdWallet />,
                            },
                            {
                                path: "visa",
                                element: <Visa />,
                            },
                        ],
                    },
                ],
            },
        ],
    },
];

export default AccountRoutes;
