import fm from "front-matter";
interface TopicInfo {
    name: string;
    slug: string;
    lang: string;
}

export interface Section {
    content: string | string[];
    type: "title" | "paragraph" | "list";
}

interface MdxData {
    title: string;
    summary: string;
    topic: TopicInfo;
}

export interface SupportPost {
    data: MdxData;
    body: string;
}

const extractTitleTopicAndBody = (content: string): SupportPost => {
    const parsedContent = fm(content);
    const data: MdxData = parsedContent.attributes as MdxData;
    const body: string = parsedContent.body;
    return { data, body };
};

const splitBody = (content: string): any => {
    const sections: Section[] = content
        .split(/(### [^\n]+)|(\n\n+)/)
        .filter((section) => section && section.trim() !== "")
        .map((section) => {
            if (section.startsWith("###")) {
                const content = section.replace("### ", "");
                if (content.startsWith("##list##")) {
                    const lines = section
                        .replace("### ##list## ", "")
                        .split("#n#");
                    return {
                        content: lines,
                        type: "list",
                    };
                }
                return {
                    content: section.replace("### ", ""),
                    type: "title",
                };
            } else {
                return {
                    content: section,
                    type: "paragraph",
                };
            }
        });

    return sections;
};

export { extractTitleTopicAndBody, splitBody };
