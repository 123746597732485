import { ChevronDown } from "lucide-react";
import React from "react";
import { NavLink, useLocation } from "react-router-dom";

export type MenuItemProps = {
    path: string;
    label: string;
    children?: MenuItemProps[];
};

export default function MenuItem({ path, label, children }: MenuItemProps) {
    const location = useLocation();
    const hasChild = children && children.length > 0;

    const isActive =
        location.pathname === path ||
        (hasChild &&
            children.some((child) => location.pathname === child.path));

    return (
        <div className="group w-full relative">
            <NavLink
                to={path}
                className={`space-x-2 p-3 hover:text-black hover:bg-white rounded-xl flex justify-center items-center text-nowrap transition-all duration-300 ${
                    isActive
                        ? "font-semibold underline-offset-[10px] decoration-[4px]"
                        : ""
                }`}
            >
                <span>{label}</span>
                {hasChild && (
                    <div
                        className={`transition-transform duration-300 ${
                            isActive ? "text-clisha-500" : ""
                        } group-hover:rotate-180`}
                    >
                        <ChevronDown size={16} />
                    </div>
                )}
            </NavLink>

            {hasChild && (
                <ul className="hidden group-hover:flex flex-col md:!items-start items-center justify-center md:absolute md:!bg-black p-5 rounded-lg space-y-1">
                    {children.map((child, index) => (
                        <li key={`child-item-${index}`}>
                            <a
                                href={child.path}
                                target="_blank"
                                className="hover:text-kalypay-100 px-4 text-left py-2 space-x-2 flex justify-center items-center"
                            >
                                {child.label}
                            </a>
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
}
