import { useEffect } from "react";
import AppAuthentication from "../../Services/Authentication/AppAuthentication";

const Logout = () => {
    const { logoutAsync } = AppAuthentication();
    useEffect(() => {
        logoutAsync();
    }, []);

    return <></>;
};

export default Logout;
