import { UserCryptoWallet } from "./UserWallet";

// export default class Transaction {
//     id: number;
//     description: string;
//     amount: number;
//     status: string;
//     date: string;
//     type!: WalletHistoryType;
//     user_wallet!: UserCryptoWallet;
//     user?: any;
//     fee?: number;
//     target?: UserCryptoWallet;
//     date_fr?: string;
//     constructor(
//         id: number,
//         description: string,
//         amount: number,
//         status: string,
//         date: string,
//         user: any,
//     ) {
//         this.description = description;
//         this.amount = amount;
//         this.status = status;
//         this.date = date;
//         this.user = user;
//         this.id = id;
//     }
// }
export enum CBPaymentStatus {
    INITIATED = "INITIATED",
    PENDING = "PENDING",
    IN_PROCESS = "IN_PROCESS",
    COMPLETED_V1 = "COMPLETED_V1",
    COMPLETED = "COMPLETED",
    CANCELED = "CANCELED",
}
export default class VisaTransaction {
    id: number;
    amount: number;
    amount_usd?: number;
    fee_usd?: number;
    fee?: number;
    total_amount?: number;
    reference?: string;
    status!: CBPaymentStatus;
    walletHistory: any;
    currency?: string;
    created_at?: string;
    constructor(
        id: number,
        amount: number,
        reference: string,
        fee: number,
        date: string,
    ) {
        this.id = id;
        this.amount = amount;
        this.created_at = date;
        this.fee = fee;
        this.reference = reference;
    }
}
