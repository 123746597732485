import { NavLink } from "react-router-dom";
import translator from "../../../Components/Extra/Translation/Translate";
import Features from "../../images/features-04.png";
import RoutesNames from "../../../Services/RoutesNames";

export default function Section04() {
    const { translate } = translator();
    return (
        <section className="mt-6 lg:!mx-20" data-aos-id-4>
            <div className="relative max-w-screen-xl mx-auto">
                {/* Bg */}
                <div
                    className="absolute inset-0 mb-24 md:mb-0 b pointer-events-none -z-10"
                    aria-hidden="true"
                />

                <div className="max-w-7xl mx-auto px-4 sm:!px-0">
                    <div className="py-12 md:py-20">
                        {/* Section content */}
                        <div className="relative max-w-xl mx-auto md:max-w-none text-center md:!text-left flex flex-col md:flex-row items-center">
                            {/* Content */}

                            <div className="relative w-full md:!w-1/2 md:mx-0">
                                <img
                                    src={Features}
                                    className="w-full mr-auto md:!-ml-16"
                                    alt="Features 04"
                                    data-aos="fade-up"
                                    data-aos-anchor="[data-aos-id-4]"
                                />
                            </div>

                            <div className="md:!w-1/2 w-full space-y-2 max-w-full shrink-0">
                                {/* Copy */}
                                <h2
                                    className="text-white bg-clip-text font-bold md:!text-5xl text-3xl leading-tight tracking-tighter"
                                    data-aos="fade-up"
                                    data-aos-anchor="[data-aos-id-4]"
                                    data-aos-delay="100"
                                >
                                    {translate("LANDING", "SECTION4_TITLE")}
                                </h2>
                                <p
                                    className="text-lg text-slate-200 mb-6"
                                    data-aos="fade-up"
                                    data-aos-anchor="[data-aos-id-4]"
                                    data-aos-delay="200"
                                >
                                    {translate("LANDING", "SECTION4_DESC")}
                                </p>

                                {/* Lists */}
                                <div
                                    className="grid md:!grid-cols-2 grid-cols-1 w-full text-left gap-4 justify-between mb-8 space-y-8 sm:space-y-0"
                                    data-aos="fade-up"
                                    data-aos-anchor="[data-aos-id-4]"
                                    data-aos-delay="300"
                                >
                                    {/* Column #1 */}
                                    <div>
                                        <h5 className="font-bold text-kalypay-200 text-lg mb-5">
                                            {translate(
                                                "LANDING",
                                                "PHYSICAL_STORE",
                                            )}
                                        </h5>
                                        <div className="bg-slate-800 w-full p-5 rounded-2xl ">
                                            <ul className="inline-flex flex-col text-slate-200 space-y-2.5 ">
                                                <li className="flex items-center">
                                                    <svg
                                                        className="shrink-0 mr-3"
                                                        width="20"
                                                        height="20"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <circle
                                                            className="fill-blue-100"
                                                            cx="10"
                                                            cy="10"
                                                            r="10"
                                                        />
                                                        <path
                                                            className="fill-kalypay-400"
                                                            d="M15.335 7.933 14.87 7c-4.025 1.167-6.067 3.733-6.067 3.733l-1.867-1.4-.933.934L8.802 14c2.158-4.025 6.533-6.067 6.533-6.067Z"
                                                        />
                                                    </svg>
                                                    <span>Socose MALL</span>
                                                </li>
                                                <li className="flex items-center">
                                                    <svg
                                                        className="shrink-0 mr-3"
                                                        width="20"
                                                        height="20"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <circle
                                                            className="fill-blue-100"
                                                            cx="10"
                                                            cy="10"
                                                            r="10"
                                                        />
                                                        <path
                                                            className="fill-kalypay-400"
                                                            d="M15.335 7.933 14.87 7c-4.025 1.167-6.067 3.733-6.067 3.733l-1.867-1.4-.933.934L8.802 14c2.158-4.025 6.533-6.067 6.533-6.067Z"
                                                        />
                                                    </svg>
                                                    <span>Prima</span>
                                                </li>
                                                <li className="flex items-center">
                                                    <svg
                                                        className="shrink-0 mr-3"
                                                        width="20"
                                                        height="20"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <circle
                                                            className="fill-blue-100"
                                                            cx="10"
                                                            cy="10"
                                                            r="10"
                                                        />
                                                        <path
                                                            className="fill-kalypay-400"
                                                            d="M15.335 7.933 14.87 7c-4.025 1.167-6.067 3.733-6.067 3.733l-1.867-1.4-.933.934L8.802 14c2.158-4.025 6.533-6.067 6.533-6.067Z"
                                                        />
                                                    </svg>
                                                    <span>MALL Group</span>
                                                </li>
                                                <li className="flex items-center">
                                                    <svg
                                                        className="shrink-0 mr-3"
                                                        width="20"
                                                        height="20"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <circle
                                                            className="fill-blue-100"
                                                            cx="10"
                                                            cy="10"
                                                            r="10"
                                                        />
                                                        <path
                                                            className="fill-kalypay-400"
                                                            d="M15.335 7.933 14.87 7c-4.025 1.167-6.067 3.733-6.067 3.733l-1.867-1.4-.933.934L8.802 14c2.158-4.025 6.533-6.067 6.533-6.067Z"
                                                        />
                                                    </svg>
                                                    <span>Super U</span>
                                                </li>
                                                <li className="flex items-center">
                                                    <svg
                                                        className="shrink-0 mr-3"
                                                        width="20"
                                                        height="20"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <circle
                                                            className="fill-blue-100"
                                                            cx="10"
                                                            cy="10"
                                                            r="10"
                                                        />
                                                        <path
                                                            className="fill-kalypay-400"
                                                            d="M15.335 7.933 14.87 7c-4.025 1.167-6.067 3.733-6.067 3.733l-1.867-1.4-.933.934L8.802 14c2.158-4.025 6.533-6.067 6.533-6.067Z"
                                                        />
                                                    </svg>
                                                    <span>Coccinelle</span>
                                                </li>
                                                <li className="flex items-center">
                                                    <svg
                                                        className="shrink-0 mr-3"
                                                        width="20"
                                                        height="20"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <circle
                                                            className="fill-blue-100"
                                                            cx="10"
                                                            cy="10"
                                                            r="10"
                                                        />
                                                        <path
                                                            className="fill-kalypay-400"
                                                            d="M15.335 7.933 14.87 7c-4.025 1.167-6.067 3.733-6.067 3.733l-1.867-1.4-.933.934L8.802 14c2.158-4.025 6.533-6.067 6.533-6.067Z"
                                                        />
                                                    </svg>
                                                    <span>Auchan</span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    {/* Column #2 */}
                                    <div>
                                        <h5 className="font-bold text-kalypay-200 text-lg mb-5">
                                            {translate(
                                                "LANDING",
                                                "ONLINE_STORE",
                                            )}
                                        </h5>
                                        <div className="bg-slate-800 w-full p-5 rounded-2xl ">
                                            {" "}
                                            <ul className="inline-flex flex-col text-slate-200 space-y-2.5">
                                                <li className="flex items-center">
                                                    <svg
                                                        className="shrink-0 mr-3"
                                                        width="20"
                                                        height="20"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <circle
                                                            className="fill-blue-100"
                                                            cx="10"
                                                            cy="10"
                                                            r="10"
                                                        />
                                                        <path
                                                            className="fill-kalypay-400"
                                                            d="M15.335 7.933 14.87 7c-4.025 1.167-6.067 3.733-6.067 3.733l-1.867-1.4-.933.934L8.802 14c2.158-4.025 6.533-6.067 6.533-6.067Z"
                                                        />
                                                    </svg>
                                                    <span>Amazon Prime</span>
                                                </li>
                                                <li className="flex items-center">
                                                    <svg
                                                        className="shrink-0 mr-3"
                                                        width="20"
                                                        height="20"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <circle
                                                            className="fill-blue-100"
                                                            cx="10"
                                                            cy="10"
                                                            r="10"
                                                        />
                                                        <path
                                                            className="fill-kalypay-400"
                                                            d="M15.335 7.933 14.87 7c-4.025 1.167-6.067 3.733-6.067 3.733l-1.867-1.4-.933.934L8.802 14c2.158-4.025 6.533-6.067 6.533-6.067Z"
                                                        />
                                                    </svg>
                                                    <span>Apple</span>
                                                </li>
                                                <li className="flex items-center">
                                                    <svg
                                                        className="shrink-0 mr-3"
                                                        width="20"
                                                        height="20"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <circle
                                                            className="fill-blue-100"
                                                            cx="10"
                                                            cy="10"
                                                            r="10"
                                                        />
                                                        <path
                                                            className="fill-kalypay-400"
                                                            d="M15.335 7.933 14.87 7c-4.025 1.167-6.067 3.733-6.067 3.733l-1.867-1.4-.933.934L8.802 14c2.158-4.025 6.533-6.067 6.533-6.067Z"
                                                        />
                                                    </svg>
                                                    <span>Booking.com</span>
                                                </li>
                                                <li className="flex items-center">
                                                    <svg
                                                        className="shrink-0 mr-3"
                                                        width="20"
                                                        height="20"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <circle
                                                            className="fill-blue-100"
                                                            cx="10"
                                                            cy="10"
                                                            r="10"
                                                        />
                                                        <path
                                                            className="fill-kalypay-400"
                                                            d="M15.335 7.933 14.87 7c-4.025 1.167-6.067 3.733-6.067 3.733l-1.867-1.4-.933.934L8.802 14c2.158-4.025 6.533-6.067 6.533-6.067Z"
                                                        />
                                                    </svg>
                                                    <span>Netflix</span>
                                                </li>
                                                <li className="flex items-center">
                                                    <svg
                                                        className="shrink-0 mr-3"
                                                        width="20"
                                                        height="20"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <circle
                                                            className="fill-blue-100"
                                                            cx="10"
                                                            cy="10"
                                                            r="10"
                                                        />
                                                        <path
                                                            className="fill-kalypay-400"
                                                            d="M15.335 7.933 14.87 7c-4.025 1.167-6.067 3.733-6.067 3.733l-1.867-1.4-.933.934L8.802 14c2.158-4.025 6.533-6.067 6.533-6.067Z"
                                                        />
                                                    </svg>
                                                    <span>Spotify</span>
                                                </li>
                                                <li className="flex items-center">
                                                    <svg
                                                        className="shrink-0 mr-3"
                                                        width="20"
                                                        height="20"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <circle
                                                            className="fill-blue-100"
                                                            cx="10"
                                                            cy="10"
                                                            r="10"
                                                        />
                                                        <path
                                                            className="fill-kalypay-400"
                                                            d="M15.335 7.933 14.87 7c-4.025 1.167-6.067 3.733-6.067 3.733l-1.867-1.4-.933.934L8.802 14c2.158-4.025 6.533-6.067 6.533-6.067Z"
                                                        />
                                                    </svg>
                                                    <span>Uber</span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                                {/* Button */}
                                <div
                                    className="max-w-xs mx-auto sm:max-w-none"
                                    data-aos="fade-up"
                                    data-aos-anchor="[data-aos-id-4]"
                                    data-aos-delay="300"
                                >
                                    <div>
                                        <NavLink
                                            className="btn-sm mt-5 inline-flex items-center text-base text-black bg-kalypay-500 hover:bg-[#ffd67c] group shadow-sm"
                                            to={RoutesNames.Cards}
                                        >
                                            {translate("LANDING", "GET_CARDS")}
                                            <span className="tracking-normal text-white group-hover:translate-x-0.5 transition-transform duration-150 ease-in-out ml-2">
                                                <svg
                                                    className="fill-current"
                                                    width="12"
                                                    height="10"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path d="M1 6.002h7.586L6.293 8.295a1 1 0 1 0 1.414 1.414l4-4a1 1 0 0 0 0-1.416l-4-4a1 1 0 0 0-1.414 1.416l2.293 2.293H1a1 1 0 1 0 0 2Z" />
                                                </svg>
                                            </span>
                                        </NavLink>
                                    </div>
                                </div>
                            </div>

                            {/* img */}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
