import React from "react";
import bggateway from "../../images/invest/gateway.png";

export type contentInfo = {
    title: string;
    description: string;
};

export type InvestComponentProps = {
    title: string;
    intro: string[];
    howItWorksTitle: string;
    howItWorksSteps: contentInfo[];
    advantagesTitle: string;
    advantages: contentInfo[];
    startProcessTitle: string;
    startProcessSteps: contentInfo[];
    id: string;
    backgroundImage: string;
    image: string;
};

export default function InvestComponent({
    title,
    intro,
    howItWorksTitle,
    howItWorksSteps,
    advantagesTitle,
    advantages,
    startProcessTitle,
    startProcessSteps,
    id,
    backgroundImage,
    image,
}: InvestComponentProps) {
    return (
        <div className="min-h-screen text-white" id={id}>
            <div className="max-w-8xl mx-auto px-4 py-4">
                <h1 className="text-5xl font-extrabold text-center mb-4 text-kalypay-100">
                    {title}
                </h1>
                <div className="p-5 mb-7">
                    {intro.map((text, index) => (
                        <p key={index} className="text-center text-xl">
                            {text}
                        </p>
                    ))}
                </div>

                <section
                    className="mb-28 rounded-3xl py-20 px-8 shadow-lg bg-cover bg-center"
                    style={{ backgroundImage: `url(${backgroundImage})` }}
                >
                    <h2 className="text-4xl font-semibold mb-12 text-center">
                        {howItWorksTitle}
                    </h2>
                    <div
                        className="grid gap-12 md:grid-cols-2 lg:grid-cols-3"
                        data-aos="zoom-out"
                    >
                        {howItWorksSteps.map((step, index) => (
                            <div
                                key={index}
                                className="border border-white rounded-2xl bg-slate-800  p-7"
                            >
                                <div className="bg-kalypay-100 rounded-full w-12 h-12 flex items-center justify-center font-bold mb-6 mx-auto">
                                    {index + 1}
                                </div>
                                <h3 className="text-2xl text-center font-semibold mb-6 ">
                                    {step.title}
                                </h3>
                                <p className="text-center">
                                    {step.description}
                                </p>
                            </div>
                        ))}
                    </div>
                </section>

                <section className="mb-28 px-4 md:px-6 mx-auto">
                    <div className="flex flex-col md:flex-row items-start md:items-center gap-8 md:gap-12">
                        <div className="md:w-1/3">
                            <h2 className="text-3xl md:text-4xl font-semibold mb-4 ">
                                {advantagesTitle}
                            </h2>
                            <p>
                                Chez KALYPAY, nous combinons technologie de
                                pointe et sécurité renforcée pour protéger vos
                                investissements. Notre application, à la fois
                                simple et intuitive, vous offre un accès
                                sécurisé à des solutions performantes, adaptées
                                aussi bien aux utilisateurs novices qu'aux
                                experts.
                            </p>
                        </div>
                        <div className="md:w-2/3">
                            <ul className="grid gap-6">
                                {advantages.map((item, index) => (
                                    <li
                                        key={index}
                                        className="flex items-start border-2 border-white rounded-xl p-6 shadow-sm"
                                        data-aos="zoom-in"
                                    >
                                        <div className="bg-kalypay-100 border-white rounded-full w-10 h-10 flex items-center justify-center mr-4 flex-shrink-0">
                                            {index + 1}
                                        </div>
                                        <div>
                                            <h3 className="font-semibold text-lg mb-2">
                                                {item.title}
                                            </h3>
                                            <p>{item.description}</p>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </section>

                <section className="mb-28 px-4 md:px-6 mx-auto">
                    <h2 className="text-4xl font-semibold mb-12 text-center ">
                        {startProcessTitle}
                    </h2>
                    <div className="flex flex-col md:flex-row justify-between">
                        <div
                            className="md:w-1/2 flex justify-center items-center"
                            style={{
                                backgroundImage: `url(${bggateway})`,
                                backgroundSize: "cover",
                            }}
                            data-aos="fade-up"
                        >
                            <img
                                src={image}
                                alt="image invest"
                                className="w-1/2 h-full md:-ml-20 object-cover"
                            />
                        </div>

                        <div className="md:w-1/2 flex flex-col justify-center space-y-6">
                            {startProcessSteps.map((step, index) => (
                                <div
                                    key={index}
                                    className="flex p-6"
                                    data-aos="fade-down"
                                >
                                    <div className="bg-kalypay-100  rounded-full w-10 h-10 flex items-center justify-center mr-4 flex-shrink-0 font-bold text-lg">
                                        {index + 1}
                                    </div>
                                    <div>
                                        <h3 className="text-xl font-semibold mb-2">
                                            {step.title}
                                        </h3>
                                        <p className="text-sm">
                                            {step.description}
                                        </p>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </section>
            </div>
        </div>
    );
}
