import { ChartSpline, HandCoins, Settings, ShieldCheck } from "lucide-react";
import translator from "../../../../Components/Extra/Translation/Translate";
import team from "../../../images/team-image.webp";
import support from "../../../images/support.jpg";

export default function Section1() {
    const { translate } = translator();
    return (
        <section
            className="flex justify-center items-center lg:mx-20 my-20"
            id="iban"
        >
            <div className="max-w-screen-xl mx-auto w-full ">
                <div
                    data-aos="zoom-in-up"
                    className="relative h-[75vh] md:!rounded-[85px] overflow-hidden md:!my-10"
                >
                    <div
                        className="absolute inset-0 brightness-50 backdrop-blur-[4px] bg-cover bg-center "
                        style={{ backgroundImage: `url(${team})` }}
                    ></div>

                    <div className="relative z-10 flex items-end justify-center h-full text-center">
                        <div className="sm:p-12 p-4">
                            <button
                                type="button"
                                className="bg-transparent border-2 dark:border-white border-white my-5 transition-all text-white font-bold text-sm rounded-md px-6 py-2.5"
                            >
                                IBAN
                            </button>
                            <h2 className="text-white lg:!text-4xl text-3xl font-semibold lg:!leading-[56px]">
                                {translate("LANDING_IBAN", "section1_titre2")}
                            </h2>
                            <p className="text-slate-100 mt-6 text-base leading-relaxed">
                                {translate("LANDING_IBAN", "section1_titre2_p")}
                            </p>
                        </div>
                    </div>
                </div>

                <div data-aos="fade-up" className="text-left">
                    <div className="grid  md:!grid-cols-2 gap-6 my-12 lg:!mx-0 mx-4">
                        <div
                            data-aos="flip-down"
                            className="bg-slate-700 p-6 rounded-xl"
                        >
                            <span className="bg-white rounded-lg p-2 w-fit mb-4 flex items-center justify-center">
                                <Settings color="#D88E12" size={40} />
                            </span>

                            <h3 className="text-xl text-gray-300 font-bold mb-2 ">
                                {translate(
                                    "LANDING_IBAN",
                                    "section1_card1_titre",
                                )}
                            </h3>
                            <p className="text-base text-slate-300">
                                {translate("LANDING_IBAN", "section1_card1_p")}
                            </p>
                        </div>
                        <div
                            data-aos="flip-down"
                            className="bg-slate-800 p-6 rounded-xl"
                        >
                            <span className="bg-white rounded-lg p-2 w-fit mb-4 flex items-center justify-center">
                                <HandCoins color="#D88E12" size={40} />
                            </span>

                            <h3 className="text-xl text-gray-300 font-bold mb-2 ">
                                {translate(
                                    "LANDING_IBAN",
                                    "section1_card2_titre",
                                )}
                            </h3>
                            <p className="text-base text-slate-300 ">
                                {translate("LANDING_IBAN", "section1_card2_p")}
                            </p>
                        </div>
                        <div
                            data-aos="flip-down"
                            className="bg-slate-800 p-6 rounded-xl"
                        >
                            <span className="bg-white rounded-lg p-2 w-fit mb-4 flex items-center justify-center">
                                <ChartSpline color="#D88E12" size={40} />
                            </span>
                            <h3 className="text-xl text-gray-300 font-bold mb-2 ">
                                {translate(
                                    "LANDING_IBAN",
                                    "section1_card3_titre",
                                )}
                            </h3>
                            <p className="text-base text-slate-300 ">
                                {translate("LANDING_IBAN", "section1_card3_p")}
                            </p>
                        </div>
                        <div
                            data-aos="flip-down"
                            className="bg-slate-700 p-6 rounded-xl"
                        >
                            <span className="bg-white rounded-lg p-2 w-fit mb-4 flex items-center justify-center">
                                <ShieldCheck color="#D88E12" size={40} />
                            </span>
                            <h3 className="text-xl text-gray-300 font-bold mb-2 ">
                                {translate(
                                    "LANDING_IBAN",
                                    "section1_card4_titre",
                                )}
                            </h3>
                            <p className="text-base text-slate-300 ">
                                {translate("LANDING_IBAN", "section1_card4_p")}
                            </p>
                        </div>
                    </div>
                </div>

                {/* <div className="grid lg:grid-cols-4 md:grid-cols-2 gap-6 my-12 lg:!mx-0 mx-4">
                    <div
                        data-aos="flip-down"
                        className="bg-gradient-to-b from-yellow-200 to-blue-50 p-6 rounded-xl"
                    >
                        <span className="bg-white rounded-lg p-2 w-fit mb-4 flex items-center justify-center">
                            <Settings color="#D88E12" size={25} />
                        </span>

                        <h3 className="text-lg text-gray-300 font-bold mb-2 ">
                            {translate("LANDING_IBAN", "section1_card1_titre")}
                        </h3>
                        <p className="text-sm text-slate-300">
                            {translate("LANDING_IBAN", "section1_card1_p")}
                        </p>
                    </div>
                    <div
                        data-aos="flip-down"
                        className="bg-gradient-to-b from-yellow-200 to-blue-50 p-6 rounded-xl"
                    >
                        <span className="bg-white rounded-lg p-2 w-fit mb-4 flex items-center justify-center">
                            <HandCoins color="#D88E12" size={25} />
                        </span>

                        <h3 className="text-lg text-gray-300 font-bold mb-2 ">
                            {translate("LANDING_IBAN", "section1_card2_titre")}
                        </h3>
                        <p className="text-sm text-slate-300 ">
                            {translate("LANDING_IBAN", "section1_card2_p")}
                        </p>
                    </div>
                    <div
                        data-aos="flip-down"
                        className="bg-gradient-to-b from-yellow-200 to-blue-50 p-6 rounded-xl"
                    >
                        <span className="bg-white rounded-lg p-2 w-fit mb-4 flex items-center justify-center">
                            <ChartSpline color="#D88E12" size={25} />
                        </span>
                        <h3 className="text-lg text-gray-300 font-bold mb-2 ">
                            {translate("LANDING_IBAN", "section1_card3_titre")}
                        </h3>
                        <p className="text-sm text-slate-300 ">
                            {translate("LANDING_IBAN", "section1_card3_p")}
                        </p>
                    </div>
                    <div
                        data-aos="flip-down"
                        className="bg-gradient-to-b from-slate-800 to-slate-500 p-6 rounded-xl"
                    >
                        <span className="bg-white rounded-lg p-2 w-fit mb-4 flex items-center justify-center">
                            <ShieldCheck color="#D88E12" size={25} />
                        </span>
                        <h3 className="text-lg text-gray-300 font-bold mb-2 ">
                            {translate("LANDING_IBAN", "section1_card4_titre")}
                        </h3>
                        <p className="text-sm text-slate-300 ">
                            {translate("LANDING_IBAN", "section1_card4_p")}
                        </p>
                    </div>
                </div> */}
            </div>
        </section>
    );
}
