import { useEffect, useState } from "react";
import { NavLink, Outlet } from "react-router-dom";
import translator from "../../../Components/Extra/Translation/Translate";
import Ecosystem from "../../../Models/Ecosystem";
import EcosystemService from "../../../Services/EcosystemService/EcosystemService";
import KpBreadCrumb from "../../Components/KpBreadCrumb";
import { useAppProvider } from "../../Provider/AppProvider";

const EcosystemHeader = () => {
    const { translate } = translator();
    const [cards, setCards] = useState<Ecosystem[]>([]);
    const { setProcessing, finalizeProcessing } = useAppProvider();
    const getAll = async () => {
        try {
            const res = await EcosystemService.getAll();
            setCards(res);
        } catch (err: any) {}
    };
    useEffect(() => {
        getAll();
        finalizeProcessing();
    }, []);
    return (
        <ul className="flex flex-wrap text-sm font-medium md:w-max lg:w-max xl:w-max ul-bg-mode text-center text-gray-500 border-gray-200 rounded-t-lg">
            {cards.map((val: Ecosystem, i: number) => (
                <li className="" key={i}>
                    <NavLink
                        to={val.title.toLowerCase()}
                        aria-current="page"
                        className={(navData) =>
                            navData.isActive
                                ? "inline-block p-4 text-blue-600 bg-gray-100 rounded-t-lg nav-active"
                                : "inline-block p-4 text-blue-600 bg-gray-100 rounded-t-lg nav-not-active"
                        }
                        state={{ ecosystem: val }}
                    >
                        {val.title.toUpperCase()}
                    </NavLink>
                </li>
            ))}
        </ul>
    );
};

const EcosystemBase = () => {
    const { translate } = translator();
    return (
        <main className="grow mb-20 md:mb-5">
            <div className="px-6 sm:px-6 xl:!px-[80px] w-full  mx-auto">
                <KpBreadCrumb
                    title={translate("ACCOUNT_MENU", "ECOSYSTEM")}
                ></KpBreadCrumb>

                <div className="grid grid-cols-12 mt-7">
                    <EcosystemHeader />
                    <Outlet />
                </div>
            </div>
        </main>
    );
};

export default EcosystemBase;
