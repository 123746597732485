export interface IHighNotice {
    link: string;
    status: boolean;
    photo?: File;
}

export default class HighNotice {
    id?: number;
    link!: string;
    status!: boolean;
    rank!: number;
    photo?: string;
    created_at?: Date;
    updated_at?: Date;

    constructor(link: string, status: boolean) {
        this.link = link;
        this.status = status;
    }
}
