import { useEffect } from "react";
import Testimonial01 from "../../images/testimonial-01.jpg";
import TestimonialSign01 from "../../images/testimonial-sign-01.svg";
import Testimonial02 from "../../images/testimonial-02.jpg";
import TestimonialSign02 from "../../images/testimonial-sign-02.svg";

import Swiper, { Pagination, EffectFade } from "swiper";
import "swiper/swiper.min.css";
import "swiper/css/effect-fade";
import "swiper/css/pagination";
import Features from "../../images/features-06.png";
import translator from "../../../Components/Extra/Translation/Translate";

Swiper.use([Pagination, EffectFade]);

export function Section06() {
    useEffect(() => {
        const testimonial = new Swiper(".testimonial-carousel", {
            slidesPerView: 1,
            watchSlidesProgress: true,
            effect: "fade",
            pagination: {
                el: ".testimonial-carousel-pagination",
                clickable: true,
            },
        });
    }, []);
    const { translate } = translator();

    return (
        <section className="flex justify-center items-center bg-slate-800 py-10 md:!my-20 mt-0 ">
            <div className=" w-full max-w-screen-xl mx-auto">
                <div className="flex flex-col items-stretch mx-4 lg:!mx-20 md:flex-row  justify-center  gap-5 sm:gap-4 md:!gap-12 text-left">
                    <div
                        data-aos="fade-up"
                        className="flex flex-col justify-center gap-4 items-start w-full md:w-1/2"
                    >
                        <h4 className="text-white bg-clip-text font-bold md:!text-5xl text-3xl leading-tight tracking-tighter">
                            {translate("LANDING", "SECTION6_TITLE")}
                        </h4>
                        <div className="text-slate-200 text-base font-light leading-normal ">
                            <p
                                className="text-lg  mb-8"
                                dangerouslySetInnerHTML={{
                                    __html: translate(
                                        "LANDING",
                                        "SECTION6_DESC",
                                    ),
                                }}
                            />
                        </div>
                    </div>
                    <div
                        data-aos="fade-down"
                        className="relative flex justify-center items-center w-full md:w-1/2 md:bg-left"
                    >
                        <img
                            src={Features}
                            alt="hero_img2"
                            className="md:!rounded-[50px] rounded-2xl h-auto object-cover md:!min-h-[400px] w-full"
                        />
                    </div>
                </div>
            </div>
        </section>
        // <section className="mt-12 md:mt-20" data-aos-id-3>
        //     <div className="relative max-w-7xl mx-auto">
        //         {/* Bg */}
        //         <div
        //             className="absolute inset-0 rounded-xl mb-24 md:mb-0  pointer-events-none -z-10"
        //             aria-hidden="true"
        //         />

        //         <div className="max-w-7xl mx-auto px-4 sm:px-6">
        //             <div className="pb-6">
        //                 {/* Section content */}
        //                 <div className="relative max-w-xl mx-auto md:max-w-none text-center md:text-left flex flex-col md:flex-row items-center justify-end">
        //                     {/* Content */}
        //                     <div className="w-[512px] max-w-full shrink-0 md:order-1">
        //                         {/* Copy */}
        //                         <h2
        //                             className="h2 mb-4"
        //                             data-aos="fade-up"
        //                             data-aos-anchor="[data-aos-id-3]"
        //                             data-aos-delay="100"
        //                         >
        //                             {translate("LANDING", "SECTION6_TITLE")}
        //                         </h2>
        //                         <p
        //                             className="text-lg text-slate-500 mb-8"
        //                             data-aos="fade-up"
        //                             data-aos-anchor="[data-aos-id-3]"
        //                             data-aos-delay="200"
        //                         >
        //                             {translate("LANDING", "SECTION6_DESC")}
        //                         </p>
        //                     </div>

        //                     {/* img */}
        //                     <div className="w-full max-w-sm md:max-w-none md:mr-8 mt-8 md:mt-0">
        //                         <div className="relative mdg-features-03 -mx-8 md:mx-0 my-4">
        //                             <img
        //                                 src={Features}
        //                                 className="md:max-w-none"
        //                                 width={500}
        //                                 height={500}
        //                                 alt="Features 06"
        //                                 data-aos="fade-up"
        //                                 data-aos-anchor="[data-aos-id-3]"
        //                             />
        //                         </div>
        //                     </div>
        //                 </div>
        //             </div>
        //         </div>
        //     </div>
        // </section>
    );
}
