import React, { useState } from "react";
import Moderation, { AdressTypeModeraton } from "../../../Models/Moderation";
import translator from "../../../Components/Extra/Translation/Translate";
import { ModalContext } from "../../../Components/Extra/Modal/ModalContext";
import UtilsService from "../../../Services/UtilsService";
import MailModeration from "../../../Models/MailModeration";

const MailModerationContent = ({
    moderation,
}: {
    moderation: MailModeration;
}) => {
    const { translate } = translator();
    return (
        <>
            <div className="md-flex">
                <div className="w-full p-2">
                    <h1 className="text-center font-bold uppercase">
                        {translate(
                            "Moderation",
                            "Demandes de changement de mail",
                        )}
                    </h1>
                </div>
            </div>
            <div className="md-flex">
                <div className="w-full p-2">
                    <h1 className="font-bold">
                        {translate("Moderation", "Ancienne mail")}
                    </h1>
                    <div>
                        <p>Adresse: {moderation.old_email}</p>
                    </div>
                </div>
                <div className="w-full p-2">
                    <h1 className="font-bold">
                        {translate("Moderation", "Nouvelle mail")}
                    </h1>
                    <div>
                        <p>Adresse: {moderation.new_email}</p>
                    </div>
                </div>
            </div>
        </>
    );
};

const ButtonViewMailModalModeraton = ({
    moderation,
}: {
    moderation: MailModeration;
}) => {
    const { translate } = translator();
    const { handleModal } = React.useContext(ModalContext);

    return (
        <button
            type="button"
            className="flex items-center mr-2"
            onClick={() =>
                handleModal(<MailModerationContent moderation={moderation} />)
            }
        >
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="#d46f1c"
                strokeWidth="1.25"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="lucide lucide-eye"
            >
                <path d="M2 12s3-7 10-7 10 7 10 7-3 7-10 7-10-7-10-7Z" />
                <circle cx="12" cy="12" r="3" />
            </svg>
        </button>
    );
};

export default ButtonViewMailModalModeraton;
