import { NavLink } from "react-router-dom";
import translator from "../../../Components/Extra/Translation/Translate";
import RoutesNames from "../../../Services/RoutesNames";
import UtilsService from "../../../Services/UtilsService";
import Illustration from "../../images/footer-illustration.png";

export default function KalypayFooter() {
    const { translate } = translator();
    const currentYear = new Date().getFullYear();
    return (
        <footer className="relative footer-div">
            {/* Bg */}
            <div
                className="absolute inset-0 bg-slate-800 -z-10"
                aria-hidden="true"
            />

            {/* Illustration */}
            <div
                className="absolute bottom-0 left-1/2 -translate-x-1/2 pointer-events-none -z-10"
                aria-hidden="true"
            >
                <img
                    className="max-w-none"
                    src={Illustration}
                    alt="Footer illustration"
                />
            </div>

            <div className="max-w-8xl mx-auto px-4 sm:px-6">
                {/* Blocks */}
                <div className="grid sm:grid-cols-12 lg:grid-cols-12 gap-8 pt-8 pb-12 border-t border-slate-700">
                    {/* 1st block */}
                    <div className="sm:col-span-12 lg:col-span-4 lg:max-w-xs">
                        {/* Logo */}
                        <NavLink
                            className="flex justify-center"
                            to={RoutesNames.Dashboard}
                        >
                            <img
                                src={UtilsService.getPulicImage(
                                    "/dist/image/Logo-Kalypay-HD-Original-PNG.png",
                                )}
                                className="h-[50px] w-auto"
                                alt="Kalypay Logo"
                            />
                        </NavLink>
                        <div className="text-center text-slate-400">
                            {translate("FOOTER_INDICATION", "KALYSSI")}
                        </div>
                    </div>

                    {/* 2nd block */}
                    <div className="sm:col-span-12 md:col-span-2 lg:col-span-2">
                        <h6 className="text-sm text-slate-100 font-bold mb-3">
                            {translate("FOOTER", "INFORMATION")}
                        </h6>
                        <ul className="text-sm font-[450] space-y-2">
                            <li>
                                <NavLink
                                    className="text-slate-400 hover:text-kalypay-500 transition duration-150 ease-in-out"
                                    to={RoutesNames.MentionLegale}
                                >
                                    {translate("FOOTER", "MENTION")}
                                </NavLink>
                            </li>
                            <li>
                                <NavLink
                                    className="text-slate-400 hover:text-kalypay-500 transition duration-150 ease-in-out"
                                    to={RoutesNames.CGV}
                                >
                                    {translate("FOOTER", "CGV")}
                                </NavLink>
                            </li>
                            <li>
                                <NavLink
                                    className="text-slate-400 hover:text-kalypay-500 transition duration-150 ease-in-out"
                                    to={RoutesNames.Confidentialite}
                                >
                                    {translate("FOOTER", "PRIVACY")}
                                </NavLink>
                            </li>
                            <li>
                                <NavLink
                                    className="text-slate-400 hover:text-kalypay-500 transition duration-150 ease-in-out"
                                    to={RoutesNames.KycAml}
                                >
                                    KYC / AML
                                </NavLink>
                            </li>
                        </ul>
                    </div>

                    {/* 3rd block */}
                    <div className="sm:col-span-12 md:col-span-2 lg:col-span-2">
                        <h6 className="text-sm text-slate-100 font-bold mb-3">
                            {translate("FOOTER", "INVEST")}
                        </h6>
                        <ul className="text-sm font-[450] space-y-2">
                            <li>
                                <NavLink
                                    className="text-slate-400 hover:text-kalypay-500 transition duration-150 ease-in-out"
                                    to="/invest"
                                >
                                    Borrowing
                                </NavLink>
                            </li>
                            <li>
                                <NavLink
                                    className="text-slate-400 hover:text-kalypay-500 transition duration-150 ease-in-out"
                                    to="/invest#lending"
                                >
                                    Lending
                                </NavLink>
                            </li>
                            <li>
                                <NavLink
                                    className="text-slate-400 hover:text-kalypay-500 transition duration-150 ease-in-out"
                                    to="/invest#staking"
                                >
                                    Staking
                                </NavLink>
                            </li>
                            <li>
                                <NavLink
                                    className="text-slate-400 hover:text-kalypay-500 transition duration-150 ease-in-out"
                                    to="/invest#nodes"
                                >
                                    Nodes
                                </NavLink>
                            </li>
                        </ul>
                    </div>

                    {/* 4th block */}
                    <div className="sm:col-span-12 md:col-span-2 lg:col-span-2">
                        <h6 className="text-sm text-slate-100 font-bold mb-3">
                            {translate("FOOTER", "DISCORVER")}
                        </h6>
                        <ul className="text-sm font-[450] space-y-2">
                            <li>
                                <NavLink
                                    className="text-slate-400 hover:text-kalypay-500 transition duration-150 ease-in-out"
                                    to="/discover#developper"
                                >
                                    {translate("FOOTER", "DEVELOPPER")}
                                </NavLink>
                            </li>
                            <li>
                                <NavLink
                                    className="text-slate-400 hover:text-kalypay-500 transition duration-150 ease-in-out"
                                    to="/discover#ambassador"
                                >
                                    {translate("FOOTER", "AMBASSADOR")}
                                </NavLink>
                            </li>
                            <li>
                                <NavLink
                                    className="text-slate-400 hover:text-kalypay-500 transition duration-150 ease-in-out"
                                    to="/discover"
                                >
                                    {translate("FOOTER", "FAQ")}
                                </NavLink>
                            </li>
                        </ul>
                    </div>

                    {/* 5th block */}
                    <div className="sm:col-span-12 md:col-span-2 lg:col-span-2">
                        <h6 className="text-sm text-slate-100 font-bold mb-3">
                            {translate("FOOTER", "CadrdIBAN")}
                        </h6>
                        <ul className="text-sm font-[450] space-y-2">
                            <li>
                                <NavLink
                                    className="text-slate-400 hover:text-kalypay-500 transition duration-150 ease-in-out"
                                    to="/iban-card#iban"
                                >
                                    {translate("FOOTER", "IBAN")}
                                </NavLink>
                            </li>
                            <li>
                                <NavLink
                                    className="text-slate-400 hover:text-kalypay-500 transition duration-150 ease-in-out"
                                    to="/iban-card#cards"
                                >
                                    {translate("FOOTER", "CARD")}
                                </NavLink>
                            </li>
                            <li>
                                <NavLink
                                    className="text-slate-400 hover:text-kalypay-500 transition duration-150 ease-in-out"
                                    to="/iban-card#subscription"
                                >
                                    {translate("FOOTER", "abonnement")}
                                </NavLink>
                            </li>
                        </ul>
                    </div>
                </div>

                {/* Bottom area */}
                <div className="pb-4 md:pb-8">
                    <div className="text-sm text-center text-slate-100">
                        © 2022-{currentYear}{" "}
                        <a
                            className="font-medium hover:text-kalypay-500 transition duration-150 ease-in-out"
                            href="#0"
                        >
                            KalyPay,
                        </a>{" "}
                        {translate("FOOTER", "COPYRIGHT")}
                    </div>
                </div>
            </div>
        </footer>
    );
}
