import { NavLink, useLocation } from "react-router-dom";
import { useAppProvider } from "../../../Front/Provider/AppProvider";

interface SidebarLinkProps {
    children: React.ReactNode;
    href: string;
}

export default function SidebarLink({ children, href }: SidebarLinkProps) {
    const { pathname } = useLocation();
    const { setSidebarOpen } = useAppProvider();
    return (
        <NavLink
            className={`${
                pathname === href
                    ? "text-blue-500 font-[550]"
                    : "text-black font-[400]"
            }`}
            to={href}
            onClick={() => setSidebarOpen(false)}
        >
            {children}
        </NavLink>
    );
}
