import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";

import AppRoutes from "../Routes/AppRoutes";
import { store } from "../globalState/Store";
import AuthenticationProvider from "./AuthenticationProvider";
import MessageProvider from "./MessageProvider";

import "./App.css";
import { MaintenanceProvider } from "./AppGlobalProvider";
import SideMenuProvider from "../Front/Provider/SideMenuProvider";

function App() {
    return (
        <Provider store={store}>
            <AuthenticationProvider>
                <MessageProvider>
                    <SideMenuProvider>
                        <BrowserRouter
                            basename={process.env.REACT_APP_BASE_NAME || "/"}
                        >
                            <MaintenanceProvider>
                                <AppRoutes />
                            </MaintenanceProvider>
                        </BrowserRouter>
                    </SideMenuProvider>
                </MessageProvider>
            </AuthenticationProvider>
        </Provider>
    );
}

export default App;
