import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { AMLAddress, AMLCheckResult, useAml } from "../../../Hooks/useAml";
import alert_message from "../../../Services/Alert/AlertMessage";
import { useAssets } from "../../Provider/AssetsProvider";
import UtilsService from "../../../Services/UtilsService";
import { useNavigate } from "react-router-dom";
import RoutesNames from "../../../Services/RoutesNames";

export const CheckForm = () => {
    const { check, linkSearchToAddress } = useAml();
    const [aml, setAml] = useState<AMLCheckResult | undefined>();
    const { assets } = useAssets();
    const navigate = useNavigate();

    const form = useForm({
        defaultValues: {
            objectId: "",
        },
    });

    const search = (address: AMLAddress) => {
        if (!aml) return;
        linkSearchToAddress(aml.aml_id, address.blockchain, address.hash)
            .then(() => {
                alert_message.success("Addresse liée à l'AML avec succès");
                navigate(
                    RoutesNames.AmlCheckId.replace(":id", String(aml.aml_id)),
                );
            })
            .catch((err) => {
                alert_message.error(err);
            });
    };

    const onSubmit = async (data: any) => {
        setAml(undefined);
        await check(data.objectId)
            .catch((err) => {
                alert_message.error(err);

                return undefined;
            })
            .then((res) => setAml(res));
    };

    return (
        <FormProvider {...form}>
            <form
                className=" block md:flex md:flex-col-1 gap-4  dark:bg  sm:rounded-lg sm:p-6 p-2 rounded-lg border bg-card text-card-foreground shadow-sm mb-6"
                onSubmit={form.handleSubmit(onSubmit)}
            >
                <div className="w-full">
                    <div className="relative">
                        <div className=" flex rounded-md shadow-sm">
                            <input
                                type="text"
                                className="block w-full rounded-md border-1 dark:bg-transparent border-gray-300 py-1.5 text-gray-900 shadow-sm ring-1 h-[40px] ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-kalypay-600 sm:text-sm sm:leading-6"
                                placeholder="Addresse"
                                {...form.register("objectId")}
                            />
                        </div>
                        <div className="flex justify-end w-full mr-0 mb-1 font-bold pt-1">
                            <span>Frais : 1 USDT</span>
                        </div>
                    </div>
                </div>

                <div className="">
                    <button
                        disabled={form.watch("objectId").length < 10}
                        type="submit"
                        className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm  h-[40px] text-sm font-medium text-white bg-kalypay-600 hover:bg-kalypay-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-kalypay-500"
                    >
                        Check
                    </button>
                </div>
            </form>
            <div className="flex flex-col gap-4 flex-col-1 py-5">
                {aml != null && (
                    <>
                        {!aml ||
                            (aml.addresses.length == 0 && (
                                <div className="text-sm font-medium text-slate-600 text-center">
                                    Aucune adresse trouvée
                                </div>
                            ))}

                        {aml && aml.addresses.length > 0 && (
                            <>
                                <div className="text-sm font-medium text-slate-600">
                                    {aml.addresses.length} adresses trouvées
                                </div>
                                <div className="flex flex-col gap-2">
                                    {aml.addresses.map((address, index) => {
                                        const asset = assets.find(
                                            (asset) =>
                                                asset.name.toUpperCase() ===
                                                address.blockchain,
                                        );

                                        return (
                                            <div
                                                key={index}
                                                className="flex justify-between cursor-pointer group  p-2 bg-gray-400 rounded-md"
                                                onClick={() => search(address)}
                                            >
                                                <div className=" block md:flex items-center  gap-2">
                                                    <div className="text-sm items-center  text-slate-600 flex gap-1 font-bold">
                                                        {asset && (
                                                            <img
                                                                src={UtilsService.getPhotoUrl(
                                                                    asset.logo,
                                                                )}
                                                                alt={asset.name}
                                                                className="w-6 h-6"
                                                            />
                                                        )}
                                                        {address.blockchain}
                                                    </div>
                                                    <div className="text-sm font-medium text-slate-600 group-hover:text-slate-700 group-hover:underline">
                                                        {address.hash}
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            </>
                        )}
                    </>
                )}
            </div>
        </FormProvider>
    );
};
