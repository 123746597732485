import { useCallback, useEffect, useState } from "react";
import { DataTableColums } from "../../../Components/Extra/DataTable/DataTable";
import translator from "../../../Components/Extra/Translation/Translate";
import UtilsService from "../../../Services/UtilsService";
import DataTableWithPaginatedData from "../../../Components/Extra/DataTable/DataTableWithPaginatedData/DataTableWithPaginatedData";
import { DatatableFilter } from "../../../Models/DatatableFilter";
import UserService from "../../../Services/User/UserService";
import ModerationService from "../../../Services/Moderation/ModerationService";
import { ModalProvider } from "../../../Components/Extra/Modal/ModalContext";
import ButtonViewModalModeraton from "./ButtonViewMailModalModeraton";
import Moderation, { StatusModeration } from "../../../Models/Moderation";
import async from "react-select/dist/declarations/src/async/index";
import KpButton from "../../Components/Inputs/KpButton";
import Swal from "sweetalert2";
import alert_message from "../../../Services/Alert/AlertMessage";
import { useAppProvider } from "../../Provider/AppProvider";
import MailModeration, {
    StatusMailModeration,
} from "../../../Models/MailModeration";
import MailModerationService from "../../../Services/MailModeration/MailModerationService";
import ButtonViewMailModalModeraton from "./ButtonViewMailModalModeraton";
import useAuthentication from "../../../Services/Authentication/useAuthentication";
import ButtonAddQuestion from "./ButtonAddQuestion";
import ButtonViewResponse from "./ButtonViewResponse";

const MailModerationList = ({ status }: { status: string }) => {
    const { translate } = translator();
    const { processing, setProcessing, finalizeProcessing } = useAppProvider();
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [perPage, setPerPage] = useState<number>(10);
    const [incrementor, setIncrementor] = useState<number>(0);
    const [nbPage, setNbPage] = useState<number>(0);
    const [search, setSearch] = useState<string>("");
    const [filterIndex, setFilterIndex] = useState<string>();
    const [filterProcess, setFilterProcess] = useState<boolean>(false);
    const [moderations, setModerations] = useState<Array<Moderation>>([]);
    const { isAdmin, isAdvisor } = useAuthentication();

    const changeStatus = async (
        mod: MailModeration,
        status: StatusMailModeration,
    ) => {
        Swal.fire({
            title: "Confirmation",
            icon: "question",
            text: `Vous êtes sûr de vouloir ${
                status === StatusMailModeration.REJECTED
                    ? "refuser"
                    : "accepter"
            } cette demande ?`,
            showCancelButton: true,
        }).then(async (value: any) => {
            if (value.isConfirmed) {
                setProcessing(true);
                try {
                    const data = {
                        id: mod.id,
                        status: status,
                    };
                    const action = MailModerationService.changeStatus(data);

                    await alert_message.promise(
                        "En cours ...",
                        action,
                        "Action fait avec succès",
                    );

                    if (currentPage !== 0) setCurrentPage(0);
                    else setIncrementor(incrementor + 1);
                } catch (err: any) {
                } finally {
                    finalizeProcessing();
                }
            }
        });
    };

    const colums: DataTableColums[] = [
        {
            index: "created_at",
            title: "Date",
            content: (row: MailModeration) => {
                return row.created_at
                    ? UtilsService.getBeautifulFormatedDate(row.created_at)
                    : "";
            },
            filter: true,
            className: "whitespace-nowrap",
            // headerFilter: headerFilter.created_at.filter,
        },
        {
            index: "uid",
            title: "UID",
            content: (row: MailModeration) => {
                return row.user.uid;
            },
            filter: true,
            className: "whitespace-nowrap",
            // headerFilter: headerFilter.uid.filter,
        },
        // {
        //     index: "name",
        //     title: "Admin_Users.Name",
        //     content: (row: MailModeration) => {
        //         return row.user.first_name + " " + row.user.last_name;
        //     },
        //     filter: true,
        //     className: "whitespace-nowrap",
        //     // headerFilter: headerFilter.name.filter,
        // },
        {
            index: "new_mail",
            title: "Admin_Users.New_Mail",
            content: (row: MailModeration) => {
                return row.new_email;
            },
            filter: true,
            className: "whitespace-nowrap",
            // headerFilter: headerFilter.type.filter,
        },
        {
            index: "new_mail",
            title: "Admin_Users.Old_Mail",
            content: (row: MailModeration) => {
                return row.old_email;
            },
            filter: true,
            className: "whitespace-nowrap",
            // headerFilter: headerFilter.type.filter,
        },
        {
            index: "status",
            title: "Status",
            content: (row: MailModeration) => {
                if (row.status == StatusMailModeration.ACCEPTED_V1 && isAdmin) {
                    return (
                        <>
                            {" "}
                            {translate(
                                "STATUS",
                                StatusMailModeration.PENDING || "",
                            )}{" "}
                        </>
                    );
                }
                if (
                    row.status == StatusMailModeration.ACCEPTED_V1 &&
                    isAdvisor
                ) {
                    return (
                        <>
                            {" "}
                            {translate(
                                "STATUS",
                                StatusMailModeration.ACCEPTED_V1 || "",
                            )}{" "}
                        </>
                    );
                }
                return <> {translate("STATUS", row.status || "")} </>;
            },
            filter: true,
            className: "whitespace-nowrap",
            // headerFilter: headerFilter.email.filter,
        },
        {
            index: "action",
            title: "Action",
            content: (row: MailModeration) => {
                return (
                    <div className="flex flex-row space-x-1">
                        <ModalProvider>
                            <ButtonViewMailModalModeraton moderation={row} />
                        </ModalProvider>
                        {row.status == StatusMailModeration.PENDING &&
                        isAdvisor ? (
                            <div className="space-x-1">
                                <KpButton
                                    mode="normal"
                                    type="button"
                                    onClick={() =>
                                        changeStatus(
                                            row,
                                            StatusMailModeration.ACCEPTED_V1,
                                        )
                                    }
                                >
                                    Valider
                                </KpButton>
                                <KpButton
                                    mode="danger"
                                    type="button"
                                    onClick={() =>
                                        changeStatus(
                                            row,
                                            StatusMailModeration.REJECTED,
                                        )
                                    }
                                >
                                    Rejeter
                                </KpButton>

                                <ModalProvider>
                                    <ButtonAddQuestion moderation={row} />
                                </ModalProvider>
                            </div>
                        ) : row.status == StatusMailModeration.ACCEPTED_V1 &&
                          isAdmin ? (
                            <div className="space-x-1">
                                <KpButton
                                    mode="normal"
                                    type="button"
                                    onClick={() =>
                                        changeStatus(
                                            row,
                                            StatusMailModeration.ACCEPTED,
                                        )
                                    }
                                >
                                    Valider
                                </KpButton>
                                <KpButton
                                    mode="danger"
                                    type="button"
                                    onClick={() =>
                                        changeStatus(
                                            row,
                                            StatusMailModeration.REJECTED,
                                        )
                                    }
                                >
                                    Rejeter
                                </KpButton>
                            </div>
                        ) : (
                            <></>
                        )}
                        <ModalProvider>
                            <ButtonViewResponse moderation={row} />
                        </ModalProvider>
                    </div>
                );
            },
            filter: true,
            className: "whitespace-nowrap",
            // headerFilter: headerFilter.email.filter,
        },
    ];
    const handlePageChange = useCallback((page: number) => {
        setCurrentPage(page + 1);
    }, []);

    const handlePerRowsChange = useCallback((newPerPage: number) => {
        setPerPage(newPerPage);
    }, []);

    const onSearchInputChange = useCallback(async (txt: string) => {
        setSearch(txt);
    }, []);

    const getAllEmailModerations = async () => {
        setProcessing(true);
        const filter: DatatableFilter = {
            page: currentPage,
            size: perPage,
            search: search,
        };

        filter.status = status;

        try {
            const res = await MailModerationService.getAllFilter(filter);
            setModerations([...res.data]);
            setNbPage(res.lastPage);
        } catch (err: any) {
        } finally {
            finalizeProcessing();
        }
    };
    useEffect(() => {
        setIncrementor(incrementor + 1);
    }, [perPage, search, filterIndex, filterProcess, status]);

    useEffect(() => {
        if (incrementor > 1) setIncrementor(incrementor + 1);
    }, [currentPage]);

    useEffect(() => {
        getAllEmailModerations();
    }, [incrementor]);

    return (
        <>
            <DataTableWithPaginatedData
                className="table-report -mt-2 table"
                columns={colums}
                data={moderations}
                handlePaginationChange={handlePageChange}
                handlePerRowsChange={handlePerRowsChange}
                onSearchInputChange={onSearchInputChange}
                nbPage={nbPage}
            />
        </>
    );
};

export default MailModerationList;
