import translator from "../../../../Components/Extra/Translation/Translate";
import developper from "../../../images/discover/KalypayHand.png";

export default function Section1() {
    const { translate } = translator();
    return (
        <div className="p-4">
            <div className="max-w-8xl mx-auto">
                <div className="grid md:grid-cols-2 gap-12">
                    <div className="max-h-72">
                        <img
                            src={developper}
                            alt="Image developper"
                            className="rounded-lg object-cover w-full h-full"
                            data-aos="fade-down"
                        />
                    </div>
                    <div
                        className="flex flex-col justify-center"
                        data-aos="fade-up"
                    >
                        <h2 className="text-kalypay-100 text-2xl font-bold mb-4">
                            {translate("LANDING_DISCOVER", "title")}
                        </h2>
                        <p className="mb-4">
                            {translate("LANDING_DISCOVER", "subtitle")}
                        </p>
                        <p className="mb-4">
                            {translate("LANDING_DISCOVER", "description")}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}
