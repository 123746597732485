import { createContext, useContext, useEffect, useState } from "react";
import ModerationService from "../../Services/Moderation/ModerationService";
// import { MainSocket } from "../../Services/Socket/Socket";

export interface NotificationData {
    moderation: number;
    mail_moderation: number;
    transation_moderation: number;
    virement: number;
    card_order: number;
}

interface ContextProps {
    notiifcation: NotificationData;
    setNotification: (data: NotificationData) => any;
}

const SideMenuContext = createContext<ContextProps>({
    notiifcation: {
        moderation: 0,
        mail_moderation: 0,
        transation_moderation: 0,
        virement: 0,
        card_order: 0,
    },
    setNotification: (val: NotificationData) => {
        return;
    },
});

export default function SideMenuProvider({
    children,
}: {
    children: React.ReactNode;
}) {
    const [notiifcation, setNotification] = useState<NotificationData>({
        moderation: 0,
        mail_moderation: 0,
        transation_moderation: 0,
        virement: 0,
        card_order: 0,
    });

    const getNotificationData = async () => {
        try {
            const res = await ModerationService.getNotificationData();
            setNotification(res);
        } catch (err: any) {}
    };

    useEffect(() => {
        const interval = setInterval(() => {
            getNotificationData();
        }, 60000);

        return () => {
            clearInterval(interval);
        };
    }, []);

    useEffect(() => {
        getNotificationData();
    }, []);

    return (
        <SideMenuContext.Provider
            value={{
                notiifcation: notiifcation,
                setNotification: setNotification,
            }}
        >
            {children}
        </SideMenuContext.Provider>
    );
}

export const useSideMenu = () => useContext(SideMenuContext);
