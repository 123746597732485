import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useParams } from "react-router-dom";
import { IUnlockAccount, IUserPasswordReset } from "../../../../Models/User";
import alert_message from "../../../../Services/Alert/AlertMessage";
import UserService from "../../../../Services/User/UserService";
import translator from "../../../../Components/Extra/Translation/Translate";
import LockSvg from "../../../../Components/Extra/Svg/LockSvg";
import RoutesNames from "../../../../Services/RoutesNames";

const FormNewPassword: React.FC = () => {
    const navigate = useNavigate();
    const { translate } = translator();
    const [processing, setProcessing] = useState<boolean>(false);
    const [confirmNewPassword, setConfirmNewPassword] = useState<string>("");
    const [newPassword, setNewPassword] = useState<string>("");

    const query = new URLSearchParams(window.location.search);
    const token = query.get("token");
    const { code, uid } = useParams();

    const handleResetPassword = async (
        e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    ) => {
        e.preventDefault();
        if (token) resetPassword(token);
        else if (uid && code) unlockAccount(code, uid);
    };

    const resetPassword = (token: string) => {
        setProcessing(true);
        const payload: IUserPasswordReset = {
            new_password: newPassword,
            confirm_password: confirmNewPassword,
            token: token,
        };
        const res = UserService.resetPassword(payload);
        alert_message.promise(" ...", res);
        setProcessing(false);
        navigate(RoutesNames.Login);
    };

    const unlockAccount = (code: string, uid: string) => {
        setProcessing(true);
        const payload: IUnlockAccount = {
            code: code,
            uid: uid,
        };
        const res = UserService.unlockAccount(payload);
        alert_message
            .promise("Débloquage de votre compte encours...", res)
            .then(() => {
                setProcessing(false);
                navigate(RoutesNames.Login);
            });
    };

    useEffect(() => {
        if (code && uid) {
            unlockAccount(code, uid);
        }
    }, [code, uid]);

    //resetPassword = async (payload: IUserPasswordReset)
    return (
        <div className="m-auto vl-mob w-full rounded-md sm:block md:flex lg:flex xl:flex 2xl:flex justify-center bg-white-log px-5 py-8 shadow-md sm:w-3/4 sm:px-8 lg:w-2/4 xl:bg-transparent xl:p-0 xl:shadow-none">
            {code && uid && (
                <div className="relative">
                    <div className="intro-x flex justify-center">
                        Débloquage de votre compte encours...
                    </div>
                </div>
            )}

            {(!code || !uid) && (
                <form>
                    <div className="relative">
                        <div className="intro-x flex justify-center">
                            {translate("SignIn", "Please_make_official_site")}
                        </div>
                        <div className="intro-x w-full mb-4 text-sm flex justify-center">
                            <span className="flex items-center justify-center to-bg-slate-200 rounded sm:w-[100%] lg:w-[55%] xl:w-[55%] 2xl:w-[55%] font-medium">
                                <LockSvg />
                                <span className="mt-1">
                                    <span className="text-[#00AA4D]">
                                        https://
                                    </span>
                                    kalypay.com
                                </span>
                            </span>
                        </div>
                        <h2 className="intro-x text-center text-2xl font-bold xl:text-left xl:text-3xl mt-8">
                            {translate("Reset", "Create")}
                        </h2>
                        <span className="intro-x text-center mt-8">
                            {translate("Reset", "Info")}
                        </span>
                        <div className="intro-x mt-8">
                            <div className="relative mt-4">
                                <input
                                    type="password"
                                    id="password-input"
                                    className="border-1 peer block w-full appearance-none rounded-lg bg-transparent px-2.5 pb-2.5 pt-4 text-sm text-gray-900 focus:outline-none focus:ring-0"
                                    placeholder=""
                                    required
                                    value={newPassword}
                                    onChange={(e) =>
                                        setNewPassword(e.target.value)
                                    }
                                />
                                <label
                                    htmlFor="password-input"
                                    className="!peer-focus:text-[#053B3B] absolute top-2 left-1 z-10 origin-[0] -translate-y-4 scale-75 bg-white px-2 text-sm text-gray-500 duration-300 peer-placeholder-shown:top-1/2 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:scale-100 peer-focus:top-2 peer-focus:-translate-y-4 peer-focus:scale-75 peer-focus:px-2"
                                >
                                    {translate("Reset", "Password")}
                                </label>
                            </div>
                            <div className="relative mt-4">
                                <input
                                    type="password"
                                    id="confirm-password-input"
                                    className="border-1 peer block w-full appearance-none rounded-lg bg-transparent px-2.5 pb-2.5 pt-4 text-sm text-gray-900 focus:outline-none focus:ring-0"
                                    placeholder=""
                                    required
                                    value={confirmNewPassword}
                                    onChange={(e) =>
                                        setConfirmNewPassword(e.target.value)
                                    }
                                />
                                <label
                                    htmlFor="confirm-password-input"
                                    className="!peer-focus:text-[#053B3B] absolute top-2 left-1 z-10 origin-[0] -translate-y-4 scale-75 bg-white px-2 text-sm text-gray-500 duration-300 peer-placeholder-shown:top-1/2 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:scale-100 peer-focus:top-2 peer-focus:-translate-y-4 peer-focus:scale-75 peer-focus:px-2"
                                >
                                    {translate("Reset", "Password_Confirm")}
                                </label>
                            </div>
                        </div>
                        {token && (
                            <div className="intro-x mt-5 text-center xl:mt-8 xl:text-left flex justify-center">
                                <button
                                    className="btn btn-perfect w-1/2 py-3 px-4 align-top xl:mr-3"
                                    type="submit"
                                    disabled={processing}
                                    onClick={(e) => {
                                        handleResetPassword(e);
                                    }}
                                >
                                    {translate("Reset", "Reset_Password")}
                                </button>
                            </div>
                        )}
                    </div>
                </form>
            )}
        </div>
    );
};

export default FormNewPassword;
