import translator from "../../../Components/Extra/Translation/Translate";
import useScrollToHash from "../../../Hooks/useScrollToHash ";
import InvestComponent, { InvestComponentProps } from "./InvestComponent";
import borrowing from "../../images/invest/borrowing.jpg";
import crypto from "../../images/invest/crypto.jpg";
import nodes from "../../images/invest/nodes.jpg";
import staking from "../../images/invest/staking7.jpg";
import phoneBorrowing from "../../images/invest/BorrowingPhone.png";
import phoneLanding from "../../images/invest/Lendingphone.png";
import phoneStacking from "../../images/invest/stakingPhone.png";
import phoneNode from "../../images/invest/nodePhone.png";
import Section from "./Section";

const Invest: React.FC = () => {
    const { translate } = translator();
    useScrollToHash();
    const dataInvestComponents: InvestComponentProps[] = [
        {
            id: "borrowing",
            title: "Borrowing",
            intro: [
                translate("LANDING_INVEST", "intro_1"),
                translate("LANDING_INVEST", "intro_2"),
            ],
            howItWorksTitle: translate("LANDING_INVEST", "howItWorksTitle"),

            howItWorksSteps: [
                {
                    title: translate(
                        "LANDING_INVEST",
                        "howItWorksSteps_title1",
                    ),
                    description: translate(
                        "LANDING_INVEST",
                        "howItWorksSteps_description1",
                    ),
                },
                {
                    title: translate(
                        "LANDING_INVEST",
                        "howItWorksSteps_title2",
                    ),
                    description: translate(
                        "LANDING_INVEST",
                        "howItWorksSteps_description2",
                    ),
                },
                {
                    title: translate(
                        "LANDING_INVEST",
                        "howItWorksSteps_title3",
                    ),
                    description: translate(
                        "LANDING_INVEST",
                        "howItWorksSteps_description3",
                    ),
                },
            ],
            advantagesTitle: translate("LANDING_INVEST", "advantagesTitle"),
            advantages: [
                {
                    title: translate("LANDING_INVEST", "advantages_1_title"),
                    description: translate("LANDING_INVEST", "advantages_1"),
                },
                {
                    title: translate("LANDING_INVEST", "advantages_2_title"),
                    description: translate("LANDING_INVEST", "advantages_2"),
                },
                {
                    title: translate("LANDING_INVEST", "advantages_3_title"),
                    description: translate("LANDING_INVEST", "advantages_3"),
                },
            ],
            startProcessTitle: translate("LANDING_INVEST", "startProcessTitle"),
            startProcessSteps: [
                {
                    title: translate(
                        "LANDING_INVEST",
                        "startProcessSteps_title1",
                    ),
                    description: translate(
                        "LANDING_INVEST",
                        "startProcessSteps_description1",
                    ),
                },
                {
                    title: translate(
                        "LANDING_INVEST",
                        "startProcessSteps_title2",
                    ),
                    description: translate(
                        "LANDING_INVEST",
                        "startProcessSteps_description2",
                    ),
                },
                {
                    title: translate(
                        "LANDING_INVEST",
                        "startProcessSteps_title3",
                    ),
                    description: translate(
                        "LANDING_INVEST",
                        "startProcessSteps_description3",
                    ),
                },
            ],
            image: phoneBorrowing,
            backgroundImage: borrowing,
        },
        {
            id: "lending",
            title: "Lending Crypto",
            intro: [
                translate("LANDING_INVEST", "intro_lending_1"),
                translate("LANDING_INVEST", "intro_lending_2"),
            ],
            howItWorksTitle: translate("LANDING_INVEST", "howItWorksTitle"),
            howItWorksSteps: [
                {
                    title: translate(
                        "LANDING_INVEST",
                        "howItWorksSteps_lending_title1",
                    ),
                    description: translate(
                        "LANDING_INVEST",
                        "howItWorksSteps_lending_description1",
                    ),
                },
                {
                    title: translate(
                        "LANDING_INVEST",
                        "howItWorksSteps_lending_title2",
                    ),
                    description: translate(
                        "LANDING_INVEST",
                        "howItWorksSteps_lending_description2",
                    ),
                },
                {
                    title: translate(
                        "LANDING_INVEST",
                        "howItWorksSteps_lending_title3",
                    ),
                    description: translate(
                        "LANDING_INVEST",
                        "howItWorksSteps_lending_description3",
                    ),
                },
            ],
            advantagesTitle: translate(
                "LANDING_INVEST",
                "advantagesTitleLending",
            ),
            advantages: [
                {
                    title: translate(
                        "LANDING_INVEST",
                        "advantages_lending_1_title",
                    ),
                    description: translate(
                        "LANDING_INVEST",
                        "advantages_lending_1",
                    ),
                },
                {
                    title: translate(
                        "LANDING_INVEST",
                        "advantages_lending_2_title",
                    ),
                    description: translate(
                        "LANDING_INVEST",
                        "advantages_lending_2",
                    ),
                },
                {
                    title: translate(
                        "LANDING_INVEST",
                        "advantages_lending_3_title",
                    ),
                    description: translate(
                        "LANDING_INVEST",
                        "advantages_lending_3",
                    ),
                },
            ],
            startProcessTitle: translate(
                "LANDING_INVEST",
                "startProcessTitle_lending",
            ),
            startProcessSteps: [
                {
                    title: translate(
                        "LANDING_INVEST",
                        "startProcessSteps_lending_title1",
                    ),
                    description: translate(
                        "LANDING_INVEST",
                        "startProcessSteps_lending_description1",
                    ),
                },
                {
                    title: translate(
                        "LANDING_INVEST",
                        "startProcessSteps_lending_title2",
                    ),
                    description: translate(
                        "LANDING_INVEST",
                        "startProcessSteps_lending_description2",
                    ),
                },
                {
                    title: translate(
                        "LANDING_INVEST",
                        "startProcessSteps_lending_title3",
                    ),
                    description: translate(
                        "LANDING_INVEST",
                        "startProcessSteps_lending_description3",
                    ),
                },
            ],
            image: phoneLanding,
            backgroundImage: crypto,
        },
        {
            id: "staking",
            title: "Staking",
            intro: [
                translate("LANDING_INVEST", "intro_staking_1"),
                translate("LANDING_INVEST", "intro_staking_2"),
            ],
            howItWorksTitle: translate("LANDING_INVEST", "howItWorksTitle"),

            howItWorksSteps: [
                {
                    title: translate(
                        "LANDING_INVEST",
                        "howItWorksSteps_staking_title1",
                    ),
                    description: translate(
                        "LANDING_INVEST",
                        "howItWorksSteps_staking_description1",
                    ),
                },
                {
                    title: translate(
                        "LANDING_INVEST",
                        "howItWorksSteps_staking_title2",
                    ),
                    description: translate(
                        "LANDING_INVEST",
                        "howItWorksSteps_staking_description2",
                    ),
                },
                {
                    title: translate(
                        "LANDING_INVEST",
                        "howItWorksSteps_staking_title3",
                    ),
                    description: translate(
                        "LANDING_INVEST",
                        "howItWorksSteps_staking_description3",
                    ),
                },
            ],
            advantagesTitle: translate(
                "LANDING_INVEST",
                "advantagesTitleStaking",
            ),
            advantages: [
                {
                    title: translate(
                        "LANDING_INVEST",
                        "advantages_staking_1_title",
                    ),
                    description: translate(
                        "LANDING_INVEST",
                        "advantages_staking_1",
                    ),
                },
                {
                    title: translate(
                        "LANDING_INVEST",
                        "advantages_staking_2_title",
                    ),
                    description: translate(
                        "LANDING_INVEST",
                        "advantages_staking_2",
                    ),
                },
                {
                    title: translate(
                        "LANDING_INVEST",
                        "advantages_staking_3_title",
                    ),
                    description: translate(
                        "LANDING_INVEST",
                        "advantages_staking_3",
                    ),
                },
            ],
            startProcessTitle: translate(
                "LANDING_INVEST",
                "startProcessTitle_staking",
            ),
            startProcessSteps: [
                {
                    title: translate(
                        "LANDING_INVEST",
                        "startProcessSteps_staking_title1",
                    ),
                    description: translate(
                        "LANDING_INVEST",
                        "startProcessSteps_staking_description1",
                    ),
                },
                {
                    title: translate(
                        "LANDING_INVEST",
                        "startProcessSteps_staking_title2",
                    ),
                    description: translate(
                        "LANDING_INVEST",
                        "startProcessSteps_staking_description2",
                    ),
                },
                {
                    title: translate(
                        "LANDING_INVEST",
                        "startProcessSteps_staking_title3",
                    ),
                    description: translate(
                        "LANDING_INVEST",
                        "startProcessSteps_staking_description3",
                    ),
                },
            ],
            image: phoneStacking,
            backgroundImage: staking,
        },
        {
            id: "nodes",
            title: "Nodes",
            intro: [
                translate("LANDING_INVEST", "intro_node_1"),
                translate("LANDING_INVEST", "intro_node_2"),
            ],
            howItWorksTitle: translate("LANDING_INVEST", "howItWorksTitle"),
            howItWorksSteps: [
                {
                    title: translate(
                        "LANDING_INVEST",
                        "howItWorksSteps_node_title1",
                    ),
                    description: translate(
                        "LANDING_INVEST",
                        "howItWorksSteps_node_description1",
                    ),
                },
                {
                    title: translate(
                        "LANDING_INVEST",
                        "howItWorksSteps_node_title2",
                    ),
                    description: translate(
                        "LANDING_INVEST",
                        "howItWorksSteps_node_description2",
                    ),
                },
                {
                    title: translate(
                        "LANDING_INVEST",
                        "howItWorksSteps_node_title3",
                    ),
                    description: translate(
                        "LANDING_INVEST",
                        "howItWorksSteps_node_description3",
                    ),
                },
            ],
            advantagesTitle: translate("LANDING_INVEST", "advantagesTitleNode"),
            advantages: [
                {
                    title: translate(
                        "LANDING_INVEST",
                        "advantages_node_1_title",
                    ),
                    description: translate(
                        "LANDING_INVEST",
                        "advantages_node_1",
                    ),
                },
                {
                    title: translate(
                        "LANDING_INVEST",
                        "advantages_node_2_title",
                    ),
                    description: translate(
                        "LANDING_INVEST",
                        "advantages_node_2",
                    ),
                },
                {
                    title: translate(
                        "LANDING_INVEST",
                        "advantages_node_3_title",
                    ),
                    description: translate(
                        "LANDING_INVEST",
                        "advantages_node_3",
                    ),
                },
            ],
            startProcessTitle: translate(
                "LANDING_INVEST",
                "startProcessTitle_node",
            ),
            startProcessSteps: [
                {
                    title: translate(
                        "LANDING_INVEST",
                        "startProcessSteps_node_title1",
                    ),
                    description: translate(
                        "LANDING_INVEST",
                        "startProcessSteps_node_description1",
                    ),
                },
                {
                    title: translate(
                        "LANDING_INVEST",
                        "startProcessSteps_node_title2",
                    ),
                    description: translate(
                        "LANDING_INVEST",
                        "startProcessSteps_node_description2",
                    ),
                },
                {
                    title: translate(
                        "LANDING_INVEST",
                        "startProcessSteps_node_title3",
                    ),
                    description: translate(
                        "LANDING_INVEST",
                        "startProcessSteps_node_description3",
                    ),
                },
            ],
            image: phoneNode,
            backgroundImage: nodes,
        },
    ];
    return (
        <div className="mt-20">
            <Section />
            {dataInvestComponents.map((dataInvestComponent, index) => (
                <InvestComponent
                    key={index}
                    id={dataInvestComponent.id}
                    title={dataInvestComponent.title}
                    intro={dataInvestComponent.intro}
                    howItWorksTitle={dataInvestComponent.howItWorksTitle}
                    howItWorksSteps={dataInvestComponent.howItWorksSteps}
                    advantagesTitle={dataInvestComponent.advantagesTitle}
                    advantages={dataInvestComponent.advantages}
                    startProcessTitle={dataInvestComponent.startProcessTitle}
                    startProcessSteps={dataInvestComponent.startProcessSteps}
                    image={dataInvestComponent.image}
                    backgroundImage={dataInvestComponent.backgroundImage}
                />
            ))}
        </div>
    );
};

export default Invest;
