import Section1 from "./section1";
import Section2 from "./section2";
import Section3 from "./section3";

export default function Developper() {
    return (
        <div className="p-4 text-white" id="developper">
            <div className="max-w-7xl mx-auto px-4 py-4">
                <Section1 />
                <Section2 />
                <Section3 />
            </div>
        </div>
    );
}
