import { NavLink } from "react-router-dom";
import translator from "../../../Components/Extra/Translation/Translate";
import useAuthentication from "../../../Services/Authentication/useAuthentication";
import RoutesNames from "../../../Services/RoutesNames";
import Features from "../../images/features-03.png";
import Section01 from "../fintech/section-01";
import Section02 from "../fintech/section-02";
import Section03 from "../fintech/section-03";
import Section04 from "../fintech/section-04";
import Section05 from "../fintech/section-05";
import { Section06 } from "../fintech/section-06";
import Section07 from "../fintech/section-07";
import Section08 from "../fintech/section-08";
import Faqs from "../fintech/faqs";
import Cta from "../fintech/cta";
import Caroussel from "../../../Components/Dashboard/Caroussel";
import Illustration from "../../images/hero-illustration-kp.png";

const OurFee = () => {
    const { translate } = translator();
    const { isAuthenticated } = useAuthentication();
    return (
        <>
            {/* Page Title */}
            <section className="relative z-10 bg-black mdg-bg-hero overflow-hidden">
                {/* Bg */}
                <div
                    className="absolute inset-0 rounded-bl-[100px] mb-28 md:mb-0 pointer-events-none -z-10"
                    aria-hidden="true"
                />

                <div className="max-w-[70%] mx-auto px-4 sm:px-6">
                    <div className="pt-36 pb-14">
                        {/* Page Title content */}
                        <div className="relative max-w-xl mx-auto md:max-w-none text-center md:text-left">
                            {/* Content */}
                            <div className="">
                                {/* Copy */}
                                <h1
                                    className="h1 text-white mb-6 text-center"
                                    data-aos="fade-up"
                                    data-aos-delay="100"
                                    dangerouslySetInnerHTML={{
                                        __html: translate("OUR_FEE", "TITLE"),
                                    }}
                                />
                            </div>

                            {/* Image */}
                            <div className="max-w-sm mx-auto md:max-w-none md:absolute md:left-[90%] md:top-0 -mb-12 md:-mt-12 md:mb-0">
                                <div className="relative md:-ml-3 md:-mr-24 md:mx-0">
                                    <img
                                        className="absolute opacity-50 top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 mt-16 md:mt-0 pointer-events-none -z-10 max-w-none mix-blend-lighten"
                                        src={Illustration}
                                        alt="Hero illustration"
                                        aria-hidden="true"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* Contenu Page */}
            <section className="md:mt-20" id="transfert" data-aos-id-3>
                <div className="relative max-w-8xl mx-auto">
                    {/* Bg */}
                    <div
                        className="absolute inset-0 md:rounded-tl-[100px] mb-24 md:mb-0 bg-gradient-to-b dark:from-slate-800 from-slate-100 pointer-events-none -z-10"
                        aria-hidden="true"
                    />

                    <div className="max-w-6xl mx-auto px-4 sm:px-6">
                        <div className="pb-6 pt-12 md:pt-20">
                            <div className="mb-12 md:mb-20" data-aos="fade-up">
                                <div className="mb-20">
                                    <h4 className="h2 mb-4 text-kalypay-500 text-center">
                                        {translate("OUR_FEE", "GLOBAL_FEE")}
                                    </h4>
                                    <div>
                                        <table className="table-fixed text-center w-full">
                                            <tbody>
                                                <tr className="h-10 border-2">
                                                    <td>Ouverture de compte</td>
                                                    <td>Gratuit</td>
                                                </tr>
                                                <tr className="h-10 border-2">
                                                    <td>Tenu de compte</td>
                                                    <td>Gratuit</td>
                                                </tr>
                                                <tr className="h-10 border-2">
                                                    <td>Carte Bancaire</td>
                                                    <td>
                                                        Disponible à l’achat ou
                                                        sous conditions de
                                                        Staking *
                                                    </td>
                                                </tr>
                                                <tr className="h-10 border-2">
                                                    <td>Transferts interne</td>
                                                    <td>Gratuit</td>
                                                </tr>
                                                <tr className="h-10 border-2">
                                                    <td>Échanges</td>
                                                    <td>1 à 3%</td>
                                                </tr>
                                                <tr className="h-10 border-2">
                                                    <td>Marchands</td>
                                                    <td>1 à 3%</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="mb-20">
                                    <h4 className="h2 mb-4 text-kalypay-500 text-center">
                                        {translate("OUR_FEE", "DEPOSIT_FEE")}
                                    </h4>
                                    <div>
                                        <table className="table-fixed text-center w-full">
                                            <thead>
                                                <tr className="h-10 text-lg border-2">
                                                    <th>
                                                        <strong>Dépôts</strong>
                                                    </th>
                                                    <th>
                                                        <strong>Devise</strong>
                                                    </th>
                                                    <th>
                                                        <strong>Frais</strong>
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr className="h-10 border-2">
                                                    <td>Agents Kalypay</td>
                                                    <td>GNF / FCFA</td>
                                                    <td>Gratuit</td>
                                                </tr>
                                                <tr className="h-10 border-2">
                                                    <td>Visa / Mastercard</td>
                                                    <td>USD / EUR</td>
                                                    <td>3%</td>
                                                </tr>
                                                <tr className="h-10 border-2">
                                                    <td>Perfect Money</td>
                                                    <td>USD / EUR</td>
                                                    <td>2%</td>
                                                </tr>
                                                <tr className="h-10 border-2">
                                                    <td>Payeer</td>
                                                    <td>USD / EUR</td>
                                                    <td>2%</td>
                                                </tr>
                                                <tr className="h-10 border-2">
                                                    <td>Orange Money</td>
                                                    <td>GNF / FCFA</td>
                                                    <td>1 à 3%</td>
                                                </tr>
                                                <tr className="h-10 border-2">
                                                    <td>MTN Money</td>
                                                    <td>GNF / FCFA</td>
                                                    <td>1 à 3%</td>
                                                </tr>
                                                <tr className="h-10 border-2">
                                                    <td>Moov Money</td>
                                                    <td>GNF / FCFA</td>
                                                    <td>1 à 3%</td>
                                                </tr>
                                                <tr className="h-10 border-2">
                                                    <td>Wave Money</td>
                                                    <td>GNF / FCFA</td>
                                                    <td>1 à 3%</td>
                                                </tr>
                                                <tr className="h-10 border-2">
                                                    <td>Kalycoin</td>
                                                    <td>KLC</td>
                                                    <td>Gratuit</td>
                                                </tr>
                                                <tr className="h-10 border-2">
                                                    <td>Bitcoin</td>
                                                    <td>BTC</td>
                                                    <td>Gratuit</td>
                                                </tr>
                                                <tr className="h-10 border-2">
                                                    <td>Ethereum</td>
                                                    <td>ETH</td>
                                                    <td>Gratuit</td>
                                                </tr>
                                                <tr className="h-10 border-2">
                                                    <td>Tether</td>
                                                    <td>USDT</td>
                                                    <td>Gratuit</td>
                                                </tr>
                                                <tr className="h-10 border-2">
                                                    <td>Usd Coin</td>
                                                    <td>USDC</td>
                                                    <td>Gratuit</td>
                                                </tr>
                                                <tr className="h-10 border-2">
                                                    <td>Binance Coin</td>
                                                    <td>BNB</td>
                                                    <td>Gratuit</td>
                                                </tr>
                                                <tr className="h-10 border-2">
                                                    <td>Solana</td>
                                                    <td>SOL</td>
                                                    <td>Gratuit</td>
                                                </tr>
                                                <tr className="h-10 border-2">
                                                    <td>Polkadot</td>
                                                    <td>DOT</td>
                                                    <td>Gratuit</td>
                                                </tr>
                                                <tr className="h-10 border-2">
                                                    <td>Ripple</td>
                                                    <td>XRP</td>
                                                    <td>Gratuit</td>
                                                </tr>
                                                <tr className="h-10 border-2">
                                                    <td>Tron</td>
                                                    <td>TRX</td>
                                                    <td>Gratuit</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="mb-20">
                                    <h4 className="h2 mb-4 text-kalypay-500 text-center">
                                        {translate("OUR_FEE", "WITHDRAW_FEE")}
                                    </h4>
                                    <div>
                                        <table className="table-fixed text-center w-full">
                                            <thead>
                                                <tr className="h-10 text-lg border-2">
                                                    <th>
                                                        <strong>
                                                            Retraits
                                                        </strong>
                                                    </th>
                                                    <th>
                                                        <strong>Devise</strong>
                                                    </th>
                                                    <th>
                                                        <strong>Frais</strong>
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr className="h-10 border-2">
                                                    <td>Agents Kalypay</td>
                                                    <td>GNF / FCFA</td>
                                                    <td>1%</td>
                                                </tr>
                                                <tr className="h-10 border-2">
                                                    <td>Visa / Mastercard</td>
                                                    <td>USD / EUR</td>
                                                    <td>3%</td>
                                                </tr>
                                                <tr className="h-10 border-2">
                                                    <td>Perfect Money</td>
                                                    <td>USD / EUR</td>
                                                    <td>3%</td>
                                                </tr>
                                                <tr className="h-10 border-2">
                                                    <td>Payeer</td>
                                                    <td>USD / EUR</td>
                                                    <td>3%</td>
                                                </tr>
                                                <tr className="h-10 border-2">
                                                    <td>Orange Money</td>
                                                    <td>GNF / FCFA</td>
                                                    <td>3%</td>
                                                </tr>
                                                <tr className="h-10 border-2">
                                                    <td>MTN Money</td>
                                                    <td>GNF / FCFA</td>
                                                    <td>3%</td>
                                                </tr>
                                                <tr className="h-10 border-2">
                                                    <td>Moov Money</td>
                                                    <td>GNF / FCFA</td>
                                                    <td>3%</td>
                                                </tr>
                                                <tr className="h-10 border-2">
                                                    <td>Wave Money</td>
                                                    <td>GNF / FCFA</td>
                                                    <td>3%</td>
                                                </tr>
                                                <tr className="h-10 border-2">
                                                    <td>Kalycoin</td>
                                                    <td>KLC</td>
                                                    <td>5</td>
                                                </tr>
                                                <tr className="h-10 border-2">
                                                    <td>Bitcoin</td>
                                                    <td>BTC</td>
                                                    <td>0.0005</td>
                                                </tr>
                                                <tr className="h-10 border-2">
                                                    <td>Ethereum</td>
                                                    <td>ETH</td>
                                                    <td>0.0029</td>
                                                </tr>
                                                <tr className="h-10 border-2">
                                                    <td>Tether</td>
                                                    <td>USDT ERC20</td>
                                                    <td>17.15</td>
                                                </tr>
                                                <tr className="h-10 border-2">
                                                    <td>Tether</td>
                                                    <td>USDT BEP20</td>
                                                    <td>3</td>
                                                </tr>
                                                <tr className="h-10 border-2">
                                                    <td>Tether</td>
                                                    <td>USDT TRC20</td>
                                                    <td>2</td>
                                                </tr>
                                                <tr className="h-10 border-2">
                                                    <td>Usd Coin</td>
                                                    <td>USDC ERC20</td>
                                                    <td>17.32</td>
                                                </tr>
                                                <tr className="h-10 border-2">
                                                    <td>Usd Coin</td>
                                                    <td>USDC TRC20</td>
                                                    <td>2</td>
                                                </tr>
                                                <tr className="h-10 border-2">
                                                    <td>Binance Coin</td>
                                                    <td>BNB BEP2</td>
                                                    <td>0.003</td>
                                                </tr>
                                                <tr className="h-10 border-2">
                                                    <td>Binance Coin</td>
                                                    <td>BNB BEP20</td>
                                                    <td>0.002</td>
                                                </tr>
                                                <tr className="h-10 border-2">
                                                    <td>Solana</td>
                                                    <td>SOL</td>
                                                    <td>0.02</td>
                                                </tr>
                                                <tr className="h-10 border-2">
                                                    <td>Polkadot</td>
                                                    <td>DOT</td>
                                                    <td>0.23</td>
                                                </tr>
                                                <tr className="h-10 border-2">
                                                    <td>Ripple</td>
                                                    <td>XRP</td>
                                                    <td>1</td>
                                                </tr>
                                                <tr className="h-10 border-2">
                                                    <td>Tron</td>
                                                    <td>TRX</td>
                                                    <td>2</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <p
                                    className="text-lg text-slate-500 mb-8"
                                    data-aos="fade-up"
                                    data-aos-anchor="[data-aos-id-3]"
                                    data-aos-delay="200"
                                >
                                    {translate("OUR_FEE", "NOTE1")}
                                </p>
                                <p
                                    className="text-lg text-slate-500 mb-8"
                                    data-aos="fade-up"
                                    data-aos-anchor="[data-aos-id-3]"
                                    data-aos-delay="200"
                                >
                                    {translate("OUR_FEE", "NOTE2")}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <Section03 />
            <Section04 />
            <Section05 />
            <Section06 />
            <Section07 />
            <Section08 />
            <Faqs /> */}
            <Cta />
        </>
    );
};

export default OurFee;
