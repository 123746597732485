import { useEffect, useMemo } from "react";
import translator from "../../../Components/Extra/Translation/Translate";
import KpButton from "../../Components/Inputs/KpButton";
import KpInput from "../../Components/Inputs/KpInput";
import KpSelect, {
    KpSelectOption,
    createSelectOption,
} from "../../Components/Inputs/KpSelect";
import { useAssets } from "../../Provider/AssetsProvider";
import { useAppProvider } from "../../Provider/AppProvider";
import { FormProvider, useForm } from "react-hook-form";
import { AssetType } from "../../../Models/Crypto";
import { OAssetLtv, UserCryptoWallet } from "../../../Models/UserWallet";
import { useFidelity } from "../../Provider/FidelityProvider";
import UtilsService from "../../../Services/UtilsService";
import { IBorrowing } from "../../../Models/Borrowing";
import alert_message from "../../../Services/Alert/AlertMessage";
import BorrowingService from "../../../Services/Borrowing/BorrowingService";
import { useNavigate } from "react-router-dom";
import RoutesNames from "../../../Services/RoutesNames";

interface BorrowFormValuesType {
    loan_amount: number;
    toBorrow: KpSelectOption | undefined;
    guarantee_amount: number;
    toGuarantee: KpSelectOption | undefined;
    ltv: KpSelectOption | undefined;
}

const BorrowingForm = () => {
    const { translate } = translator();
    const { setProcessing, finalizeProcessing, processing } = useAppProvider();
    const { assets, refreshAssets } = useAssets();
    const { currentLoyalty } = useFidelity();
    const navigate = useNavigate();

    const methods = useForm<BorrowFormValuesType>({
        defaultValues: {
            loan_amount: 0,
            toBorrow: undefined,
            guarantee_amount: 0,
            toGuarantee: undefined,
            ltv: undefined,
        },
    });

    const loan_amount = methods.watch("loan_amount");
    const toBorrow = methods.watch("toBorrow");
    const guarantee_amount = methods.watch("guarantee_amount");
    const toGuarantee = methods.watch("toGuarantee");
    const ltv = methods.watch("ltv");

    const toBorrowOptions = useMemo<KpSelectOption[]>(() => {
        const options: KpSelectOption[] = [];
        for (const asset of assets) {
            if (
                // asset.category?.some(
                //     (elt: any) =>
                //         elt.slug === "stable-coins" ||
                //         elt.slug === "stable-tokens",
                // ) &&
                asset.asset_borrowing &&
                asset.asset_borrowing.status
            ) {
                const option: KpSelectOption = createSelectOption(
                    asset,
                    "crypto",
                );
                options.push(option);
            }
        }

        return options;
    }, [assets]);

    const toGuaranteeOptions = useMemo<KpSelectOption[]>(() => {
        const options: KpSelectOption[] = [];
        for (const asset of assets) {
            if (
                asset.type === AssetType.CRYPTO &&
                !asset.category?.some(
                    (elt: any) =>
                        elt.slug === "stable-coins" ||
                        elt.slug === "stable-tokens",
                ) &&
                asset.can_set_as_guarantee
            ) {
                const option: KpSelectOption = createSelectOption(
                    asset,
                    "crypto",
                );
                options.push(option);
            }
        }

        return options;
    }, [assets]);

    const getInterestValue = (config: OAssetLtv) => {
        let result = config.interest_basic;
        if (currentLoyalty) {
            switch (currentLoyalty.name.toLowerCase()) {
                case "basic":
                    result = config.interest_basic;
                    break;
                case "red":
                    result = config.interest_red;
                    break;
                case "gold":
                    result = config.interest_gold;
                    break;
                case "black":
                    result = config.interest_black;
                    break;
                default:
                    break;
            }
        }

        return result;
    };

    const ltvOptions = useMemo<KpSelectOption[]>(() => {
        const options: KpSelectOption[] = [];
        if (assets && toBorrow) {
            const res = assets.find(
                (elt: UserCryptoWallet) => toBorrow?.value === elt.abbreviation,
            );
            if (res && res.asset_ltv) {
                for (const row of res.asset_ltv) {
                    const option: KpSelectOption = {
                        value: String(row.iltv),
                        label: row.name || "",
                    };
                    options.push(option);
                }
            }
        }

        if (options.length > 0) {
            methods.setValue("ltv", options[0]);
        }

        return options;
    }, [toBorrow, assets]);

    const onSubmited = (data: BorrowFormValuesType) => {
        if (
            processing ||
            !ableToSubmit ||
            !data.toBorrow ||
            !data.toGuarantee ||
            !data.ltv
        )
            return;

        const borrowing: IBorrowing = {
            loan_amount: data.loan_amount,
            to_borrow: data.toBorrow.value,
            guarantee_amount: data.guarantee_amount,
            to_guarantee: data.toGuarantee.value,
            ltv: Number(data.ltv.value),
        };

        setProcessing(true);

        const create = BorrowingService.create(borrowing);
        alert_message
            .promise("Borrowing encours ...", create)
            .then(() => {
                navigate(RoutesNames.Transactions);
            })
            .catch(() => {
                finalizeProcessing();
            });
    };

    const getGuaranteeAmount = () => {
        if (toBorrow && toGuarantee) {
            const borrow = assets.find(
                (elt: UserCryptoWallet) => elt.abbreviation === toBorrow.value,
            );
            const guarantee = assets.find(
                (elt: UserCryptoWallet) =>
                    elt.abbreviation === toGuarantee.value,
            );

            if (borrow && guarantee && ltv) {
                const loan_amount_usd = UtilsService.convertDevise(
                    borrow.current_price,
                    1,
                    loan_amount,
                    0,
                );

                const guarantee_amount_usd =
                    (loan_amount_usd * 100) / Number(ltv.value);

                const new_guarantee_amount = UtilsService.convertDevise(
                    1,
                    guarantee.current_price,
                    guarantee_amount_usd,
                    8,
                );

                methods.setValue("guarantee_amount", new_guarantee_amount);
            }
        }
    };

    const interest = useMemo(() => {
        if (!toBorrow || !ltv) return;
        const res = assets.find(
            (elt: UserCryptoWallet) => toBorrow.value === elt.abbreviation,
        );

        if (!res || !res.asset_ltv) return;
        const config = res.asset_ltv.find(
            (elt: OAssetLtv) => Number(ltv.value) === Number(elt.iltv),
        );
        if (!config) return;
        return getInterestValue(config);
    }, [toBorrow, ltv]);

    useEffect(() => {
        setProcessing(true);
        Promise.all([refreshAssets()]).then(() => {
            finalizeProcessing();
        });
    }, []);

    useEffect(() => {
        getGuaranteeAmount();
    }, [loan_amount, toBorrow, toGuarantee, ltv]);

    const ableToSubmit =
        toBorrow !== undefined &&
        toGuarantee !== undefined &&
        ltv !== undefined &&
        loan_amount > 0 &&
        guarantee_amount > 0 &&
        !processing;

    return (
        <>
            <FormProvider {...methods}>
                <form
                    onSubmit={methods.handleSubmit(onSubmited)}
                    className="col-span-12"
                >
                    <div className="col-span-12">
                        <div className="intro-y box">
                            <div className="flex flex-col border-b border-slate-200/60 pb-5 lg:flex-row relative p-3">
                                <div className="flex-1 px-5 pt-5 lg:mt-0 lg:border-t-0 lg:pt-0">
                                    <div className="mt-2 flex flex-col items-center justify-center lg:items-start gap-3 md:!gap-0">
                                        <div className="intro-y w-full mt-1">
                                            <div className="grid grid-cols-12 gap-2">
                                                <div className="col-span-12 lg:col-span-3 self-center">
                                                    {translate(
                                                        "BORROW",
                                                        "Withdrawal",
                                                    )}
                                                    :
                                                </div>
                                                <div className="col-span-12 lg:col-span-3">
                                                    <KpInput
                                                        label={translate(
                                                            "BORROW",
                                                            "",
                                                        )}
                                                        name="loan_amount"
                                                        type="number"
                                                        options={{
                                                            required: true,
                                                            min: 4,
                                                        }}
                                                        inputProps={{
                                                            required: true,
                                                            min: 4,
                                                        }}
                                                        decimal={0}
                                                        className={`mt-3`}
                                                    />
                                                </div>
                                                <div className="col-span-12 lg:col-span-6">
                                                    <KpSelect
                                                        label={translate(
                                                            "BORROW",
                                                            "",
                                                        )}
                                                        options={
                                                            toBorrowOptions
                                                        }
                                                        id="borrow-wallet"
                                                        name="toBorrow"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="intro-y w-full ">
                                            <div className="grid grid-cols-12 gap-2">
                                                <div className="col-span-12 lg:col-span-3 self-center">
                                                    {translate(
                                                        "BORROW",
                                                        "Guarantee",
                                                    )}
                                                    :
                                                </div>
                                                <div className="col-span-12 lg:col-span-3 ">
                                                    <KpInput
                                                        label={translate(
                                                            "BORROW",
                                                            "",
                                                        )}
                                                        name="guarantee_amount"
                                                        type="number"
                                                        options={{
                                                            required: true,
                                                            min: 4,
                                                        }}
                                                        inputProps={{
                                                            required: true,
                                                            min: 4,
                                                        }}
                                                        decimal={8}
                                                        className={`mt-3`}
                                                        disabled
                                                    />
                                                </div>
                                                <div className="col-span-12 lg:col-span-6">
                                                    <KpSelect
                                                        label={translate(
                                                            "BORROW",
                                                            "",
                                                        )}
                                                        options={
                                                            toGuaranteeOptions
                                                        }
                                                        id="guarantee-wallet"
                                                        name="toGuarantee"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="intro-y w-full ">
                                            <div className="grid grid-cols-12 gap-2">
                                                <div className="col-span-12 lg:col-span-3 self-center ">
                                                    LTV:
                                                </div>
                                                <div className="col-span-12 lg:col-span-9">
                                                    <KpSelect
                                                        label={translate(
                                                            "Swap",
                                                            "",
                                                        )}
                                                        options={ltvOptions}
                                                        id="ltv-id"
                                                        name="ltv"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-6 flex-1 border-l border-b-dark border-slate-200/60 px-5 pt-5 lg:mt-0 lg:border-t-0 lg:pt-0 relative">
                                    <div className="mt-2 flex flex-col items-center justify-center lg:items-start">
                                        <div className="intro-y w-full mt-1">
                                            <div className="grid grid-cols-12 gap-6">
                                                <div className="col-span-6 mt-3">
                                                    {translate(
                                                        "BORROW",
                                                        "Withdrawal",
                                                    )}
                                                    :
                                                </div>
                                                <div className="col-span-6 mt-3">
                                                    {loan_amount && toBorrow
                                                        ? `${loan_amount} ${toBorrow.value}`
                                                        : 0}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="intro-y w-full mt-1">
                                            <div className="grid grid-cols-12 gap-6">
                                                <div className="col-span-6 mt-3">
                                                    {translate(
                                                        "BORROW",
                                                        "Interest",
                                                    )}
                                                    : / APR:
                                                </div>
                                                <div className="col-span-6 mt-3">
                                                    {interest || 0} %
                                                </div>
                                            </div>
                                        </div>
                                        <div className="intro-y w-full mt-1">
                                            <div className="grid grid-cols-12 gap-6">
                                                <div className="col-span-6 mt-3">
                                                    {translate(
                                                        "BORROW",
                                                        "Guarantee",
                                                    )}
                                                    :
                                                </div>
                                                <div className="col-span-6 mt-3">
                                                    {guarantee_amount &&
                                                    toGuarantee
                                                        ? `${guarantee_amount} ${toGuarantee.value}`
                                                        : 0}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="intro-y w-full mt-1">
                                            <div className="grid grid-cols-12 gap-6">
                                                <div className="col-span-6 mt-3">
                                                    LTV:
                                                </div>
                                                <div className="col-span-6 mt-3">
                                                    {ltv?.label || "0 %"}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="intro-y w-full mt-1">
                                            <div className="grid grid-cols-12 gap-6">
                                                <div className="col-span-6 mt-3">
                                                    {translate(
                                                        "BORROW",
                                                        "Due_date",
                                                    )}
                                                    :
                                                </div>
                                                <div className="col-span-6 mt-3">
                                                    1{" "}
                                                    {translate(
                                                        "BORROW",
                                                        "Year",
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mt-3 flex w-full items-center justify-end truncate sm:whitespace-normal md:bottom-0 md:right-[1.25rem]  lg:bottom-0 lg:right-[1.25rem] xl:bottom-0 xl:right-[1.25rem]">
                                        <div>
                                            <KpButton
                                                type="submit"
                                                className={` mb-2 w-32`}
                                                label={translate(
                                                    "BORROW",
                                                    "Get",
                                                )}
                                                disabled={!ableToSubmit}
                                            ></KpButton>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </FormProvider>
        </>
    );
};

export default BorrowingForm;
