import AssetLtv, {
    IAssetBorrowing,
    IAssetLtv,
} from "../../Models/AssetBorrowingConfig";
import Notice, { INotice } from "../../Models/Notice";
import axios from "../AxiosInstance";
import UtilsService from "../UtilsService";

const map = (item: any): AssetLtv => {
    const asset_ltv = new AssetLtv(
        item.iltv,
        item.mcltv,
        item.llltv,
        item.interest_basic,
        item.interest_red,
        item.interest_gold,
        item.interest_black,
    );
    asset_ltv.name = item.name;
    asset_ltv.id = item.id;
    asset_ltv.crypto = item.crypto;

    return asset_ltv;
};

const createAssetLtv = async (payload: IAssetLtv): Promise<any> => {
    return axios
        .post("/asset-borrowing-config/create-asset-ltv", {
            ...payload,
        })
        .then((res) => {
            return Promise.resolve(res.data.message);
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const getAssetLtvByCryptoId = async (id: number): Promise<any> => {
    return axios
        .get(`/asset-borrowing-config/get-all-asset-ltv/${id}`)
        .then(({ data }) => {
            const assets: AssetLtv[] = data.map((item: any) => map(item));

            return assets;
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const deleteAssetLtvById = async (id?: number) => {
    return axios
        .delete(`/asset-borrowing-config/delete-asset-ltv/${id}`)
        .then((res) => Promise.resolve(res.data.message))
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const updateAssetLtv = async (
    id: number,
    payload: IAssetLtv,
): Promise<string> => {
    return axios
        .put(`/asset-borrowing-config/update-asset-ltv/${id}`, {
            ...payload,
        })
        .then((res) => {
            return Promise.resolve(res.data.message);
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const addUpdateAssetBorrowing = async (id: number): Promise<string> => {
    return axios
        .put(`/asset-borrowing-config/${id}`)
        .then((res) => {
            return Promise.resolve(res.data.message);
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const AssetBorrowingConfigService = {
    createAssetLtv,
    getAssetLtvByCryptoId,
    deleteAssetLtvById,
    updateAssetLtv,
    addUpdateAssetBorrowing,
};

export default AssetBorrowingConfigService;
